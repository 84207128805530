import { Injectable } from '@angular/core';
import { ProjectBudgetEntryModel } from '../../../../../../../core/models/project/project-budget.model';

@Injectable({
  providedIn: 'root',
})
export class BudgetEntryService {
  constructor() {}

  public createBudgetEntry(
    value: number,
    valueType: 'costs' | 'hours',
    taskId: number,
    memberId: number,
  ): ProjectBudgetEntryModel {
    const budgetEntry = new ProjectBudgetEntryModel();
    budgetEntry[valueType] = value;
    budgetEntry.memberId = memberId;
    budgetEntry.taskId = taskId;
    return budgetEntry;
  }
}
