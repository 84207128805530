import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { UserOperation } from '../../../../core/enums/user-operation';
import { faHousePersonLeave } from '@fortawesome/pro-light-svg-icons';
import { AbsenceReasonsHttpService } from '../../../../core/state/settings/absence-reasons/absence-reasons-http.service';
import { take } from 'rxjs';
import { ShiveGridComponent } from '../../../../shared/components/form-components/shive-grid/shive-grid.component';
import { combineLatestWith, filter, map, tap } from 'rxjs/operators';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { ShiveDialogService } from '../../../../core/services/controls/shive-dialog.service';
import { AbsenceReasonSettingsDialogComponent } from './dialog/absence-type-settings-dialog/absence-reason-settings-dialog.component';
import { SubSink } from 'subsink';
import { AbsenceReasonsStore } from '../state-and-data-handling/absence-reasons.store';
import { AbsenceReasonModel } from '../../../../core/models/absence-reason/absence-reason.model';
import { cloneDeep } from 'lodash-es';
import { generateDummyId } from '../../../../shared/functions/array-functions';
import { Actions, ofType } from '@ngrx/effects';
import * as fromAbsenceReasonActions from '../../../../core/state/settings/absence-reasons/absence-reasons.actions';

@Component({
  selector: 'app-absence-reason-settings',
  templateUrl: './absence-reason-settings.component.html',
  styleUrls: ['./absence-reason-settings.component.scss'],
})
export class AbsenceReasonSettingsComponent implements AfterViewInit, OnDestroy {
  @ViewChild('gridWrapperComponent') gridWrapperComponent: ShiveGridComponent;
  public absenceReasons: AbsenceReasonModel[];
  public readonly UserOperation = UserOperation;
  public readonly faHousePersonLeave = faHousePersonLeave;
  private readonly subs = new SubSink();

  constructor(
    private readonly absenceReasonsHttpService: AbsenceReasonsHttpService,
    private readonly absenceReasonsStore: AbsenceReasonsStore,
    private readonly actions$: Actions,
    private readonly shiveDialogService: ShiveDialogService,
  ) {}

  ngAfterViewInit(): void {
    this.initialize();
    this.initContextMenuCallbacks();
    this.handlePostResponse();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public upsertAbsenceReason(
    userOperation: UserOperation,
    existingAbsenceReason?: AbsenceReasonModel,
  ): void {
    const dialogRef = this.buildDialog(userOperation);

    const absenceReasonSettingsDialogComponent = dialogRef.content
      .instance as AbsenceReasonSettingsDialogComponent;
    absenceReasonSettingsDialogComponent.userOperation = userOperation;
    if (userOperation === UserOperation.Update) {
      absenceReasonSettingsDialogComponent.absenceReasonName = existingAbsenceReason.name;
      absenceReasonSettingsDialogComponent.isWorkingTime = existingAbsenceReason.isWorkingTime;
    }

    this.subs.sink = dialogRef.result
      .pipe(
        filter((value) => value !== UserOperation.Cancel),
        combineLatestWith(this.absenceReasonsStore.selectAbsenceReasons$.pipe(take(1))),
        take(1),
      )
      .subscribe(([result, allAbsenceReasons]: [AbsenceReasonModel, AbsenceReasonModel[]]) => {
        const clonedAbsenceReasonModel = cloneDeep(result);
        if (userOperation === UserOperation.Create) {
          this.insertAbsenceReason(clonedAbsenceReasonModel, allAbsenceReasons);
        } else if (userOperation === UserOperation.Update) {
          this.updateAbsenceReason(
            clonedAbsenceReasonModel,
            existingAbsenceReason.id,
            allAbsenceReasons,
          );
        } else {
          throw new Error('Illegal user operation.');
        }
        this.absenceReasonsStore.userOperationConducted.next(true);
      });
  }

  private initialize(): void {
    this.absenceReasonsHttpService
      .getAbsenceReasons()
      .pipe(
        tap((absenceReasons) => {
          absenceReasons.forEach((absenceReason) => {
            this.absenceReasonsStore.addAbsenceReason(absenceReason);
          });
        }),
        take(1),
      )
      .subscribe((absenceReasons) => {
        this.absenceReasons = [...absenceReasons];
      });
  }

  private initContextMenuCallbacks(): void {
    this.gridWrapperComponent.contextMenuEditFn = (absenceReason: AbsenceReasonModel) => {
      this.upsertAbsenceReason(UserOperation.Update, absenceReason);
    };

    this.gridWrapperComponent.contextMenuDeleteFn = (absenceReason: AbsenceReasonModel) => {
      this.removeAbsenceReason(absenceReason.id);
    };

    this.gridWrapperComponent.setContextMenuItems();
  }

  private buildDialog(userOperation: UserOperation): DialogRef {
    return this.shiveDialogService.open({
      content: AbsenceReasonSettingsDialogComponent,
      width: 572,
      title:
        userOperation === UserOperation.Create
          ? 'Abwesenheitsgrund hinzufügen'
          : 'Abwesenheitsgrund editieren',
    });
  }

  private insertAbsenceReason(
    newAbsenceReason: AbsenceReasonModel,
    allAbsenceReasons: AbsenceReasonModel[],
  ) {
    newAbsenceReason.id = generateDummyId(allAbsenceReasons);
    this.absenceReasons = [newAbsenceReason, ...this.absenceReasons];
    this.absenceReasonsStore.addAbsenceReason(newAbsenceReason);
  }

  private updateAbsenceReason(
    clonedAbsenceReasonModel: AbsenceReasonModel,
    id: number,
    allAbsenceReasons: AbsenceReasonModel[],
  ) {
    const absenceReasonIndex = allAbsenceReasons.findIndex(
      (absenceReason) => absenceReason.id === id,
    );
    if (absenceReasonIndex < 0) {
      throw new Error('Holiday template could not be found.');
    }

    // Update store
    const updatedAbsenceReason = cloneDeep(allAbsenceReasons[absenceReasonIndex]);
    updatedAbsenceReason.name = clonedAbsenceReasonModel.name;
    updatedAbsenceReason.isWorkingTime = clonedAbsenceReasonModel.isWorkingTime;
    this.absenceReasonsStore.updateAbsenceReason(updatedAbsenceReason);

    // Update grid data
    const oldAbsenceReasons = [...this.absenceReasons];
    const oldAbsenceReasonIndex = oldAbsenceReasons.findIndex(
      (absenceReason) => absenceReason.id === id,
    );
    oldAbsenceReasons[oldAbsenceReasonIndex] = updatedAbsenceReason;
    this.absenceReasons = [...oldAbsenceReasons];
  }

  private removeAbsenceReason(id: number) {
    this.absenceReasonsStore
      .selectSingleAbsenceReason$(id)
      .pipe(take(1))
      .subscribe((absenceReason) => {
        if (!absenceReason) {
          throw new Error(`Absence reason not found. id: ${id}`);
        }

        // Update store
        this.absenceReasonsStore.removeAbsenceReason(absenceReason);
        this.absenceReasonsStore.userOperationConducted.next(true);

        // Update grid data
        const oldAbsenceReasons = [...this.absenceReasons];
        const oldAbsenceReasonIndex = oldAbsenceReasons.findIndex(
          (absenceReason) => absenceReason.id === id,
        );
        oldAbsenceReasons.splice(oldAbsenceReasonIndex, 1);
        this.absenceReasons = [...oldAbsenceReasons];
      });
  }

  private handlePostResponse() {
    this.actions$
      .pipe(
        ofType(fromAbsenceReasonActions.absenceReasonAdded),
        map((response) => response.payload),
      )
      .subscribe((payload) => {
        this.absenceReasonsStore.addAbsenceReason(payload);
      });
  }
}
