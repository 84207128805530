import { createAction, props } from '@ngrx/store';
import { ColorModel } from '../../models/color/color.model';

export const fetchSingleColor = createAction(
  '[Colors] Fetch Single Color',
  props<{ payload: number }>(),
);
export const setSingleColor = createAction(
  '[Colors] Set Single Color',
  props<{ payload: ColorModel }>(),
);

export const fetchColorList = createAction('[Colors] Fetch Colors');
export const setColors = createAction('[Colors] Set Colors', props<{ payload: ColorModel[] }>());

export const addColor = createAction('[Colors] Add Color', props<{ payload: ColorModel }>());
export const colorAdded = createAction('[Colors] Added Color', props<{ payload: ColorModel }>());

export const updateColor = createAction('[Colors] Update Color', props<{ payload: ColorModel }>());
export const colorUpdated = createAction(
  '[Colors] Updated Color',
  props<{ payload: ColorModel }>(),
);

export const deleteColor = createAction('[Colors] Delete Color', props<{ payload: ColorModel }>());
export const colorDeleted = createAction(
  '[Colors] Deleted Color',
  props<{ payload: ColorModel }>(),
);

export const httpFail = createAction('[Colors] Http fail', props<{ payload: string }>());
