import { Injectable } from '@angular/core';
import {
  GeneratedTimeBlockComponent,
  ITimeBlockComponentItem,
} from '../time-block-component-items';
import { CalendarService } from '../../../services/calendar.service';
import {
  ITimeBlockViewType,
  TimeBlockContentType,
} from '../../../../../shared/data-types/time-block-types';
import {
  TimeBlockDayOrWeekFulldayType,
  TimeBlockDayOrWeekInnerdayType,
  TimeBlockMonthFulldayType,
  TimeBlockMonthInnerdayType,
} from '../../../../../core/models/timeblock/time-block-view-type.model';
import { clone } from 'lodash-es';
import { TimeBlockModel } from '../../../../../core/models/timeblock/time-block.model';
import { TimeBlockGeometryData } from '../../../../../core/models/timeblock/time-block-geometry-data.model';
import { DateTimeHelper } from '../../../util/date-time-helper';
import { TimeBlockHttpTransformationService } from '../http/time-block-http-transformation.service';
import { CalendarView } from '../../../../../shared/data-types/calendar-types';

@Injectable()
export class TimeBlockItemBuilderService {
  constructor(private readonly calendarService: CalendarService) {}

  public buildTimeBlockParts(
    timeBlock: ITimeBlockComponentItem,
  ): readonly ITimeBlockComponentItem[] {
    const [start, end] = this.getTimeBlockStartAndEndDates(timeBlock);
    const timeBlockParts = new Array<ITimeBlockComponentItem>();

    const occupiedDays = DateTimeHelper.eachDayOfInterval(start, end);
    occupiedDays.forEach((day, index) => {
      const clonedTimeBlock = timeBlock.clone(this);
      clonedTimeBlock.timeBlockModel.partNumber = index;
      clonedTimeBlock.timeBlockModel.partCount = occupiedDays.length;
      timeBlockParts.push(clonedTimeBlock);
    });

    return timeBlockParts;
  }

  public buildFromExisting(
    timeBlock: ITimeBlockComponentItem | TimeBlockModel,
    timeBlockContentType = TimeBlockContentType.Project,
  ): ITimeBlockComponentItem {
    let tbModel: TimeBlockModel;
    if (timeBlock instanceof TimeBlockModel) {
      tbModel = clone(timeBlock);
    } else {
      tbModel = clone(timeBlock.timeBlockModel);
    }

    let timeBlockViewType: ITimeBlockViewType;
    if (
      this.calendarService.model.calendarViewMode === CalendarView.DayGrid ||
      this.calendarService.model.calendarViewMode === CalendarView.WeekGrid
    ) {
      timeBlockViewType = tbModel.isFullday
        ? new TimeBlockDayOrWeekFulldayType()
        : new TimeBlockDayOrWeekInnerdayType();
    } else {
      timeBlockViewType = tbModel.isFullday
        ? new TimeBlockMonthFulldayType()
        : new TimeBlockMonthInnerdayType();
    }

    if (!tbModel.timeBlockViewType) {
      tbModel.timeBlockViewType = timeBlockViewType;
    } else {
      Object.assign(timeBlockViewType, tbModel.timeBlockViewType);
      tbModel.timeBlockViewType = timeBlockViewType;
    }

    if (tbModel.componentRef?.instance.timeBlockComponentItem) {
      tbModel.componentRef.instance.timeBlockComponentItem.timeBlockModel = tbModel;
    }

    // For non-active time blocks, we transform the end date to the previous date.
    // For active time blocks, we take the end time as is.
    if (!tbModel.isActive) {
      tbModel.end = TimeBlockHttpTransformationService.transformEndDate(tbModel.end);
    }
    return new GeneratedTimeBlockComponent(tbModel, timeBlockContentType);
  }

  /**
   * Used to build ghost time blocks
   */
  public buildDefaultTimeBlock(
    start: Date,
    end: Date,
    containerId: number,
    isInnerday: boolean,
    timeBlockContentType: TimeBlockContentType,
  ): ITimeBlockComponentItem {
    const timeBlockModel = new TimeBlockModel();
    let timeBlockViewTyp:
      | TimeBlockDayOrWeekFulldayType
      | TimeBlockDayOrWeekInnerdayType
      | TimeBlockMonthFulldayType;
    if (
      this.calendarService.model.calendarViewMode === CalendarView.DayGrid ||
      this.calendarService.model.calendarViewMode === CalendarView.WeekGrid
    ) {
      if (isInnerday) {
        timeBlockViewTyp = new TimeBlockDayOrWeekInnerdayType();
        timeBlockViewTyp.laneIndex = containerId;
      } else {
        timeBlockViewTyp = new TimeBlockDayOrWeekFulldayType();
        timeBlockViewTyp.laneIndexStart = containerId;
        timeBlockViewTyp.laneIndexEnd = containerId;
      }
      timeBlockViewTyp.geometryData = new TimeBlockGeometryData();
    } else {
      // Month view
      timeBlockViewTyp = new TimeBlockMonthFulldayType();
    }

    timeBlockModel.timeBlockViewType = timeBlockViewTyp;

    const timeBlock = new GeneratedTimeBlockComponent(timeBlockModel, timeBlockContentType);
    timeBlock.timeBlockModel.start = start;
    timeBlock.timeBlockModel.end = end;
    timeBlock.timeBlockModel.isFullday = !isInnerday;

    return timeBlock;
  }

  public getTimeBlockStartAndEndDates(timeBlock: ITimeBlockComponentItem): [Date, Date] {
    const offsetStartDate = this.calendarService.model.calendarProperties.offsetStartDate;
    const offsetEndDate = this.calendarService.model.calendarProperties.offsetEndDate;

    const start = DateTimeHelper.isBefore(timeBlock.timeBlockModel.start, offsetStartDate)
      ? offsetStartDate
      : timeBlock.timeBlockModel.start;

    const end = DateTimeHelper.isAfter(timeBlock.timeBlockModel.end, offsetEndDate)
      ? offsetEndDate
      : timeBlock.timeBlockModel.end;
    return [start, end];
  }
}
