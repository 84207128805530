import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { BudgetOptionLiterals } from '../../../../../../../core/text-db/project-calculation.text';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { mapToDropdownOptions } from '../../../../../../../shared/functions/dropdown-functions';
import { DropdownOption } from '../../../../../../../shared/data-types/dropdown-option';
import { take } from 'rxjs';
import { getEnumKeyByEnumValue } from '../../../../../../../shared/functions/enum-functions';
import { BudgetOptions } from 'src/app/core/data-repository/dropdown-constants';
import { ProjectModel } from '../../../../../../../core/models/project/project.model';
import { ProjectService } from '../../../../../services/project.service';

@Component({
  selector: 'app-project-calculation-form-budget',
  templateUrl: './project-calculation-form-budget.component.html',
  styleUrls: ['./project-calculation-form-budget.component.scss'],
})
export class ProjectCalculationFormBudgetComponent implements OnInit, OnChanges {
  @Input() toggleHidden: boolean;
  @Output() readonly selectedBudgetOptionChanged = new EventEmitter();
  public budgetForm: FormGroup;
  public budgetOptions: DropdownOption[];
  public selectedBudgetOption: BudgetOptions;
  public readonly BudgetOptions = BudgetOptions;
  private currentlyHidden = false;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly projectService: ProjectService,
  ) {}

  ngOnInit(): void {
    const projectClone = this.projectService.getProjectClone();
    this.selectedBudgetOption = projectClone.budgetType;
    this.initEvents(projectClone);
  }

  ngOnChanges(): void {
    if (!this.toggleHidden && this.toggleHidden !== this.currentlyHidden && this.budgetForm) {
      const option = this.budgetOptions[0];
      this.budgetForm.controls.budgetType.patchValue(option);
      window.setTimeout(() => {
        this.budgetOptionChanged(option);
      });
    }
    this.currentlyHidden = this.toggleHidden;
  }

  public budgetOptionChanged($event: DropdownOption): void {
    const enumOption = getEnumKeyByEnumValue(BudgetOptions, $event.value.toString());
    this.budgetForm.controls.budgetValue.patchValue(0);
    this.selectedBudgetOption = BudgetOptions[enumOption];

    const clonedProject = this.projectService.getProjectClone();
    clonedProject.projectBudgetEntries = [];

    if (this.selectedBudgetOption === BudgetOptions.TotalProjectFees) {
      clonedProject.projectBudgetEntries = [{ costs: 0, hours: null }];
    } else if (this.selectedBudgetOption === BudgetOptions.TotalProjectHours) {
      // Total project hours
      clonedProject.projectBudgetEntries = [{ costs: null, hours: 0 }];
    }

    clonedProject.budgetType = this.selectedBudgetOption;
    this.projectService.updateProject(clonedProject);
    this.selectedBudgetOptionChanged.next(this.selectedBudgetOption);
  }

  public budgetValueChanged(value: string): void {
    const projectClone = this.projectService.getProjectClone();
    if (this.selectedBudgetOption === BudgetOptions.TotalProjectFees) {
      const projectFees = +value;
      projectClone.projectBudgetEntries = [{ costs: projectFees, hours: null }];
    } else if (this.selectedBudgetOption === BudgetOptions.TotalProjectHours) {
      const projectHours = +value;
      projectClone.projectBudgetEntries = [{ costs: null, hours: projectHours }];
    }

    this.projectService.updateProject(projectClone);
    this.projectService.userOperationConducted$.next(true);
  }

  private initEvents(project: ProjectModel): void {
    mapToDropdownOptions(BudgetOptionLiterals)
      .pipe(take(1))
      .subscribe((budgetOptions) => {
        this.budgetOptions = budgetOptions;
        const selectedOption = budgetOptions.find((option) => option.value === project.budgetType);

        let budget = 0;
        if (this.selectedBudgetOption === BudgetOptions.TotalProjectFees) {
          budget = project.projectBudgetEntries[0].costs;
        } else if (this.selectedBudgetOption === BudgetOptions.TotalProjectHours) {
          budget = project.projectBudgetEntries[0].hours;
        }

        this.budgetForm = this.formBuilder.group({
          budgetType: [selectedOption],
          budgetValue: [budget],
        });
      });
  }
}
