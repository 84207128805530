import { ColorModel } from '../../models/color/color.model';
import { createReducer, on } from '@ngrx/store';
import {
  colorAdded,
  colorDeleted,
  colorUpdated,
  setColors,
  setSingleColor,
} from './colors.actions';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

export interface ColorState extends EntityState<ColorModel> {}

export const colorAdapter: EntityAdapter<ColorModel> = createEntityAdapter<ColorModel>({
  selectId: (entity: ColorModel) => entity.id,
  sortComparer: false,
});
const initialState: ColorState = colorAdapter.getInitialState();

export const colorsReducer = createReducer(
  initialState,
  on(setColors, (state, { payload }): ColorState => {
    return colorAdapter.setAll(payload, state);
  }),
  on(setSingleColor, (state, { payload }): ColorState => {
    return colorAdapter.setOne(payload, state);
  }),
  on(colorAdded, (state, { payload }): ColorState => {
    if (Array.isArray(payload)) {
      return colorAdapter.addMany(payload, state);
    }
    return colorAdapter.addOne(payload, state);
  }),
  on(colorUpdated, (state, { payload }): ColorState => {
    if (Array.isArray(payload)) {
      return colorAdapter.upsertMany(payload, state);
    }
    return colorAdapter.upsertOne(payload, state);
  }),
  on(colorDeleted, (state, { payload }): ColorState => {
    if (Array.isArray(payload)) {
      return colorAdapter.removeMany(
        payload.map((t) => t.id),
        state,
      );
    }
    return colorAdapter.removeOne(payload.id, state);
  }),
);
