import { ComponentRef, Injectable } from '@angular/core';
import { TimeBlockComponent } from '../time-block.component';
import { CalendarService } from '../../../services/calendar.service';
import { ITimeBlockComponentItem } from '../time-block-component-items';
import { TimeBlockItemBuilderService } from './time-block-item-builder.service';
import {
  CompoundTimeBlockViewType,
  TimeBlockContentType,
  TimeBlockHookContainer,
} from '../../../../../shared/data-types/time-block-types';
import { DayOrWeekCalendarModel } from '../../../../../core/models/calendar/day-or-week-calendar.model';
import {
  TimeBlockDayOrWeekFulldayType,
  TimeBlockDayOrWeekInnerdayType,
  TimeBlockMonthFulldayType,
  TimeBlockMonthInnerdayType,
} from '../../../../../core/models/timeblock/time-block-view-type.model';
import { MonthCalendarModel } from '../../../../../core/models/calendar/month-calendar.model';
import { TimeBlockHttpTransformationService } from '../http/time-block-http-transformation.service';
import { DateTimeHelper } from '../../../util/date-time-helper';
import { CalendarView } from '../../../../../shared/data-types/calendar-types';
import { dropdownOptionToEntity } from '../../../../../shared/functions/dropdown-functions';
import { DropdownOption } from '../../../../../shared/data-types/dropdown-option';
import {
  TimeBlockAbsenceModel,
  TimeBlockAppointmentModel,
  TimeBlockModel,
  TimeBlockProjectModel,
} from '../../../../../core/models/timeblock/time-block.model';

@Injectable()
export class TimeBlockComponentHandlerService {
  private timeBlockComponentBuilder: TimeBlockComponentFactory;

  constructor(
    private readonly timeBlockItemBuilderService: TimeBlockItemBuilderService,
    private readonly calendarService: CalendarService,
  ) {}

  public static hydrateTimeBlockModel(
    timeBlockItem: ITimeBlockComponentItem,
    controls,
  ): TimeBlockModel {
    const timeBlockModel = timeBlockItem.timeBlockModel;

    const startTime = DateTimeHelper.setSecondsAndMillisToZero(controls.timeFrom.value);

    const endTime = DateTimeHelper.setSecondsAndMillisToZero(controls.timeTo.value);

    const startDate = DateTimeHelper.mergeDateAndTime(controls.dateFrom.value, startTime);
    const endDate = DateTimeHelper.mergeDateAndTime(controls.dateTo.value, endTime);

    const timeBlockContentType = timeBlockItem.timeBlockContentType;
    let updatedTimeBlockModel: any;

    if (timeBlockContentType === TimeBlockContentType.Project) {
      updatedTimeBlockModel = new TimeBlockProjectModel();
      Object.assign(updatedTimeBlockModel, timeBlockModel);

      updatedTimeBlockModel.task = controls.task.value?.value
        ? dropdownOptionToEntity(controls.task.value as DropdownOption)
        : null;
      updatedTimeBlockModel.text = controls.description.value;
    } else if (timeBlockContentType === TimeBlockContentType.Absence) {
      updatedTimeBlockModel = new TimeBlockAbsenceModel();
      Object.assign(updatedTimeBlockModel, timeBlockModel);

      updatedTimeBlockModel.reason = dropdownOptionToEntity(
        controls.reason.value as DropdownOption,
      );
    } else if (timeBlockContentType === TimeBlockContentType.Appointment) {
      updatedTimeBlockModel = new TimeBlockAppointmentModel();
      Object.assign(updatedTimeBlockModel, timeBlockModel);
    } else {
      throw new Error('Content type not supported.');
    }

    updatedTimeBlockModel.start = startDate;
    updatedTimeBlockModel.end = endDate;
    updatedTimeBlockModel.isFullday = controls.fullday.value;
    return updatedTimeBlockModel;
  }

  public get TimeBlockComponentBuilder(): TimeBlockComponentFactory {
    if (!this.timeBlockComponentBuilder) {
      this.timeBlockComponentBuilder = new TimeBlockComponentFactory(
        this.timeBlockItemBuilderService,
        this.calendarService,
      );
    }
    return this.timeBlockComponentBuilder;
  }

  /**
   * Don't call this method directly. Use the updateInCalendar() method of the time block instead.
   */
  public destroyComponent(comp: ITimeBlockComponentItem): void {
    if (!comp.timeBlockModel.componentRef) {
      return;
    }
    const componentRef = comp.timeBlockModel.componentRef;
    componentRef.destroy();
    componentRef.instance.cd.detectChanges();
  }
}

export interface ITimeBlockComponentBuilder {
  buildComponent: (comp: ITimeBlockComponentItem) => ComponentRef<TimeBlockComponent>;
}

class TimeBlockComponentFactory implements ITimeBlockComponentBuilder {
  constructor(
    private readonly timeBlockItemBuilderService: TimeBlockItemBuilderService,
    private calService: CalendarService,
  ) {}

  public buildComponent(timeBlock: ITimeBlockComponentItem): ComponentRef<TimeBlockComponent> {
    const [start, end] = this.timeBlockItemBuilderService.getTimeBlockStartAndEndDates(timeBlock);
    timeBlock.timeBlockModel.timeBlockViewType = this.updateTimeBlockViewType(
      timeBlock,
      start,
      end,
    );

    // If this time block is a fullday time block in the day, week or month view, don't render non-head parts.
    if (this.isHiddenFulldayTimeBlockPart(timeBlock)) {
      // Also remove the component ref.
      timeBlock.timeBlockModel.componentRef = null;
      return;
    }

    const activeViewContainer = this.getViewContainer(timeBlock);

    if (!activeViewContainer) {
      throw new Error(`View container is undefined for time block ${timeBlock}`);
    }
    const componentRef = activeViewContainer.vcr.createComponent(TimeBlockComponent);
    timeBlock.timeBlockModel.componentRef = componentRef;

    // / Clone the original model and assign it
    const clonedComponent = timeBlock.clone(this.timeBlockItemBuilderService);
    timeBlock.timeBlockModel = clonedComponent.timeBlockModel;
    componentRef.instance.timeBlockComponentItem = timeBlock;

    const newTimeBlockModel = componentRef.instance.timeBlockComponentItem.timeBlockModel;

    // For non-active time blocks: If the end time is 00:00:00,
    // we have to use the previous day and use the end time of this day(i.e. 23:59:59.99999).
    // For active time blocks, we take the end time as is.
    if (!newTimeBlockModel.isActive) {
      newTimeBlockModel.end = TimeBlockHttpTransformationService.transformEndDate(
        newTimeBlockModel.end,
      );
    }

    // / Add component to the view container
    activeViewContainer.vcr.insert(componentRef.hostView);
    activeViewContainer.cd.detectChanges();

    return componentRef;
  }

  public set CalendarService(value: CalendarService) {
    this.calService = value;
  }

  /**
   * Check if time block part is a full day time block part and is not visible in the calendar view.
   */
  public isHiddenFulldayTimeBlockPart(timeBlockComponent: ITimeBlockComponentItem): boolean {
    const timeBlockModel = timeBlockComponent.timeBlockModel;
    const calendarViewType = this.calService.model.calendarViewMode;
    const calendarModel = this.calService.model;

    if (
      timeBlockModel.isFullday &&
      calendarViewType === CalendarView.WeekGrid &&
      timeBlockModel.partNumber > 0
    ) {
      return true;
    } else if (timeBlockModel.isFullday && calendarViewType === CalendarView.MonthGrid) {
      const timeBlockHeadDate = DateTimeHelper.isBefore(
        timeBlockModel.start,
        calendarModel.calendarProperties.visibleStartDate,
      )
        ? calendarModel.calendarProperties.visibleStartDate
        : timeBlockModel.start;
      const currentDate = DateTimeHelper.addDays(timeBlockHeadDate, timeBlockModel.partNumber);

      const currentStartOfWeek = DateTimeHelper.startOfWeek(currentDate, {
        weekStartsOn: calendarModel.calendarProperties.weekStartsOn,
      });

      const start = DateTimeHelper.isBefore(timeBlockModel.start, currentStartOfWeek)
        ? currentStartOfWeek
        : timeBlockModel.start;
      return !DateTimeHelper.isSameDay(start, currentDate);
    }
    return false;
  }

  private getViewContainer(timeBlock: ITimeBlockComponentItem): TimeBlockHookContainer {
    const timeBlockViewType = timeBlock.timeBlockModel.timeBlockViewType;
    const calendarModel = this.calService.model as DayOrWeekCalendarModel | MonthCalendarModel;

    if (
      timeBlockViewType instanceof TimeBlockDayOrWeekFulldayType ||
      timeBlockViewType instanceof TimeBlockMonthFulldayType
    ) {
      const containerIndex = this.calService.getContainerIndexByTimeBlock(timeBlock);
      return calendarModel.fulldayCalendarModel.timeBlockContainers[containerIndex];
    } else if (timeBlockViewType instanceof TimeBlockDayOrWeekInnerdayType) {
      const containerIndex = this.calService.getContainerIndexByTimeBlock(timeBlock);
      return calendarModel.timeBlockContainers[containerIndex];
    }
    const containerIndex = this.calService.getContainerIndexByTimeBlock(timeBlock);
    return calendarModel.timeBlockContainers[containerIndex];
  }

  private updateTimeBlockViewType(
    timeBlockComponentItem: ITimeBlockComponentItem,
    timeBlockStart: Date,
    timeBlockEnd: Date,
  ): CompoundTimeBlockViewType {
    const tbModel = timeBlockComponentItem.timeBlockModel;
    let updatedTimeBlockViewType: CompoundTimeBlockViewType;
    const calendarOffsetStart = this.calService.model.calendarProperties.offsetStartDate;

    if (
      tbModel.timeBlockViewType instanceof TimeBlockDayOrWeekFulldayType ||
      tbModel.timeBlockViewType instanceof TimeBlockMonthFulldayType
    ) {
      updatedTimeBlockViewType =
        tbModel.timeBlockViewType instanceof TimeBlockDayOrWeekFulldayType
          ? new TimeBlockDayOrWeekFulldayType()
          : new TimeBlockMonthFulldayType();
      Object.assign(updatedTimeBlockViewType, tbModel.timeBlockViewType);
      updatedTimeBlockViewType.laneIndexStart = DateTimeHelper.differenceInDays(
        timeBlockStart,
        calendarOffsetStart,
      );
      updatedTimeBlockViewType.laneIndexEnd = DateTimeHelper.differenceInDays(
        timeBlockEnd,
        calendarOffsetStart,
      );
    } else if (
      tbModel.timeBlockViewType instanceof TimeBlockDayOrWeekInnerdayType ||
      tbModel.timeBlockViewType instanceof TimeBlockMonthInnerdayType
    ) {
      updatedTimeBlockViewType =
        tbModel.timeBlockViewType instanceof TimeBlockDayOrWeekInnerdayType
          ? new TimeBlockDayOrWeekInnerdayType()
          : new TimeBlockMonthInnerdayType();
      Object.assign(updatedTimeBlockViewType, tbModel.timeBlockViewType);
      const timeBlockPartDate = DateTimeHelper.addDays(timeBlockStart, tbModel.partNumber);
      updatedTimeBlockViewType.laneIndex = DateTimeHelper.differenceInDays(
        timeBlockPartDate,
        calendarOffsetStart,
      );
    } else {
      throw new Error('Time block view type not supported.');
    }

    return updatedTimeBlockViewType;
  }
}
