import interact from 'interactjs';
import { InteractHandlerService } from '../../../interact-handler.service';
import { SnapOptions } from '@interactjs/modifiers/snap/pointer';
import { DropEvent } from '@interactjs/actions/drop/DropEvent';
import { BaseCalendarModel } from '../../../../../../../../../core/models/calendar/base-calendar.model';
import Autobind from '../../../../../../../../../shared/typescript-decorators/autobind.decorator';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DropzoneOptions } from '@interactjs/actions/drop/plugin';
import { ITimeBlockComponentItem } from '../../../../../time-block-component-items';
import { InteractCallbackFns } from '../../../../../../../../../shared/data-types/interact.types';
import { DragPart } from '../../drag-part';
import { TimeBlockItemBuilderService } from '../../../../../generation/time-block-item-builder.service';
import { HorizontalDragTolerance } from '../../../../../../../../../core/data-repository/css-constants';
import { CalendarServiceHelper } from '../../../../../../../services/calendar-service-helper';
import { CalendarGeometryService } from '../../../../../../../services/calendar-geometry.service';
import { TimeBlockMonthInnerdayType } from '../../../../../../../../../core/models/timeblock/time-block-view-type.model';

@Injectable()
export class DragAndDropHandlerService {
  public sourceContainerId: number;
  public targetContainerId: number;
  public dragStarted$ = new Subject<DragPart>();

  private calendarModel: BaseCalendarModel;
  private timeBlockComponent: ITimeBlockComponentItem;

  constructor(
    private readonly timeBlockItemBuilderService: TimeBlockItemBuilderService,
    private readonly calendarGeometryService: CalendarGeometryService,
    private readonly interactHandlerService: InteractHandlerService,
  ) {}

  /**
   * Initialize drag and its corresponding event handler. Restriction of vertical time blocks is done via drag function.
   * Remark: Snapping does not work for horizontal time blocks since the onDragEnter callback is not triggered because
   * the 'overlap' property in the initDrop() function cannot be set correctly to fit our purposes.
   * Furthermore, month view inner day time blocks are also snapped manually in the drag() function.
   */
  public initDrag(
    calendarModel: BaseCalendarModel,
    tbComponent: ITimeBlockComponentItem,
    dragPart: DragPart,
    callbackFns?: InteractCallbackFns,
  ): void {
    this.calendarModel = calendarModel;
    this.timeBlockComponent = tbComponent.clone(this.timeBlockItemBuilderService);
    const timeBlockModel = this.timeBlockComponent.timeBlockModel;

    const targetFn =
      timeBlockModel.isFullday ||
      timeBlockModel.timeBlockViewType instanceof TimeBlockMonthInnerdayType
        ? null
        : this.setTargetForVerticalBlock;
    const snapOptions: SnapOptions = {
      targets: [targetFn],
      relativePoints: [{ x: 0, y: 0 }],
      endOnly: false,
      range: Infinity,
      offset: null,
      origin: null,
    };

    this.interactHandlerService.makeMovable(tbComponent, dragPart, snapOptions, null, callbackFns);
  }

  /**
   * Initialize drop and its corresponding event handler (only for inner day time blocks).
   */
  public initDrop(): void {
    const dropZoneOptions: DropzoneOptions = {
      overlap: this.timeBlockComponent.timeBlockModel.isFullday ? null : 0.5,
    };

    const onDropActivate = (event: DropEvent) => {
      event.target.classList.add('drop-active');
    };

    const onDragEnter = (event: DropEvent) => {
      const draggableElement = event.relatedTarget;
      const dropzoneElement = event.target;

      // feedback the possibility of a drop
      dropzoneElement.classList.add('drop-target');
      draggableElement.classList.add('can-drop');

      // set the target container id (only for inner day time blocks)
      if (
        !this.timeBlockComponent.timeBlockModel.isFullday &&
        this.targetContainerId !== +event.target.id
      ) {
        // Check for vertical lane switch
        this.targetContainerId = +event.target.id;
      }

      this.calcDropCoordinate(event);
    };

    // Todo: This function takes a lot of CPU power. I don't know why yet.
    const onDragLeave = (event: DropEvent) => {
      // remove the drop feedback style
      event.target.classList.remove('drop-target');
      event.relatedTarget.classList.remove('can-drop');
      event.relatedTarget.classList.remove('drop-ok');
    };

    const onDrop = (event: DropEvent) => {
      event.relatedTarget.classList.add('drop-ok');
      // set the target container id only for vertical time blocks since for horizontal ones
      // we set them in the horizontal drag handler.
      if (!this.timeBlockComponent.timeBlockModel.isFullday) {
        this.targetContainerId = +event.target.id;
      }
      InteractHandlerService.dropCoordinate = null;
    };

    const onDropDeactivate = (event: DropEvent) => {
      event.target.classList.remove('drop-active');
      event.target.classList.remove('drop-target');
    };

    this.interactHandlerService.makeDroppable(
      '.dropzone',
      dropZoneOptions,
      onDragEnter,
      onDragLeave,
      onDropActivate,
      onDrop,
      onDropDeactivate,
    );
  }

  private calcDropCoordinate(event?: Interact.DragEvent | Interact.DropEvent): void {
    if (!event) {
      return;
    }

    const laneElement = event.currentTarget as Interact.Element as HTMLElement;
    const leftBorderWidth = this.calendarGeometryService.getLaneLeftBorderWidth(laneElement);
    const dropRect = interact.getElementRect(laneElement);
    // Immediately set the correct drop coordinate to prevent flickering when drag starts
    InteractHandlerService.dropCoordinate = {
      x: Math.round(dropRect.left) + leftBorderWidth,
    };
  }

  @Autobind
  private setTargetForVerticalBlock(x, y): unknown {
    if (!InteractHandlerService.dropCoordinate || !this.calendarModel) {
      return;
    }

    let range = HorizontalDragTolerance;
    const leftBound = this.calendarModel.geometryData.calendarBodyOffsetLeft;

    // Calculate right bound
    const lastLane = document.querySelector('.item-container .lane.last');
    const lastLaneWidth = (lastLane as HTMLElement).offsetWidth;
    const calOffsetWidth = this.calendarModel.geometryData.calendarBodyWidth;
    const rightBound = leftBound + calOffsetWidth - lastLaneWidth;

    // Get lane width
    const laneWidth = this.calendarGeometryService.getDayOrWeekInnerdayLaneWidth();
    // Check if time block stays within the calendar boundaries. Otherwise, snap it to nearest drop coordinate.
    if (x < leftBound - laneWidth + range) {
      range = Infinity;
      InteractHandlerService.dropCoordinate.x = leftBound - laneWidth;
      this.targetContainerId =
        CalendarServiceHelper.getFirstVisibleLaneIndex(this.calendarModel) - 1;
    } else if (x > rightBound + laneWidth - range) {
      range = Infinity;
      InteractHandlerService.dropCoordinate.x = rightBound + laneWidth;
      this.targetContainerId =
        CalendarServiceHelper.getLastVisibleLaneIndex(this.calendarModel) + 1;
    }
    return {
      x: InteractHandlerService.dropCoordinate.x,
      y,
      range,
    };
  }
}
