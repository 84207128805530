import { Injectable } from '@angular/core';
import { ProjectModel } from '../../../../../core/models/project/project.model';
import { BillingEntry, TaskGridRow } from '../../../../../shared/data-types/project-types';
import { ProjectTaskModel } from '../../../../../core/models/project/project-task.model';
import { UserModel } from '../../../../../core/models/user/user.model';
import { StatusModel } from '../../../../../core/models/project/status.model';
import { ProjectService } from '../../../services/project.service';
import { ProjectBillingBudgetTransformerService } from './project-billing-budget-transformer.service';
import {
  BillableRateOptions,
  BudgetOptions,
} from '../../../../../core/data-repository/dropdown-constants';
import { assert } from '../../../../../core/assert/assert';
import { UserService } from '../../../../../core/services/users/user.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectTaskService {
  constructor(
    private readonly projectService: ProjectService,
    private readonly projectBillingBudgetTransformer: ProjectBillingBudgetTransformerService,
  ) {}

  public createTask(
    id: number,
    taskName: string,
    projectId: number,
    status: StatusModel,
    assignee: UserModel,
  ): ProjectTaskModel {
    const newTask = new ProjectTaskModel();
    newTask.id = id;
    newTask.name = taskName;
    newTask.projectId = projectId;
    newTask.assignee = assignee;
    newTask.status = status;
    return newTask;
  }

  public removeTask(task: ProjectTaskModel): void {
    const projectClone = this.projectService.getProjectClone();
    const index = projectClone.tasks.findIndex((existingTask) => existingTask.id === task.id);

    assert(index >= 0, 'Project task could not be found.');
    projectClone.tasks = projectClone.tasks.toSpliced(index, 1);
    this.projectService.updateProject(projectClone);
  }

  public removeTaskGridRow(taskGridRows: TaskGridRow[], toBeDeletedRow: TaskGridRow): void {
    const index = taskGridRows.findIndex((row) => row.task.id === toBeDeletedRow.task.id);
    assert(index >= 0, 'Project task could not be found.');
    taskGridRows.splice(index, 1);
  }

  public resetBilling(gridData: TaskGridRow[]): void {
    gridData.forEach((row, index) => {
      row.billingRateHistory = [
        {
          id: index,
          startingFrom: this.projectService.getProjectClone().startsAt,
          billingRate: 0,
        },
      ];
    });
  }

  public resetBudget(gridData: TaskGridRow[]): void {
    gridData.forEach((row) => {
      row.budgetHours = 0;
      row.budgetCosts = 0;
    });
  }

  public syncProjectModel(gridData: TaskGridRow[], projectClone: ProjectModel): void {
    const costsPerTask = projectClone.budgetType === BudgetOptions.CostsPerTask;
    const [billingEntries, budgetEntries] =
      this.projectBillingBudgetTransformer.transformTaskGridDataToBillingAndBudgetEntries(
        gridData,
        costsPerTask,
      );
    if (
      projectClone.billingType === BillableRateOptions.BillableHourlyValuePerTask ||
      projectClone.billingType === BillableRateOptions.BillableValuePerTask
    ) {
      projectClone.projectBillingEntries = billingEntries;
    }

    if (
      projectClone.budgetType === BudgetOptions.HoursPerTask ||
      projectClone.budgetType === BudgetOptions.CostsPerTask
    ) {
      projectClone.projectBudgetEntries = budgetEntries;
    }

    projectClone.tasks = gridData.map((row) => row.task);
    this.projectService.updateProject(projectClone);
  }

  public getHistoryByTaskId(gridData: TaskGridRow[], taskId: number): BillingEntry[] {
    const taskGridRow = gridData.find((x) => x.task.id === taskId);
    assert(taskGridRow !== undefined);
    return taskGridRow.billingRateHistory;
  }

  public setHistoryForTaskId(
    gridData: TaskGridRow[],
    taskId: number,
    billingRateHistory: BillingEntry[],
  ): void {
    const taskGridRow = gridData.find((x) => x.task.id === taskId);
    assert(taskGridRow !== undefined);
    taskGridRow.billingRateHistory = billingRateHistory;
  }

  public setUnassignedUserForRemovedAssignee(gridData: TaskGridRow[], assigneeId: number): void {
    if (assigneeId === null) {
      return;
    }
    const unassignedUserModel = UserService.buildUnassignedUser();
    const taskGridRows = gridData.filter((x) => x.task.assignee.id === assigneeId);
    taskGridRows.forEach((taskGridRow) => (taskGridRow.task.assignee = unassignedUserModel));

    this.syncProjectModel(gridData, this.projectService.getProjectClone());
  }
}
