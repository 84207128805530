export function generateDummyId<T extends { id: number }>(allItems: T[]): number {
  if (allItems.length === 0) {
    return -1;
  }

  const elementWithLowestId = allItems.reduce(function (prev, curr) {
    return prev.id < curr.id ? prev : curr;
  });

  return elementWithLowestId.id >= 0 ? -1 : elementWithLowestId.id - 1;
}
