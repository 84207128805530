export enum DragPart {
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
  Body = 'body',
  None = 'none',
  TopOrBottom = 'topOrBottom', // Applied when a new time block is created
  LeftOrRight = 'leftOrRight', // Applied when a new time block is created
}

export enum CardinalPoints {
  None,
  North,
  South,
  West,
  East,
}

export type DragDirection = {
  horizontal: CardinalPoints.West | CardinalPoints.East | CardinalPoints.None;
  vertical: CardinalPoints.North | CardinalPoints.South | CardinalPoints.None;
};
