import { Injectable } from '@angular/core';
import { IDragTimeBlock } from '../../../../../../../../../shared/data-types/time-block-types';
import Autobind from '../../../../../../../../../shared/typescript-decorators/autobind.decorator';
import { AbstractDragController } from '../drag-and-drop/abstract-drag-controller';
import { DragDropPositioningService } from '../drag-and-drop/drag-drop-positioning.service';
import { TimeBlockCrudService } from '../../../../../crud/time-block-crud.service';
import { TimeBlockStructureService } from '../../../../../time-block-structure/time-block-structure.service';
import { TimeBlockRenderService } from '../../../../../rendering/time-block-render.service';
import { TimeBlockDragResizeControllerService } from '../../time-block-drag-resize-handling/time-block-drag-resize-controller.service';
import { DragAndDropHandlerService } from '../drag-and-drop/drag-and-drop-handler.service';
import { TimeBlockService } from '../../../../../time-block.service';
import { SharedHorizontalDragResizeService } from '../../time-block-drag-resize-handling/shared-horizontal-drag-resize.service';

@Injectable()
export class HorizontalMonthFullDayInnerDayDragControllerService
  extends AbstractDragController
  implements IDragTimeBlock
{
  constructor(
    private readonly dragDropPositioningService: DragDropPositioningService,
    private readonly timeBlockDragResizeControllerService: TimeBlockDragResizeControllerService,
    private readonly timeBlockCrudService: TimeBlockCrudService,
    private readonly dragAndDropHandlerService: DragAndDropHandlerService,
    private readonly timeBlockService: TimeBlockService,
    private readonly sharedDraggingResizingService: SharedHorizontalDragResizeService,
    private readonly timeBlockStructureService: TimeBlockStructureService,
    private readonly timeBlockRenderService: TimeBlockRenderService,
  ) {
    super(
      timeBlockCrudService,
      timeBlockStructureService,
      dragDropPositioningService,
      timeBlockRenderService,
      timeBlockDragResizeControllerService,
      timeBlockService,
      dragAndDropHandlerService,
      sharedDraggingResizingService,
    );
  }

  @Autobind
  drag(): void {
    this.sharedDraggingResizingService.dragOrResizeMonthTimeBlock();
  }
}
