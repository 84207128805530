<app-page-header></app-page-header>

<!-- Base data -->
<div class="card-wrapper">
  <div class="card">
    <div class="card-header">
      <div class="title-row">
        <div class="title d-flex k-align-items-center">
          <h2 class="mb-0">Kundendaten</h2>
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="form-wrapper">
        <form [formGroup]="clientBaseForm" class="k-form">
          <div class="row">
            <div class="col-5 first-col">
              <kendo-formfield orientation="horizontal" class="k-mt-0">
                <kendo-label class="!k-align-items-start" [for]="clientName" text="Kundenname">
                </kendo-label>
                <kendo-textbox
                  showErrorIcon="initial"
                  id="clientName"
                  formControlName="clientName"
                  #clientName
                >
                </kendo-textbox>
              </kendo-formfield>

              <kendo-formfield orientation="horizontal" class="k-mt-0">
                <kendo-label class="!k-align-items-start" [for]="email" text="Email"> </kendo-label>
                <kendo-textbox showErrorIcon="initial" id="email" formControlName="email" #email>
                </kendo-textbox>
              </kendo-formfield>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<app-page-footer>
  <ng-template #controlBarTemplate>
    <!-- Local reference is needed -->
    <button kendoButton (click)="cancel()" themeColor="secondary" size="large">Abbrechen</button>
    <button
      class="ms-3"
      kendoButton
      themeColor="base"
      size="large"
      [disabled]="!clientBaseForm.valid"
      (click)="onSubmit()"
    >
      <span class="custom-symbol save k-mr-3"></span>
      <span>
        {{ createEditClientDataService.editMode ? "Aktualisieren" : "Speichern" }}
      </span>
    </button>
  </ng-template>
</app-page-footer>
