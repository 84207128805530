import { Routes } from '@angular/router';
import { ShiveRoutes } from '../segments/route-segments';
import { UserProfileBaseDataComponent } from '../../../features/user/user-profile/user-profile-base-data/user-profile-base-data.component';
import { UserProfileWorkingHoursComponent } from '../../../features/user/user-profile/user-profile-working-hours/user-profile-working-hours.component';
import { UrlSegmentDelimiter } from '../../data-repository/css-constants';
import { UserProfileAttachmentsComponent } from '../../../features/user/user-profile/user-profile-attachments/user-profile-attachments.component';
import { UserProfileAbsenceOverviewComponent } from '../../../features/user/user-profile/user-profile-absence-overview/user-profile-absence-overview.component';

export const UserProfileRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ShiveRoutes.BaseData,
  },
  {
    path: `${ShiveRoutes.BaseData + UrlSegmentDelimiter}:id`,
    component: UserProfileBaseDataComponent,
  },
  {
    path: `${ShiveRoutes.WorkingHours + UrlSegmentDelimiter}:id`,
    component: UserProfileWorkingHoursComponent,
  },
  {
    path: `${ShiveRoutes.AbsenceOverview + UrlSegmentDelimiter}:id`,
    component: UserProfileAbsenceOverviewComponent,
  },
  {
    path: `${ShiveRoutes.Attachments + UrlSegmentDelimiter}:id`,
    component: UserProfileAttachmentsComponent,
  },
];
