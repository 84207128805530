import { Injectable } from '@angular/core';
import { ITimeBlockComponentItem } from '../time-block-component-items';
import { TimeBlockGeometryService } from '../calculation/geometry/time-block-geometry.service';
import { TimeBlockCrudService } from '../crud/time-block-crud.service';
import { ParallelTimeBlockGeometryCalculatorService } from '../calculation/geometry/parallel-time-block-geometry-calculator.service';
import { DateTimeHelper } from '../../../util/date-time-helper';
import { CalendarView } from '../../../../../shared/data-types/calendar-types';
import { DragOrResizeInteraction } from '../interaction/movement/drag-and-resize/time-block-drag-resize-handling/time-block-drag-resize.service';
import { CalendarService } from '../../../services/calendar.service';
import { TimeBlockDragResizeControllerService } from '../interaction/movement/drag-and-resize/time-block-drag-resize-handling/time-block-drag-resize-controller.service';
import { TimeBlockItemBuilderService } from '../generation/time-block-item-builder.service';
import { TimeBlockStructureService } from '../time-block-structure/time-block-structure.service';
import { assert } from '../../../../../core/assert/assert';
import { TimeBlockType } from '../../../../../shared/data-types/time-block-types';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SharedActiveTimeBlockService {
  // activeTimeBlockId === null => loading
  // activeTimeBlockId < 0 => no active time block
  // activeTimeBlockId >= 0 => active time block exists
  public manualActiveTimeBlockIdChanged$ = new BehaviorSubject<number>(null);
  constructor(
    private readonly timeBlockGeometryService: TimeBlockGeometryService,
    private readonly timeBlockCrudService: TimeBlockCrudService,
    private readonly parallelTimeBlockGeometryCalculatorService: ParallelTimeBlockGeometryCalculatorService,
    private readonly calendarService: CalendarService,
    private readonly timeBlockDragResizeControllerService: TimeBlockDragResizeControllerService,
    private readonly timeBlockItemBuilderService: TimeBlockItemBuilderService,
    private readonly timeBlockStructureService: TimeBlockStructureService,
  ) {}

  public insertActiveTimeBlock(generatedTimeBlock: ITimeBlockComponentItem) {
    this.timeBlockCrudService.insertTimeBlock(generatedTimeBlock);
    this.parallelTimeBlockGeometryCalculatorService.updateParallelTimeBlockGeometries(false);
    return generatedTimeBlock.id;
  }

  public replaceActiveTimeBlock(activeTimeBlockId: number) {
    const activeTimeBlock = this.timeBlockStructureService.retrieveTimeBlockPart(
      activeTimeBlockId,
    ) as ITimeBlockComponentItem;
    const clone = activeTimeBlock.clone(this.timeBlockItemBuilderService);
    clone.timeBlockModel.isActive = false;
    this.replace(clone, true);
    return -1;
  }

  public replace(activeClone: ITimeBlockComponentItem, allTimeBlockParts: boolean): void {
    // Replace all time block parts (i.e. delete old time block parts, create new time block parts)
    if (allTimeBlockParts) {
      this.timeBlockCrudService.removeTimeBlock(activeClone);
      this.timeBlockCrudService.insertTimeBlock(activeClone);
    } else {
      // Only update the extending active time block part
      this.timeBlockGeometryService.calculateInitialTimeBlockGeometry(activeClone);
      this.timeBlockCrudService.replaceTimeBlock(activeClone);
    }
    this.parallelTimeBlockGeometryCalculatorService.updateParallelTimeBlockGeometries(false);
  }

  public canUpdate(activeTimeBlockId: number) {
    return (
      this.calendarService.model.calendarViewMode !== CalendarView.MonthGrid &&
      typeof activeTimeBlockId === 'number' &&
      activeTimeBlockId >= 0 &&
      this.timeBlockDragResizeControllerService.currentDragResizeInteraction ===
        DragOrResizeInteraction.None
    );
  }

  updateActiveTimeBlock(activeTimeBlockId: number, end: Date): void {
    assert(activeTimeBlockId !== null, 'Active time block not set.');

    // Retrieve active time block parts
    const activeTimeBlockParts = this.timeBlockStructureService.retrieveTimeBlockPart(
      activeTimeBlockId,
      TimeBlockType.ExistingBlock,
      -1,
    ) as ITimeBlockComponentItem[];

    // If the insertion process of the active time block has not finished yet, return.
    if (!activeTimeBlockParts) {
      return;
    }

    const clone = this.setActiveTimeBlockEnd(activeTimeBlockParts, end);

    this.replace(clone, false);
  }

  public setActiveTimeBlockEnd(
    activeTimeBlock: ITimeBlockComponentItem[],
    now: Date,
  ): ITimeBlockComponentItem {
    assert(activeTimeBlock !== null, 'Active time block array not set');

    // Select the last part of the active time block compound.
    const tail = activeTimeBlock[activeTimeBlock.length - 1];
    const clone = tail.clone(this.timeBlockItemBuilderService);

    const start = clone.timeBlockModel.start;
    let end = clone.timeBlockModel.end;

    const minimumTimeBlockDuration =
      this.calendarService.model.calendarProperties.minimumTimeBlockDuration;

    end = DateTimeHelper.differenceInMinutes(start, now) < minimumTimeBlockDuration ? end : now;
    clone.timeBlockModel.end = end;
    return clone;
  }
}
