import { Injectable, NgZone } from '@angular/core';
import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CalendarMouseHandlerService, ScrollData } from '../mouse/calendar-mouse-handler.service';
import { Data2d, ScrollStatus } from 'smooth-scrollbar/interfaces';
import * as I from 'smooth-scrollbar/src/interfaces';
import {
  CalendarBodyCSSId,
  CalendarHeaderCSSId,
} from '../../../core/data-repository/css-constants';
import { assert } from '../../../core/assert/assert';

// This services is responsible for the smooth-scrolling plugin (https://github.com/idiotWu/smooth-scrollbar).
// We need a JavaScript based scrollbar since we need a more fine-tuned scrollbar for the calendar.

@Injectable()
export class CalendarScrollbarService {
  public static ScrollState$ = new BehaviorSubject(false);
  public calendarHeaderScrollbarInitialized$ = new BehaviorSubject<Scrollbar>(null);
  public calendarBodyScrollbarInitialized$ = new BehaviorSubject<Scrollbar>(null);

  public calendarHeaderScrollbar = new ScrollbarHandler(
    this.calendarMouseHandlerService,
    this.calendarHeaderScrollbarInitialized$,
  );
  public calendarBodyScrollbar = new ScrollbarHandler(
    this.calendarMouseHandlerService,
    this.calendarBodyScrollbarInitialized$,
  );

  constructor(
    private readonly calendarMouseHandlerService: CalendarMouseHandlerService,
    private readonly ngZone: NgZone,
  ) {}

  public initializeScrollbars(): void {
    this.ngZone.runOutsideAngular(() => {
      window.setTimeout(() => {
        this.calendarHeaderScrollbar.init(CalendarHeaderCSSId);
        // Initially disable scroll bar.
        this.calendarHeaderScrollbar.disable(true);

        this.calendarBodyScrollbar.init(CalendarBodyCSSId);
      }, 0);
    });

    Scrollbar.use(StatePlugin);
    Scrollbar.use(DisableScrollPlugin);
  }

  public destroyScrollbars(): void {
    this.calendarHeaderScrollbar?.destroy();
    this.calendarBodyScrollbar?.destroy();
  }

  public disableScrollbars(disable: boolean): void {
    this.calendarHeaderScrollbar?.disable(disable);
    this.calendarBodyScrollbar?.disable(disable);
  }
}

class ScrollbarHandler {
  public scrollData: ScrollData = {
    scrollDelta: 0,
    scrollPosY: 0,
  };
  public wheelSubj$ = new Subject<ScrollData>();

  private scrollbar: Scrollbar;
  private lastPageOffset = 0;

  constructor(
    private readonly calendarMouseHandlerService: CalendarMouseHandlerService,
    private readonly scrollbarInitialized$: BehaviorSubject<Scrollbar>,
  ) {}

  public init(cssSelector: string, options?: Partial<I.ScrollbarOptions>): void {
    this.scrollData = {
      scrollDelta: 0,
      scrollPosY: 0,
    };

    const containerEl = document.querySelector(cssSelector);
    assert(typeof containerEl !== 'undefined', `Container element invalid: ${containerEl}`);

    if (!options) {
      options = {
        damping: environment.damping,
        alwaysShowTracks: true,
        plugins: {
          disableScroll: {},
        },
      };
    }

    this.scrollbar = Scrollbar.init(containerEl as HTMLElement, options);
    if (!this.scrollbar) {
      return;
    }
    this.scrollbarInitialized$.next(this.scrollbar);
  }

  public scrollToTop(deltaY: number, callback?: () => void): void {
    if (!this.scrollbar) {
      return;
    }

    this.scrollbar.scrollTo(0, this.scrollData.scrollPosY - deltaY, 100, {
      callback,
    });
  }

  public scrollPosChanged(status: ScrollStatus): void {
    this.scrollData = {
      scrollPosY: status.offset.y,
      scrollDelta: status.offset.y - this.lastPageOffset,
    };

    this.lastPageOffset = status.offset.y;
    // Update calendar position
    this.calendarMouseHandlerService.MouseMovePositionWrapper.mouseCalendarPosition.y +=
      this.scrollData.scrollDelta;
    this.wheelSubj$.next(this.scrollData);
  }

  public scrollIntoView(elem: HTMLElement, options?: Partial<I.ScrollIntoViewOptions>): void {
    this.scrollbar.scrollIntoView(elem, options);
  }

  public disable(disable: boolean): void {
    if (!this.scrollbar) {
      return;
    }
    this.scrollbar.updatePluginOptions('disableScroll', { disable }); // true, false
  }

  public destroy(): void {
    if (this.scrollbar) {
      this.scrollbar.destroy();
    }
    this.scrollbarInitialized$.next(null);
  }
}

// / Scroll Plugins
// ////////////////////////////////////////
class StatePlugin extends ScrollbarPlugin {
  static pluginName = 'state';

  public onRender(remainMomentum): void {
    if (remainMomentum.x === 0 && remainMomentum.y === 0) {
      CalendarScrollbarService.ScrollState$.next(false);
    } else {
      CalendarScrollbarService.ScrollState$.next(true);
    }
  }
}

class DisableScrollPlugin extends ScrollbarPlugin {
  static pluginName = 'disableScroll';

  static defaultOptions = {
    disable: false,
  };

  // For calendar header and calendar body scrollbars, only permit vertical scrolling.
  public transformDelta(delta): Data2d {
    return this.options.disable ? { x: 0, y: 0 } : { x: 0, y: delta.y };
  }
}
