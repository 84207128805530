import { Component, Input } from '@angular/core';
import { ShiveGridComponent } from '../../form-components/shive-grid/shive-grid.component';
import { UserOperation } from '../../../../core/enums/user-operation';
import { faEllipsis as faEllipsisRegular } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-context-menu-anchor',
  templateUrl: './context-menu-anchor.component.html',
  styleUrl: './context-menu-anchor.component.scss',
})
export class ContextMenuAnchorComponent {
  @Input() gridWrapperComponent!: ShiveGridComponent;
  @Input() dataItem!: unknown;
  public readonly faEllipsis = faEllipsisRegular;
  public readonly UserOperation = UserOperation;
}
