import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  public getTranslation(expression: string | number): string {
    if (typeof expression === 'number') {
      return this.getResponseCodeMsg(expression);
    }
    return $localize`${expression}`;
  }

  private getResponseCodeMsg(expression: number): string {
    switch (expression) {
      case 202:
        return $localize`:@@calendar.creationSuccessful:"Timeblock successfully created"`;
      case 203:
        return $localize`:@@calendar.updateSuccessful:Update successful`;
      case 200:
        return $localize`:@@calendar.updateNotWorking:Worked!`;
      default:
        return '';
    }
  }
}
