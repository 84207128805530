import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { filter, map, take } from 'rxjs/operators';
import * as fromAppConfigActions from '../state/app-config/app-config.actions';
import {
  selectCalendarConfigData,
  selectProjectConfigData,
  selectUIConfigData,
} from '../state/app-config/app-config.selectors';
import { UiConfigModel } from '../models/app-config/ui-config.model';
import { CalendarConfigModel } from '../models/app-config/calendar-config.model';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
  ) {}

  public loadAppConfig(): Observable<unknown> {
    this.store.dispatch(fromAppConfigActions.fetchAppConfigData());

    return this.actions$.pipe(
      ofType(fromAppConfigActions.setAppConfigData),
      map((result) => result.payload),
      take(1),
    );
  }

  public getCalendarConfigData(): Observable<CalendarConfigModel> {
    return this.store.select(selectCalendarConfigData).pipe(filter((config) => !!config));
  }

  public getProjectConfigData(): Observable<unknown> {
    return this.store.select(selectProjectConfigData).pipe(filter((config) => !!config));
  }

  public getUiConfigData(): Observable<UiConfigModel> {
    return this.store.select(selectUIConfigData).pipe(filter((config) => !!config));
  }
}
