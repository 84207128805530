<app-project-calculation-form-billing
  (selectedBillableRateOptionChanged)="this.billableRateOptionChanged($event)"
  [resetBillableValueForm$]="selectedBudgetOptionChanged$"
>
</app-project-calculation-form-billing>

<div class="row gx-5">
  <div class="col-8">
    <hr class="k-my-10" />
  </div>
</div>

<app-project-calculation-form-budget
  (selectedBudgetOptionChanged)="this.budgetOptionChanged($event)"
/>

<app-project-calculation-form-reset-budget
  [selectedBudgetRateOptionChanged]="selectedBudgetOptionChanged$"
/>
