<app-shive-grid #gridWrapperComponent [grid]="grid">
  <kendo-grid
    kendoGridSelectBy="id"
    scrollable="none"
    size="medium"
    [data]="gridData"
    [pageSize]="20"
    [selectable]="{ enabled: true, checkboxOnly: true }"
    [columnMenu]="{ filter: true }"
    #grid
  >
    <kendo-grid-column field="member_name" title="Name" [class]="'cell-with-avatar'">
      <ng-template kendoGridCellTemplate let-row>
        <app-shared-member-template [user]="row.member"></app-shared-member-template>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</app-shive-grid>
