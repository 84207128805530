import { Injectable } from '@angular/core';
import { TimeBlockItemBuilderService } from './time-block-item-builder.service';
import { TimeBlockCrudService } from '../crud/time-block-crud.service';
import { DateTimeHelper } from '../../../util/date-time-helper';
import { TimeCoordinateMappingService } from '../../../time-mapping/time-coordinate-mapping.service';
import { CalendarService } from '../../../services/calendar.service';
import { TimeBlockHttpCallbackService } from '../http/time-block-http-callback.service';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { Subject, merge, of, timer } from 'rxjs';
import {
  TimeBlockContentType,
  TimeBlockType,
} from '../../../../../shared/data-types/time-block-types';
import { CalendarGeometryService } from '../../../services/calendar-geometry.service';
import { CalendarMouseHandlerService } from '../../../mouse/calendar-mouse-handler.service';
import { DayOrWeekCalendarModel } from '../../../../../core/models/calendar/day-or-week-calendar.model';
import { TouchAndMouseHandlerService } from '../../../../../core/services/touch-and-mouse-handler.service';
import { environment } from '../../../../../../environments/environment';
import { CalendarView } from '../../../../../shared/data-types/calendar-types';

@Injectable()
export class NewTimeBlockGenerationService {
  public emptySpaceMouseDown$ = new Subject();
  public readyForGeneration = false;
  private readonly timeBlockTypeDetected$ = new Subject();
  private readonly stop$ = new Subject();
  private readonly tick = environment.mousePressedTriggerDuration;

  constructor(
    private readonly calendarService: CalendarService,
    private readonly calendarMouseHandlerService: CalendarMouseHandlerService,
    private readonly calendarGeometryService: CalendarGeometryService,
    private readonly timeBlockItemBuilderService: TimeBlockItemBuilderService,
    private readonly timeCoordinateMappingService: TimeCoordinateMappingService,
    private readonly timeBlockHttpCallbackService: TimeBlockHttpCallbackService,
    private readonly touchAndMouseHandlerService: TouchAndMouseHandlerService,
    private readonly timeBlockCrudService: TimeBlockCrudService,
  ) {
    this.timeBlockHttpCallbackService.handleHTTPCallbacks();

    this.initCalendarMouseInteractions();
  }

  /**
   * User clicks on an empty space in the calendar, so a non-existing time block is created.
   */
  public buildUserGeneratedTimeBlock(
    calendarView: CalendarView,
    date: Date,
    containerId: number,
    isFulldayLane: boolean,
  ): void {
    if (calendarView === CalendarView.DayGrid || calendarView === CalendarView.WeekGrid) {
      if (isFulldayLane) {
        this.buildDayOrWeekViewFulldayTimeBlock(date, containerId, TimeBlockContentType.Project);
      } else {
        this.buildDayOrWeekViewInnerdayTimeBlock(date, containerId, TimeBlockContentType.Project);
      }
    } else {
      this.buildMonthViewFulldayOrInnerdayTimeBlock(
        date,
        containerId,
        TimeBlockContentType.Project,
      );
    }
  }

  private buildDayOrWeekViewInnerdayTimeBlock(
    date: Date,
    laneIndex: number,
    timeBlockContentType: TimeBlockContentType,
  ): void {
    this.timeCoordinateMappingService.pixelsPerMinuteCalculated$
      .pipe(
        take(1),
        filter((ppm) => !!ppm),
        switchMap((ppm) => {
          const mouseCalendarPosition =
            this.calendarMouseHandlerService.MouseMovePositionWrapper.mouseCalendarPosition;
          const startTime = this.timeCoordinateMappingService.toDateTime(
            mouseCalendarPosition.y,
            ppm,
          );

          const timeBlockDraggingDuration = (this.calendarService.model as DayOrWeekCalendarModel)
            .calendarProperties.timeBlockDraggingDuration;
          // Calculate the difference to the previous threshold to snap the time block start to an appropriate threshold
          // (e.g. 10:05, 10:10, 10:15, etc. but not 10:02, 10:07 etc.).
          const startMinutes =
            startTime.getMinutes() - (startTime.getMinutes() % timeBlockDraggingDuration);

          const start = new Date(date);
          start.setHours(startTime.getHours());
          start.setMinutes(startMinutes);

          let end = new Date(start);
          end = DateTimeHelper.addMinutes(
            end,
            this.calendarService.model.calendarProperties.minimumTimeBlockDuration,
          );
          return of(
            this.timeBlockItemBuilderService.buildDefaultTimeBlock(
              start,
              end,
              laneIndex,
              true,
              timeBlockContentType,
            ),
          );
        }),
      )
      .subscribe((generatedTimeBlock) => {
        generatedTimeBlock.timeBlockModel.type = TimeBlockType.NonExistingBlock;
        const nonExistingGhost = this.timeBlockItemBuilderService.buildFromExisting(
          generatedTimeBlock.timeBlockModel,
          timeBlockContentType,
        );
        // Insert the non-existing ghost block
        this.timeBlockCrudService.insertTimeBlock(nonExistingGhost);
      });
  }

  private buildDayOrWeekViewFulldayTimeBlock(
    date: Date,
    laneIndex: number,
    timeBlockContentType: TimeBlockContentType,
  ): void {
    const start = DateTimeHelper.mergeDateAndTime(
      date,
      this.calendarService.model.calendarProperties.workTimeStart,
    );
    const end = DateTimeHelper.mergeDateAndTime(
      date,
      this.calendarService.model.calendarProperties.workTimeEnd,
    );

    const nonExistingGhost = this.timeBlockItemBuilderService.buildDefaultTimeBlock(
      start,
      end,
      laneIndex,
      false,
      timeBlockContentType,
    );

    nonExistingGhost.timeBlockModel.type = TimeBlockType.NonExistingBlock;
    nonExistingGhost.timeBlockModel.timeBlockViewType.geometryData.width =
      this.calendarGeometryService.getDayOrWeekFulldayLaneWidth();
    this.timeBlockCrudService.insertTimeBlock(nonExistingGhost);
  }

  private buildMonthViewFulldayOrInnerdayTimeBlock(
    date: Date,
    laneIndex: number,
    timeBlockContentType: TimeBlockContentType,
  ): void {
    this.timeBlockTypeDetected$.pipe(take(1)).subscribe((isFullday) => {
      const isInnerday = !isFullday;
      const start = DateTimeHelper.mergeDateAndTime(
        date,
        this.calendarService.model.calendarProperties.workTimeStart,
      );
      const end = DateTimeHelper.mergeDateAndTime(
        date,
        this.calendarService.model.calendarProperties.workTimeEnd,
      );

      const nonExistingGhost = this.timeBlockItemBuilderService.buildDefaultTimeBlock(
        start,
        end,
        laneIndex,
        isInnerday,
        timeBlockContentType,
      );

      nonExistingGhost.timeBlockModel.type = TimeBlockType.NonExistingBlock;
      this.timeBlockCrudService.insertTimeBlock(nonExistingGhost);
    });
  }

  private initCalendarMouseInteractions() {
    const stopCondition = merge(this.stop$);

    this.emptySpaceMouseDown$
      .pipe(
        filter((val) => val === true),
        switchMap(() => {
          return merge(
            timer(this.tick, this.tick)
              .pipe(takeUntil(stopCondition))
              .pipe(map(() => true)),
            this.touchAndMouseHandlerService.globalMouseReleased$.pipe(map(() => false)),
            this.touchAndMouseHandlerService.globalMouseMoved$.pipe(map(() => true)),
          ).pipe(take(1));
        }),
      )
      .subscribe((isFullday) => {
        if (this.calendarService.model.calendarViewMode === CalendarView.MonthGrid) {
          this.timeBlockTypeDetected$.next(isFullday);
        }
        this.stop$.next(true);
      });
  }
}
