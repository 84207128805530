import {
  DayComposite,
  DayCompositeWithinMonth,
  WeekComposite,
} from '../../../shared/data-types/calendar-types';
import { DateTimeHelper } from '../util/date-time-helper';
import { MonthCalendarModel } from '../../../core/models/calendar/month-calendar.model';
import { Interval } from 'date-fns';

export class DayCompositeConverter {
  public static toDayComposite(days: Date[], start?: Date, end?: Date): DayComposite[] {
    return days.map((d) => {
      let weekInterval: Interval;
      if (start && end) {
        weekInterval = { start, end };
      }

      const dayComposite: DayComposite = {
        theDate: d,
        epoch: DateTimeHelper.isWhen(d),
        outsideOfView: weekInterval ? !DateTimeHelper.isWithinInterval(d, weekInterval) : false,
        isFirstVisibleDay: start ? DateTimeHelper.isSameDay(d, start) : false,
        isLastVisibleDay: end ? DateTimeHelper.isSameDay(d, end) : false,
      };
      return dayComposite;
    });
  }

  public static toWeekComposite(calendarModel: MonthCalendarModel): WeekComposite[] {
    return calendarModel.calendarProperties.weeks.map((week) => {
      const firstDayOfWeek = week[0];
      const weekIndex = DateTimeHelper.weekNumber(firstDayOfWeek);
      const currentMonth = calendarModel.calendarProperties.month;
      const year = calendarModel.calendarProperties.year;
      const currentMonthDate = new Date(year, currentMonth);

      const monthInterval: Interval = {
        start: DateTimeHelper.startOfMonth(currentMonthDate),
        end: DateTimeHelper.endOfMonth(currentMonthDate),
      };

      const dayComposites = week.map((d) => {
        const dayComposite: DayCompositeWithinMonth = {
          theDate: d,
          epoch: DateTimeHelper.isWhen(d),
          outsideOfView: false,
          isFirstVisibleDay: DateTimeHelper.isSameDay(
            d,
            calendarModel.calendarProperties.visibleStartDate,
          ),
          isLastVisibleDay: DateTimeHelper.isSameDay(
            d,
            calendarModel.calendarProperties.visibleEndDate,
          ),
          isWithinMonth: DateTimeHelper.isWithinInterval(d, monthInterval),
        };
        return dayComposite;
      });
      const weekComposite: WeekComposite = {
        days: dayComposites,
        weekIndex,
      };
      return weekComposite;
    });
  }
}
