<kendo-tabstrip [animate]="false" #tabstrip (tabSelect)="selectedTab = $event.index">
  <kendo-tabstrip-tab
    title="Projektzeit"
    [selected]="true"
    [disabled]="tabHidden(TimeBlockContentType.Project)"
  >
    <ng-template kendoTabContent>
      @if (!tabHidden(TimeBlockContentType.Project)) {
        <app-project-type-dialog
          [selectedTimeBlock]="selectedTimeBlockComponent"
          [actionObs$]="actionEmitted$.asObservable()"
          [footer]="footer"
        >
        </app-project-type-dialog>
      }
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab title="Abwesenheitszeit">
    <ng-template kendoTabContent>
      <app-time-off-type-dialog
        [selectedTimeBlock]="selectedTimeBlockComponent"
        [actionObs$]="actionEmitted$.asObservable()"
        [footer]="footer"
      >
      </app-time-off-type-dialog>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab title="Termin">
    <ng-template kendoTabContent>
      <div class="content">
        <img
          ngSrc="https://www.telerik.com/kendo-angular-ui-develop/components/layout/assets/tabstrip/sunny.png"
          alt="sunny"
          fill
        />
        <h2>23<span>ºC</span></h2>
        <span>Sunny weather in Tallinn.</span>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>

<ng-template #footer>
  <footer class="k-d-flex k-justify-content-between">
    <div>
      <button
        (click)="cancel()"
        kendoButton
        size="large"
        themeColor="secondary"
        class="k-mr-4 !k-pr-4"
      >
        <span class="custom-symbol close k-mr-3"></span>
        Schließen
      </button>
      @if (
        selectedTimeBlockComponent.id >= 0 && !selectedTimeBlockComponent.timeBlockModel.isActive
      ) {
        <button
          (click)="sendAction(UserOperation.Delete)"
          kendoButton
          themeColor="error"
          class="!k-pr-4"
          size="large"
        >
          <span class="custom-symbol delete k-mr-3 red"></span>
          Löschen
        </button>
      }
    </div>
    <div>
      <button
        kendoButton
        (click)="
          sendAction(
            selectedTimeBlockComponent.id >= 0 ? UserOperation.Update : UserOperation.Create
          )
        "
        themeColor="base"
        class="!k-pr-4"
        size="large"
      >
        <span class="custom-symbol save k-mr-3"></span>
        {{ selectedTimeBlockComponent.id >= 0 ? "Aktualisieren" : "Speichern" }}
      </button>
    </div>
  </footer>
</ng-template>
