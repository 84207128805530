/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import * as i1 from '@progress/kendo-angular-l10n';
import { ComponentMessages, LocalizationService, L10N_PREFIX } from '@progress/kendo-angular-l10n';
import * as i0 from '@angular/core';
import { isDevMode, Component, HostBinding, Input, Directive, forwardRef, EventEmitter, Output, ViewChild, ContentChild, NgModule } from '@angular/core';
import { validatePackage } from '@progress/kendo-licensing';
import * as i3 from '@progress/kendo-angular-common';
import { hasObservers, isDocumentAvailable, isChanged, ResizeSensorModule } from '@progress/kendo-angular-common';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

/**
 * @hidden
 */
const _c0 = ["progressStatus"];
const _c1 = ["progressStatusWrap"];
function ProgressBarComponent_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 7);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.formattedLabelValue);
  }
}
function ProgressBarComponent_span_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 7);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.formattedLabelValue);
  }
}
function ChunkProgressBarComponent_li_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "li", 3);
  }
  if (rf & 2) {
    const chunk_r1 = ctx.$implicit;
    const i_r2 = ctx.index;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵstyleProp("width", ctx_r2.orientationStyles.width)("height", ctx_r2.orientationStyles.height);
    i0.ɵɵclassProp("k-first", i_r2 === 0)("k-last", i_r2 === ctx_r2.chunkCount - 1)("k-selected", chunk_r1);
    i0.ɵɵproperty("ngClass", chunk_r1 ? ctx_r2.progressCssClass : ctx_r2.emptyCssClass)("ngStyle", chunk_r1 ? ctx_r2.progressCssStyle : ctx_r2.emptyCssStyle);
  }
}
const _c2 = ["progress"];
const _c3 = ["scale"];
const _c4 = ["label"];
const _c5 = ["surface"];
function CircularProgressBarComponent_div_11_ng_template_2_Template(rf, ctx) {}
function CircularProgressBarComponent_div_11_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 11, 4);
    i0.ɵɵtemplate(2, CircularProgressBarComponent_div_11_ng_template_2_Template, 0, 0, "ng-template", 12);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.centerTemplate.templateRef)("ngTemplateOutletContext", ctx_r1.centerTemplateContext);
  }
}
const packageMetadata = {
  name: '@progress/kendo-angular-progressbar',
  productName: 'Kendo UI for Angular',
  productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
  publishDate: 1718884665,
  version: '16.3.0',
  licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/'
};

/**
 * @hidden
 */
const MIN_MAX_ERROR_MESSAGE = `The max value should be greater than the min.`;
/**
 * @hidden
 */
const LABEL_DECIMALS = 3;
/**
 * @hidden
 */
const MIN_RATIO = 0.0001;

/**
 * @hidden
 */
const formatValue = (value, min, max, label) => {
  const defaultFormattedValue = truncateNumber(value);
  if (typeof label !== 'boolean') {
    if (typeof label.format === 'string') {
      switch (label.format) {
        case 'value':
          return defaultFormattedValue;
        case 'percent':
          return `${Math.floor(calculatePercentage(value, min, max))}%`;
        default:
          return defaultFormattedValue;
      }
    } else if (typeof label.format === 'function') {
      return label.format(value);
    } else {
      return defaultFormattedValue;
    }
  }
  return defaultFormattedValue;
};
/**
 * @hidden
 */
const validateRange = (min, max) => {
  if (isDevMode && min > max) {
    throw new Error(MIN_MAX_ERROR_MESSAGE);
  }
};
/**
 * @hidden
 */
const adjustValueToRange = (min, max, value) => Math.max(Math.min(value, max), min);
/**
 * @hidden
 */
const calculatePercentage = (value, min, max) => {
  const decimalValue = Math.abs((value - min) / (max - min));
  return decimalValue * 100;
};
/**
 * @hidden
 */
const truncateNumber = value => {
  const numberParts = value.toString().split('.');
  return numberParts.length === 1 ? `${numberParts[0]}` : `${numberParts[0]}.${numberParts[1].substr(0, LABEL_DECIMALS)}`;
};
/**
 * @hidden
 */
const calculateRatio = (min, max, value) => Math.max((value - min) / (max - min), MIN_RATIO);
/**
 * @hidden
 */
const extractValueFromChanges = (changes, type, value) => changes[type] && changes[type].currentValue !== undefined ? changes[type].currentValue : value;
/**
 * @hidden
 */
const runAnimation = (changes, animation, previousValue, displayValue) => animation && typeof requestAnimationFrame !== 'undefined' && changes['value'] && previousValue !== displayValue;
/**
 * @hidden
 */
const stopCurrentAnimation = changes => {
  const isAnimationChanged = Boolean(changes['animation']);
  const hasAnimation = isAnimationChanged && changes['animation'].currentValue;
  return isAnimationChanged && !hasAnimation;
};
/**
 * @hidden
 */
const setProgressBarStyles = (props, renderer) => {
  props.forEach(prop => {
    renderer[prop.method](prop.el, prop.attr, `${prop.attrValue}`);
  });
};
/**
 * @hidden
 */
const removeProgressBarStyles = (props, renderer) => {
  props.forEach(prop => {
    renderer[prop.method](prop.el, prop.attr);
  });
};
/**
 * @hidden
 */
const hasElementSize = element => {
  return !!(element.style.width && element.style.height);
};

/**
 * @hidden
 */
class ProgressBarBase {
  /**
   * @hidden
   */
  constructor(elem, renderer, localization) {
    this.elem = elem;
    this.renderer = renderer;
    this.localization = localization;
    this.hostClasses = true;
    this.roleAttribute = 'progressbar';
    /**
     * The maximum value of the ProgressBar.
     * Defaults to `100`.
     */
    this.max = 100;
    /**
     * The minimum value of the ProgressBar.
     * Defaults to `0`.
     */
    this.min = 0;
    /**
     * The value of the ProgressBar.
     * Has to be between `min` and `max`.
     * By default, the value is equal to the `min` value.
     */
    /**
     * The value of the ProgressBar.
     * Has to be between `min` and `max`.
     * Defaults to `0`.
     */
    this.value = 0;
    /**
     * Defines the orientation of the ProgressBar
     * ([see example]({% slug progressbar_orientation %})).
     * Defaults to `horizontal`.
     */
    this.orientation = 'horizontal';
    /**
     * If set to `true`, the ProgressBar will be disabled
     * ([see example]({% slug progressbar_disabled %})).
     * It will still allow you to change its value.
     * Defaults to `false`.
     */
    this.disabled = false;
    /**
     * If set to `true`, the ProgressBar will be reversed
     * ([see example]({% slug progressbar_direction %})).
     * Defaults to `false`.
     */
    this.reverse = false;
    /**
     * Sets the `indeterminate` state of the ProgressBar.
     * Defaults to `false`.
     */
    this.indeterminate = false;
    this.displayValue = 0;
    this.previousValue = 0;
    validatePackage(packageMetadata);
    this.localizationChangeSubscription = localization.changes.subscribe(({
      rtl
    }) => {
      this.direction = rtl ? 'rtl' : 'ltr';
    });
  }
  get isHorizontal() {
    return this.orientation === 'horizontal';
  }
  get isVertical() {
    return this.orientation === 'vertical';
  }
  get disabledClass() {
    return this.disabled;
  }
  get reverseClass() {
    return this.reverse;
  }
  get indeterminateClass() {
    return this.indeterminate;
  }
  get dirAttribute() {
    return this.direction;
  }
  get ariaMinAttribute() {
    return String(this.min);
  }
  get ariaMaxAttribute() {
    return String(this.max);
  }
  get ariaValueAttribute() {
    return this.indeterminate ? undefined : String(this.displayValue);
  }
  /**
   * @hidden
   */
  get isCompleted() {
    return this.value === this.max;
  }
  /**
   * @hidden
   */
  get statusWidth() {
    return this.orientation === 'horizontal' ? this._progressRatio * 100 : 100;
  }
  /**
   * @hidden
   */
  get statusHeight() {
    return this.orientation === 'vertical' ? this._progressRatio * 100 : 100;
  }
  /**
   * @hidden
   */
  get statusWrapperWidth() {
    return this.orientation === 'horizontal' ? 100 / this._progressRatio : 100;
  }
  /**
   * @hidden
   */
  get statusWrapperHeight() {
    return this.orientation === 'vertical' ? 100 / this._progressRatio : 100;
  }
  get _progressRatio() {
    return calculateRatio(this.min, this.max, this.displayValue);
  }
  ngAfterViewInit() {
    const elem = this.elem.nativeElement;
    const label = this.localization.get('progressBarLabel');
    this.renderer.setAttribute(elem, 'aria-label', label);
  }
  ngOnChanges(changes) {
    const min = extractValueFromChanges(changes, 'min', this.min);
    const max = extractValueFromChanges(changes, 'max', this.max);
    const value = extractValueFromChanges(changes, 'value', this.value);
    if (changes['min'] || changes['max'] || changes['value']) {
      if (changes['min'] || changes['max']) {
        validateRange(min, max);
      }
      if (changes['value']) {
        if (value == null || Number.isNaN(value)) {
          this.value = min;
        }
        const previousValue = this.displayValue;
        this.displayValue = adjustValueToRange(this.min, this.max, value);
        this.previousValue = previousValue;
      }
      this.min = min;
      this.max = max;
      this.displayValue = adjustValueToRange(this.min, this.max, value);
    }
  }
  ngOnDestroy() {
    if (this.localizationChangeSubscription) {
      this.localizationChangeSubscription.unsubscribe();
    }
  }
}
ProgressBarBase.ɵfac = function ProgressBarBase_Factory(t) {
  return new (t || ProgressBarBase)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i1.LocalizationService));
};
ProgressBarBase.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ProgressBarBase,
  selectors: [["ng-component"]],
  hostVars: 17,
  hostBindings: function ProgressBarBase_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("dir", ctx.dirAttribute)("role", ctx.roleAttribute)("aria-valuemin", ctx.ariaMinAttribute)("aria-valuemax", ctx.ariaMaxAttribute)("aria-valuenow", ctx.ariaValueAttribute);
      i0.ɵɵclassProp("k-progressbar", ctx.hostClasses)("k-progressbar-horizontal", ctx.isHorizontal)("k-progressbar-vertical", ctx.isVertical)("k-disabled", ctx.disabledClass)("k-progressbar-reverse", ctx.reverseClass)("k-progressbar-indeterminate", ctx.indeterminateClass);
    }
  },
  inputs: {
    max: "max",
    min: "min",
    value: "value",
    orientation: "orientation",
    disabled: "disabled",
    reverse: "reverse",
    indeterminate: "indeterminate"
  },
  features: [i0.ɵɵNgOnChangesFeature],
  decls: 0,
  vars: 0,
  template: function ProgressBarBase_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProgressBarBase, [{
    type: Component,
    args: [{
      template: ''
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i1.LocalizationService
    }];
  }, {
    hostClasses: [{
      type: HostBinding,
      args: ['class.k-progressbar']
    }],
    isHorizontal: [{
      type: HostBinding,
      args: ['class.k-progressbar-horizontal']
    }],
    isVertical: [{
      type: HostBinding,
      args: ['class.k-progressbar-vertical']
    }],
    disabledClass: [{
      type: HostBinding,
      args: ['class.k-disabled']
    }],
    reverseClass: [{
      type: HostBinding,
      args: ['class.k-progressbar-reverse']
    }],
    indeterminateClass: [{
      type: HostBinding,
      args: ['class.k-progressbar-indeterminate']
    }],
    dirAttribute: [{
      type: HostBinding,
      args: ['attr.dir']
    }],
    roleAttribute: [{
      type: HostBinding,
      args: ['attr.role']
    }],
    ariaMinAttribute: [{
      type: HostBinding,
      args: ['attr.aria-valuemin']
    }],
    ariaMaxAttribute: [{
      type: HostBinding,
      args: ['attr.aria-valuemax']
    }],
    ariaValueAttribute: [{
      type: HostBinding,
      args: ['attr.aria-valuenow']
    }],
    max: [{
      type: Input
    }],
    min: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    orientation: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    reverse: [{
      type: Input
    }],
    indeterminate: [{
      type: Input
    }]
  });
})();

/**
 * @hidden
 */
class ProgressBarMessages extends ComponentMessages {}
ProgressBarMessages.ɵfac = /* @__PURE__ */(() => {
  let ɵProgressBarMessages_BaseFactory;
  return function ProgressBarMessages_Factory(t) {
    return (ɵProgressBarMessages_BaseFactory || (ɵProgressBarMessages_BaseFactory = i0.ɵɵgetInheritedFactory(ProgressBarMessages)))(t || ProgressBarMessages);
  };
})();
ProgressBarMessages.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ProgressBarMessages,
  inputs: {
    progressBarLabel: "progressBarLabel"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProgressBarMessages, [{
    type: Directive,
    args: [{}]
  }], null, {
    progressBarLabel: [{
      type: Input
    }]
  });
})();

/**
 * @hidden
 */
class LocalizedProgressBarMessagesDirective extends ProgressBarMessages {
  constructor(service) {
    super();
    this.service = service;
  }
}
LocalizedProgressBarMessagesDirective.ɵfac = function LocalizedProgressBarMessagesDirective_Factory(t) {
  return new (t || LocalizedProgressBarMessagesDirective)(i0.ɵɵdirectiveInject(i1.LocalizationService));
};
LocalizedProgressBarMessagesDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: LocalizedProgressBarMessagesDirective,
  selectors: [["", "kendoProgressBarLocalizedMessages", ""]],
  features: [i0.ɵɵProvidersFeature([{
    provide: ProgressBarMessages,
    useExisting: forwardRef(() => LocalizedProgressBarMessagesDirective)
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalizedProgressBarMessagesDirective, [{
    type: Directive,
    args: [{
      providers: [{
        provide: ProgressBarMessages,
        useExisting: forwardRef(() => LocalizedProgressBarMessagesDirective)
      }],
      selector: `[kendoProgressBarLocalizedMessages]`
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }];
  }, null);
})();

/**
 * Represents the [Kendo UI ProgressBar component for Angular]({% slug overview_progressbar %}).
 *
 * @example
 * ```ts-preview
 * _@Component({
 *    selector: 'my-app',
 *    template: `
 *        <kendo-progressbar [value]="value">
 *        </kendo-progressbar>
 *    `
 * })
 * class AppComponent {
 *     public value = 50;
 * }
 * ```
 */
class ProgressBarComponent extends ProgressBarBase {
  /**
   * @hidden
   */
  constructor(localization, elem, renderer, zone) {
    super(elem, renderer, localization);
    this.localization = localization;
    this.elem = elem;
    this.renderer = renderer;
    this.zone = zone;
    /**
     * Determines whether the status label will be visible.
     * Defaults to `true`&mdash;the label will be visible and displayed with the default
     * `LabelSettings` having its position set to `end` and its format set to `value`.
     */
    this.label = true;
    /**
     * The animation configuration of the ProgressBar.
     * Defaults to `false`.
     */
    this.animation = false;
    /**
     * Fires when the animation which indicates the latest value change is completed.
     */
    this.animationEnd = new EventEmitter();
  }
  /**
   * @hidden
   */
  get showLabel() {
    if (typeof this.label === 'boolean') {
      return this.label;
    } else {
      if (this.label && !this.label.hasOwnProperty('visible')) {
        this.label.visible = true;
      }
      return this.label.visible;
    }
  }
  /**
   * @hidden
   */
  get labelPosition() {
    if (typeof this.label === 'boolean') {
      return 'end';
    } else {
      if (this.label && !this.label.hasOwnProperty('position')) {
        this.label.position = 'end';
      }
      return this.label.position;
    }
  }
  /**
   * @hidden
   */
  get isPositionStart() {
    return this.labelPosition === 'start';
  }
  /**
   * @hidden
   */
  get isPositionCenter() {
    return this.labelPosition === 'center';
  }
  /**
   * @hidden
   */
  get isPositionEnd() {
    return this.labelPosition === 'end';
  }
  /**
   * @hidden
   */
  get formattedLabelValue() {
    return formatValue(this.displayValue, this.min, this.max, this.label);
  }
  /**
   * @hidden
   */
  ngOnChanges(changes) {
    super.ngOnChanges(changes);
    if (this.isAnimationInProgress && stopCurrentAnimation(changes)) {
      this.cancelCurrentAnimation = true;
    }
    if (runAnimation(changes, this.animation, this.previousValue, this.displayValue) && !changes['value'].firstChange) {
      this.startAnimation(this.previousValue);
    }
  }
  /**
   * @hidden
   */
  ngOnDestroy() {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
    }
  }
  /**
   * @hidden
   */
  startAnimation(previousValue) {
    this.isAnimationInProgress = true;
    const element = this.progressStatusElement.nativeElement;
    const wrapperElement = this.progressStatusWrapperElement.nativeElement;
    const animationOptions = this.getAnimationOptions(previousValue);
    this.zone.runOutsideAngular(() => {
      if (this.animationFrame) {
        cancelAnimationFrame(this.animationFrame);
      }
      const animate = () => {
        const elapsed = new Date().getTime() - animationOptions.startTime;
        const position = Math.min(elapsed / animationOptions.duration, 1);
        const size = animationOptions.startSize + animationOptions.deltaSize * position;
        const wrapperSize = 100 / size * 100;
        this.renderValueChange(element, wrapperElement, animationOptions.property, size, wrapperSize);
        if (position < 1) {
          if (this.cancelCurrentAnimation) {
            this.resetProgress(element, wrapperElement, animationOptions.property);
            return;
          }
          this.animationFrame = requestAnimationFrame(animate);
        } else {
          this.stopAnimation(previousValue);
        }
      };
      animate();
    });
  }
  /**
   * @hidden
   */
  get animationDuration() {
    if (typeof this.animation === 'boolean') {
      return 400;
    } else {
      if (this.animation && !this.animation.hasOwnProperty('duration')) {
        this.animation.duration = 400;
      }
      return this.animation.duration;
    }
  }
  stopAnimation(value) {
    if (hasObservers(this.animationEnd)) {
      this.zone.run(() => {
        this.animationEnd.emit({
          from: value,
          to: this.displayValue
        });
      });
    }
    this.zone.run(() => {
      this.isAnimationInProgress = false;
    });
  }
  getAnimationOptions(value) {
    const isHorizontal = this.orientation === 'horizontal';
    const previousRatio = calculateRatio(this.min, this.max, value);
    const previousStatusWidth = isHorizontal ? previousRatio * 100 : 100;
    const previousStatusHeight = !isHorizontal ? previousRatio * 100 : 100;
    const property = isHorizontal ? 'width' : 'height';
    const startTime = new Date().getTime();
    const startSize = isHorizontal ? previousStatusWidth : previousStatusHeight;
    const deltaSize = isHorizontal ? this.statusWidth - previousStatusWidth : this.statusHeight - previousStatusHeight;
    const duration = this.animationDuration * Math.abs(deltaSize / 100);
    return {
      property,
      startTime,
      startSize,
      deltaSize,
      duration
    };
  }
  renderValueChange(element, wrapperElement, property, size, wrapperSize) {
    this.renderer.setStyle(element, property, size + '%');
    this.renderer.setStyle(wrapperElement, property, wrapperSize + '%');
  }
  resetProgress(element, wrapperElement, property) {
    const size = calculateRatio(this.min, this.max, this.value);
    const newSize = size * 100;
    const newWrapperSize = 100 / size;
    this.renderValueChange(element, wrapperElement, property, newSize, newWrapperSize);
    this.zone.run(() => {
      this.cancelCurrentAnimation = false;
      this.isAnimationInProgress = false;
    });
  }
}
ProgressBarComponent.ɵfac = function ProgressBarComponent_Factory(t) {
  return new (t || ProgressBarComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.NgZone));
};
ProgressBarComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ProgressBarComponent,
  selectors: [["kendo-progressbar"]],
  viewQuery: function ProgressBarComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5);
      i0.ɵɵviewQuery(_c1, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.progressStatusElement = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.progressStatusWrapperElement = _t.first);
    }
  },
  inputs: {
    label: "label",
    progressCssStyle: "progressCssStyle",
    progressCssClass: "progressCssClass",
    emptyCssStyle: "emptyCssStyle",
    emptyCssClass: "emptyCssClass",
    animation: "animation"
  },
  outputs: {
    animationEnd: "animationEnd"
  },
  exportAs: ["kendoProgressBar"],
  features: [i0.ɵɵProvidersFeature([LocalizationService, {
    provide: L10N_PREFIX,
    useValue: 'kendo.progressbar'
  }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature],
  decls: 8,
  vars: 28,
  consts: () => {
    let i18n_0;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The aria-label attribute for the ProgressBar component.
       * @meaning kendo.progressbar.progressBarLabel
       */
      const MSG__USR_SHARE_NGINX_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PROGRESSBAR_FESM2020_PROGRESS_KENDO_ANGULAR_PROGRESSBAR_MJS_0 = goog.getMsg("Progressbar");
      i18n_0 = MSG__USR_SHARE_NGINX_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PROGRESSBAR_FESM2020_PROGRESS_KENDO_ANGULAR_PROGRESSBAR_MJS_0;
    } else {
      i18n_0 = $localize`:kendo.progressbar.progressBarLabel|The aria-label attribute for the ProgressBar component.:Progressbar`;
    }
    return [["progressStatus", ""], ["progressStatusWrap", ""], ["kendoProgressBarLocalizedMessages", "", "progressBarLabel", i18n_0], [1, "k-progress-status-wrap", 3, "ngStyle", "ngClass"], ["class", "k-progress-status", 4, "ngIf"], [1, "k-selected", "k-progressbar-value", 3, "ngStyle", "ngClass"], [1, "k-progress-status-wrap"], [1, "k-progress-status"]];
  },
  template: function ProgressBarComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementContainer(0, 2);
      i0.ɵɵelementStart(1, "span", 3);
      i0.ɵɵtemplate(2, ProgressBarComponent_span_2_Template, 2, 1, "span", 4);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(3, "div", 5, 0)(5, "span", 6, 1);
      i0.ɵɵtemplate(7, ProgressBarComponent_span_7_Template, 2, 1, "span", 4);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵclassProp("k-progress-start", ctx.isPositionStart)("k-progress-center", ctx.isPositionCenter)("k-progress-end", ctx.isPositionEnd);
      i0.ɵɵproperty("ngStyle", ctx.emptyCssStyle)("ngClass", ctx.emptyCssClass);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.showLabel);
      i0.ɵɵadvance();
      i0.ɵɵstyleProp("width", ctx.statusWidth, "%")("height", ctx.statusHeight, "%");
      i0.ɵɵclassProp("k-complete", ctx.isCompleted);
      i0.ɵɵproperty("ngStyle", ctx.progressCssStyle)("ngClass", ctx.progressCssClass);
      i0.ɵɵadvance(2);
      i0.ɵɵstyleProp("width", ctx.statusWrapperWidth, "%")("height", ctx.statusWrapperHeight, "%");
      i0.ɵɵclassProp("k-progress-start", ctx.isPositionStart)("k-progress-center", ctx.isPositionCenter)("k-progress-end", ctx.isPositionEnd);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.showLabel);
    }
  },
  dependencies: [i2.NgClass, i2.NgIf, i2.NgStyle, LocalizedProgressBarMessagesDirective],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProgressBarComponent, [{
    type: Component,
    args: [{
      exportAs: 'kendoProgressBar',
      selector: 'kendo-progressbar',
      template: `
        <ng-container kendoProgressBarLocalizedMessages
            i18n-progressBarLabel="kendo.progressbar.progressBarLabel|The aria-label attribute for the ProgressBar component."
            progressBarLabel="Progressbar"
        >
        </ng-container>
        <span class="k-progress-status-wrap"
            [class.k-progress-start]="isPositionStart"
            [class.k-progress-center]="isPositionCenter"
            [class.k-progress-end]="isPositionEnd"
            [ngStyle]="emptyCssStyle"
            [ngClass]="emptyCssClass">
            <span *ngIf="showLabel" class="k-progress-status">{{formattedLabelValue}}</span>
        </span>
        <div
            #progressStatus
            class="k-selected k-progressbar-value"
            [class.k-complete]="isCompleted"
            [ngStyle]="progressCssStyle"
            [ngClass]="progressCssClass"
            [style.width.%]="statusWidth"
            [style.height.%]="statusHeight"
            >
            <span
                #progressStatusWrap
                class="k-progress-status-wrap"
                [style.width.%]="statusWrapperWidth"
                [style.height.%]="statusWrapperHeight"
                [class.k-progress-start]="isPositionStart"
                [class.k-progress-center]="isPositionCenter"
                [class.k-progress-end]="isPositionEnd"
                >
                <span *ngIf="showLabel" class="k-progress-status">{{formattedLabelValue}}</span>
            </span>
        </div>
       `,
      providers: [LocalizationService, {
        provide: L10N_PREFIX,
        useValue: 'kendo.progressbar'
      }]
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }, {
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.NgZone
    }];
  }, {
    label: [{
      type: Input
    }],
    progressCssStyle: [{
      type: Input
    }],
    progressCssClass: [{
      type: Input
    }],
    emptyCssStyle: [{
      type: Input
    }],
    emptyCssClass: [{
      type: Input
    }],
    animation: [{
      type: Input
    }],
    animationEnd: [{
      type: Output
    }],
    progressStatusElement: [{
      type: ViewChild,
      args: ['progressStatus', {
        static: false
      }]
    }],
    progressStatusWrapperElement: [{
      type: ViewChild,
      args: ['progressStatusWrap', {
        static: false
      }]
    }]
  });
})();

/**
 * Represents the [Kendo UI ChunkProgressBar component for Angular]({% slug overview_chunkprogressbar %}).
 *
 * @example
 * ```ts-preview
 * _@Component({
 *    selector: 'my-app',
 *    template: `
 *        <kendo-chunkprogressbar [value]="value">
 *        </kendo-chunkprogressbar>
 *    `
 * })
 * class AppComponent {
 *     public value = 40;
 * }
 * ```
 */
class ChunkProgressBarComponent extends ProgressBarBase {
  /**
   * @hidden
   */
  constructor(localization, elem, renderer) {
    super(elem, renderer, localization);
    this.localization = localization;
    this.elem = elem;
    this.renderer = renderer;
    this.chunkClass = true;
    /**
     * Sets the number of chunks into which the ChunkProgressBar will be split.
     * Defaults to `5`.
     */
    this.chunkCount = 5;
    this._orientationStyles = {
      width: `${this.chunkSizePercentage}%`,
      height: null
    };
  }
  /**
   * @hidden
   */
  get chunks() {
    const count = this.chunkCount;
    const chunks = Array(count).fill(false);
    const completedChunks = Math.floor(this._progressRatio * count);
    for (let i = 0; i < completedChunks; i++) {
      chunks[i] = true;
    }
    return chunks;
  }
  /**
   * @hidden
   */
  get chunkSizePercentage() {
    return 100 / this.chunkCount;
  }
  /**
   * @hidden
   */
  get orientationStyles() {
    if (this.orientation === 'horizontal') {
      this._orientationStyles.width = `${this.chunkSizePercentage}%`;
      this._orientationStyles.height = null;
    } else {
      this._orientationStyles.height = `${this.chunkSizePercentage}%`;
      this._orientationStyles.width = null;
    }
    return this._orientationStyles;
  }
}
ChunkProgressBarComponent.ɵfac = function ChunkProgressBarComponent_Factory(t) {
  return new (t || ChunkProgressBarComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
};
ChunkProgressBarComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ChunkProgressBarComponent,
  selectors: [["kendo-chunkprogressbar"]],
  hostVars: 2,
  hostBindings: function ChunkProgressBarComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("k-chunk-progressbar", ctx.chunkClass);
    }
  },
  inputs: {
    chunkCount: "chunkCount",
    progressCssStyle: "progressCssStyle",
    progressCssClass: "progressCssClass",
    emptyCssStyle: "emptyCssStyle",
    emptyCssClass: "emptyCssClass"
  },
  exportAs: ["kendoChunkProgressBar"],
  features: [i0.ɵɵProvidersFeature([LocalizationService, {
    provide: L10N_PREFIX,
    useValue: 'kendo.chunkprogressbar'
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 3,
  vars: 1,
  consts: () => {
    let i18n_1;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The aria-label attribute for the ChunkProgressBar component.
       * @meaning kendo.chunkprogressbar.progressBarLabel
       */
      const MSG__USR_SHARE_NGINX_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PROGRESSBAR_FESM2020_PROGRESS_KENDO_ANGULAR_PROGRESSBAR_MJS_1 = goog.getMsg("Chunk progressbar");
      i18n_1 = MSG__USR_SHARE_NGINX_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PROGRESSBAR_FESM2020_PROGRESS_KENDO_ANGULAR_PROGRESSBAR_MJS_1;
    } else {
      i18n_1 = $localize`:kendo.chunkprogressbar.progressBarLabel|The aria-label attribute for the ChunkProgressBar component.:Chunk progressbar`;
    }
    return [["kendoProgressBarLocalizedMessages", "", "progressBarLabel", i18n_1], [1, "k-reset", "k-progressbar-chunks"], ["class", "k-progressbar-chunk", 3, "k-first", "k-last", "k-selected", "ngClass", "ngStyle", "width", "height", 4, "ngFor", "ngForOf"], [1, "k-progressbar-chunk", 3, "ngClass", "ngStyle"]];
  },
  template: function ChunkProgressBarComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementContainer(0, 0);
      i0.ɵɵelementStart(1, "ul", 1);
      i0.ɵɵtemplate(2, ChunkProgressBarComponent_li_2_Template, 1, 12, "li", 2);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngForOf", ctx.chunks);
    }
  },
  dependencies: [i2.NgClass, i2.NgForOf, i2.NgStyle, LocalizedProgressBarMessagesDirective],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ChunkProgressBarComponent, [{
    type: Component,
    args: [{
      exportAs: 'kendoChunkProgressBar',
      selector: 'kendo-chunkprogressbar',
      template: `
        <ng-container kendoProgressBarLocalizedMessages
            i18n-progressBarLabel="kendo.chunkprogressbar.progressBarLabel|The aria-label attribute for the ChunkProgressBar component."
            progressBarLabel="Chunk progressbar"
        >
        </ng-container>
        <ul class="k-reset k-progressbar-chunks">
            <li class="k-progressbar-chunk" *ngFor="let chunk of chunks; let i = index;"
                [class.k-first]="i === 0"
                [class.k-last]="i === chunkCount - 1"
                [class.k-selected]="chunk"
                [ngClass]="chunk ? progressCssClass : emptyCssClass"
                [ngStyle]="chunk ? progressCssStyle : emptyCssStyle"
                [style.width]="orientationStyles.width"
                [style.height]="orientationStyles.height"
                >
            </li>
        </ul>
    `,
      providers: [LocalizationService, {
        provide: L10N_PREFIX,
        useValue: 'kendo.chunkprogressbar'
      }]
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }, {
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, {
    chunkClass: [{
      type: HostBinding,
      args: ['class.k-chunk-progressbar']
    }],
    chunkCount: [{
      type: Input
    }],
    progressCssStyle: [{
      type: Input
    }],
    progressCssClass: [{
      type: Input
    }],
    emptyCssStyle: [{
      type: Input
    }],
    emptyCssClass: [{
      type: Input
    }]
  });
})();

/**
 * A directive that selects a template within the <kendo-circularprogessbar> component which will be used for the center template.
 * ([see example]({% slug center_template_circularprogressbar %})).
 */
class CircularProgressbarCenterTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
CircularProgressbarCenterTemplateDirective.ɵfac = function CircularProgressbarCenterTemplateDirective_Factory(t) {
  return new (t || CircularProgressbarCenterTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef));
};
CircularProgressbarCenterTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: CircularProgressbarCenterTemplateDirective,
  selectors: [["", "kendoCircularProgressbarCenterTemplate", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CircularProgressbarCenterTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoCircularProgressbarCenterTemplate]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }];
  }, null);
})();
const DEFAULT_SURFACE_SIZE = 200;
/**
 * Represents the [Kendo UI Circular ProgressBar component for Angular]({% slug overview_circularprogressbar %}).
 *
 * @example
 * ```ts-preview
 * _@Component({
 *    selector: 'my-app',
 *    template: `
 *        <kendo-circularprogressbar [value]="value"></kendo-circularprogressbar>
 *    `
 * })
 * class AppComponent {
 *     public value: number = 50;
 * }
 * ```
 */
class CircularProgressBarComponent {
  constructor(renderer, cdr, localization, element, zone) {
    this.renderer = renderer;
    this.cdr = cdr;
    this.localization = localization;
    this.element = element;
    this.zone = zone;
    this.hostClasses = true;
    this.roleAttribute = 'progressbar';
    /**
     * Indicates whether an animation will be played on value changes.
     *
     * @default false
     */
    this.animation = false;
    /**
     * The opacity of the value arc.
     * @default 1
     */
    this.opacity = 1;
    /**
     * Fires when the animation which indicates the latest value change is completed.
     */
    this.animationEnd = new EventEmitter();
    this.centerTemplateContext = {};
    this._indeterminate = false;
    this._max = 100;
    this._min = 0;
    this._value = 0;
    this.previousValue = 0;
    this.internalValue = 0;
    this.subscriptions = new Subscription();
    validatePackage(packageMetadata);
    this.subscriptions.add(this.localization.changes.subscribe(this.rtlChange.bind(this)));
  }
  get ariaMinAttribute() {
    return String(this.min);
  }
  get ariaMaxAttribute() {
    return String(this.max);
  }
  get ariaValueAttribute() {
    return this.indeterminate ? undefined : String(this.value);
  }
  /**
   * Sets the default value of the Circular Progressbar between `min` and `max`.
   *
   * @default 0
   */
  set value(value) {
    if (value > this.max) {
      this.handleErrors('value > max');
    }
    if (value < this.min) {
      this.handleErrors('value < min');
    }
    this.previousValue = this.value;
    this._value = value;
  }
  get value() {
    return this._value;
  }
  /**
   * The maximum value which the Circular Progressbar can accept.
   *
   * @default 100
   */
  set max(max) {
    if (max < this.min) {
      this.handleErrors('max < min');
    }
    this._max = max;
  }
  get max() {
    return this._max;
  }
  /**
   * The minimum value which the Circular Progressbar can accept.
   *
   * @default 0
   */
  set min(min) {
    if (min > this.max) {
      this.handleErrors('max < min');
    }
    this._min = min;
  }
  get min() {
    return this._min;
  }
  /**
   * Puts the Circular ProgressBar in indeterminate state.
   * @default false
   */
  set indeterminate(indeterminate) {
    this._indeterminate = indeterminate;
  }
  get indeterminate() {
    return this._indeterminate;
  }
  ngAfterViewInit() {
    if (!isDocumentAvailable()) {
      return;
    }
    const elem = this.element.nativeElement;
    const ariaLabel = this.localization.get('progressBarLabel');
    this.renderer.setAttribute(elem, 'aria-label', ariaLabel);
    this.initProgressArc();
  }
  ngOnChanges(changes) {
    const skipFirstChange = true;
    if (isChanged('value', changes, skipFirstChange) && this.progress) {
      if (this.animation) {
        this.progressbarAnimation();
      } else {
        const value = this.value - this.min;
        this.internalValue = changes['value'].currentValue;
        this.calculateProgress(value);
      }
    }
    if (changes['opacity'] && this.progress) {
      setProgressBarStyles([{
        method: 'setAttribute',
        el: this.progress.nativeElement,
        attr: 'opacity',
        attrValue: this.opacity.toString()
      }], this.renderer);
    }
    if (changes['indeterminate'] && !changes['indeterminate'].firstChange) {
      this.indeterminateState();
    }
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  /**
   * @hidden
   */
  onResize() {
    this.setStyles();
    const value = this.animation ? this.internalValue : this.value;
    this.calculateProgress(value);
    this.updateCenterTemplate(value);
  }
  initProgressArc() {
    this.setStyles();
    if (this.indeterminate) {
      this.indeterminateState();
    } else {
      if (!this.animation) {
        const value = this.value - this.min;
        this.calculateProgress(value);
      } else {
        this.progressbarAnimation();
      }
    }
  }
  calculateProgress(value) {
    if (this.progressColor) {
      this.updateProgressColor(value);
    }
    // needed when we have *ngIf inside the template to render different content depending on some condition
    this.zone.onStable.pipe(take(1)).subscribe(() => {
      this.updateCenterTemplate(value + this.min);
    });
    const progressArc = this.progress.nativeElement;
    const radius = this.progress.nativeElement.r.baseVal.value;
    const circumference = Math.PI * (radius * 2);
    const dir = this.rtl ? circumference * -1 : circumference;
    const strokeDashOffest = circumference - dir * (value / (this.max - this.min));
    const progressCalculations = [{
      method: 'setStyle',
      el: progressArc,
      attr: 'strokeDasharray',
      attrValue: circumference.toString()
    }, {
      method: 'setStyle',
      el: progressArc,
      attr: 'strokeDashoffset',
      attrValue: strokeDashOffest.toString()
    }];
    setProgressBarStyles(progressCalculations, this.renderer);
  }
  progressbarAnimation() {
    const forwardProgress = {
      isOngoing: this.internalValue > this.value - this.min,
      isPositive: this.value >= this.previousValue
    };
    const backwardProgress = {
      isOngoing: this.internalValue < this.value - this.min,
      isNegative: this.value <= this.previousValue
    };
    if (forwardProgress.isOngoing && forwardProgress.isPositive || backwardProgress.isOngoing && backwardProgress.isNegative) {
      return;
    }
    this.calculateProgress(this.internalValue);
    const from = this.internalValue;
    if (hasObservers(this.animationEnd)) {
      this.animationEnd.emit({
        from: from,
        to: this.internalValue
      });
    }
    // eslint-disable-next-line no-unused-expressions
    forwardProgress.isPositive ? this.internalValue += 1 : this.internalValue -= 1;
    requestAnimationFrame(this.progressbarAnimation.bind(this));
  }
  setStyles() {
    const progressArc = this.progress.nativeElement;
    const scale = this.scale.nativeElement;
    const surface = this.surface.nativeElement;
    const element = this.element.nativeElement;
    let elWidth = element.getBoundingClientRect().width;
    if (!hasElementSize(element)) {
      const surfaceSize = [{
        method: 'setStyle',
        el: surface,
        attr: 'width',
        attrValue: `${DEFAULT_SURFACE_SIZE}px`
      }, {
        method: 'setStyle',
        el: surface,
        attr: 'height',
        attrValue: `${DEFAULT_SURFACE_SIZE}px`
      }];
      elWidth = DEFAULT_SURFACE_SIZE;
      setProgressBarStyles(surfaceSize, this.renderer);
    }
    const attributesArray = [{
      method: 'setAttribute',
      el: progressArc,
      attr: 'r',
      attrValue: String(elWidth / 2 - 10)
    }, {
      method: 'setAttribute',
      el: progressArc,
      attr: 'cx',
      attrValue: String(elWidth / 2)
    }, {
      method: 'setAttribute',
      el: progressArc,
      attr: 'cy',
      attrValue: String(elWidth / 2)
    }, {
      method: 'setAttribute',
      el: progressArc,
      attr: 'opacity',
      attrValue: String(this.opacity)
    }, {
      method: 'setAttribute',
      el: scale,
      attr: 'r',
      attrValue: String(elWidth / 2 - 10)
    }, {
      method: 'setAttribute',
      el: scale,
      attr: 'cx',
      attrValue: String(elWidth / 2)
    }, {
      method: 'setAttribute',
      el: scale,
      attr: 'cy',
      attrValue: String(elWidth / 2)
    }];
    setProgressBarStyles(attributesArray, this.renderer);
  }
  indeterminateState() {
    const progressArc = this.progress.nativeElement;
    if (this.indeterminate) {
      // the indeterminate state wont work as the `k-circular-progressbar-arc` has a transform: rotate(-90deg) which is
      // interfering with the svg animation as the animateTransform brings its own transform: rotate()
      // This will be like this until the themes release a new version, bringing a new class `k-circular-progressbar-indeterminate-arc`
      // containing only the necassery CSS styles and we will switch between them when the state of the progressbar is switched.
      this.calculateProgress(this.value - this.min);
      const rotate = this.rtl ? {
        from: 360,
        to: 0
      } : {
        from: 0,
        to: 360
      };
      let color;
      if (!this.progressColor) {
        color = getComputedStyle(progressArc).stroke;
      }
      const indeterminateStyles = [{
        method: 'setStyle',
        el: progressArc,
        attr: 'transform-origin',
        attrValue: 'center'
      }, {
        method: 'setStyle',
        el: progressArc,
        attr: 'fill',
        attrValue: 'none'
      }, {
        method: 'setStyle',
        el: progressArc,
        attr: 'stroke-linecap',
        attrValue: 'round'
      }, {
        method: 'setStyle',
        el: progressArc,
        attr: 'stroke',
        attrValue: color ? color : this.currentColor
      }];
      setProgressBarStyles(indeterminateStyles, this.renderer);
      this.renderer.removeClass(progressArc, 'k-circular-progressbar-arc');
      progressArc.innerHTML = `<animateTransform attributeName="transform" type="rotate" from="${rotate.from} 0 0" to="${rotate.to} 0 0" dur="1s" repeatCount="indefinite" />`;
    } else {
      this.renderer.addClass(progressArc, 'k-circular-progressbar-arc');
      const removeIndeterminateStyles = [{
        method: 'removeStyle',
        el: progressArc,
        attr: 'transform-origin'
      }, {
        method: 'removeStyle',
        el: progressArc,
        attr: 'fill'
      }, {
        method: 'removeStyle',
        el: progressArc,
        attr: 'stroke-linecap'
      }];
      removeProgressBarStyles(removeIndeterminateStyles, this.renderer);
      progressArc.innerHTML = '';
      if (this.animation) {
        this.progressbarAnimation();
      }
    }
  }
  updateCenterTemplate(value) {
    if (!this.centerTemplate) {
      return;
    }
    this.centerTemplateContext.value = value;
    this.centerTemplateContext.color = this.currentColor;
    this.cdr.detectChanges();
    this.positionLabel();
  }
  positionLabel() {
    const labelEl = this.labelElement.nativeElement;
    const element = this.element.nativeElement;
    const surface = this.surface.nativeElement;
    let elWidth;
    let elHeight;
    if (!hasElementSize(element)) {
      const surfaceSize = surface.getBoundingClientRect();
      elWidth = surfaceSize.width;
      elHeight = surfaceSize.height;
    } else {
      const elementSize = element.getBoundingClientRect();
      elWidth = elementSize.width;
      elHeight = elementSize.height;
    }
    const left = elWidth / 2 - labelEl.offsetWidth / 2;
    const top = elHeight / 2 - labelEl.offsetHeight / 2;
    const labelCalculations = [{
      method: 'setStyle',
      el: labelEl,
      attr: 'left',
      attrValue: `${left}px`
    }, {
      method: 'setStyle',
      el: labelEl,
      attr: 'top',
      attrValue: `${top}px`
    }];
    setProgressBarStyles(labelCalculations, this.renderer);
  }
  get currentColor() {
    const currentColor = this.progress.nativeElement.style.stroke;
    return currentColor;
  }
  updateProgressColor(value) {
    const progressArc = this.progress.nativeElement;
    if (typeof this.progressColor === 'string') {
      this.renderer.setStyle(progressArc, 'stroke', this.progressColor);
    } else {
      for (let i = 0; i < this.progressColor.length; i++) {
        const from = this.progressColor[i].from;
        const to = this.progressColor[i].to;
        if (value >= from && value <= to || !from && value <= to) {
          this.renderer.setStyle(progressArc, 'stroke', this.progressColor[i].color);
          break;
        }
        if (!to && value >= from) {
          this.renderer.setStyle(progressArc, 'stroke', this.progressColor[i].color);
        }
      }
    }
  }
  handleErrors(type) {
    if (isDevMode()) {
      switch (type) {
        case 'value > max':
          throw new Error('The value of the CircularProgressbar cannot exceed the max value');
        case 'value < min':
          throw new Error('The value of the CircularProgressbar cannot be lower than the min value');
        case 'max < min':
          throw new Error('The min value cannot be higher than the max value');
        default:
      }
    }
  }
  setDirection() {
    this.rtl = this.localization.rtl;
    if (this.element) {
      this.renderer.setAttribute(this.element.nativeElement, 'dir', this.rtl ? 'rtl' : 'ltr');
    }
    if (this.labelElement) {
      this.renderer.setAttribute(this.labelElement.nativeElement, 'dir', this.rtl ? 'rtl' : 'ltr');
    }
  }
  rtlChange() {
    if (this.element && this.rtl !== this.localization.rtl) {
      this.setDirection();
    }
  }
}
CircularProgressBarComponent.ɵfac = function CircularProgressBarComponent_Factory(t) {
  return new (t || CircularProgressBarComponent)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
CircularProgressBarComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CircularProgressBarComponent,
  selectors: [["kendo-circularprogressbar"]],
  contentQueries: function CircularProgressBarComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, CircularProgressbarCenterTemplateDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.centerTemplate = _t.first);
    }
  },
  viewQuery: function CircularProgressBarComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c2, 5);
      i0.ɵɵviewQuery(_c3, 5);
      i0.ɵɵviewQuery(_c4, 5);
      i0.ɵɵviewQuery(_c5, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.progress = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.scale = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.labelElement = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.surface = _t.first);
    }
  },
  hostVars: 6,
  hostBindings: function CircularProgressBarComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("aria-valuemin", ctx.ariaMinAttribute)("aria-valuemax", ctx.ariaMaxAttribute)("aria-valuenow", ctx.ariaValueAttribute)("role", ctx.roleAttribute);
      i0.ɵɵclassProp("k-circular-progressbar", ctx.hostClasses);
    }
  },
  inputs: {
    value: "value",
    max: "max",
    min: "min",
    animation: "animation",
    opacity: "opacity",
    indeterminate: "indeterminate",
    progressColor: "progressColor"
  },
  outputs: {
    animationEnd: "animationEnd"
  },
  exportAs: ["kendoCircularProgressBar"],
  features: [i0.ɵɵProvidersFeature([LocalizationService, {
    provide: L10N_PREFIX,
    useValue: 'kendo.circularprogressbar'
  }]), i0.ɵɵNgOnChangesFeature],
  decls: 13,
  vars: 1,
  consts: () => {
    let i18n_2;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The aria-label attribute for the Circular ProgressBar component.
       * @meaning kendo.circularprogressbar.progressBarLabel
       */
      const MSG__USR_SHARE_NGINX_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PROGRESSBAR_FESM2020_PROGRESS_KENDO_ANGULAR_PROGRESSBAR_MJS_2 = goog.getMsg("Circular progressbar");
      i18n_2 = MSG__USR_SHARE_NGINX_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PROGRESSBAR_FESM2020_PROGRESS_KENDO_ANGULAR_PROGRESSBAR_MJS_2;
    } else {
      i18n_2 = $localize`:kendo.circularprogressbar.progressBarLabel|The aria-label attribute for the Circular ProgressBar component.:Circular progressbar`;
    }
    return [["surface", ""], ["svg", ""], ["scale", ""], ["progress", ""], ["label", ""], ["kendoProgressBarLocalizedMessages", "", "progressBarLabel", i18n_2], [1, "k-circular-progressbar-surface"], ["stroke-width", "9.5", 1, "k-circular-progressbar-scale"], ["stroke-width", "9.5", 1, "k-circular-progressbar-arc"], ["class", "k-circular-progressbar-label", 4, "ngIf"], [3, "resize"], [1, "k-circular-progressbar-label"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"]];
  },
  template: function CircularProgressBarComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵelementContainer(0, 5);
      i0.ɵɵelementStart(1, "div", 6, 0)(3, "div");
      i0.ɵɵnamespaceSVG();
      i0.ɵɵelementStart(4, "svg", null, 1)(6, "g");
      i0.ɵɵelement(7, "circle", 7, 2)(9, "circle", 8, 3);
      i0.ɵɵelementEnd()();
      i0.ɵɵtemplate(11, CircularProgressBarComponent_div_11_Template, 3, 2, "div", 9);
      i0.ɵɵelementEnd()();
      i0.ɵɵnamespaceHTML();
      i0.ɵɵelementStart(12, "kendo-resize-sensor", 10);
      i0.ɵɵlistener("resize", function CircularProgressBarComponent_Template_kendo_resize_sensor_resize_12_listener() {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onResize());
      });
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵadvance(11);
      i0.ɵɵproperty("ngIf", ctx.centerTemplate);
    }
  },
  dependencies: [i2.NgIf, i2.NgTemplateOutlet, i3.ResizeSensorComponent, LocalizedProgressBarMessagesDirective],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CircularProgressBarComponent, [{
    type: Component,
    args: [{
      exportAs: 'kendoCircularProgressBar',
      selector: 'kendo-circularprogressbar',
      template: `
        <ng-container kendoProgressBarLocalizedMessages
            i18n-progressBarLabel="kendo.circularprogressbar.progressBarLabel|The aria-label attribute for the Circular ProgressBar component."
            progressBarLabel="Circular progressbar"
        >
        </ng-container>
        <div #surface class="k-circular-progressbar-surface">
            <div>
                <svg #svg>
                    <g>
                        <circle class="k-circular-progressbar-scale" #scale stroke-width="9.5"></circle>
                        <circle class="k-circular-progressbar-arc" #progress stroke-width="9.5"></circle>
                    </g>
                </svg>
                <div class="k-circular-progressbar-label" *ngIf="centerTemplate" #label>
                    <ng-template [ngTemplateOutlet]="centerTemplate.templateRef" [ngTemplateOutletContext]="centerTemplateContext"></ng-template>
                </div>
            </div>
        </div>
        <kendo-resize-sensor (resize)="onResize()"></kendo-resize-sensor>
    `,
      providers: [LocalizationService, {
        provide: L10N_PREFIX,
        useValue: 'kendo.circularprogressbar'
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: i1.LocalizationService
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, {
    hostClasses: [{
      type: HostBinding,
      args: ['class.k-circular-progressbar']
    }],
    ariaMinAttribute: [{
      type: HostBinding,
      args: ['attr.aria-valuemin']
    }],
    ariaMaxAttribute: [{
      type: HostBinding,
      args: ['attr.aria-valuemax']
    }],
    ariaValueAttribute: [{
      type: HostBinding,
      args: ['attr.aria-valuenow']
    }],
    roleAttribute: [{
      type: HostBinding,
      args: ['attr.role']
    }],
    value: [{
      type: Input
    }],
    max: [{
      type: Input
    }],
    min: [{
      type: Input
    }],
    animation: [{
      type: Input
    }],
    opacity: [{
      type: Input
    }],
    indeterminate: [{
      type: Input
    }],
    progressColor: [{
      type: Input
    }],
    animationEnd: [{
      type: Output
    }],
    progress: [{
      type: ViewChild,
      args: ['progress']
    }],
    scale: [{
      type: ViewChild,
      args: ['scale']
    }],
    labelElement: [{
      type: ViewChild,
      args: ["label"]
    }],
    surface: [{
      type: ViewChild,
      args: ["surface"]
    }],
    centerTemplate: [{
      type: ContentChild,
      args: [CircularProgressbarCenterTemplateDirective]
    }]
  });
})();

/**
 * Custom component messages override default component messages
 * ([see example]({% slug rtl_layout %})).
 */
class ProgressBarCustomMessagesComponent extends ProgressBarMessages {
  constructor(service) {
    super();
    this.service = service;
  }
  get override() {
    return true;
  }
}
ProgressBarCustomMessagesComponent.ɵfac = function ProgressBarCustomMessagesComponent_Factory(t) {
  return new (t || ProgressBarCustomMessagesComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService));
};
ProgressBarCustomMessagesComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ProgressBarCustomMessagesComponent,
  selectors: [["kendo-progressbar-messages"]],
  features: [i0.ɵɵProvidersFeature([{
    provide: ProgressBarMessages,
    useExisting: forwardRef(() => ProgressBarCustomMessagesComponent)
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function ProgressBarCustomMessagesComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProgressBarCustomMessagesComponent, [{
    type: Component,
    args: [{
      providers: [{
        provide: ProgressBarMessages,
        useExisting: forwardRef(() => ProgressBarCustomMessagesComponent)
      }],
      selector: 'kendo-progressbar-messages',
      template: ``
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }];
  }, null);
})();
const COMPONENT_DIRECTIVES = [ProgressBarComponent, ChunkProgressBarComponent, CircularProgressBarComponent, CircularProgressbarCenterTemplateDirective, LocalizedProgressBarMessagesDirective, ProgressBarCustomMessagesComponent];
const MODULES = [CommonModule, ResizeSensorModule];
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmodules'])
 * definition for the ProgressBar components.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the ProgressBar module
 * import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, ProgressBarModule], // import ProgressBar module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class ProgressBarModule {}
ProgressBarModule.ɵfac = function ProgressBarModule_Factory(t) {
  return new (t || ProgressBarModule)();
};
ProgressBarModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ProgressBarModule
});
ProgressBarModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [MODULES]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProgressBarModule, [{
    type: NgModule,
    args: [{
      declarations: COMPONENT_DIRECTIVES,
      exports: COMPONENT_DIRECTIVES,
      imports: MODULES
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ChunkProgressBarComponent, CircularProgressBarComponent, CircularProgressbarCenterTemplateDirective, LocalizedProgressBarMessagesDirective, ProgressBarComponent, ProgressBarCustomMessagesComponent, ProgressBarModule };