import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { redo, undo } from './undo-redo.actions';
import { combineLatest } from 'rxjs';
import { history$ } from './undo-redo-meta.reducer';
import { UndoRedoOperations } from '../../../shared/data-types/undo-redo-types';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UndoRedoService {
  constructor(private readonly store: Store) {
    combineLatest([history$])
      .pipe(
        filter(
          ([historyEntry]) =>
            historyEntry.undoRedoAction === UndoRedoOperations.Undo ||
            historyEntry.undoRedoAction === UndoRedoOperations.Redo,
        ),
      )
      .subscribe(([historyEntry]) => {
        const undoRedoMeta = historyEntry.meta;
        const item =
          historyEntry.present[undoRedoMeta.undoableType].entities[undoRedoMeta.undoableId];
        undoRedoMeta.undoableFn(item, false);
      });
  }

  public doUndo(): void {
    this.store.dispatch(undo());
  }

  public doRedo(): void {
    this.store.dispatch(redo());
  }
}
