import interact from 'interactjs';
import { Interactable } from '@interactjs/core/Interactable';
import { SnapOptions } from '@interactjs/modifiers/snap/pointer';
import { DropzoneOptions } from '@interactjs/actions/drop/plugin';
import { RestrictOptions } from '@interactjs/modifiers/restrict/pointer';
import { Position } from 'src/app/shared/data-structures/position';
import { InteractCallbackFns } from '../../../../../../shared/data-types/interact.types';
import { ITimeBlockComponentItem } from '../../time-block-component-items';
import { DragPart } from './drag-and-resize/drag-part';
import { TimeBlockElementSelectorService } from '../../rendering/time-block-element-selector.service';
import { Injectable, NgZone } from '@angular/core';
import { CalendarMouseHandlerService } from '../../../../mouse/calendar-mouse-handler.service';
import { take } from 'rxjs/operators';
import { TimeBlockDurationCalculationService } from '../../calculation/duration/time-block-duration-calculation.service';
import { Interaction } from '@interactjs/types';

/**
 * Primary class for interact.js actions. Initializes drag and resize events for time block. Not applied when creating a time block.
 */
@Injectable()
export class InteractHandlerService {
  public static dropCoordinate: Partial<Position>;
  private interactHandlerRefs = new Array<Interactable>();

  constructor(
    private readonly calendarMouseHandlerService: CalendarMouseHandlerService,
    private readonly timeBlockDuractionCalculationService: TimeBlockDurationCalculationService,
    private readonly ngZone: NgZone,
  ) {}

  public makeMovable(
    timeBlock: ITimeBlockComponentItem,
    dragPart: DragPart,
    snapOptions?: SnapOptions,
    restrictOptions?: RestrictOptions,
    callbackFns?: InteractCallbackFns,
  ): void {
    let listeners: Interact.Listeners;
    if (callbackFns) {
      listeners = {
        start: callbackFns.startFn,
        move: callbackFns.dragFn,
        end: callbackFns.endFn,
      };
    }

    const modifiers = [];
    if (restrictOptions) {
      modifiers.push(interact.modifiers.restrict(restrictOptions));
    }

    if (snapOptions) {
      modifiers.push(interact.modifiers.snap(snapOptions));
    }

    let target: HTMLElement;

    if (dragPart === DragPart.Top || dragPart === DragPart.Left) {
      target = TimeBlockElementSelectorService.getTimeBlockFirstHandle(timeBlock);
    } else if (dragPart === DragPart.Bottom || dragPart === DragPart.Right) {
      target = TimeBlockElementSelectorService.getTimeBlockSecondHandle(timeBlock);
    } else {
      target = TimeBlockElementSelectorService.getTimeBlockBody(timeBlock);
    }

    // 1. Take the original target (top, bottom, left, right or body of time block) and clone it.
    // 2. Manually start the drag interaction since we need to create a ghost (transparent) block and a drag dummy block first.
    // 3. The start callback in time-block-drag-resize.services.ts is being triggered and
    // the dragStart$ or resizeStart$ subject is emitted.
    // 4. The interaction (drag or resize) is called.
    // Info: We use the "down" event here since "move" does not work if the mouse cursor is moved very quickly.
    this.ngZone.runOutsideAngular(() => {
      const ref = interact(target)
        .draggable({
          listeners,
          modifiers,
          manualStart: true,
        })
        .on(
          'down',
          (event: {
            interactable?: Interactable;
            currentTarget?: unknown;
            interaction?: Interaction;
          }) => {
            const { interaction } = event;

            this.calendarMouseHandlerService.timeBlockPressedSub =
              this.calendarMouseHandlerService.timeBlockPressed$
                .pipe(take(1))
                .subscribe((selectedTimeBlock) => {
                  if (!interaction.interacting()) {
                    const draggingTimeBlock = callbackFns.init(selectedTimeBlock);
                    interaction.start(
                      { name: 'drag' },
                      event.interactable,
                      TimeBlockElementSelectorService.getTimeBlockHTMLWrapper(draggingTimeBlock),
                    );
                  }
                });
          },
        );
      this.interactHandlerRefs.push(ref);
    });
  }

  public makeDroppable(
    target: Interact.Target,
    options: DropzoneOptions,
    dragEnter?: DropFn,
    dragLeave?: DropFn,
    dropActivate?: DropFn,
    drop?: DropFn,
    dropDeactivate?: DropFn,
  ): void {
    options.ondragenter = dragEnter;
    options.ondragleave = dragLeave;
    options.ondropactivate = dropActivate;
    options.ondrop = drop;
    options.ondropdeactivate = dropDeactivate;

    interact(target).dropzone(options);
  }

  public deregisterHandlers(): void {
    this.interactHandlerRefs.forEach((handler) => handler.unset());
    this.interactHandlerRefs = [];
  }
}

export type DragFn = (event?: Interact.DragEvent) => void;
export type DropFn = (event?: Interact.DropEvent) => void;
