import { TimeBlockStructureService } from './time-block-structure/time-block-structure.service';
import { TimeBlockItemBuilderService } from './generation/time-block-item-builder.service';
import { UndoableItem } from '../../../../shared/data-types/undo-redo-types';
import { TimeBlockContentType } from '../../../../shared/data-types/time-block-types';
import { TimeBlockModel } from '../../../../core/models/timeblock/time-block.model';

export interface ITimeBlockComponentItem {
  id: number;
  timeBlockModel: TimeBlockModel;
  timeBlockContentType: TimeBlockContentType;

  clone: (timeBlockItemBuilderService: TimeBlockItemBuilderService) => ITimeBlockComponentItem;

  insertIntoCalendar: (timeBlockStructureService: TimeBlockStructureService) => void;

  replaceInCalendar?: (timeBlockStructureService: TimeBlockStructureService) => void;

  removeFromCalendar: (timeBlockStructureService: TimeBlockStructureService) => void;
}

/**
 * Either a time block that is fetched from the server and is already built or a ghost time block
 */
export class GeneratedTimeBlockComponent implements ITimeBlockComponentItem, UndoableItem {
  public readonly id: number;

  constructor(
    public timeBlockModel: TimeBlockModel,
    public timeBlockContentType: TimeBlockContentType,
  ) {
    this.id = timeBlockModel.id;
  }

  public clone(timeBlockItemBuilderService: TimeBlockItemBuilderService): ITimeBlockComponentItem {
    return timeBlockItemBuilderService.buildFromExisting(this, this.timeBlockContentType);
  }

  public insertIntoCalendar(timeBlockStructureService: TimeBlockStructureService): void {
    timeBlockStructureService.insert(this);
  }

  public replaceInCalendar(timeBlockStructureService: TimeBlockStructureService): void {
    timeBlockStructureService.replace(this);
  }

  public removeFromCalendar(timeBlockStructureService: TimeBlockStructureService): void {
    timeBlockStructureService.remove(this);
  }
}
