import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  BillableRateOptions,
  BudgetOptions,
} from 'src/app/core/data-repository/dropdown-constants';
import { DropdownOption } from '../../../../../../../shared/data-types/dropdown-option';
import { mapToDropdownOptions } from '../../../../../../../shared/functions/dropdown-functions';
import {
  BudgetOptionLiterals,
  ResetBudgetOptionLiterals,
} from '../../../../../../../core/text-db/project-calculation.text';

@Component({
  selector: 'app-project-calculation-form-reset-budget',
  templateUrl: './project-calculation-form-reset-budget.component.html',
  styleUrls: ['./project-calculation-form-reset-budget.component.scss'],
})
export class ProjectCalculationFormResetBudgetComponent implements OnInit {
  @Input() selectedBudgetRateOptionChanged: EventEmitter<BudgetOptions>;
  public projectCalculationForm: FormGroup;
  public selectedBudgetOption: BudgetOptions;
  public budgetOptions$: Observable<DropdownOption[]>;
  public resetBudgetOptions$: Observable<DropdownOption[]>;
  public readonly BudgetOptions = BudgetOptions;
  public readonly BillableRateOptions = BillableRateOptions;

  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.buildForm();
    this.initEvents();
  }

  private buildForm(): void {
    this.projectCalculationForm = this.formBuilder.group({
      budgetGroup: this.formBuilder.group({
        budgetType: [
          {
            text: 'Kein Budget',
            value: 0,
          },
          Validators.required,
        ],
        budgetValue: [null, Validators.required],
      }),
      resetBudgetGroup: this.formBuilder.group({
        resetBudget: [null, Validators.required],
        resetBudgetFrequency: [null, Validators.required],
        resetBudgetAt: [null, Validators.required],
        resetBudgetValue: [null, Validators.required],
      }),
      exceedingBudgetGroup: this.formBuilder.group({
        exceedingBudgetWarning: [null, Validators.required],
        exceedingBudgetWarningEmail: [null, Validators.required],
        exceedingBudgetLimit: [null, Validators.required],
      }),
      billableRateGroup: this.formBuilder.group({
        billableRateType: [
          {
            text: 'Kein Verrechnungssatz',
            value: 0,
          },
          Validators.required,
        ],
        billableRateValue: [null, Validators.required],
      }),
    });
  }

  private initEvents(): void {
    this.budgetOptions$ = mapToDropdownOptions(BudgetOptionLiterals);
    this.resetBudgetOptions$ = mapToDropdownOptions(ResetBudgetOptionLiterals);

    this.selectedBudgetRateOptionChanged.subscribe((option) => {
      this.selectedBudgetOption = option;
    });
  }
}
