<div class="dialog-content">
  <form id="create-client-dialog" class="k-form" [formGroup]="clientForm">
    <kendo-formfield orientation="horizontal">
      <kendo-label class="!k-align-items-start" [for]="clientName" text="Kunde"></kendo-label>
      <kendo-textbox formControlName="clientName" #clientName> </kendo-textbox>
      <kendo-formerror>Error: Full Name is required</kendo-formerror>
    </kendo-formfield>
  </form>
</div>

<footer #dialogFooter class="k-d-flex k-justify-content-between">
  <button (click)="close()" kendoButton size="large" themeColor="secondary" class="k-mr-4 !k-pr-4">
    <span class="custom-symbol close k-mr-3"></span>
    <span class="label">Schließen</span>
  </button>

  <button kendoButton (click)="submit()" class="!k-pr-4" themeColor="base" size="large">
    <fa-icon class="add text-white k-mr-3" [icon]="faCirclePlus"></fa-icon>
    <span class="label">Hinzufügen</span>
  </button>
</footer>
