import { Injectable } from '@angular/core';
import { ActiveTimeBlockTimerService } from './active-time-block-timer.service';
import { ActiveTimeBlockTrackerService } from './active-time-block-tracker.service';
import { CalendarService } from '../../../services/calendar.service';
import { BaseCalendarModel } from '../../../../../core/models/calendar/base-calendar.model';
import { CalendarEvents, CalendarView } from '../../../../../shared/data-types/calendar-types';
import { DayOrWeekCalendarModel } from '../../../../../core/models/calendar/day-or-week-calendar.model';
import { MonthCalendarModel } from '../../../../../core/models/calendar/month-calendar.model';
import { plainToInstance } from 'class-transformer';
import { TrackerResponseModel } from '../../../../../core/models/tracker/tracker-response.model';
import { CalendarServiceHelper } from '../../../services/calendar-service-helper';
import { take } from 'rxjs';

/**
 * This service is responsible for both the manual time tracking and the automatic time tracking via Shive Tracker.
 */
@Injectable()
export class ActiveTimeBlockService {
  private timeTrackingStarted = false;

  constructor(
    private readonly activeTimeBlockTimerService: ActiveTimeBlockTimerService,
    private readonly activeTimeBlockTrackerService: ActiveTimeBlockTrackerService,
    private readonly calendarService: CalendarService,
  ) {
    this.initialize();
  }

  private initialize(): void {
    const targetEvents = [CalendarEvents.RenderedTimeBlocks];
    const callback = (): void => {
      if (this.timeTrackingStarted) {
        return;
      }
      this.initActiveTimeBlockTracking();
      this.timeTrackingStarted = true;
    };
    CalendarServiceHelper.calendarModelUpdated(this.calendarService, callback, targetEvents);

    this.trackerAliveChanged();
  }

  private initActiveTimeBlockTracking(): void {
    // Send initial message to find out if tracker is active.
    this.activeTimeBlockTrackerService.sendMessage();
    // Initialize correct tracker service depending on if the Shive tracker is active.
    this.activeTimeBlockTrackerService
      .getTrackerObservable()
      .pipe(take(1))
      .subscribe((trackerResponse) => {
        const trackerResponseModel = plainToInstance(TrackerResponseModel, trackerResponse);
        this.emitUpdatedCalendarModel(trackerResponseModel.isTrackerAlive);

        this.activeTimeBlockTrackerService.registerTimeTracking();
        this.activeTimeBlockTimerService.registerTimeTracking();
      });
  }

  private trackerAliveChanged(): void {
    this.activeTimeBlockTrackerService.trackerAliveChanged$.subscribe((trackerAlive) => {
      this.emitUpdatedCalendarModel(trackerAlive);
    });
  }

  /**
   * Update the calendar model with tracker active flag if Shive Tracker is active.
   */
  private emitUpdatedCalendarModel(isShiveTrackerActive: boolean): void {
    const calendarModel = this.calendarService.model;
    let updatedCalendarModel: BaseCalendarModel;
    if (
      calendarModel.calendarViewMode === CalendarView.DayGrid ||
      calendarModel.calendarViewMode === CalendarView.WeekGrid
    ) {
      updatedCalendarModel = new DayOrWeekCalendarModel();
    } else {
      // Month view
      updatedCalendarModel = new MonthCalendarModel();
    }
    Object.assign(updatedCalendarModel, this.calendarService.model);
    updatedCalendarModel.shiveTrackerActive = isShiveTrackerActive;

    this.calendarService.emitCalendarChange(
      updatedCalendarModel,
      CalendarEvents.UpdatedShiveTrackerStatus,
    );
  }
}
