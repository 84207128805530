@switch (userOperation) {
  @case (UserOperation.Delete) {
    <ng-container *ngTemplateOutlet="deleteTemplate; context: { client: clientModel }">
    </ng-container>
  }
}

<ng-template #deleteTemplate let-client="client">
  <div class="inner-wrapper k-p-10">
    {{ client.name }}
  </div>
</ng-template>

<footer #dialogFooter class="k-d-flex k-justify-content-between">
  <button (click)="close()" kendoButton size="large" themeColor="secondary" class="k-mr-4 !k-pr-4">
    <span class="custom-symbol close turquoise k-mr-3"></span>
    Schließen
  </button>
  <button kendoButton (click)="delete()" themeColor="error" class="!k-pr-4" size="large">
    <span class="custom-symbol delete red k-mr-3"></span>
    Löschen
  </button>
</footer>
