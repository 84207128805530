import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { SubSink } from 'subsink';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '../../../../../core/services/navigation.service';
import { Observable, forkJoin, merge, of, switchMap, take } from 'rxjs';
import {
  BillableRateOptions,
  BudgetOptions,
} from '../../../../../core/data-repository/dropdown-constants';
import { ManageProjectService } from './manage-project.service';
import { ComponentCanDeactivate } from '../../../../../core/guards/pending-changes.guard';
import { UserOperation } from '../../../../../core/enums/user-operation';
import { CustomError } from '../../../../../shared/data-types/client-error';
import { ProjectModel } from '../../../../../core/models/project/project.model';
import { map } from 'rxjs/operators';
import { StatusModel } from '../../../../../core/models/project/status.model';
import { ProjectService } from '../../../services/project.service';
import { ShiveRoutes } from '../../../../../core/routing/segments/route-segments';
import { Actions, ofType } from '@ngrx/effects';
import * as fromProjectActions from '../../../../../core/state/projects/projects.actions';

@Component({
  selector: 'app-manage-project',
  templateUrl: './manage-project.component.html',
  styleUrls: ['./manage-project.component.scss'],
})
export class ManageProjectComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  @Input() hideTitleBar = false;
  public selectedBillableRateOption: BillableRateOptions;
  public selectedBudgetOption: BudgetOptions;
  public saveButtonDisabled = true;
  public title = '';
  public projectStatuses: StatusModel[] = [];
  public removedAssigneeId = null;
  public userOperation = UserOperation.None;
  public readonly UserOperation = UserOperation;
  private readonly subs = new SubSink();

  constructor(
    public route: ActivatedRoute,
    private readonly router: Router,
    private readonly projectService: ProjectService,
    private readonly manageProjectService: ManageProjectService,
    private readonly navigationService: NavigationService,
    private readonly actions$: Actions,
  ) {}

  ngOnInit(): void {
    this.subs.sink = this.route.url
      .pipe(
        switchMap((url) => {
          if (url[0].path === ShiveRoutes.Create) {
            this.userOperation = UserOperation.Create;
            return of({});
          }

          this.userOperation = UserOperation.Update;
          return of(this.projectService.getProjectClone());
        }),
        switchMap((project: ProjectModel) => {
          return forkJoin([
            of(project),
            this.route.data.pipe(
              take(1),
              map((data) => data.projectStatuses),
            ),
          ]);
        }),
        take(1),
      )
      .subscribe(([project, statuses]) => {
        this.projectStatuses = statuses;

        if (this.userOperation === UserOperation.Update) {
          this.title = project.name;
        } else if (this.userOperation === UserOperation.Create) {
          this.title = null;
          this.manageProjectService.initNewProject(statuses);
        } else {
          throw new CustomError(
            'Error',
            `Invalid user operation: ${UserOperation[this.userOperation]}`,
          );
        }

        this.initEvents();
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  @HostListener('window:beforeunload')
  public canDeactivate(): boolean | Observable<boolean> {
    return this.saveButtonDisabled;
  }

  public cancel(): void {
    this.navigationService.back();
  }

  public save(): void {
    const success = this.manageProjectService.emitManageProjectHttpRequests();
    if (success) {
      this.saveButtonDisabled = true;
    }
  }

  private initEvents(): void {
    this.subs.sink = merge(this.projectService.userOperationConducted$).subscribe(() => {
      this.saveButtonDisabled = false;
    });

    // After a new project was created, reload this project to switch to edit mode.
    this.actions$
      .pipe(
        ofType(fromProjectActions.projectAdded),
        map((response) => response.payload),
      )
      .subscribe((newProject: ProjectModel) => {
        void this.router.navigate([
          ShiveRoutes.Projects,
          ShiveRoutes.Detail,
          ShiveRoutes.Edit,
          newProject.id,
        ]);
      });
  }
}
