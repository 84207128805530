import { UserOperation } from '../../enums/user-operation';
import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { UserModel } from '../user/user.model';
import { StatusModel } from './status.model';
import { UserService } from '../../services/users/user.service';

export class ProjectTaskModel {
  public id = null;
  public name = '';
  @Expose({ name: 'project_id', toPlainOnly: true })
  public projectId = -1;
  @Expose({ name: 'status_id', toPlainOnly: true })
  @Transform(({ value }) => (value?.id ? value.id : null), { toPlainOnly: true })
  @Type(() => StatusModel)
  public status: StatusModel = null;
  @Expose({ name: 'assignee_id', toPlainOnly: true })
  @Transform(({ value }) => (value.id >= 0 ? value.id : null), { toPlainOnly: true })
  @Transform(({ value }) => value ?? UserService.buildUnassignedUser(), { toClassOnly: true })
  @Type(() => UserModel)
  public assignee: UserModel = null;
  @Exclude()
  public userOperation = UserOperation.None;
}
