import { Injectable } from '@angular/core';
import { DialogRef, DialogService, DialogSettings } from '@progress/kendo-angular-dialog';
import { TouchAndMouseHandlerService } from '../touch-and-mouse-handler.service';
import { UserOperation } from '../../enums/user-operation';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShiveDialogService {
  public onOutsideClickSub = new Subject();
  private dialogRef: DialogRef;
  private closeOnOutsideClick: boolean;

  constructor(
    private readonly dialogService: DialogService,
    private readonly touchAndMouseHandlerService: TouchAndMouseHandlerService,
  ) {
    this.touchAndMouseHandlerService.globalMouseReleased$.subscribe((e) => {
      const haystack = Array.from((e.target as HTMLElement).classList);
      const outsideClasses = ['k-overlay', 'tb'];
      // User clicked outside of dialog
      if (
        this.dialogRef &&
        this.closeOnOutsideClick &&
        outsideClasses.some((val) => haystack.includes(val))
      ) {
        this.dialogRef.close(UserOperation.Cancel);
        this.onOutsideClickSub.next(null);
      }
    });
  }

  public open(options: DialogSettings, closeOnOutsideClick = true): DialogRef {
    if (!options.width) {
      options.width = 572;
    }

    if (!options.animation) {
      options.animation = false;
    }

    this.closeOnOutsideClick = closeOnOutsideClick;
    this.dialogRef = this.dialogService.open(options);
    return this.dialogRef;
  }

  public close(result?: unknown): void {
    if (!this.dialogRef) {
      throw new Error('No dialog ref available.');
    }

    this.dialogRef.close(result);
  }
}
