export enum TimeUnits {
  Day,
  Week,
  Month,
  Year,
}

export const TimeUnitOptionLiterals = new Map<TimeUnits, string>([
  [TimeUnits.Day, 'Tag'],
  [TimeUnits.Week, 'Woche'],
  [TimeUnits.Month, 'Monat'],
  [TimeUnits.Year, 'Jahr'],
]);
