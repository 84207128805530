import { forEach, forOwn, isArray, isObject } from 'lodash-es';

export function processNested(
  entry: object,
  plainObject: object,
  cb: (entry: object, key: string | symbol, plainObject: object) => void,
): void {
  forOwn(entry, (value, key) => {
    if (isArray(value)) {
      forEach(value, (item) => {
        if (isArray(item) || isObject(item)) {
          processNested(item, plainObject, cb);
        } else {
          cb(entry, key, plainObject);
        }
      });
    } else if (isObject(value)) {
      processNested(value, plainObject, cb);
    } else {
      cb(entry, key, plainObject);
    }
  });
}
