import { UserOperation } from '../../core/enums/user-operation';
import { ITimeBlockComponentItem } from '../../features/calendar/components/time-block/time-block-component-items';
import { ChangeDetectorRef, ViewContainerRef } from '@angular/core';
import { TimeBlockGeometryData } from '../../core/models/timeblock/time-block-geometry-data.model';
import {
  TimeBlockDayOrWeekFulldayType,
  TimeBlockDayOrWeekInnerdayType,
  TimeBlockMonthFulldayType,
  TimeBlockMonthInnerdayType,
} from '../../core/models/timeblock/time-block-view-type.model';

export type TimeBlockUserOperation = {
  operation: UserOperation;
  timeBlockItem: ITimeBlockComponentItem;
};

export type TimeBlockHookContainer = {
  vcr: ViewContainerRef;
  cd: ChangeDetectorRef;
};

export enum TimeBlockType {
  GhostBlock, // Copy of a block used as indicator when dragging / resizing.
  TransformingBlock, // Copy of a time block that can be dragged / resized freely.
  NonExistingBlock, // Created by user click. Can be resized. Not persisted in database yet.
  ExistingBlock, // Persisted in database.
}

export enum TimeBlockContentType {
  Project,
  Absence,
  Appointment,
}

export interface ITimeBlockViewType {
  geometryData: TimeBlockGeometryData;
}

export interface IInnerdayViewType extends ITimeBlockViewType {
  laneIndex: number;
}

export interface IFulldayViewType extends ITimeBlockViewType {
  laneIndexStart: number;
  laneIndexEnd: number;
  parallelIndex: number;
}

export interface IDragTimeBlock {
  beforeStartDragging: () => void;

  drag: (event: Interact.DragEvent | number) => void;

  afterDragging: (event: Interact.DragEvent) => void;
}

export type TimeBlockIndexSelector = {
  id: number;
  partNr: number;
};

export type CompoundTimeBlockViewType =
  | TimeBlockDayOrWeekFulldayType
  | TimeBlockDayOrWeekInnerdayType
  | TimeBlockMonthFulldayType
  | TimeBlockMonthInnerdayType;

export type TimeBlockFetchData = {
  startDate: Date;
  endDate: Date;
  selectedUserIds: number[];
};
