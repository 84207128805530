import { Component, Input, OnInit } from '@angular/core';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ShiveDialogService } from '../../../../../../core/services/controls/shive-dialog.service';
import { UserOperation } from '../../../../../../core/enums/user-operation';
import { BillableHourlyRateType } from '../../../../../../shared/data-types/budgeted-types';
import { BillingEntryService } from '../../manage-project/project-calculation-form/services/billing-entry.service';
import { BillingEntry } from '../../../../../../shared/data-types/project-types';
import { ProjectService } from '../../../../services/project.service';

@Component({
  selector: 'app-billing-history-dialog',
  templateUrl: './billing-history-dialog.component.html',
  styleUrls: ['./billing-history-dialog.component.scss'],
})
export class BillingHistoryDialogComponent implements OnInit {
  @Input() memberOrTaskId: number;
  @Input() billableHourlyRateType: BillableHourlyRateType;
  @Input() memberId: number;
  @Input() billingRateHistory: BillingEntry[];
  public updateEnabled = false;
  public billingHistoryForm: FormGroup;
  protected readonly faPenToSquare = faPenToSquare;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly shiveDialogService: ShiveDialogService,
    private readonly billingEntryService: BillingEntryService,
    private readonly projectService: ProjectService,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  public close(): void {
    this.shiveDialogService.close(UserOperation.Cancel);
  }

  public submit(): void {
    const billableValue = +this.billingHistoryForm.controls.newBillableRate.value;

    if (this.billingHistoryForm.controls.applyBillableRateTo.value === 'all') {
      this.billingRateHistory = [
        this.billingEntryService.createBillingEntry(
          billableValue,
          this.projectService.getProjectClone().startsAt,
          0,
        ),
      ];
    } else {
      // 'custom'
      const startingFrom = this.billingHistoryForm.controls.dateFrom.value;
      const newBillingEntry: BillingEntry = this.billingEntryService.createBillingEntry(
        billableValue,
        startingFrom,
        this.billingRateHistory.length,
      );

      this.billingRateHistory = this.billingEntryService.alignBillingEntriesByDateFrom(
        newBillingEntry,
        this.billingRateHistory,
      );
    }
    this.shiveDialogService.close(this.billingRateHistory);
  }

  public buttonChanged(): void {
    if (this.billingHistoryForm.controls.applyBillableRateTo.value === 'custom') {
      this.billingHistoryForm.controls.dateFrom.disable();
    } else {
      this.billingHistoryForm.controls.dateFrom.enable();
    }
  }

  public inputValueChanged(newValue: unknown): void {
    this.updateEnabled = newValue !== null;
  }

  private initForm(): void {
    this.billingHistoryForm = this.formBuilder.group({
      newBillableRate: [],
      applyBillableRateTo: ['all'],
      dateFrom: [{ value: new Date(), disabled: true }],
    });
  }
}
