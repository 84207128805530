import { ElementRef, Injectable } from '@angular/core';
import { PopupRef, PopupService, PopupSettings } from '@progress/kendo-angular-popup';

@Injectable({
  providedIn: 'root',
})
export class ShivePopupService {
  public popupRef: PopupRef;
  public anchor: HTMLElement | ElementRef<HTMLElement>;

  private readonly defaultContextMenuSettings: PopupSettings = {
    popupAlign: {
      horizontal: 'right',
      vertical: 'center',
    },
    anchorAlign: {
      horizontal: 'right',
      vertical: 'center',
    },
    margin: {
      horizontal: 40,
      vertical: 0,
    },
    popupClass: 'grid-context-menu context-menu',
    animate: false,
  };

  constructor(private readonly popupService: PopupService) {}

  public open(popupSettings: PopupSettings): PopupRef {
    const updatedSettings: PopupSettings = {
      ...this.defaultContextMenuSettings,
      ...popupSettings,
    };

    this.anchor = updatedSettings.anchor;
    this.popupRef = this.popupService.open(updatedSettings);

    return this.popupRef;
  }
}
