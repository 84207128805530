import { Injectable } from '@angular/core';
import { NotificationRef, NotificationService } from '@progress/kendo-angular-notification';
import { NotificationComponent } from '../../../shared/components/notification/notification.component';

@Injectable({
  providedIn: 'root',
})
export class ShiveNotificationService {
  private active = false;
  private notificationRef: NotificationRef;

  constructor(private readonly notificationService: NotificationService) {}

  public show(notificationType: NotificationType = NotificationType.None, content?: string): void {
    if (!this.active) {
      this.showNotification(content, notificationType);
      this.notificationRef.afterHide.subscribe(() => {
        this.active = false;
      });
    } else {
      this.notificationRef.notification.destroy();
      this.showNotification(content, notificationType);
    }
  }

  public hide(): void {
    if (this.notificationRef) {
      this.notificationRef.notification.destroy();
    }
  }

  private showNotification(content: string, notificationType: NotificationType): void {
    this.notificationRef = this.notificationService.show({
      content: NotificationComponent,
      cssClass: 'button-notification',
      animation: { type: 'fade', duration: 150 },
      position: { horizontal: 'center', vertical: 'bottom' },
      type: { style: notificationType, icon: false },
      hideAfter: 5000,
    });

    const notificationComponent = this.notificationRef.content.instance as NotificationComponent;
    notificationComponent.content = content;
    notificationComponent.type = notificationType;
    notificationComponent.notificationRef = this.notificationRef;
    this.active = true;
  }
}

export enum NotificationType {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  None = 'none',
}
