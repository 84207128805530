import { Component, Input } from '@angular/core';
import { NotificationType } from '../../../core/services/controls/shive-notification.service';
import { NotificationRef } from '@progress/kendo-angular-notification';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  @Input() content: string;
  @Input() type: NotificationType = NotificationType.None;
  @Input() notificationRef: NotificationRef;
  public NotificationType = NotificationType;
}
