import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SubSink } from 'subsink';
import { Observable } from 'rxjs';
import { DropdownOption } from '../../../shared/data-types/dropdown-option';
import { NavigationService } from '../../../core/services/navigation.service';
import { CreateEditClientDataService } from './data-handling/create-edit-client-data.service';
import { take } from 'rxjs/operators';
import { ClientModel } from '../../../core/models/client/client.model';
import { observableToDropdownOptions } from '../../../shared/functions/dropdown-functions';
import { ClientsHttpService } from '../../../core/state/clients/clients-http.service';

@Component({
  selector: 'app-create-edit-client',
  templateUrl: './create-edit-client.component.html',
  styleUrls: ['./create-edit-client.component.scss'],
})
export class CreateEditClientComponent implements OnInit, OnDestroy {
  @ViewChild('formControls', { read: TemplateRef }) formControls: TemplateRef<unknown>;
  public clientBaseForm: FormGroup;
  public allClients$: Observable<DropdownOption[]>;

  private readonly subs = new SubSink();

  constructor(
    public createEditClientDataService: CreateEditClientDataService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly clientsHttpService: ClientsHttpService,
    private readonly route: ActivatedRoute,
    private readonly navigationService: NavigationService,
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.loadData(this.route.snapshot.params.id);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public onSubmit(): void {
    const formData = this.clientBaseForm.value;

    const clientModel = new ClientModel();
    clientModel.name = formData.clientName;

    this.createEditClientDataService
      .sendRequests(clientModel)
      .pipe(take(1))
      .subscribe(() => this.navigationService.back());
  }

  public cancel(): void {
    this.navigationService.back();
  }

  private buildForm(): void {
    this.clientBaseForm = this.formBuilder.group({
      clientName: ['', Validators.required],
      email: [''],
    });
  }

  private loadData(clientId: number | undefined): void {
    this.allClients$ = observableToDropdownOptions(this.clientsHttpService.getClients());
    this.createEditClientDataService
      .loadClientData(this.clientBaseForm, clientId)
      .subscribe((client: ClientModel) => {
        this.clientBaseForm.patchValue({
          clientName: client.name,
          email: client.email,
        });
      });
  }
}
