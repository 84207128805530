import { createFeatureSelector, createSelector } from '@ngrx/store';
import { colorAdapter } from './colors.reducer';

export const selectColors = createFeatureSelector('colors');

export const selectAllColors = createSelector(selectColors, colorAdapter.getSelectors().selectAll);

export const selectColorByValue = (colorValue: string) =>
  createSelector(selectAllColors, (entities) =>
    entities.find((color) => color.value.toLowerCase() === colorValue.toLowerCase()),
  );
