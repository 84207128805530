<div class="form-wrapper">
  <form [formGroup]="projectBillingForm" class="k-form k-d-grid">
    <div class="col">
      <kendo-formfield class="!k-mt-0 !k-mb-0" orientation="horizontal">
        <kendo-label class="!k-align-items-start" [for]="billableRateType" text="Verrechnung">
        </kendo-label>
        <kendo-dropdownlist
          textField="text"
          valueField="value"
          [showStickyHeader]="false"
          (selectionChange)="billableRateOptionChanged($event)"
          [data]="billableRateOptions"
          formControlName="billableRateType"
          #billableRateType
        >
        </kendo-dropdownlist>
      </kendo-formfield>
    </div>

    <div class="col">
      @if (selectedBillableRateOption !== BillableRateOptions.NoBilling) {
        <div class="k-h-full k-d-flex k-align-items-center">
          @if (isBillingFixed()) {
            <div class="horizontal-controls k-d-flex k-align-items-center">
              @if (selectedBillableRateOption === BillableRateOptions.BillableHourlyValueFixed) {
                {{ billableValue | shiveCurrency }}

                <div class="k-d-flex k-align-items-center">
                  <div>&nbsp;pro Stunde</div>
                </div>

                <fa-icon
                  class="edit turquoise icon-button k-display-flex k-align-items-stretch k-ml-3.5"
                  role="button"
                  (click)="openNewBillableRateDialog()"
                  size="lg"
                  [icon]="faPenToSquare"
                ></fa-icon>
              } @else {
                <span class="prefix-currency">&euro;</span>
                <kendo-numerictextbox
                  id="billableRateValue"
                  formControlName="billableRateValue"
                  [spinners]="false"
                  format="n2"
                  (valueChange)="billableRateValueChanged($event.toString())"
                >
                </kendo-numerictextbox>
              }
            </div>
          }
          @if (
            selectedBillableRateOption === BillableRateOptions.BillableValuePerTask ||
            selectedBillableRateOption === BillableRateOptions.BillableHourlyValuePerMember ||
            selectedBillableRateOption === BillableRateOptions.BillableHourlyValuePerTask
          ) {
            <div class="text-box info k-mt-0 k-d-flex k-align-items-center">
              <div class="custom-symbol notification-info k-mr-2.5"></div>
              Hinweis:
              @if (selectedBillableRateOption === BillableRateOptions.BillableHourlyValuePerTask) {
                Bitte die Stundensätze den Aufgaben in der Sektion unten zuweisen.
              }
              @if (
                selectedBillableRateOption === BillableRateOptions.BillableHourlyValuePerMember
              ) {
                Bitte die Stundensätze den Projektmitgliedern in der Sektion unten zuweisen.
              }
              @if (selectedBillableRateOption === BillableRateOptions.BillableValuePerTask) {
                Bitte die Kosten den Aufgaben in der Sektion unten zuweisen.
              }
            </div>
          }
        </div>
      }
    </div>
  </form>
</div>
