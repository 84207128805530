import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, Subject } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { AbsenceReasonModel } from '../../../../core/models/absence-reason/absence-reason.model';

export interface AbsenceReasonsState {
  absenceReasons: AbsenceReasonModel[];
}

@Injectable()
export class AbsenceReasonsStore extends ComponentStore<AbsenceReasonsState> {
  public userOperationConducted = new Subject<boolean>();
  public absenceReasonDeletionList: AbsenceReasonModel[] = [];
  public readonly addAbsenceReason = this.updater(
    (state, newAbsenceReason: AbsenceReasonModel) => ({
      absenceReasons: [...state.absenceReasons, newAbsenceReason],
    }),
  );
  public readonly selectAbsenceReasons$: Observable<AbsenceReasonModel[]> = this.select(
    (state) => state.absenceReasons,
  );

  constructor() {
    super({ absenceReasons: [] });
  }

  public readonly selectSingleAbsenceReason$ = (templateId: number) =>
    this.select(this.selectAbsenceReasons$, (AbsenceReasons) =>
      AbsenceReasons.find((template) => template.id === templateId),
    );

  public readonly updateAbsenceReason = (updatedAbsenceReasonList: AbsenceReasonModel) => {
    this.patchState((state) => {
      const index = state.absenceReasons.findIndex(
        (entry) => entry.id === updatedAbsenceReasonList.id,
      );
      if (index < 0) {
        throw new Error('Existing absence reason not found.');
      }

      const updatedState = cloneDeep(state);
      updatedState.absenceReasons[index] = updatedAbsenceReasonList;
      return updatedState;
    });
  };

  public readonly removeAbsenceReason = (AbsenceReason: AbsenceReasonModel) => {
    this.patchState((state) => {
      const index = state.absenceReasons.findIndex((entry) => entry.id === AbsenceReason.id);
      if (index < 0) {
        throw new Error('Existing holiday template list not found.');
      }

      // Store id of existing list for upcoming delete request.
      if (AbsenceReason.id >= 0) {
        this.absenceReasonDeletionList.push(AbsenceReason);
      }

      const updatedState = cloneDeep(state);
      updatedState.absenceReasons.splice(index, 1);
      return updatedState;
    });
  };

  public readonly reset = () => {
    this.patchState(() => {
      return { absenceReasons: [] };
    });
  };
}
