import { Injectable } from '@angular/core';
import { ProjectBillingEntryModel } from '../../../../../../../core/models/project/project-billing.model';
import { BillingEntry } from '../../../../../../../shared/data-types/project-types';
import { assert } from '../../../../../../../core/assert/assert';
import { DateTimeHelper } from '../../../../../../calendar/util/date-time-helper';

@Injectable({
  providedIn: 'root',
})
export class BillingEntryService {
  constructor() {}

  public createBillingEntryModel(
    billableValue: number,
    startingFrom?: Date,
    id?: number,
    type?: 'memberId' | 'taskId',
  ): ProjectBillingEntryModel {
    const billingEntry = new ProjectBillingEntryModel();
    billingEntry.billableValue = billableValue;
    billingEntry.startingFrom = startingFrom ?? null;
    if (type) {
      billingEntry[type] = id;
    }
    return billingEntry;
  }

  public createBillingEntry(billingRate: number, startingFrom: Date, id: number): BillingEntry {
    return { startingFrom, billingRate, id };
  }

  /**
   * Add newBillingEntry to the list of billing entries. If the new billing entry is before an existing billing entry, then
   * all subsequent billing entries will be deleted.
   */
  public alignBillingEntriesByDateFrom(
    newBillingEntry: BillingEntry,
    currentEntries: readonly BillingEntry[],
  ): BillingEntry[] {
    assert(newBillingEntry.startingFrom !== null);
    assert(currentEntries.length > 0);

    const updatedEntries: BillingEntry[] = [];
    for (const entry of currentEntries) {
      const differenceInDays = DateTimeHelper.differenceInDays(
        entry.startingFrom,
        newBillingEntry.startingFrom,
      );
      if (differenceInDays >= 0) {
        break;
      } else {
        updatedEntries.push(entry);
      }
    }
    updatedEntries.push(newBillingEntry);

    return updatedEntries;
  }
}
