import { BaseCalendarModel } from '../../../core/models/calendar/base-calendar.model';
import { CalendarEvents } from '../../../shared/data-types/calendar-types';
import { Subscription } from 'rxjs';
import { filterEmpty } from '../../../core/rxjs/rxjs';
import { filter, map } from 'rxjs/operators';
import { CalendarService } from './calendar.service';
import { DateTimeHelper } from '../util/date-time-helper';
import { isArray } from 'lodash-es';

export class CalendarServiceHelper {
  /**
   * Helper method that is triggered as soon as the calendar model was changed.
   */
  public static calendarModelUpdated(
    calendarService: CalendarService,
    callback: (
      model: BaseCalendarModel,
      targetEvents: CalendarEvents | CalendarEvents[],
      meta?: unknown,
    ) => void,
    targetEvents: CalendarEvents[],
  ): Subscription {
    return calendarService.calendarModelUpdated$
      .pipe(
        filterEmpty(),
        filter(([model, calendarEvents]) => {
          if (isArray(calendarEvents)) {
            return !!model && calendarEvents.some((prop) => targetEvents.includes(prop));
          }
          return targetEvents.includes(calendarEvents);
        }),
        map(([model, calendarEvents, meta]) => {
          return [model, calendarEvents, meta];
        }),
      )
      .subscribe(
        ([model, calendarEvents, meta]: [
          BaseCalendarModel,
          CalendarEvents | CalendarEvents[],
          unknown?,
        ]) => {
          if (!isArray(calendarEvents)) {
            calendarEvents = [calendarEvents];
          }
          callback(model, calendarEvents, meta);
        },
      );
  }

  public static getVisibleDayCount(model: BaseCalendarModel): number {
    return model.calendarProperties.days.filter((d) => !d.outsideOfView).length;
  }

  public static getInvisibleOffsetCount(model: BaseCalendarModel): number {
    let counter = 0;
    model.calendarProperties.days.find((value, index) => {
      if (!value.outsideOfView) {
        counter = index;
        return value;
      }
    });
    return counter;
  }

  /**
   * Calculate the visible lane index for an absolut lane index between 0 and model.calendarProperties.days.length.
   */
  public static toVisibleLaneIndex(model: BaseCalendarModel, absolutLaneIndex: number): number {
    return absolutLaneIndex - this.getVisibleDayCount(model);
  }

  /**
   * Calculate the invisible lane index (lane that is not visible).
   */
  public static toInvisibleLaneIndex(model: BaseCalendarModel, laneIndex: number): number {
    let offsetStart = 0;
    const days = model.calendarProperties.days;
    for (const item of days) {
      if (!item.outsideOfView) {
        break;
      }
      offsetStart++;
    }

    return offsetStart + laneIndex;
  }

  /**
   *  Check whether the absolut lane index ranging from 0 to max days is in the visible portion of the calendar view.
   */
  public static isInsideOfVisibleCalendarView(
    model: BaseCalendarModel,
    absolutLaneIndex: number,
  ): boolean {
    const firstVisibleLaneIndex = this.getFirstVisibleLaneIndex(model);
    const lastVisibleLaneIndex = this.getLastVisibleLaneIndex(model);
    return absolutLaneIndex >= firstVisibleLaneIndex && absolutLaneIndex <= lastVisibleLaneIndex;
  }

  /**
   * Return absolute index of first visible lane.
   */
  public static getFirstVisibleLaneIndex(model: BaseCalendarModel): number {
    const visibleStart = model.calendarProperties.visibleStartDate;
    const offsetStart = model.calendarProperties.offsetStartDate;
    return DateTimeHelper.differenceInCalendarDays(visibleStart, offsetStart);
  }

  /**
   * Return absolute index of last visible lane.
   */
  public static getLastVisibleLaneIndex(model: BaseCalendarModel): number {
    const offsetStartDate = model.calendarProperties.offsetStartDate;
    const visibleEndDate = model.calendarProperties.visibleEndDate;
    return DateTimeHelper.differenceInCalendarDays(visibleEndDate, offsetStartDate);
  }
}
