<div id="status-project" class="card-wrapper">
  <div class="card">
    <div class="card-header">
      <div class="title-row">
        <div class="title k-d-flex k-align-items-center">
          <h2 class="mb-0">Projektfortschritt</h2>
        </div>
      </div>
    </div>

    <div class="card-body container-fluid">
      <div class="row">
        <div class="col-9 k-pr-15">
          <div class="row controls">
            <div class="col k-d-flex k-justify-content-end">
              <kendo-dropdownlist
                [data]="viewOptions$ | async"
                [style.width.px]="170"
                [value]="1"
                [valuePrimitive]="true"
                textField="text"
                valueField="value"
                class="height-inherit !k-mr-5"
              ></kendo-dropdownlist>

              <kendo-dropdownlist
                [data]="viewOptions$ | async"
                [style.width.px]="170"
                [value]="1"
                [valuePrimitive]="true"
                textField="text"
                valueField="value"
                class="height-inherit"
              ></kendo-dropdownlist>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <kendo-chart (render)="onRender($event)" style="height: 580px">
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item
                    name="categoryAxis"
                    [categories]="['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']"
                    [title]="{ text: 'Months' }"
                  >
                  </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item name="valueAxis"> </kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-series>
                  <kendo-chart-series-item type="line" [data]="[123, 276, 310, 212, 240, 156, 98]">
                  </kendo-chart-series-item>
                  <kendo-chart-series-item type="line" [data]="[165, 210, 287, 144, 190, 167, 212]">
                  </kendo-chart-series-item>
                  <kendo-chart-series-item type="line" [data]="[56, 140, 195, 46, 123, 78, 95]">
                  </kendo-chart-series-item>
                </kendo-chart-series>
              </kendo-chart>
            </div>
          </div>
        </div>

        <div class="col-3">
          <app-status-project-summary />
        </div>
      </div>
    </div>
  </div>
</div>

<div id="status-tasks" class="card-wrapper k-mt-10">
  <div class="card">
    <div class="card-header">
      <div class="title-row">
        <div class="title k-d-flex k-align-items-center">
          <h2 class="mb-0">Aufgaben</h2>
        </div>
      </div>
    </div>

    <div class="card-body container-fluid">
      <div class="row">
        <div class="col">tst</div>
      </div>
    </div>
  </div>
</div>
