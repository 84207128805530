import { Injectable } from '@angular/core';
import { CardinalPoints, DragDirection } from '../drag-part';
import { combineLatest } from 'rxjs';
import { DragOrResizeInteraction } from './time-block-drag-resize.service';
import { TimeBlockDragResizeControllerService } from './time-block-drag-resize-controller.service';
import { CalendarScrollbarService } from '../../../../../../services/calendar-scrollbar.service';
import {
  CalendarMouseHandlerService,
  MousePositionWrapper,
} from '../../../../../../mouse/calendar-mouse-handler.service';
import { TouchAndMouseHandlerService } from '../../../../../../../../core/services/touch-and-mouse-handler.service';
import { CalendarService } from '../../../../../../services/calendar.service';
import { TimeBlockType } from '../../../../../../../../shared/data-types/time-block-types';
import { FreelyDayOrWeekDragControllerService } from '../dragging/drag-view-controller/freely-day-or-week-drag-controller.service';
import { CalendarView } from '../../../../../../../../shared/data-types/calendar-types';

@Injectable()
export class TimeBlockDragResizeMouseHandlerService {
  constructor(
    private readonly timeBlockDragResizeControllerService: TimeBlockDragResizeControllerService,
    private readonly calendarScrollbarService: CalendarScrollbarService,
    private readonly freelyDayOrWeekDragControllerService: FreelyDayOrWeekDragControllerService,
    private readonly calendarService: CalendarService,
    private readonly touchAndMouseHandlerService: TouchAndMouseHandlerService,
    private readonly calendarMouseHandlerService: CalendarMouseHandlerService,
  ) {
    this.hideRightClickContextMenu();
  }

  public initMouseEvents(startDragOrResizeInteraction: () => void): void {
    // Resize or drag by mouse wheel
    this.calendarScrollbarService.calendarBodyScrollbar.wheelSubj$.subscribe((scrollData) => {
      if (
        !scrollData ||
        !this.timeBlockDragResizeControllerService.TransformationTimeBlock ||
        this.timeBlockDragResizeControllerService.currentDragResizeInteraction ===
          DragOrResizeInteraction.None ||
        this.timeBlockDragResizeControllerService.TransformationTimeBlock.timeBlockModel.isFullday
      ) {
        return;
      }

      if (!this.timeBlockDragResizeControllerService.dragOrResizeActionInProgress) {
        this.timeBlockDragResizeControllerService.dragOrResizeActionInProgress = true;
        startDragOrResizeInteraction();
      }

      const dragDirection: DragDirection = {
        vertical: scrollData.scrollDelta < 0 ? CardinalPoints.North : CardinalPoints.South,
        horizontal: CardinalPoints.None,
      };

      if (
        this.timeBlockDragResizeControllerService.isResizeAction(
          this.timeBlockDragResizeControllerService.TransformationTimeBlock.timeBlockModel.id,
          this.timeBlockDragResizeControllerService.currentDragResizeInteraction,
        )
      ) {
        this.timeBlockDragResizeControllerService.resizeControllerService.resize(
          this.calendarMouseHandlerService.MouseMovePositionWrapper.mouseCalendarPosition,
          dragDirection,
        );
        this.timeBlockDragResizeControllerService.lastWorldMousePos.y += scrollData.scrollDelta;
      } else if (
        this.timeBlockDragResizeControllerService.isDragAction(
          this.timeBlockDragResizeControllerService.TransformationTimeBlock.timeBlockModel.id,
          this.timeBlockDragResizeControllerService.currentDragResizeInteraction,
        )
      ) {
        const viewMode = this.calendarService.model.calendarViewMode;
        const dragControllerService =
          viewMode === CalendarView.DayGrid || viewMode === CalendarView.WeekGrid
            ? this.freelyDayOrWeekDragControllerService
            : null;

        dragControllerService.drag(scrollData.scrollDelta);
      }
    });

    // Drag or resize by mouse move
    combineLatest([
      this.calendarMouseHandlerService.mousePosChangedSubj$,
      this.touchAndMouseHandlerService.globalMouseMoved$,
    ]).subscribe(([calendarMousePositionWrapper]) => {
      if (
        !calendarMousePositionWrapper ||
        !this.timeBlockDragResizeControllerService.TransformationTimeBlock
      ) {
        return;
      }

      this.dragOrResizeTimeBlock(calendarMousePositionWrapper);
    });
  }

  private dragOrResizeTimeBlock(calendarMousePositionWrapper: MousePositionWrapper): void {
    if (
      this.timeBlockDragResizeControllerService.isResizeAction(
        this.timeBlockDragResizeControllerService.TransformationTimeBlock.timeBlockModel.id,
        this.timeBlockDragResizeControllerService.currentDragResizeInteraction,
      )
    ) {
      const resizeDirection = this.timeBlockDragResizeControllerService.getResizeDirection(
        calendarMousePositionWrapper.mouseViewportPosition,
        calendarMousePositionWrapper.mouseCalendarPosition,
        this.timeBlockDragResizeControllerService.lastWorldMousePos,
      );
      this.timeBlockDragResizeControllerService.resizeControllerService.resize(
        this.calendarMouseHandlerService.MouseMovePositionWrapper.mouseCalendarPosition,
        resizeDirection,
      );
    }
    this.timeBlockDragResizeControllerService.lastWorldMousePos =
      calendarMousePositionWrapper.mouseViewportPosition;

    if (
      this.timeBlockDragResizeControllerService.TransformationTimeBlock.timeBlockModel.type !==
        TimeBlockType.NonExistingBlock &&
      !this.timeBlockDragResizeControllerService.TransformationTimeBlock.timeBlockModel.isFullday
    ) {
      this.adjustScrollbarPos();
    }
  }

  /**
   * Scroll to top or bottom if time block hits top or bottom edge of calendar.
   */
  private adjustScrollbarPos(): void {
    // No time block selected.
    if (!this.timeBlockDragResizeControllerService.TransformationTimeBlock) {
      return;
    }

    const mouseViewportPosY = this.touchAndMouseHandlerService.currentMouseViewportPos.y;
    const calendarTop = this.calendarService.model.geometryData.calendarBodyOffsetTop;
    const calendarBottom =
      calendarTop + this.calendarService.model.geometryData.calendarBodyVisibleHeight;

    const tolerance = 20;
    // Mouse cursor is not in the scrolling areas and is inside of calendar (if cursor is outside, mouseCalendarPosY is -1),
    // so don't scroll.
    if (
      mouseViewportPosY >= calendarTop + tolerance &&
      mouseViewportPosY <= calendarBottom - tolerance
    ) {
      return;
    }

    let scrollDelta = 120;
    if (mouseViewportPosY > calendarBottom - tolerance) {
      scrollDelta *= -1;
    }

    this.calendarScrollbarService.calendarBodyScrollbar.scrollToTop(
      scrollDelta,
      this.adjustScrollbarPos.bind(this),
    );
  }

  private hideRightClickContextMenu(): void {
    document.addEventListener('contextmenu', (event) => {
      if (this.timeBlockDragResizeControllerService.dragOrResizeActionInProgress) {
        event.preventDefault();
      }
    });
  }
}
