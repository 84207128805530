import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { TimeBlockInjectable } from '../time-block-injectable';
import { TimeBlockContainerDirective } from '../time-block-container.directive';
import { Viewable } from '../viewable';
import { CalendarService } from '../../services/calendar.service';
import { TimeBlockCrudService } from '../../components/time-block/crud/time-block-crud.service';
import { combineLatestWith, filter } from 'rxjs/operators';
import { TimeCoordinateMappingService } from '../../time-mapping/time-coordinate-mapping.service';
import { CalendarScrollbarService } from '../../services/calendar-scrollbar.service';
import { DayOrWeekViewHandler } from '../handlers/day-or-week-view-handler';
import { DayOrWeekCalendarModel } from '../../../../core/models/calendar/day-or-week-calendar.model';
import { TimeBlockHookContainer } from '../../../../shared/data-types/time-block-types';
import { Observable } from 'rxjs';
import { CalendarEvents, CalendarView } from '../../../../shared/data-types/calendar-types';
import { DateTimeHelper } from '../../util/date-time-helper';
import { SidebarService } from '../../../../core/services/ui/sidebar.service';
import { CalendarBodyCSSId } from '../../../../core/data-repository/css-constants';
import { ParallelTimeBlockGeometryCalculatorService } from '../../components/time-block/calculation/geometry/parallel-time-block-geometry-calculator.service';
import { TimeBlockDurationCalculationService } from '../../components/time-block/calculation/duration/time-block-duration-calculation.service';
import { CalendarViewHandlerService } from '../../services/calendar-view-handler.service';
import { CalendarHeaderService } from '../../components/calendar-header/calendar-header.service';

@Component({
  selector: 'app-week-view',
  templateUrl: './week-view.component.html',
  styleUrls: ['./week-view.component.scss'],
})
export class WeekViewComponent
  extends DayOrWeekViewHandler
  implements OnInit, AfterViewInit, OnDestroy, TimeBlockInjectable, Viewable
{
  public CalendarView = CalendarView;
  // calendar timegrid lanes
  @ViewChildren(TimeBlockContainerDirective)
  public timeLanePlaceholders!: QueryList<TimeBlockContainerDirective>;
  @ViewChildren('timeBlockLanes', { read: ElementRef })
  public timeBlockLanesContainer: QueryList<ElementRef>;
  // calendar body
  @ViewChild('htmlItemContainer') public htmlItemContainer: ElementRef<HTMLElement>;
  // calendar full day lanes from calendar header
  @Input() fulldayLanesLoaded$: Observable<
    [QueryList<TimeBlockContainerDirective>, QueryList<ElementRef>]
  >;
  private fulldayLanePlaceholders!: QueryList<TimeBlockContainerDirective>;
  private fulldayLaneHtmlItemContainer: QueryList<ElementRef>;

  constructor(
    public calendarService: CalendarService,
    private readonly cd: ChangeDetectorRef,
    private readonly timeBlockCrudService: TimeBlockCrudService,
    private readonly calendarScrollbarService: CalendarScrollbarService,
    private readonly parallelTimeBlockGeometryCalculatorService: ParallelTimeBlockGeometryCalculatorService,
    private readonly timeBlockDurationCalculationService: TimeBlockDurationCalculationService,
    private readonly calendarHeaderService: CalendarHeaderService,
    private readonly timeCoordinateMappingService: TimeCoordinateMappingService,
    private readonly sidebarService: SidebarService,
    private readonly calendarViewHandlerService: CalendarViewHandlerService,
  ) {
    super(
      calendarService,
      timeBlockCrudService,
      timeCoordinateMappingService,
      sidebarService,
      calendarViewHandlerService,
    );
  }

  ngOnInit(): void {
    this.calendarScrollbarService.initializeScrollbars();
  }

  ngAfterViewInit(): void {
    this.cd.detach();
    window.setTimeout(() => {
      this.initCalendarView();
    });

    let model = new DayOrWeekCalendarModel();
    Object.assign(model, this.calendarService.model);

    this.calendarService.emitCalendarChange(model, CalendarEvents.InitializedCalendarView);

    this.subs.sink = this.timeBlockCrudService.timeBlocksFetched$
      .pipe(
        combineLatestWith(this.fulldayLanesLoaded$),
        filter(
          ([timeBlocks, [placeHolders, containers]]) =>
            timeBlocks !== null && placeHolders !== null && containers !== null,
        ),
      )
      .subscribe(([[fulldayTimeBlockMap, innerdayTimeBlockMap], [placeHolders, containers]]) => {
        this.cd.detectChanges();
        this.fulldayLanePlaceholders = placeHolders;
        this.fulldayLaneHtmlItemContainer = containers;
        this.setModelTemplateData();
        // Calculate height of full time block container
        this.updateAndRenderFulldayContainer();

        // Insert the time blocks into the calendar model
        model = this.calendarService.model as DayOrWeekCalendarModel;
        this.insertTimeBlocksIntoView(
          fulldayTimeBlockMap,
          model.fulldayCalendarModel.timeBlocks,
          innerdayTimeBlockMap,
          model.timeBlocks,
        );

        // Calculate geometry for parallel full day time blocks
        this.parallelTimeBlockGeometryCalculatorService.updateParallelTimeBlockGeometries(true);
        // Calculate geometry for parallel inner day time blocks
        this.parallelTimeBlockGeometryCalculatorService.updateParallelTimeBlockGeometries(false);

        // Render full day container with correct height
        this.calendarHeaderService.initDayOrWeekViewFulldayContainer();
        this.calendarHeaderService.renderDayOrWeekViewFulldayContainer();
        this.calendarHeaderService.toggleDayOrWeekViewFulldayContainer(false);

        // After full day calendar was populated, re-calculate top offset since the height of the full day calendar probably changed.
        const bcr = this.htmlItemContainer.nativeElement.getBoundingClientRect();

        this.calService.model.geometryData.calendarBodyOffsetTop =
          bcr.top + this.calendarScrollbarService.calendarBodyScrollbar.scrollData.scrollPosY;
        this.calService.model.geometryData.calendarBodyVisibleHeight =
          document.querySelector(CalendarBodyCSSId).clientHeight;
        this.calendarService.emitCalendarChange(model, CalendarEvents.RenderedTimeBlocks);
        this.timeBlockDurationCalculationService.calculateTimeBlockDurationSums$.next(null);
      });

    this.checkIfOvernight();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.destroy();
  }

  public isWorkTimeBoundary(hour: Date): boolean {
    const model = this.calendarService.model as DayOrWeekCalendarModel;
    const workTimeStart = model.calendarProperties.workTimeStart;
    const workTimeEnd = model.calendarProperties.workTimeEnd;
    return (
      DateTimeHelper.isSameTime(hour, workTimeStart) || DateTimeHelper.isSameTime(hour, workTimeEnd)
    );
  }

  protected setModelTemplateData(): void {
    const updatedWeekCalendarModel = this.calendarService.model as DayOrWeekCalendarModel;
    updatedWeekCalendarModel.timeBlockContainers = this.timeLanePlaceholders.map((e) => {
      e.viewContainerRef.clear();
      const hookContainer: TimeBlockHookContainer = {
        vcr: e.viewContainerRef,
        cd: e.changeDetectorRef,
      };
      return hookContainer;
    });
    updatedWeekCalendarModel.timeBlockHtmlContainers = this.timeBlockLanesContainer.toArray();

    // init full day model
    const fulldayCalendarModel = updatedWeekCalendarModel.fulldayCalendarModel;
    fulldayCalendarModel.timeBlockContainers = this.fulldayLanePlaceholders.map((e) => {
      e.viewContainerRef.clear();
      const hookContainer: TimeBlockHookContainer = {
        vcr: e.viewContainerRef,
        cd: e.changeDetectorRef,
      };
      return hookContainer;
    });
    fulldayCalendarModel.timeBlockHtmlContainers = this.fulldayLaneHtmlItemContainer.toArray();

    this.calendarService.emitCalendarChange(updatedWeekCalendarModel, []);
  }

  protected initCalendarView(): void {
    if (!this.initialized || this.viewportSizeChanged) {
      if (!this.viewportSizeChanged) {
        this.mapHoursOfDay();
        this.cd.detectChanges();
      }

      this.updateCalendarDimensions(
        this.htmlItemContainer,
        this.calendarScrollbarService.calendarBodyScrollbar.scrollData.scrollPosY,
      );
    }
  }
}
