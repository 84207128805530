import { TimeCoordinateMappingService } from '../../../../../../time-mapping/time-coordinate-mapping.service';
import { ResizeData } from './resize-view-controller/vertical-day-or-week-resize-controller.service';
import { TimeBlockModel } from '../../../../../../../../core/models/timeblock/time-block.model';
import { DragPart } from '../drag-part';
import { CalendarService } from '../../../../../../services/calendar.service';
import { DayOrWeekCalendarModel } from '../../../../../../../../core/models/calendar/day-or-week-calendar.model';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

@Injectable()
export class ResizeCalculatorService {
  private ppm = -1;
  private readonly dragEdgeOffset = 10; // To keep the cursor inside the time block while resizing it.

  constructor(
    private readonly calendarService: CalendarService,
    private readonly timeCoordinateMapperService: TimeCoordinateMappingService,
  ) {
    this.timeCoordinateMapperService.pixelsPerMinuteCalculated$
      .pipe(filter((ppm) => !!ppm))
      .subscribe((ppm) => {
        this.ppm = ppm;
      });
  }

  public passedVerticalBound(resizeData: ResizeData, timeBlockModel: TimeBlockModel): boolean {
    const geometryData = timeBlockModel.timeBlockViewType.geometryData;
    const isDraggingTop = resizeData.dragPart === DragPart.Top;
    const topEdgeToDragEdge = isDraggingTop
      ? geometryData.top + this.dragEdgeOffset
      : geometryData.calendarTopEdgeToTimeBlockBottomEdge - this.dragEdgeOffset;

    const [lowerBound, upperBound] = this.calcVerticalBounds(resizeData.mousePos.y);

    if (resizeData.mousePos.y > topEdgeToDragEdge) {
      resizeData.dragEdgePos.y = upperBound + (isDraggingTop ? -this.dragEdgeOffset : 0);
      return true;
    }

    if (resizeData.mousePos.y < topEdgeToDragEdge) {
      resizeData.dragEdgePos.y =
        lowerBound + (resizeData.dragPart === DragPart.Bottom ? this.dragEdgeOffset : 0);
      return true;
    }

    return false;
  }

  /**
   * Calculate the lower and upper bounds the mouse cursor needs to pass to increase / decrease the height of the time block.
   */
  private calcVerticalBounds(mousePosY: number): [number, number] {
    const dragEdgeMinutes = mousePosY / this.ppm;
    const snapDuration = (this.calendarService.model as DayOrWeekCalendarModel).calendarProperties
      .timeBlockDraggingDuration;

    const prevBoundInMinutes = Math.floor((dragEdgeMinutes - 0.1) / snapDuration) * snapDuration;
    const nextBoundInMinutes = Math.ceil((dragEdgeMinutes + 0.1) / snapDuration) * snapDuration;

    const lowerBound = this.ppm * prevBoundInMinutes;
    const upperBound = this.ppm * nextBoundInMinutes;

    return [lowerBound, upperBound];
  }
}
