<div class="form-wrapper">
  <form [formGroup]="employmentDataForm" class="k-form">
    <div class="two-column-layout k-d-grid">
      <div class="col">
        <kendo-formfield orientation="horizontal">
          <kendo-label
            class="!k-align-items-start"
            [for]="paymentType"
            text="Anstellungsart"
          ></kendo-label>
          <kendo-dropdownlist
            textField="text"
            valueField="value"
            [id]="formControlNames.PaymentType"
            [formControlName]="formControlNames.PaymentType"
            #paymentType
          >
          </kendo-dropdownlist>
          <kendo-formerror>Error: Username is required</kendo-formerror>
        </kendo-formfield>
      </div>

      <div class="col">
        <kendo-formfield orientation="horizontal">
          <kendo-label
            class="!k-align-items-start"
            [for]="employedSince"
            text="Angestellt seit"
          ></kendo-label>
          <kendo-datepicker
            [id]="formControlNames.EmployedSince"
            [formControlName]="formControlNames.EmployedSince"
            #employedSince
          >
          </kendo-datepicker>
          <kendo-formerror>Error: Username is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>

    <div id="working-hours" class="k-d-grid k-form-field !k-mb-0">
      <div class="working-hours-label k-label">Arbeitszeiten</div>
      <div class="second k-d-grid" [formGroupName]="formControlNames.WorkingHours">
        <div class="col">
          <kendo-formfield orientation="horizontal">
            <kendo-label
              class="!k-align-items-start !k-mb-0"
              [for]="monday"
              text="Mo"
            ></kendo-label>
            <kendo-textbox
              showErrorIcon="initial"
              [id]="formControlNames.Monday"
              [formControlName]="formControlNames.Monday"
              #monday
            >
            </kendo-textbox>
            <kendo-formerror>Error: Username is required</kendo-formerror>
          </kendo-formfield>
        </div>
        <div class="col">
          <kendo-formfield orientation="horizontal">
            <kendo-label
              class="!k-align-items-start !k-mb-0"
              [for]="tuesday"
              text="DI"
            ></kendo-label>
            <kendo-textbox
              showErrorIcon="initial"
              [id]="formControlNames.Tuesday"
              [formControlName]="formControlNames.Tuesday"
              #tuesday
            >
            </kendo-textbox>
            <kendo-formerror>Error: Username is required</kendo-formerror>
          </kendo-formfield>
        </div>
        <div class="col">
          <kendo-formfield orientation="horizontal">
            <kendo-label
              class="!k-align-items-start !k-mb-0"
              [for]="wednesday"
              text="MI"
            ></kendo-label>
            <kendo-textbox
              showErrorIcon="initial"
              [id]="formControlNames.Wednesday"
              [formControlName]="formControlNames.Wednesday"
              #wednesday
            >
            </kendo-textbox>
            <kendo-formerror>Error: Username is required</kendo-formerror>
          </kendo-formfield>
        </div>
        <div class="col">
          <kendo-formfield orientation="horizontal">
            <kendo-label
              class="!k-align-items-start !k-mb-0"
              [for]="thursday"
              text="DO"
            ></kendo-label>
            <kendo-textbox
              showErrorIcon="initial"
              [id]="formControlNames.Thursday"
              [formControlName]="formControlNames.Thursday"
              #thursday
            >
            </kendo-textbox>
            <kendo-formerror>Error: Username is required</kendo-formerror>
          </kendo-formfield>
        </div>
        <div class="col">
          <kendo-formfield orientation="horizontal">
            <kendo-label
              class="!k-align-items-start !k-mb-0"
              [for]="friday"
              text="FR"
            ></kendo-label>
            <kendo-textbox
              showErrorIcon="initial"
              [id]="formControlNames.Friday"
              [formControlName]="formControlNames.Friday"
              #friday
            >
            </kendo-textbox>
            <kendo-formerror>Error: Username is required</kendo-formerror>
          </kendo-formfield>
        </div>
        <div class="col">
          <kendo-formfield orientation="horizontal">
            <kendo-label
              class="!k-align-items-start !k-mb-0"
              [for]="saturday"
              text="SA"
            ></kendo-label>
            <kendo-textbox
              showErrorIcon="initial"
              [id]="formControlNames.Saturday"
              [formControlName]="formControlNames.Saturday"
              #saturday
            >
            </kendo-textbox>
            <kendo-formerror>Error: Username is required</kendo-formerror>
          </kendo-formfield>
        </div>
        <div class="col">
          <kendo-formfield orientation="horizontal">
            <kendo-label
              class="!k-align-items-start !k-mb-0"
              [for]="saturday"
              text="SO"
            ></kendo-label>
            <kendo-textbox
              showErrorIcon="initial"
              [id]="formControlNames.Sunday"
              [formControlName]="formControlNames.Sunday"
              #saturday
            >
            </kendo-textbox>
            <kendo-formerror>Error: Username is required</kendo-formerror>
          </kendo-formfield>
        </div>
        <div id="total-weekly-working-hours" class="col">(20,50 Wochenstunden)</div>
      </div>
    </div>

    <div class="two-column-layout k-d-grid">
      <div class="col">
        <kendo-formfield orientation="horizontal">
          <kendo-label
            class="!k-align-items-start"
            [for]="employedSince"
            text="Urlaubstage"
          ></kendo-label>
          <kendo-textbox
            showErrorIcon="initial"
            [id]="formControlNames.Sunday"
            [formControlName]="formControlNames.Sunday"
            #saturday
          >
          </kendo-textbox>
          <kendo-formerror>Error: Username is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
  </form>
</div>
