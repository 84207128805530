import { CalendarService } from '../../../../../services/calendar.service';
import { ITimeBlockComponentItem } from '../../../time-block-component-items';
import { TimeBlockStructureService } from '../../../time-block-structure/time-block-structure.service';
import { IntersectionService } from '../../intersection/intersection.service';
import { Slot } from './slot';
import { Injectable } from '@angular/core';
import { HorizontalSlotHandlerService } from './horizontal-slot-handler.service';
import { Interval } from 'date-fns';
import { TimeBlockItemBuilderService } from '../../../generation/time-block-item-builder.service';
import { TimeBlockType } from '../../../../../../../shared/data-types/time-block-types';

@Injectable()
export class ParallelTimeBlockWeekViewInnerdayCalculatorService {
  constructor(
    private readonly timeBlockStructureService: TimeBlockStructureService,
    private readonly calendarService: CalendarService,
    private readonly intersectionService: IntersectionService,
    private readonly timeBlockItemBuilderService: TimeBlockItemBuilderService,
    private readonly horizontalSlotHandlerService: HorizontalSlotHandlerService,
  ) {}

  public calculate(): ITimeBlockComponentItem[] {
    const visibleRange: Interval = {
      start: this.calendarService.model.calendarProperties.visibleStartDate,
      end: this.calendarService.model.calendarProperties.visibleEndDate,
    };
    const visibleTimeBlocks = this.timeBlockStructureService.getTimeBlockMap(false, visibleRange);
    const perDay = new Map<string, Slot[]>();
    visibleTimeBlocks.forEach((timeBlocksPerDay: ITimeBlockComponentItem[], date: string) => {
      const verticalSlotsPerDay = new Array<Slot>();
      perDay.set(date, verticalSlotsPerDay);
      this.horizontalSlotHandlerService.orderInnerdayByStartDate(timeBlocksPerDay);
      timeBlocksPerDay
        .filter((timeBlock) => timeBlock.timeBlockModel.type === TimeBlockType.ExistingBlock)
        .forEach((timeBlock: ITimeBlockComponentItem) => {
          this.horizontalSlotHandlerService.assignTimeBlockToSlot(
            timeBlock,
            verticalSlotsPerDay,
            this.intersectionService,
          );
        });
    });
    return this.updateGeometryVerticalSlots(perDay);
  }

  private updateGeometryVerticalSlots(perDay: Map<string, Slot[]>): ITimeBlockComponentItem[] {
    const updatedTimeBlocks = new Array<ITimeBlockComponentItem>();
    perDay.forEach((slotsPerDay: Slot[]) => {
      const slotCount = slotsPerDay.length;
      const percent = 100 / slotCount;
      for (let slotIndex = 0; slotIndex < slotCount; slotIndex++) {
        const slot = slotsPerDay[slotIndex];
        for (const timeBlock of slot.timeBlocks) {
          const timeBlockClone = timeBlock.clone(this.timeBlockItemBuilderService);
          const freeSlotCount = this.freeSlotCountAdjacentSlots(
            timeBlockClone,
            slotIndex + 1,
            slotsPerDay,
          );
          timeBlockClone.timeBlockModel.timeBlockViewType.geometryData.width =
            percent * (freeSlotCount + 1);
          timeBlockClone.timeBlockModel.timeBlockViewType.geometryData.containerLeftEdgeToTimeBlockLeftEdge =
            slotIndex * percent;
          updatedTimeBlocks.push(timeBlockClone);
        }
      }
    });
    return updatedTimeBlocks;
  }

  private freeSlotCountAdjacentSlots(
    timeBlock: ITimeBlockComponentItem,
    nextSlot: number,
    slotsPerDay: Slot[],
  ): number {
    let freeSlots = 0;
    for (let slotIndex = nextSlot; slotIndex < slotsPerDay.length; slotIndex++) {
      if (slotsPerDay[slotIndex].intersectsTimeBlock(timeBlock)) {
        break;
      }
      freeSlots++;
    }
    return freeSlots;
  }
}
