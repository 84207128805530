import { Exclude, Transform } from 'class-transformer';
import { DateTimeHelper } from '../../../../features/calendar/util/date-time-helper';

export class HolidayTemplateNameModel {
  public id: number = null;
  public name = '';
  @Transform(({ value }) => new Date(value), { toClassOnly: true })
  @Transform(({ value }) => DateTimeHelper.format(value), { toPlainOnly: true })
  public date: Date = null;
  @Exclude({ toPlainOnly: true })
  public template = -1;
}
