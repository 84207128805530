import { Renderer2, RendererStyleFlags2 } from '@angular/core';
import { ITimeBlockComponentItem } from '../time-block-component-items';
import { TimeBlockElementSelectorService } from './time-block-element-selector.service';
import { CssAttributes } from './css-attributes';
import { DateTimeHelper } from '../../../util/date-time-helper';
import { CalendarService } from '../../../services/calendar.service';
import {
  CSSHasNextWeekPart,
  CSSHasPrevWeekPart,
  DayOrWeekInnerDayTimeBlockGapPx,
  FulldayContainerTopSpacing,
  HorizontalTimeBlockGapPx,
} from '../../../../../core/data-repository/css-constants';
import { IFulldayViewType } from '../../../../../shared/data-types/time-block-types';

export class TimeBlockRenderer {
  constructor(
    private readonly renderer: Renderer2,
    private readonly calendarService: CalendarService,
  ) {}

  public renderDayOrWeekViewInnerdayTimeBlock(timeBlock: ITimeBlockComponentItem): void {
    const geometryData = timeBlock.timeBlockModel.timeBlockViewType.geometryData;
    const timeBlockHTMLWrapper = TimeBlockElementSelectorService.getTimeBlockHTMLWrapper(timeBlock);

    if (geometryData.height > 0) {
      this.renderer.setStyle(
        timeBlockHTMLWrapper,
        CssAttributes.height,
        `${geometryData.height}px`,
      );
      this.renderer.setStyle(timeBlockHTMLWrapper, CssAttributes.top, `${geometryData.top}px`);

      const left = geometryData.containerLeftEdgeToTimeBlockLeftEdge;
      this.renderer.setStyle(timeBlockHTMLWrapper, CssAttributes.left, `${left}%`);
      const width = geometryData.width;
      this.renderer.setStyle(
        timeBlockHTMLWrapper,
        CssAttributes.width,
        `calc(${width}% - ${DayOrWeekInnerDayTimeBlockGapPx}px)`,
      );
    }
  }

  public renderDayOrWeekViewFulldayTimeBlock(
    timeBlock: ITimeBlockComponentItem,
    fulldayContainerWidth: number,
  ): void {
    if (!fulldayContainerWidth || fulldayContainerWidth <= 0) {
      throw new Error(`Full day container width is invalid: ${fulldayContainerWidth}`);
    }

    const timeBlockViewType = timeBlock.timeBlockModel.timeBlockViewType as IFulldayViewType;
    const width = (timeBlockViewType.geometryData.width / fulldayContainerWidth) * 100;
    const left = (timeBlockViewType.geometryData.left / fulldayContainerWidth) * 100;
    let top = HorizontalTimeBlockGapPx + FulldayContainerTopSpacing;
    if (timeBlockViewType.parallelIndex >= 0) {
      const parallelIndex = timeBlockViewType.parallelIndex;
      const height = timeBlockViewType.geometryData.height;
      timeBlockViewType.geometryData.top = top =
        height * parallelIndex +
        parallelIndex * 2 +
        HorizontalTimeBlockGapPx +
        FulldayContainerTopSpacing;
    }

    const timeBlockHTMLWrapper = TimeBlockElementSelectorService.getTimeBlockHTMLWrapper(timeBlock);
    const calendarProperties = this.calendarService.model.calendarProperties;
    if (
      DateTimeHelper.isBefore(timeBlock.timeBlockModel.start, calendarProperties.visibleStartDate)
    ) {
      this.renderer.addClass(
        timeBlock.timeBlockModel.componentRef.instance.timeBlockHTMLWrapper.nativeElement,
        CSSHasPrevWeekPart,
      );
    }

    if (DateTimeHelper.isAfter(timeBlock.timeBlockModel.end, calendarProperties.visibleEndDate)) {
      this.renderer.addClass(
        timeBlock.timeBlockModel.componentRef.instance.timeBlockHTMLWrapper.nativeElement,
        CSSHasNextWeekPart,
      );
    }

    this.renderer.setStyle(
      timeBlockHTMLWrapper,
      CssAttributes.left,
      `${left}%`,
      RendererStyleFlags2.Important,
    );
    this.renderer.setStyle(timeBlockHTMLWrapper, CssAttributes.width, `calc(${Math.abs(width)}%)`);
    this.renderer.setStyle(timeBlockHTMLWrapper, CssAttributes.transform, `translate(0, ${top}px)`);
  }

  public renderMonthViewFulldayTimeBlock(timeBlock: ITimeBlockComponentItem): void {
    const timeBlockHTMLWrapper = TimeBlockElementSelectorService.getTimeBlockHTMLWrapper(timeBlock);
    const width = timeBlock.timeBlockModel.timeBlockViewType.geometryData.width;

    const timeBlockViewType = timeBlock.timeBlockModel.timeBlockViewType as IFulldayViewType;
    let top = 0;
    const parallelIndex = timeBlockViewType.parallelIndex;
    if (parallelIndex > 0) {
      const height = timeBlockViewType.geometryData.height;
      timeBlockViewType.geometryData.top = top =
        height * parallelIndex + parallelIndex * HorizontalTimeBlockGapPx;
    }

    this.renderer.setStyle(timeBlockHTMLWrapper, CssAttributes.transform, `translate(0, ${top}px)`);

    if (timeBlock.timeBlockModel.componentRef !== null) {
      const calendarModel = this.calendarService.model;
      // Check if week has a previous week, i.e. if the partNumber is 0 or if the full day time block starts before the visible start of the
      // current month
      const visibleStartDate = calendarModel.calendarProperties.visibleStartDate;
      if (
        timeBlock.timeBlockModel.partNumber > 0 ||
        DateTimeHelper.isBefore(
          timeBlock.timeBlockModel.start,
          calendarModel.calendarProperties.visibleStartDate,
        )
      ) {
        this.renderer.addClass(
          timeBlock.timeBlockModel.componentRef.instance.timeBlockHTMLWrapper.nativeElement,
          CSSHasPrevWeekPart,
        );
      }

      // Calculate the day difference between the visible calendar start and the time block start. Need to be done since partNumber is 0
      // for a time block that probably starts before the current month.
      let offsetDays = DateTimeHelper.differenceInDays(
        visibleStartDate,
        timeBlock.timeBlockModel.start,
      );
      offsetDays = offsetDays < 0 ? 0 : offsetDays;

      // Calculate the correct current date.
      const curDate = DateTimeHelper.addDays(
        timeBlock.timeBlockModel.start,
        timeBlock.timeBlockModel.partNumber + offsetDays,
      );
      const endOfWeek = DateTimeHelper.endOfWeek(curDate, {
        weekStartsOn: this.calendarService.model.calendarProperties.weekStartsOn,
      });
      // Check if week has a subsequent week
      if (DateTimeHelper.isAfter(timeBlock.timeBlockModel.end, endOfWeek)) {
        this.renderer.addClass(
          timeBlock.timeBlockModel.componentRef.instance.timeBlockHTMLWrapper.nativeElement,
          CSSHasNextWeekPart,
        );
      }
    }

    this.renderer.setStyle(timeBlockHTMLWrapper, CssAttributes.width, `calc(${width}px`);
  }

  public renderMonthViewInnerdayTimeBlock(timeBlock: ITimeBlockComponentItem): void {
    let top = 0;
    const timeBlockHTMLWrapper = TimeBlockElementSelectorService.getTimeBlockHTMLWrapper(timeBlock);
    const timeBlockViewType = timeBlock.timeBlockModel.timeBlockViewType as IFulldayViewType;
    const parallelIndex = timeBlockViewType.parallelIndex;
    if (!parallelIndex || parallelIndex < 0) {
      return;
    }

    const height = timeBlockViewType.geometryData.height;
    timeBlockViewType.geometryData.top = top =
      height * parallelIndex + parallelIndex * HorizontalTimeBlockGapPx;
    this.renderer.setStyle(timeBlockHTMLWrapper, CssAttributes.top, `calc(${top}px)`);
  }
}
