import { createAction, props } from '@ngrx/store';
import { UserModel } from '../../models/user/user.model';

export const fetchSingleUser = createAction(
  '[Users] Fetch Single User',
  props<{ payload: number }>(),
);
export const setSingleUser = createAction(
  '[Users] Set Single User',
  props<{ payload: UserModel }>(),
);

export const fetchUserList = createAction('[Users] Fetch Users');
export const setUsers = createAction('[Users] Set Users', props<{ payload: UserModel[] }>());

export const addUser = createAction('[Users] Add User', props<{ payload: UserModel }>());
export const userAdded = createAction('[Users] Added User', props<{ payload: UserModel }>());

export const updateUser = createAction('[Users] Update User', props<{ payload: UserModel }>());
export const userUpdated = createAction('[Users] Updated User', props<{ payload: UserModel }>());

export const deleteUser = createAction('[Users] Delete User', props<{ payload: number }>());
export const userDeleted = createAction('[Users] Deleted User');

export const httpFail = createAction('[Users] Http fail', props<{ payload: string }>());
