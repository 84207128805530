import { NavigationWrapper } from '../../navigation-data';
import { ShiveRoutes } from '../../segments/route-segments';
import { PageKey } from '../../page-keys';
import { UrlSegmentDelimiter } from '../../../data-repository/css-constants';

export const Projects: NavigationWrapper = {
  title: 'Projekte',
  route: ShiveRoutes.Projects,
};

export const ProjectList: NavigationWrapper = {
  title: 'Projektübersicht',
  route: ShiveRoutes.ProjectList,
};

export const ProjectAdd: NavigationWrapper = {
  title: 'Neues Projekt',
  route: ShiveRoutes.ProjectAdd,
  previousPage: PageKey.ProjectPageKeys.ProjectList,
};

export const ProjectStatus: NavigationWrapper = {
  title: 'Status',
  route: ShiveRoutes.ProjectStatus + UrlSegmentDelimiter + ShiveRoutes.AnyId,
  previousPage: PageKey.ProjectPageKeys.ProjectList,
};

export const ProjectEdit: NavigationWrapper = {
  title: 'Projektdaten',
  route: ShiveRoutes.ProjectEdit + UrlSegmentDelimiter + ShiveRoutes.AnyId,
  previousPage: PageKey.ProjectPageKeys.ProjectList,
};

export const ProjectAttachments: NavigationWrapper = {
  title: 'Anhänge',
  route: ShiveRoutes.ProjectAttachments + UrlSegmentDelimiter + ShiveRoutes.AnyId,
  previousPage: PageKey.ProjectPageKeys.ProjectList,
};

export const ProjectLogs: NavigationWrapper = {
  title: 'Aktivitäten',
  route: ShiveRoutes.ProjectLogs + UrlSegmentDelimiter + ShiveRoutes.AnyId,
  previousPage: PageKey.ProjectPageKeys.ProjectList,
};
