import { Component, Input, SecurityContext } from '@angular/core';
import { UserModel } from '../../../core/models/user/user.model';
import { faUserVneck } from '@fortawesome/pro-solid-svg-icons/faUserVneck';

@Component({
  selector: 'app-shared-member-template',
  templateUrl: './shared-member-template.component.html',
  styleUrls: ['./shared-member-template.component.scss'],
})
export class SharedMemberTemplateComponent {
  @Input() user: UserModel;
  public readonly SecurityContext = SecurityContext;
  protected readonly faUserVneck = faUserVneck;
}
