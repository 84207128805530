import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { pageMap } from '../routing/mapping/page-mapping';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT, Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { NavigationModel } from '../models/navigation/navigation.model';
import * as fromNavigationActions from '../state/navigation/navigation.actions';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private readonly renderer: Renderer2;
  private navigationModel: NavigationModel;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly rendererFactory: RendererFactory2,
    private readonly location: Location,
    private readonly store: Store<NavigationModel>,
    private readonly router: Router,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.initNavigationChanged();
  }

  public back(): void {
    void this.router.navigateByUrl(this.navigationModel.prevRoute);
  }

  private initNavigationChanged(): void {
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
      this.emitRouteData();
      this.setCSSHTMLClass();
    });
  }

  private emitRouteData(): void {
    const currentRoute = this.location.path().substr(1);
    const pageKey = pageMap.getPageKey(currentRoute);
    const pageTitle = pageMap.getTitle(pageKey);
    const prevPageKey = pageMap.getPreviousPage(pageKey);

    const navigationModel = new NavigationModel();
    navigationModel.pageTitle = pageTitle;

    if (prevPageKey) {
      navigationModel.prevRoute = pageMap.getRoute(prevPageKey);
      navigationModel.prevTitle = pageMap.getTitle(prevPageKey);
    }

    this.navigationModel = navigationModel;
    this.store.dispatch(
      fromNavigationActions.setNavigation({
        payload: navigationModel,
      }),
    );
  }

  private setCSSHTMLClass(): void {
    this.document.documentElement.classList.forEach((cssClass) => {
      this.renderer.removeClass(this.document.documentElement, cssClass);
    });
    this.renderer.addClass(this.document.documentElement, this.router.url.split('/')[1]);
  }
}
