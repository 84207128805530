<div class="dialog-content">
  <form class="k-form" [formGroup]="holidayForm">
    <kendo-formfield orientation="horizontal">
      <kendo-label class="!k-align-items-start" [for]="holidayName" text="Feiertag"></kendo-label>
      <kendo-textbox
        showErrorIcon="initial"
        id="holidayName"
        formControlName="holidayName"
        #holidayName
      >
      </kendo-textbox>
      <kendo-formerror>Error: Full Name is required</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield orientation="horizontal">
      <kendo-label class="!k-align-items-start" [for]="holidayDate" text="Datum"></kendo-label>
      <kendo-datepicker formControlName="holidayDate" #holidayDate> </kendo-datepicker>
      <kendo-formerror>Error: Full Name is required</kendo-formerror>
    </kendo-formfield>
  </form>
</div>

<footer class="k-d-flex k-justify-content-between">
  <button (click)="close()" kendoButton size="large" themeColor="secondary" class="k-mr-4 !k-pr-4">
    <span class="custom-symbol close k-mr-3"></span>
    <span class="label">Schließen</span>
  </button>

  <button kendoButton (click)="submit()" class="!k-pr-4" themeColor="base" size="large">
    @if (userOperation === UserOperation.Create) {
      <fa-icon class="add text-white k-mr-3" [icon]="faCirclePlus"></fa-icon>
      <span class="label">Hinzufügen</span>
    }

    @if (userOperation === UserOperation.Update) {
      <fa-icon class="update text-white k-mr-3" [icon]="faPenToSquare"></fa-icon>
      <span class="label">Aktualisieren</span>
    }

    @if (userOperation === UserOperation.Delete) {
      <span class="remove custom-symbol k-mr-3"></span>
      <span class="label">Löschen</span>
    }
  </button>
</footer>
