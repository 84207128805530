export const UrlSegmentDelimiter = '/';
export const SkipErrorInterceptor = 'skipErrorInterceptor';

// CSS time block classes
export const CSSGhostClass = 'ghost';
export const CSSDraggedClass = 'dragged';
export const CSSResizedClass = 'resized';
export const CSSHeadPart = 'head-part';
export const CSSDisplayTimeBlockDuration = 'display-time-block-duration';
export const CSSActiveClass = 'active';
export const CSSPressedClass = 'pressed';
export const CSSNewClass = 'new';
export const CSSHighlightedClass = 'highlighted';
export const CSSSmallTimeBlockClass = 'small-time-block';
export const CSSFulldayTimeBlockOverflowsLeftClass = 'overflows-left';
export const CSSFulldayTimeBlockOverflowsRightClass = 'overflows-right';
export const CSSHasPrevPart = 'has-prev-part';
export const CSSHasNextPart = 'has-next-part';
export const CSSHasPrevWeekPart = 'has-prev-week-part';
export const CSSHasNextWeekPart = 'has-next-week-part';
export const CSSTrackedTimeBlock = 'tracked-time-block';
export const CSSDefaultProjectBGColor = '#d3e9ed'; // Todo: load from database

// CSS calendar selector
export const CalendarHeaderCSSId = '#calendar-header-scroll-container';
export const CalendarBodyCSSId = '#shive-calendar-body';
export const NowIndicatorCSSId = '#now-indicator';
export const FulldayCalendarContainerId = '#full-day'; // Wrapper for fixed-col and full-day-item-container
export const FulldayCalendarContainerExpandable = 'expandable';
export const MonthViewTimeBlockContainer = 'time-block-container';
export const TimeBlockDialogCSSSelector = '.time-entry-dialog-wrapper .k-dialog';

// CSS calendar and time block values
export const DayOrWeekInnerDayTimeBlockGapPx = 12;
export const FulldayContainerMinimumHeight = 26;
export const HorizontalTimeBlockGapPx = 2;

// We use this variable for the full day container height calculation since we cannot access the time blocks height.
export const FulldayTimeBlockHeightPx = 20;
export const FulldayContainerTopSpacing = 2;
export const FulldayContainerBottomSpacing = 2;
export const HorizontalDragTolerance = 40;

// CSS dialog
export const TabStripDialogContentCSSSelector = '.k-dialog-wrapper .k-tabstrip .k-tabstrip-content';
