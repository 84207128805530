export function combineMaps<K, V>(map1: Map<K, V[]>, map2: Map<K, V[]>): Map<K, V[]> {
  const mergedMap = new Map<K, V[]>(map1); // Copy map1

  map2.forEach((value, key) => {
    if (mergedMap.has(key)) {
      let existing = mergedMap.get(key);
      existing = existing.concat(value);
      // If key exists in both maps, concatenate arrays
      mergedMap.set(key, existing);
    } else {
      // If key exists only in map2, add it to mergedMap
      mergedMap.set(key, value);
    }
  });

  return mergedMap;
}
