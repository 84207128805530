import { Component, ElementRef, Input, OnDestroy, QueryList, ViewChild } from '@angular/core';
import { TimeBlockInjectable } from '../time-block-injectable';
import { TimeBlockContainerDirective } from '../time-block-container.directive';
import { Viewable } from '../viewable';
import { CalendarService } from '../../services/calendar.service';
import { TimeBlockCrudService } from '../../components/time-block/crud/time-block-crud.service';
import { TimeCoordinateMappingService } from '../../time-mapping/time-coordinate-mapping.service';
import { DayOrWeekViewHandler } from '../handlers/day-or-week-view-handler';
import { SidebarService } from '../../../../core/services/ui/sidebar.service';
import { TimeBlockService } from '../../components/time-block/time-block.service';
import { Observable } from 'rxjs';
import { CalendarViewHandlerService } from '../../services/calendar-view-handler.service';

@Component({
  selector: 'app-day-view',
  templateUrl: './day-view.component.html',
  styleUrls: ['./day-view.component.scss'],
})
export class DayViewComponent
  extends DayOrWeekViewHandler
  implements OnDestroy, TimeBlockInjectable, Viewable
{
  @ViewChild(TimeBlockContainerDirective) public timeLanePlaceholders!: TimeBlockContainerDirective;
  @ViewChild('lane') public lane: ElementRef<HTMLTableCellElement>;
  @ViewChild('itemContainer') public htmlItemContainer: ElementRef<HTMLElement>; // / calendar body
  @Input() fulldayLanesLoaded$: Observable<
    [QueryList<TimeBlockContainerDirective>, QueryList<ElementRef>]
  >;

  constructor(
    private readonly calendarService: CalendarService,
    private readonly timeBlockService: TimeBlockService,
    private readonly timeBlockCrudService: TimeBlockCrudService,
    private readonly timeCoordinateMappingService: TimeCoordinateMappingService,
    private readonly sidebarService: SidebarService,
    private readonly calendarViewHandlerService: CalendarViewHandlerService,
  ) {
    super(
      calendarService,
      timeBlockCrudService,
      timeCoordinateMappingService,
      sidebarService,
      calendarViewHandlerService,
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  protected setModelTemplateData(): void {
    throw new Error('not implemented yet');
  }

  protected initCalendarView(): void {
    throw new Error('not implemented yet');
  }
}
