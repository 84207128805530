import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { SubSink } from 'subsink';
import { ComponentCanDeactivate } from '../../../../core/guards/pending-changes.guard';
import { Observable, merge } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import * as fromProjectActions from '../../../../core/state/projects/projects.actions';
import { ProjectService } from '../../services/project.service';

@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss'],
})
export class EditProjectComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  private readonly subs = new SubSink();
  private canLeavePage = true;

  constructor(
    private readonly actions$: Actions,
    private readonly projectService: ProjectService,
  ) {}

  ngOnInit(): void {
    this.initEvents();
    this.initHttpResponse();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  @HostListener('window:beforeunload')
  public canDeactivate(): boolean | Observable<boolean> {
    return this.canLeavePage;
  }

  public initHttpResponse(): void {
    this.subs.sink = this.actions$.pipe(ofType(fromProjectActions.projectUpdated)).subscribe(() => {
      this.canLeavePage = true;
    });
  }

  private initEvents() {
    this.subs.sink = merge(this.projectService.userOperationConducted$).subscribe(() => {
      this.canLeavePage = false;
    });
  }
}
