import { Directive, HostListener } from '@angular/core';
import { CalendarScrollbarService } from '../services/calendar-scrollbar.service';
import { CalendarMouseHandlerService } from '../mouse/calendar-mouse-handler.service';
import { CalendarService } from '../services/calendar.service';
import SmoothScrollbar from 'smooth-scrollbar';
import { filter, take } from 'rxjs/operators';

@Directive({
  selector: '[appCalendarInteraction]',
})
export class CalendarInteractionDirective {
  constructor(
    private readonly calendarService: CalendarService,
    private readonly calendarMouseHandlerService: CalendarMouseHandlerService,
    private readonly calendarScrollbarService: CalendarScrollbarService,
  ) {
    this.registerScrollbarEvent();
  }

  @HostListener('mousedown', ['$event'])
  public mouseDown(e: MouseEvent): void {
    this.calendarMouseHandlerService.mousePosChanged(
      e.clientX,
      e.clientY + this.calendarScrollbarService.calendarBodyScrollbar.scrollData.scrollPosY,
      e.type,
    );
  }

  @HostListener('mousemove', ['$event'])
  public mouseMove(e: MouseEvent): void {
    this.calendarMouseHandlerService.mousePosChanged(
      e.clientX,
      e.clientY + this.calendarScrollbarService.calendarBodyScrollbar.scrollData.scrollPosY,
      e.type,
    );
  }

  @HostListener('mouseleave', ['$event'])
  public mouseLeave(e: MouseEvent): void {
    this.calendarMouseHandlerService.mousePosChanged(
      e.clientX,
      e.clientY + this.calendarScrollbarService.calendarBodyScrollbar.scrollData.scrollPosY,
      e.type,
    );
  }

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    this.calendarService.calendarViewportResized$.next(null);
  }

  private registerScrollbarEvent(): void {
    this.calendarScrollbarService.calendarBodyScrollbarInitialized$
      .pipe(
        filter((scrollbar) => scrollbar !== null),
        take(1),
      )
      .subscribe((scrollbar: SmoothScrollbar) => {
        scrollbar.addListener((status) => {
          this.calendarScrollbarService.calendarBodyScrollbar.scrollPosChanged(status);
        });
      });
  }
}
