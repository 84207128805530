import { Injectable, OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';
import { ProjectModel } from '../../../../../core/models/project/project.model';
import { ProjectsHttpService } from '../../../../../core/state/projects/projects-http.service';
import { FormGroup } from '@angular/forms';
import { ProjectService } from '../../../services/project.service';
import { StatusModel } from '../../../../../core/models/project/status.model';

@Injectable({
  providedIn: 'root',
})
export class ManageProjectService implements OnDestroy {
  // Form Groups
  public projectBaseForm: FormGroup;

  private readonly subs = new SubSink();

  constructor(
    private readonly projectsHttpService: ProjectsHttpService,
    private readonly projectService: ProjectService,
  ) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public emitManageProjectHttpRequests(): boolean {
    if (this.formsValid()) {
      const projectClone = this.projectService.getProjectClone();
      this.sendPostOrPutRequests(projectClone);
      return true;
    }
    return false;
  }

  public initNewProject(statuses: StatusModel[]): void {
    // User creates new project.
    const project = new ProjectModel();
    project.id = -1;
    project.name = '';
    project.tasks = [];
    project.members = [];
    project.client = null;
    project.status = statuses[0];
    project.startsAt = new Date();
    project.endsAt = new Date();
    this.projectService.addProject(project);
  }

  private formsValid(): boolean {
    this.projectBaseForm.markAllAsTouched();
    return this.projectBaseForm.valid;
  }

  private sendPostOrPutRequests(project: ProjectModel): void {
    if (project.id >= 0) {
      this.projectsHttpService.updateProject(project);
    } else {
      this.projectsHttpService.addProject(project);
    }
  }
}
