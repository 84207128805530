import { Action } from '@ngrx/store';
import { UserOperation } from '../../core/enums/user-operation';

export interface UndoableItem {}

export enum UndoRedoOperations {
  Undo,
  Redo,
  None,
}

export interface UndoRedoAction extends Action {
  payload;
  undoRedoMeta: UndoRedoMeta;
  storeInHistory: boolean;
}

export type UndoRedoMeta = {
  userOperation: UserOperation;
  undoableFn: (item: UndoableItem, storeInHistory: boolean) => void;
  undoableType: string;
  undoableId: number;
};
