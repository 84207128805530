import { Action, ActionReducer, INIT } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { AppState } from '../app.state';
import { redo, undo } from './undo-redo.actions';
import {
  UndoRedoAction,
  UndoRedoMeta,
  UndoRedoOperations,
} from '../../../shared/data-types/undo-redo-types';
import { UserOperation } from '../../enums/user-operation';

export interface History {
  past: AppState[];
  present: AppState;
  future: AppState[];
  undoRedoAction: UndoRedoOperations;
  meta: UndoRedoMeta;
}

const historySub$ = new BehaviorSubject<History>({
  past: [],
  present: null,
  future: [],
  undoRedoAction: UndoRedoOperations.None,
  meta: {
    userOperation: UserOperation.None,
    undoableFn: null,
    undoableType: '',
    undoableId: -1,
  },
});

export const history$: Observable<History> = historySub$.asObservable();

export function historyReducer(reducer: ActionReducer<AppState>): unknown {
  historySub$.next({
    past: [],
    present: reducer({}, { type: INIT }),
    future: [],
    undoRedoAction: UndoRedoOperations.None,
    meta: {
      userOperation: UserOperation.None,
      undoableFn: null,
      undoableType: '',
      undoableId: -1,
    },
  });
  return (state: AppState, action: Action) => {
    const { past, present, future } = historySub$.value;

    switch (action.type) {
      case undo.type: {
        if (!past.length) {
          return state;
        }
        // use first past state as next present
        const previous = past[0];
        // ... and remove from past
        const newPast = past.slice(1);
        historySub$.next({
          ...historySub$.value,
          past: newPast,
          present: cloneDeep(previous),
          // push present into future for redo
          future: [present, ...future],
          undoRedoAction: UndoRedoOperations.Undo,
        });
        return previous;
      }
      case redo.type: {
        if (!future.length) {
          return state;
        }
        // use first future state as next present
        const next = future[0];
        // ... and remove from future
        const newFuture = future.slice(1);
        historySub$.next({
          ...historySub$.value,
          // push present into past for undo
          past: [present, ...past],
          present: cloneDeep(next),
          future: newFuture,
          undoRedoAction: UndoRedoOperations.Redo,
        });
        return next;
      }
      default: {
        // derive next state
        const newPresent = reducer(state, action);
        if (present === newPresent) {
          return state;
        }
        // update undoable history
        if ((action as UndoRedoAction).storeInHistory) {
          const undoRedoAction = action as UndoRedoAction;
          historySub$.next({
            // push previous present into past for undo
            past: [present, ...past],
            present: cloneDeep(newPresent),
            future: [], // clear future,
            undoRedoAction: UndoRedoOperations.None,
            meta: {
              userOperation: undoRedoAction.undoRedoMeta?.userOperation,
              undoableFn: undoRedoAction.undoRedoMeta?.undoableFn,
              undoableType: undoRedoAction.undoRedoMeta?.undoableType,
              undoableId: undoRedoAction.undoRedoMeta?.undoableId,
            },
          });
        }
        return newPresent;
      }
    }
  };
}
