<div id="shive-timegrid-body" class="week-view">
  <div id="shive-timegrid-slots">
    <table>
      <colgroup>
        <col class="fixed-lane" />
      </colgroup>
      <tbody>
        @for (hour of hourSegments; track i; let i = $index) {
          <tr>
            <td class="shive-timegrid-slot shive-timegrid-slots-label fixed-col">
              @if (i > 0) {
                <div class="shive-timegrid-slot-label-frame">
                  <div class="shive-timegrid-slot-label-cushion user-select-none">
                    {{ hour | date: calService.model.calendarProperties.timeFormat }}
                  </div>
                </div>
              }
            </td>
            <td
              class="shive-timegrid-slot shive-timegrid-slot-lane"
              [class.worktime-boundary]="isWorkTimeBoundary(hour)"
            ></td>
          </tr>
          <tr>
            <td
              class="shive-timegrid-slot shive-timegrid-slots-label shive-timegrid-slot-minor fixed-col"
            ></td>
            <td class="shive-timegrid-slot shive-timegrid-slot-lane shive-timegrid-slot-minor"></td>
          </tr>
        }
      </tbody>
    </table>
  </div>

  <div id="shive-timegrid-lanes">
    <table>
      <colgroup>
        <col class="fixed-lane" />
      </colgroup>
      <tbody>
        <tr>
          <td class="fixed-col"></td>
          <td>
            <table class="item-container" #htmlItemContainer>
              <tr>
                @for (day of days; track day.theDate; let i = $index) {
                  <td
                    [attr.date]="DateTimeHelper.format(day.theDate)"
                    class="weekday lane"
                    [ngClass]="{
                      'outside-of-view': day.outsideOfView,
                      'inside-of-view': !day.outsideOfView,
                      dropzone: !day.outsideOfView,
                    }"
                    [id]="i"
                    [class]="day.epoch"
                    [class.first]="
                      DateTimeHelper.isSameDay(
                        day.theDate,
                        calendarService.model.calendarProperties.visibleStartDate
                      )
                    "
                    [class.last]="
                      DateTimeHelper.isSameDay(
                        day.theDate,
                        calendarService.model.calendarProperties.visibleEndDate
                      )
                    "
                    [calendarView]="CalendarView.WeekGrid"
                    appEmptyCalendarSpaceInteraction
                    #timeBlockLanes
                  >
                    @if (day.epoch === "today") {
                      <div id="now-indicator" appNowIndicator></div>
                    }
                    <ng-container appTimeBlockContainer></ng-container>
                  </td>
                }
              </tr>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
