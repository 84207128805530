import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { GroupedDropdownOption } from '../../../../../../../shared/data-types/dropdown-option';
import { GroupResult, groupBy } from '@progress/kendo-data-query';
import { mapToGroupedDropdownOptions } from '../../../../../../../shared/functions/dropdown-functions';
import { Subject, take } from 'rxjs';
import { BillableRateOptions } from '../../../../../../../core/data-repository/dropdown-constants';
import { getEnumKeyByEnumValue } from '../../../../../../../shared/functions/enum-functions';
import { BillableRateOptionLiterals } from '../../../../../../../core/text-db/project-calculation.text';
import { ProjectModel } from '../../../../../../../core/models/project/project.model';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { ManageProjectService } from '../../manage-project.service';
import { BillingEntryService } from '../services/billing-entry.service';
import { ProjectService } from '../../../../../services/project.service';
import { ProjectBillingBudgetTransformerService } from '../../../services/project-billing-budget-transformer.service';
import { BillingHistoryDialogComponent } from '../../../dialogs/billing-history-dialog/billing-history-dialog.component';
import { ShiveDialogService } from '../../../../../../../core/services/controls/shive-dialog.service';
import { BillingEntry } from '../../../../../../../shared/data-types/project-types';
import { assert } from 'src/app/core/assert/assert';
import { filter } from 'rxjs/operators';
import { UserOperation } from '../../../../../../../core/enums/user-operation';

@Component({
  selector: 'app-project-calculation-form-billing',
  templateUrl: './project-calculation-form-billing.component.html',
  styleUrls: ['./project-calculation-form-billing.component.scss'],
})
export class ProjectCalculationFormBillingComponent implements OnInit {
  @Input() resetBillableValueForm$: Subject<unknown>;
  @Output() readonly selectedBillableRateOptionChanged = new EventEmitter<BillableRateOptions>();
  public projectBillingForm: FormGroup;
  public billableRateOptions: GroupedDropdownOption[] | GroupResult[];
  public selectedBillableRateOption: BillableRateOptions;
  public BillableRateOptions = BillableRateOptions;
  public readonly faPenToSquare = faPenToSquare;
  public billableValue: number;
  public billingHistory: BillingEntry[] = null;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly manageProjectService: ManageProjectService,
    private readonly projectService: ProjectService,
    private readonly projectBillingBudgetTransformer: ProjectBillingBudgetTransformerService,
    private readonly shiveDialogService: ShiveDialogService,
    private readonly billingEntryService: BillingEntryService,
  ) {}

  ngOnInit(): void {
    const projectClone = this.projectService.getProjectClone();
    this.selectedBillableRateOption = projectClone.billingType;

    this.billingHistory =
      this.projectBillingBudgetTransformer.transformBillingEntriesBillableHourlyValueFixed(
        projectClone,
      );
    this.initBillingEntries(projectClone);
  }

  // The item of the billable rate option combobox has changed
  public billableRateOptionChanged($event: GroupedDropdownOption): void {
    const enumOption = getEnumKeyByEnumValue(BillableRateOptions, $event.value.toString());
    this.selectedBillableRateOption = BillableRateOptions[enumOption];
    this.projectBillingForm.controls.billableRateValue.patchValue(0);

    const projectClone = this.projectService.getProjectClone();

    projectClone.billingType = this.selectedBillableRateOption;

    this.billingHistory = [
      this.billingEntryService.createBillingEntry(0, projectClone.startsAt, 0),
    ];
    projectClone.projectBillingEntries =
      this.projectBillingBudgetTransformer.transformHourlyFixedToBillingEntries(
        this.billingHistory,
      );

    // update cache
    this.projectService.updateProject(projectClone);
    // enable save button in footer
    this.selectedBillableRateOptionChanged.next(this.selectedBillableRateOption);
  }

  public openNewBillableRateDialog(): void {
    const ref = this.shiveDialogService.open({
      title: 'Verrechenbarer Stundensatz',
      content: BillingHistoryDialogComponent,
      width: 572,
    });

    const dialogComponent = ref.content.instance as BillingHistoryDialogComponent;
    dialogComponent.billingRateHistory = this.billingHistory;

    ref.result
      .pipe(filter((dialogResult) => dialogResult !== UserOperation.Cancel))
      .subscribe((dialogResult) => {
        this.billingHistory = dialogResult as BillingEntry[];
        const projectClone = this.projectService.getProjectClone();
        projectClone.projectBillingEntries =
          this.projectBillingBudgetTransformer.transformHourlyFixedToBillingEntries(
            this.billingHistory,
          );
        this.billableValue = this.billingHistory[this.billingHistory.length - 1].billingRate;
        this.projectService.updateProject(projectClone);
        this.projectService.userOperationConducted$.next(true);
      });
  }

  public isBillingFixed(): boolean {
    return (
      this.selectedBillableRateOption === BillableRateOptions.FixedFeePerProject ||
      this.selectedBillableRateOption === BillableRateOptions.BillableHourlyValueFixed
    );
  }

  public billableRateValueChanged(value: string): void {
    if (!this.isBillingFixed()) {
      return;
    }
    const projectClone = this.projectService.getProjectClone();
    assert(this.billingHistory.length === 1);
    this.billingHistory[0].billingRate = +value;
    this.billableValue = this.billingHistory[this.billingHistory.length - 1].billingRate;
    projectClone.projectBillingEntries =
      this.projectBillingBudgetTransformer.transformHourlyFixedToBillingEntries(
        this.billingHistory,
      );
    this.projectService.updateProject(projectClone);
    this.projectService.userOperationConducted$.next(true);
  }

  private getSelectedOption(
    groupedDropdownOptions: GroupedDropdownOption[],
    project: ProjectModel,
  ): GroupedDropdownOption {
    return groupedDropdownOptions.find((option) => option.value === project.billingType);
  }

  private initBillingEntries(project: ProjectModel): void {
    mapToGroupedDropdownOptions(BillableRateOptionLiterals)
      .pipe(take(1))
      .subscribe((groupedDropdownOptions: GroupedDropdownOption[]) => {
        this.billableRateOptions = groupBy(groupedDropdownOptions, [{ field: 'category' }]);

        this.projectBillingForm = this.formBuilder.group({
          billableRateType: [this.getSelectedOption(groupedDropdownOptions, project)],
          billableRateValue: [this.billingHistory[this.billingHistory.length - 1].billingRate],
        });

        this.billableValue = this.billingHistory[this.billingHistory.length - 1].billingRate;
      });
  }
}
