<app-page-header [pageTitle]="userFullName">
  <!-- Template ref Needed for content child directive -->
  <ng-template #headerBarArbitraryDataTemplate>
    <div class="k-mt-1 k-d-flex k-flex-column k-gap-1.5">
      <div class="k-d-flex">
        <div>
          <fa-icon class="icon" size="1x" [icon]="faChairOffice"></fa-icon>
          <span class="k-ml-1.5">Geschäftsführer und Co-Founder</span>
        </div>

        <div class="k-ml-4">
          <fa-icon class="icon" size="1x" [icon]="faLocationDot"></fa-icon>
          <span class="k-ml-1.5">Leonding</span>
        </div>
      </div>

      <div>
        <div>
          <fa-icon class="icon" size="1x" [icon]="faBusinessTime"></fa-icon>
          <span class="k-ml-1.5">Beschäftigt seit 15.07.2013 (4 Jahre und 3 Monate)</span>
        </div>
      </div>
    </div>
  </ng-template>
</app-page-header>

<ul class="tabbar nav">
  @for (tab of tabs; track tab) {
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" [routerLink]="['/' + tab.route]">{{ tab.title }}</a>
    </li>
  }
</ul>

<router-outlet></router-outlet>
