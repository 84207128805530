import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientModel } from '../../models/client/client.model';
import { Store } from '@ngrx/store';
import * as fromClientsActions from './clients.actions';
import { Actions, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import {
  NotificationType,
  ShiveNotificationService,
} from '../../services/controls/shive-notification.service';

@Injectable({
  providedIn: 'root',
})
export class ClientsHttpService {
  constructor(
    private readonly store: Store,
    private readonly shiveNotificationService: ShiveNotificationService,
    private readonly actions$: Actions,
  ) {
    this.handleHttpCallbacks();
  }

  public getClients(): Observable<ClientModel[]> {
    this.store.dispatch(fromClientsActions.fetchClientList());

    return this.actions$.pipe(
      ofType(fromClientsActions.setClients),
      map((result) => result.payload),
    );
  }

  public getSingleClient(id: number): Observable<ClientModel> {
    this.store.dispatch(
      fromClientsActions.fetchSingleClient({
        payload: id,
      }),
    );

    return this.actions$.pipe(
      ofType(fromClientsActions.setSingleClient),
      map((result) => result.payload),
    );
  }

  public addClient(clientModel: ClientModel | ClientModel[]): Observable<unknown> {
    this.store.dispatch(
      fromClientsActions.addClient({
        payload: clientModel,
      }),
    );

    return this.actions$.pipe(ofType(fromClientsActions.clientAdded));
  }

  public updateClient(clientModel: ClientModel | ClientModel[]): Observable<unknown> {
    this.store.dispatch(
      fromClientsActions.updateClient({
        payload: clientModel,
      }),
    );

    return this.actions$.pipe(ofType(fromClientsActions.clientUpdated));
  }

  public deleteClient(toBeDeletedClient: ClientModel | ClientModel[]): Observable<unknown> {
    this.store.dispatch(
      fromClientsActions.deleteClient({
        payload: toBeDeletedClient,
      }),
    );

    return this.actions$.pipe(ofType(fromClientsActions.clientDeleted));
  }

  private handleHttpCallbacks(): void {
    this.actions$
      .pipe(
        ofType(
          fromClientsActions.clientAdded,
          fromClientsActions.clientUpdated,
          fromClientsActions.clientDeleted,
        ),
      )
      .subscribe(() => {
        // const message = this.localizationService.getTranslation(payload.responseCode);
        this.shiveNotificationService.show(NotificationType.Success);
      });
  }
}
