<app-shive-grid #gridWrapperComponent [grid]="grid">
  <kendo-grid
    kendoGridSelectBy="id"
    scrollable="none"
    size="medium"
    [kendoGridBinding]="holidays"
    [pageSize]="20"
    [selectable]="{ enabled: true, checkboxOnly: true }"
    [columnMenu]="{ filter: true }"
    (add)="upsertHoliday(UserOperation.Create)"
    #grid
  >
    <ng-template kendoGridToolbarTemplate>
      <div class="k-d-flex k-justify-content-end k-w-full">
        <button
          kendoGridAddCommand
          size="medium"
          themeColor="secondary"
          [disabled]="gridWrapperComponent.userOperation !== UserOperation.None"
        >
          <fa-icon class="icon turquoise k-mr-3" [icon]="faTreeChristmas"></fa-icon>
          <span>Feiertag hinzufügen</span>
        </button>
      </div>
    </ng-template>

    <kendo-grid-checkbox-column
      [width]="40"
      [headerClass]="{ 'k-text-center': true }"
      class="list-selection-check"
      [resizable]="false"
      [columnMenu]="false"
      [showSelectAll]="true"
    >
    </kendo-grid-checkbox-column>

    <kendo-grid-column field="holiday_name" title="Feiertag">
      <ng-template kendoGridCellTemplate let-holiday>
        <div class="holiday-name">{{ holiday.name }}</div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="holiday_date" title="Datum">
      <ng-template kendoGridCellTemplate let-holiday>
        <div class="holiday-date">{{ holiday.date | date: "dd.MM.yyyy" }}</div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="context_menu" title="" [columnMenu]="false" [width]="40">
      <ng-template kendoGridCellTemplate let-dataItem>
        <app-context-menu-anchor
          [dataItem]="dataItem"
          [gridWrapperComponent]="gridWrapperComponent"
        />
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</app-shive-grid>
