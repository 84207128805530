<kendo-formfield orientation="horizontal" class="shive-dropdownlist k-mt-0">
  <kendo-label class="!k-align-items-start" [for]="dropdownlist" [text]="label"> </kendo-label>
  <kendo-dropdownlist
    [popupSettings]="popupSettings"
    (selectionChange)="selectionChange($event)"
    (close)="onClose($event)"
    [data]="data"
    [(ngModel)]="selectedValue"
    textField="text"
    valueField="value"
    size="medium"
    rounded="medium"
    #dropdownlist
  >
    <ng-template kendoDropDownListItemTemplate let-dataItem>
      <div class="item-wrapper">
        <div class="item default-entry d-flex justify-content-between">
          <div class="label k-flex-grow k-mr-2">{{ dataItem.text }}</div>
          <div class="controls k-display-flex">
            @if (permittedUserOperations?.includes(UserOperation.Update)) {
              <fa-icon
                class="edit turquoise k-display-flex k-align-items-stretch k-px-1.5"
                role="button"
                [icon]="faPenToSquare"
                (click)="edit(dataItem)"
              ></fa-icon>
            }
            @if (permittedUserOperations?.includes(UserOperation.Duplicate)) {
              <fa-icon
                role="button"
                class="duplicate turquoise k-display-flex k-align-items-stretch k-ml-1.5 k-px-1.5"
                [icon]="faCopy"
                (click)="duplicate(dataItem)"
              ></fa-icon>
            }
            @if (permittedUserOperations?.includes(UserOperation.Delete)) {
              <fa-icon
                role="button"
                class="trash red k-display-flex k-align-items-stretch k-ml-1.5 k-px-1.5"
                [icon]="faTrash"
                (click)="remove(dataItem)"
              ></fa-icon>
            }
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template kendoDropDownListNoDataTemplate>
      <div>
        No data found.
        @if (permittedUserOperations?.includes(UserOperation.Create)) {
          Do you want to add new item ?
        }
        <br />
        @if (permittedUserOperations?.includes(UserOperation.Create)) {
          <button kendoButton (click)="add()">Add new item</button>
        }
      </div>
    </ng-template>
  </kendo-dropdownlist>
  @if (permittedUserOperations?.includes(UserOperation.Create)) {
    <button
      kendoButton
      themeColor="base"
      class="add-entry icon-button k-button k-button-solid k-rounded-full k-ml-3.5"
      (click)="addBtnClicked.next(true)"
    >
      <fa-icon class="icon" size="xs" [icon]="faPlus"></fa-icon>
    </button>
  }
</kendo-formfield>
