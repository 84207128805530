import { ViewHandler } from './view-handler';
import { range } from 'lodash-es';
import { DateTimeHelper } from '../../util/date-time-helper';
import { CalendarService } from '../../services/calendar.service';
import { TimeBlockCrudService } from '../../components/time-block/crud/time-block-crud.service';
import { TimeCoordinateMappingService } from '../../time-mapping/time-coordinate-mapping.service';
import { BaseCalendarModel } from '../../../../core/models/calendar/base-calendar.model';
import { CalendarEvents, DayComposite } from '../../../../shared/data-types/calendar-types';
import { DayOrWeekCalendarModel } from '../../../../core/models/calendar/day-or-week-calendar.model';
import { SidebarService } from '../../../../core/services/ui/sidebar.service';
import { CalendarServiceHelper } from '../../services/calendar-service-helper';
import { DayCompositeConverter } from '../../converter/day-composite-converter';
import { CalendarViewHandlerService } from '../../services/calendar-view-handler.service';

export abstract class DayOrWeekViewHandler extends ViewHandler {
  public days: DayComposite[] = []; // Days for calendar body
  private hours = new Array<Date>(3);

  protected constructor(
    calendarService: CalendarService,
    timeBlockCrudService: TimeBlockCrudService,
    timeCoordinateMappingService: TimeCoordinateMappingService,
    sidebarService: SidebarService,
    calendarViewHandlerService: CalendarViewHandlerService,
  ) {
    super(
      calendarService,
      timeBlockCrudService,
      timeCoordinateMappingService,
      sidebarService,
      calendarViewHandlerService,
    );
  }

  get hourSegments(): Date[] {
    return this.hours;
  }

  protected initEvents(): void {
    const targetEvents = [
      CalendarEvents.ChangedStartEndDates,
      CalendarEvents.InitializedCalendarView,
      CalendarEvents.ChangedSelectedUsers,
    ];
    const callback = (calendarModel: BaseCalendarModel): void => {
      if (!(calendarModel instanceof DayOrWeekCalendarModel)) {
        return;
      }
      // Purge store
      this.tbCrudService.purgeTimeBlockStore();
      this.days = calendarModel.calendarProperties.days;

      this.tbCrudService.reloadTimeBlocks$.next(true);
    };

    this.sendTimeBlocksToView();

    // Execute callback when calendar model was updated
    this.subs.sink = CalendarServiceHelper.calendarModelUpdated(
      this.calService,
      callback,
      targetEvents,
    );
  }

  protected mapHoursOfDay(): void {
    const hoursOfDay = (this.calService.model as DayOrWeekCalendarModel).calendarProperties
      .hoursOfDay;
    if (!hoursOfDay) {
      throw new Error('No hours of day set.');
    }
    const startOfDay = DateTimeHelper.startOfDay(new Date());
    this.hours = range(0, hoursOfDay).map((i) => {
      return DateTimeHelper.addHours(startOfDay, i);
    });
  }

  protected updateAndRenderFulldayContainer(): void {
    const model = this.calService.model as DayOrWeekCalendarModel;
    const fulldayGeometryData = model.fulldayCalendarModel.geometryData;
    const fulldayHTMLContainer = fulldayGeometryData.calendarBodyElementRef.nativeElement;

    const bcr = fulldayHTMLContainer.getBoundingClientRect();
    fulldayGeometryData.calendarBodyElementRef =
      model.fulldayCalendarModel.geometryData.calendarBodyElementRef;
    fulldayGeometryData.calendarBodyOffsetLeft = Math.abs(bcr.left);
    fulldayGeometryData.calendarBodyOffsetTop = bcr.top;
    fulldayGeometryData.calendarBodyWidth = bcr.width;
  }

  protected checkIfOvernight(): void {
    const targetEvents = [CalendarEvents.NowIndicatorPassedMidnight];
    const callback = (calendarModel: BaseCalendarModel, calendarEvents: CalendarEvents[]): void => {
      if (calendarEvents.includes(CalendarEvents.NowIndicatorPassedMidnight)) {
        const days = calendarModel.calendarProperties.days.map(
          (dayComposite) => dayComposite.theDate,
        );
        const start = calendarModel.calendarProperties.visibleStartDate;
        const end = calendarModel.calendarProperties.visibleEndDate;
        calendarModel.calendarProperties.days = DayCompositeConverter.toDayComposite(
          days,
          start,
          end,
        );
        this.days = calendarModel.calendarProperties.days;
        this.tbCrudService.reloadTimeBlocks$.next(true);
        this.calViewHandlerService.reRenderCalendarHeaderDays.next(null);
      }
    };

    this.subs.sink = CalendarServiceHelper.calendarModelUpdated(
      this.calService,
      callback,
      targetEvents,
    );
  }
}
