import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { TimerStatus } from '../../../../shared/data-types/timer-types';
import * as fromTimerActions from './timer.actions';
import { Actions, ofType } from '@ngrx/effects';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TimerHttpService {
  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
  ) {}

  public postTimer(timerStatus: TimerStatus): Observable<unknown> {
    this.store.dispatch(
      fromTimerActions.postTimer({
        payload: timerStatus,
      }),
    );

    return this.actions$.pipe(ofType(fromTimerActions.timerPosted), take(1));
  }
}
