import { ITimeBlockComponentItem } from '../../../time-block-component-items';
import { TimeBlockContentType } from '../../../../../../../shared/data-types/time-block-types';
import { TimeBlockModel } from '../../../../../../../core/models/timeblock/time-block.model';

class DummyTimeBlockModel extends TimeBlockModel {}

export class SlotMonthViewTimeBlock implements ITimeBlockComponentItem {
  public realTimeBlock = null;
  id: number;
  timeBlockContentType: TimeBlockContentType;
  timeBlockModel: TimeBlockModel;

  public static fromITimeBlockComponentItem(
    timeBlock: ITimeBlockComponentItem,
    adaptedStart: Date,
    adaptedEnd: Date,
  ): SlotMonthViewTimeBlock {
    const slotMonthViewTimeBlock = new SlotMonthViewTimeBlock();
    slotMonthViewTimeBlock.realTimeBlock = timeBlock;
    slotMonthViewTimeBlock.timeBlockModel = new DummyTimeBlockModel();
    slotMonthViewTimeBlock.timeBlockModel.start = adaptedStart;
    slotMonthViewTimeBlock.timeBlockModel.end = adaptedEnd;
    return slotMonthViewTimeBlock;
  }

  clone(): ITimeBlockComponentItem {
    return undefined;
  }

  insertIntoCalendar(): void {}

  removeFromCalendar(): void {}
}
