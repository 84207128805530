import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Position } from 'src/app/shared/data-structures/position';

/**
 * Global mouse and touch utility handler used for the entire application.
 */
@Injectable({
  providedIn: 'root',
})
export class TouchAndMouseHandlerService {
  public currentMouseViewportPos: Position;
  public globalMouseMoved$ = new Subject<MouseEvent>();
  public globalMouseReleased$ = new Subject<MouseEvent>(); // Fired as soon as the user releases the left mouse button anywhere in the app.
}
