import { createFeatureSelector, createSelector } from '@ngrx/store';
import { appConfigAdapter } from './app-config.reducer';
import { UiConfigModel } from '../../models/app-config/ui-config.model';

export const selectAppConfigData = createSelector(
  createFeatureSelector('appConfig'),
  appConfigAdapter.getSelectors().selectAll,
);

export const selectCalendarConfigData = createSelector(
  selectAppConfigData,
  (state) => state[0].calendarConfigModel,
);

export const selectProjectConfigData = createSelector(
  selectAppConfigData,
  (state) => state[0].projectConfigModel,
);

export const selectUIConfigData = createSelector(
  selectAppConfigData,
  (state) => state[0].uiConfigModel as UiConfigModel,
);
