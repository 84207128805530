<div class="dialog-content">
  <section>
    <form class="k-form" [formGroup]="billingHistoryForm">
      <kendo-formfield orientation="horizontal">
        <kendo-label
          class="!k-align-items-start !k-pt-0 k-mr-2"
          [for]="newBillableRate"
          text="Neuer Stundensatz"
        ></kendo-label>
        <div class="k-d-flex">
          <span class="prefix-currency font-weight-medium">&euro;</span>
          <kendo-numerictextbox
            [spinners]="false"
            (valueChange)="inputValueChanged($event)"
            id="new-billable-rate"
            formControlName="newBillableRate"
            #newBillableRate
          >
          </kendo-numerictextbox>
        </div>
        <kendo-formerror>Error: Full Name is required</kendo-formerror>
      </kendo-formfield>

      <p class="k-font-semibold">Den neuen Stundensatz anwenden auf</p>
      <div class="k-form-field-radiobutton-wrap k-d-flex">
        <kendo-radiobutton
          value="all"
          (checkedChange)="buttonChanged()"
          #all
          formControlName="applyBillableRateTo"
        ></kendo-radiobutton>
        <label class="k-radio-label" [for]="all"
          >alle meine vergangenen und zukünftigen Zeitbuchungen.
        </label>
      </div>

      <div class="k-form-field-radiobutton-wrap k-d-flex k-mt-3">
        <kendo-radiobutton
          value="custom"
          (checkedChange)="buttonChanged()"
          #custom
          formControlName="applyBillableRateTo"
          class="!k-align-self-auto"
        ></kendo-radiobutton>
        <label class="k-radio-label !k-align-items-center text-nowrap" [for]="custom"
          >meine Zeitbuchungen ab
        </label>
        <kendo-datepicker formControlName="dateFrom" class="!k-mx-1" id="from-date">
        </kendo-datepicker>
        <label class="k-radio-label !k-align-items-center" [for]="custom"> und danach.</label>
      </div>
    </form>
  </section>

  <hr class="k-my-6.5" />

  <section>
    <p class="k-font-semibold">Stundensatzhistorie</p>
    <app-history-time-line [billingRateHistory]="billingRateHistory" />
  </section>
</div>

<footer #dialogFooter class="k-d-flex k-justify-content-between">
  <button (click)="close()" kendoButton size="large" themeColor="secondary" class="k-mr-4 !k-pr-4">
    <span class="custom-symbol close k-mr-3"></span>
    <span class="label">Schließen</span>
  </button>

  <button
    kendoButton
    (click)="submit()"
    class="!k-pr-4"
    themeColor="base"
    size="large"
    [disabled]="!updateEnabled"
  >
    <fa-icon class="add text-white k-mr-3" [icon]="faPenToSquare"></fa-icon>
    <span class="label">Ändern</span>
  </button>
</footer>
