import { Slot } from './slot';
import { ITimeBlockComponentItem } from '../../../time-block-component-items';
import { IntersectionService } from '../../intersection/intersection.service';
import { Injectable } from '@angular/core';
import { CalendarService } from '../../../../../services/calendar.service';
import { IFulldayViewType } from '../../../../../../../shared/data-types/time-block-types';

@Injectable()
export class HorizontalSlotHandlerService {
  constructor(private readonly calendarService: CalendarService) {}

  public orderInnerdayByStartDate(timeBlocksPerDay: ITimeBlockComponentItem[]): void {
    timeBlocksPerDay.sort((first: ITimeBlockComponentItem, second: ITimeBlockComponentItem) => {
      const timeDifference =
        first.timeBlockModel.start.getTime() - second.timeBlockModel.start.getTime();
      if (timeDifference === 0) {
        const lengthFirst =
          first.timeBlockModel.end.getTime() - first.timeBlockModel.start.getTime();
        const lengthSecond =
          second.timeBlockModel.end.getTime() - second.timeBlockModel.start.getTime();
        return lengthSecond - lengthFirst;
      }
      return timeDifference;
    });
  }

  public orderFullday(timeBlocksPerDay: ITimeBlockComponentItem[]): void {
    // 1. sort by longest
    // 2. if tie, sort by created date
    timeBlocksPerDay.sort((first: ITimeBlockComponentItem, second: ITimeBlockComponentItem) => {
      const timespanFirst =
        first.timeBlockModel.end.getTime() - first.timeBlockModel.start.getTime();
      const timespanSecond =
        second.timeBlockModel.end.getTime() - first.timeBlockModel.start.getTime();
      const timeDifference = timespanSecond - timespanFirst;
      if (timeDifference === 0) {
        return first.timeBlockModel.id - second.timeBlockModel.id;
      }
      return timeDifference;
    });
  }

  public updateGeometryHorizontalSlots(slots: Slot[]): ITimeBlockComponentItem[] {
    const updatedTimeBlocks = new Array<ITimeBlockComponentItem>();
    for (let slotIndex = 0; slotIndex < slots.length; slotIndex++) {
      const slot = slots[slotIndex];
      for (const timeBlock of slot.timeBlocks) {
        const timeBlockViewType = timeBlock.timeBlockModel.timeBlockViewType as IFulldayViewType;
        timeBlockViewType.parallelIndex = slotIndex;
        updatedTimeBlocks.push(timeBlock);
      }
    }
    return updatedTimeBlocks;
  }

  public assignTimeBlockToSlot(
    timeBlock: ITimeBlockComponentItem,
    slots: Slot[],
    intersectionService: IntersectionService,
  ): void {
    for (const slot of slots) {
      if (!slot.intersectsTimeBlock(timeBlock)) {
        slot.insert(timeBlock);
        return;
      }
    }
    slots.push(new Slot(intersectionService));
    slots[slots.length - 1].insert(timeBlock);
  }
}
