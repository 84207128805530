import { Injectable } from '@angular/core';
import { DragPart } from '../drag-part';
import { DateTimeHelper } from '../../../../../../util/date-time-helper';
import { TimeBlockDragResizeControllerService } from '../time-block-drag-resize-handling/time-block-drag-resize-controller.service';
import { CalendarService } from '../../../../../../services/calendar.service';
import { TimeCoordinateMappingService } from '../../../../../../time-mapping/time-coordinate-mapping.service';
import { filter, take } from 'rxjs/operators';

@Injectable()
export class ResizeTimeBlockScheduleCalculatorService {
  constructor(
    private readonly timeBlockDragResizeControllerService: TimeBlockDragResizeControllerService,
    private readonly timeCoordinateMappingService: TimeCoordinateMappingService,
    private readonly calendarService: CalendarService,
  ) {}

  public calculateSchedule(): void {
    const dragPart = this.timeBlockDragResizeControllerService.DragPart;

    if (dragPart === DragPart.Top) {
      this.calculateTimeBlockStart();
    } else {
      this.calculateTimeBlockEnd();
    }

    const resizingTimeBlock = this.timeBlockDragResizeControllerService.TransformationTimeBlock;
    if (
      DateTimeHelper.isBefore(
        resizingTimeBlock.timeBlockModel.end,
        resizingTimeBlock.timeBlockModel.start,
      )
    ) {
      throw new Error('End date is before start date.');
    }
  }

  public calculateTimeBlockStart(): void {
    this.timeCoordinateMappingService.pixelsPerMinuteCalculated$
      .pipe(
        filter((ppm) => !!ppm),
        take(1),
      )
      .subscribe((ppm) => {
        const resizingTimeBlock = this.timeBlockDragResizeControllerService.TransformationTimeBlock;
        const startOfDay = DateTimeHelper.startOfDay(resizingTimeBlock.timeBlockModel.start);
        const minutesStart =
          resizingTimeBlock.timeBlockModel.timeBlockViewType.geometryData.top / ppm;
        resizingTimeBlock.timeBlockModel.start = DateTimeHelper.minutesToDate(
          minutesStart,
          startOfDay,
        );
      });
  }

  public calculateTimeBlockEnd(): void {
    this.timeCoordinateMappingService.pixelsPerMinuteCalculated$
      .pipe(
        filter((ppm) => !!ppm),
        take(1),
      )
      .subscribe((ppm) => {
        const resizingTimeBlock = this.timeBlockDragResizeControllerService.TransformationTimeBlock;
        const geometryData = resizingTimeBlock.timeBlockModel.timeBlockViewType.geometryData;

        // If mouse cursor is outside of bottom lane edge, set end time to end of day
        if (
          geometryData.calendarTopEdgeToTimeBlockBottomEdge >=
          this.calendarService.model.geometryData.calendarBodyHeight
        ) {
          resizingTimeBlock.timeBlockModel.end = DateTimeHelper.endOfDay(
            resizingTimeBlock.timeBlockModel.end,
          );
        } else {
          // Set the time otherwise.
          const startOfDay = DateTimeHelper.startOfDay(resizingTimeBlock.timeBlockModel.end);
          const minutesEnd = geometryData.calendarTopEdgeToTimeBlockBottomEdge / ppm;
          resizingTimeBlock.timeBlockModel.end = DateTimeHelper.minutesToDate(
            minutesEnd,
            startOfDay,
          );
        }
      });
  }
}
