import { Injectable } from '@angular/core';
import { TimeBlockRenderService } from '../../../../rendering/time-block-render.service';
import { CalendarMouseHandlerService } from '../../../../../../mouse/calendar-mouse-handler.service';
import Autobind from '../../../../../../../../shared/typescript-decorators/autobind.decorator';
import { CalendarService } from '../../../../../../services/calendar.service';
import { ITimeBlockComponentItem } from '../../../../time-block-component-items';
import { merge } from 'rxjs';
import { DragAndDropHandlerService } from '../dragging/drag-and-drop/drag-and-drop-handler.service';
import { DragPart } from '../drag-part';
import { TimeBlockDragResizeControllerService } from './time-block-drag-resize-controller.service';
import { InteractHandlerService } from '../../interact-handler.service';
import { InteractCallbackFns } from '../../../../../../../../shared/data-types/interact.types';
import { TouchAndMouseHandlerService } from '../../../../../../../../core/services/touch-and-mouse-handler.service';
import { HorizontalAllResizeControllerService } from '../resizing/resize-view-controller/horizontal-all-resize-controller.service';
import { VerticalDayOrWeekResizeControllerService } from '../resizing/resize-view-controller/vertical-day-or-week-resize-controller.service';
import { TimeBlockDragResizeMouseHandlerService } from './time-block-drag-resize-mouse-handler.service';
import { TimeBlockType } from '../../../../../../../../shared/data-types/time-block-types';
import {
  TimeBlockDayOrWeekFulldayType,
  TimeBlockDayOrWeekInnerdayType,
  TimeBlockMonthFulldayType,
  TimeBlockMonthInnerdayType,
} from '../../../../../../../../core/models/timeblock/time-block-view-type.model';
import { TimeBlockHttpService } from '../../../../../../../../core/state/time-blocks/time-block-http.service';
import { TimeBlockHttpTransformationService } from '../../../../http/time-block-http-transformation.service';
import { CalendarServiceHelper } from '../../../../../../services/calendar-service-helper';
import { HorizontalDayOrWeekFullDayDragControllerService } from '../dragging/drag-view-controller/horizontal-day-or-week-full-day-drag-controller.service';
import { FreelyDayOrWeekDragControllerService } from '../dragging/drag-view-controller/freely-day-or-week-drag-controller.service';
import { HorizontalMonthFullDayInnerDayDragControllerService } from '../dragging/drag-view-controller/horizontal-month-full-day-inner-day-drag-controller.service';
import { SharedHorizontalDragResizeService } from './shared-horizontal-drag-resize.service';
import {
  CollisionHandlerService,
  IntersectionType,
} from '../../collision-handling/collision-handler.service';

@Injectable()
export class TimeBlockDragResizeService {
  constructor(
    public timeBlockDragResizeControllerService: TimeBlockDragResizeControllerService,
    public horizontalAllResizeControllerService: HorizontalAllResizeControllerService,
    public verticalDayOrWeekResizeControllerService: VerticalDayOrWeekResizeControllerService,
    public freelyDayOrWeekDragControllerService: FreelyDayOrWeekDragControllerService,
    public horizontalDayOrWeekFullDayDragControllerService: HorizontalDayOrWeekFullDayDragControllerService,
    public horizontalMonthFullDayInnerDayDragControllerService: HorizontalMonthFullDayInnerDayDragControllerService,
    private readonly sharedHorizontalMonthDragResizeService: SharedHorizontalDragResizeService,
    private readonly timeBlockDragResizeMouseHandlerService: TimeBlockDragResizeMouseHandlerService,
    private readonly dragAndDropHandlerService: DragAndDropHandlerService,
    private readonly timeBlockHttpService: TimeBlockHttpService,
    private readonly calendarService: CalendarService,
    private readonly interactHandlerService: InteractHandlerService,
    private readonly timeBlockRenderService: TimeBlockRenderService,
    private readonly calendarMouseHandlerService: CalendarMouseHandlerService,
    private readonly touchAndMouseHandlerService: TouchAndMouseHandlerService,
  ) {
    this.timeBlockDragResizeMouseHandlerService.initMouseEvents(this.startDragOrResizeInteraction);
  }

  /**
   * Initialize the dragging / resizing event handlers for the given time block Component
   */
  public initialize(tbComponent: ITimeBlockComponentItem): void {
    if (tbComponent.timeBlockModel.timeBlockViewType instanceof TimeBlockDayOrWeekInnerdayType) {
      // Vertical time block
      this.initResizeInteraction(tbComponent, DragPart.Top);
      this.initResizeInteraction(tbComponent, DragPart.Bottom);
      this.timeBlockDragResizeControllerService.resizeControllerService =
        this.verticalDayOrWeekResizeControllerService;
      this.timeBlockDragResizeControllerService.dragControllerService =
        this.freelyDayOrWeekDragControllerService;
    } else if (
      tbComponent.timeBlockModel.timeBlockViewType instanceof TimeBlockDayOrWeekFulldayType
    ) {
      this.initResizeInteraction(tbComponent, DragPart.Left);
      this.initResizeInteraction(tbComponent, DragPart.Right);
      this.timeBlockDragResizeControllerService.resizeControllerService =
        this.horizontalAllResizeControllerService;
      this.timeBlockDragResizeControllerService.dragControllerService =
        this.horizontalDayOrWeekFullDayDragControllerService;
    } else if (tbComponent.timeBlockModel.timeBlockViewType instanceof TimeBlockMonthFulldayType) {
      this.initResizeInteraction(tbComponent, DragPart.Left);
      this.initResizeInteraction(tbComponent, DragPart.Right);
      this.timeBlockDragResizeControllerService.resizeControllerService =
        this.horizontalAllResizeControllerService;
      this.timeBlockDragResizeControllerService.dragControllerService =
        this.horizontalMonthFullDayInnerDayDragControllerService;
    } else if (tbComponent.timeBlockModel.timeBlockViewType instanceof TimeBlockMonthInnerdayType) {
      // Resizing is not taken into account for month inner day time blocks since there are no drag handlers.
      this.timeBlockDragResizeControllerService.resizeControllerService =
        this.horizontalAllResizeControllerService;
      this.timeBlockDragResizeControllerService.dragControllerService =
        this.horizontalMonthFullDayInnerDayDragControllerService;
    } else {
      throw new Error(
        `Time block type is not supported: ${tbComponent.timeBlockModel.timeBlockViewType}`,
      );
    }

    this.initDragEvents();
    this.initDragInteraction(tbComponent, DragPart.Body);
  }

  /**
   * Called as soon as the user scrolls the mouse wheel or moves the mouse while selecting a time block.
   */
  private startDragOrResizeInteraction(): void {
    if (this.timeBlockDragResizeControllerService.DragPart === DragPart.None) {
      throw new Error('No drag part selected.');
    }

    this.timeBlockRenderService.toggleCalendarBodyClass(
      true,
      this.timeBlockDragResizeControllerService.DragPart,
      this.calendarService.model.geometryData.calendarBodyElementRef,
    );

    this.startInteraction();
  }

  /**
   * Start interaction (drag or resize).
   */
  private startInteraction(): void {
    const dragPart = this.timeBlockDragResizeControllerService.DragPart;
    this.timeBlockDragResizeControllerService.currentDragResizeInteraction =
      dragPart === DragPart.Bottom ||
      dragPart === DragPart.Top ||
      dragPart === DragPart.TopOrBottom ||
      dragPart === DragPart.Left ||
      dragPart === DragPart.Right ||
      dragPart === DragPart.LeftOrRight
        ? DragOrResizeInteraction.Resize
        : DragOrResizeInteraction.Drag;

    // Initialize lastWorldMousePos since we need to identify the drag / resize direction (i.e. West, East, North or South)
    this.timeBlockDragResizeControllerService.lastWorldMousePos =
      this.calendarMouseHandlerService.MouseClickPositionWrapper.mouseViewportPosition;

    const clickedMousePos =
      this.calendarMouseHandlerService.MouseClickPositionWrapper.mouseCalendarPosition;

    const transformationTimeBlock =
      this.timeBlockDragResizeControllerService.TransformationTimeBlock;
    if (!transformationTimeBlock) {
      throw new Error('Time block dragger is undefined');
    }

    const calendarModel =
      this.calendarService.getCalendarModelByTimeBlockType(transformationTimeBlock);

    // Initialize collision type.
    CollisionHandlerService.collisionType = IntersectionType.intersectNone;

    const mouseLaneIndex = this.calendarMouseHandlerService.calcVisibleMouseLaneIndex(
      clickedMousePos.x,
      clickedMousePos.y,
      transformationTimeBlock.timeBlockModel.isFullday,
    );
    this.sharedHorizontalMonthDragResizeService.lastMouseLaneIndex =
      CalendarServiceHelper.toInvisibleLaneIndex(calendarModel, mouseLaneIndex);

    if (
      this.timeBlockDragResizeControllerService.currentDragResizeInteraction ===
      DragOrResizeInteraction.Resize
    ) {
      this.timeBlockDragResizeControllerService.resizeControllerService.beforeStartResizing(
        this.timeBlockDragResizeControllerService.DragPart,
      );
    } else if (
      this.timeBlockDragResizeControllerService.currentDragResizeInteraction ===
      DragOrResizeInteraction.Drag
    ) {
      this.timeBlockDragResizeControllerService.dragControllerService.beforeStartDragging();
    }
  }

  /**
   * End interaction (drag or resize). Will be called when an interaction has finished.
   */
  @Autobind
  private endInteraction(event?: Interact.DragEvent): void {
    this.timeBlockDragResizeControllerService.dragOrResizeActionInProgress = false;

    if (
      this.timeBlockDragResizeControllerService.currentDragResizeInteraction ===
      DragOrResizeInteraction.None
    ) {
      return;
    }

    // Update calendar model with updated or created time block.
    if (
      this.timeBlockDragResizeControllerService.currentDragResizeInteraction ===
      DragOrResizeInteraction.Resize
    ) {
      // Update the time block in the calendar model for resizing or open the time entry dialog.
      this.timeBlockDragResizeControllerService.resizeControllerService.afterResizing();
    } else if (
      this.timeBlockDragResizeControllerService.currentDragResizeInteraction ===
      DragOrResizeInteraction.Drag
    ) {
      // Delete original time block in the calendar model and replace it by dragging block.
      this.timeBlockDragResizeControllerService.dragControllerService.afterDragging(event);
    }

    const transformationTimeBlock =
      this.timeBlockDragResizeControllerService.TransformationTimeBlock;
    const timeBlockModel = transformationTimeBlock.timeBlockModel;

    // If the end time is 00:00:00, change it to 23:59:59
    transformationTimeBlock.timeBlockModel.end =
      TimeBlockHttpTransformationService.transformEndDate(timeBlockModel.end);

    this.timeBlockRenderService.toggleCalendarBodyClass(
      false,
      this.timeBlockDragResizeControllerService.DragPart,
      this.calendarService.model.geometryData.calendarBodyElementRef,
    );

    // Make http put request if it is an existing block. Furthermore, don't reset DraggingBlock since we need it and its geometry in the
    // dialog handler.
    const currentDraggedBlock = this.timeBlockDragResizeControllerService.TransformationTimeBlock;
    if (currentDraggedBlock.timeBlockModel.type !== TimeBlockType.NonExistingBlock) {
      this.timeBlockHttpService.putBlock(
        this.timeBlockDragResizeControllerService.TransformationTimeBlock,
      );
      this.timeBlockDragResizeControllerService.TransformationTimeBlock = null;
    }

    // Reset everything
    this.timeBlockDragResizeControllerService.currentDragResizeInteraction =
      DragOrResizeInteraction.None;
    this.timeBlockDragResizeControllerService.DragPart = DragPart.None;
  }

  private initDragEvents(): void {
    // Start the drag / resize action. Also called when creating a new time block.
    merge(
      this.dragAndDropHandlerService.dragStarted$,
      this.timeBlockDragResizeControllerService.resizeControllerService.resizeStarted$,
    ).subscribe((dragPart) => {
      this.timeBlockDragResizeControllerService.DragPart = dragPart;

      if (!this.timeBlockDragResizeControllerService.dragOrResizeActionInProgress) {
        this.timeBlockDragResizeControllerService.dragOrResizeActionInProgress = true;
        this.startDragOrResizeInteraction();
      }
    });

    // Stop the drag / resize action. Also called when creating a new time block.
    this.touchAndMouseHandlerService.globalMouseReleased$.subscribe(() => {
      const currentDraggedBlock = this.timeBlockDragResizeControllerService.TransformationTimeBlock;

      // Nothing to do here
      if (
        !this.timeBlockDragResizeControllerService.dragOrResizeActionInProgress ||
        !currentDraggedBlock
      ) {
        return;
      }
      this.endInteraction(null);
    });
  }

  /**
   * This method is used for all time block types (i.e. full day and inner day).
   */
  private initResizeInteraction(timeBlock: ITimeBlockComponentItem, dragPart: DragPart): void {
    const startResize = (event) => {
      if (!event) {
        return;
      }
      let curDragPart: DragPart;

      // Vertical time block
      if (timeBlock.timeBlockModel.timeBlockViewType instanceof TimeBlockDayOrWeekInnerdayType) {
        curDragPart = event.interactable.target.classList.contains('top')
          ? DragPart.Top
          : DragPart.Bottom;
      } else {
        // Horizontal time block
        curDragPart = event.interactable.target.classList.contains('left')
          ? DragPart.Left
          : DragPart.Right;
      }
      this.timeBlockDragResizeControllerService.resizeControllerService.resizeStarted$.next(
        curDragPart,
      );
    };

    const interactFns: InteractCallbackFns = {
      init: this.timeBlockDragResizeControllerService.buildGhostAndTransformationBlock,
      startFn: startResize,
      dragFn: null, // Not needed since custom subjects (e.g. mousePosChangedSubj$) are used.
      endFn: null, // Not needed since custom endInteraction() is used.
    };

    this.interactHandlerService.makeMovable(timeBlock, dragPart, null, null, interactFns);
  }

  /**
   * This method is used for all time block types (i.e. full day and inner day).
   */
  private initDragInteraction(tbComponent: ITimeBlockComponentItem, dragPart: DragPart): void {
    // Don't drag  active time blocks
    if (tbComponent.timeBlockModel.isActive) {
      return;
    }

    const startDrag = function (event): void {
      if (!event) {
        return;
      }
      InteractHandlerService.dropCoordinate = {
        x: event.rect.left,
      };

      if ('laneIndexStart' in tbComponent.timeBlockModel.timeBlockViewType) {
        this.targetContainerId = tbComponent.timeBlockModel.timeBlockViewType.laneIndexStart;
      } else if ('laneIndex' in tbComponent.timeBlockModel.timeBlockViewType) {
        this.targetContainerId = tbComponent.timeBlockModel.timeBlockViewType.laneIndex;
      } else {
        throw new Error('No lane index supported.');
      }

      const curDragPart = DragPart.Body;
      this.dragStarted$.next(curDragPart);
    };

    const interactFns: InteractCallbackFns = {
      init: this.timeBlockDragResizeControllerService.buildGhostAndTransformationBlock,
      startFn: startDrag.bind(this.dragAndDropHandlerService),
      dragFn: this.timeBlockDragResizeControllerService.dragControllerService.drag,
    };

    this.dragAndDropHandlerService.initDrag(
      this.calendarService.model,
      tbComponent,
      dragPart,
      interactFns,
    );
    this.dragAndDropHandlerService.initDrop();
  }
}

export enum DragOrResizeInteraction {
  None,
  Drag,
  Resize,
}
