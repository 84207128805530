<div class="form-wrapper">
  <form [formGroup]="personalDataForm" class="k-form three-column-layout k-d-inline-grid">
    <div class="col">
      <div id="profile-picture" class="k-pos-relative">
        <img ngSrc="assets/img/mock/avatar-large.png" width="160" height="160" alt="avatar" />

        <div
          id="change-picture"
          class="k-button icon-button !k-border-none !k-pos-absolute turquoise"
          role="button"
          (click)="changePicture()"
        >
          <fa-icon class="icon" size="lg" [icon]="faCameraRetro"></fa-icon>
        </div>
      </div>
    </div>

    <div class="col">
      <kendo-formfield orientation="horizontal">
        <kendo-label class="!k-align-items-start" [for]="firstName" text="Vorname"></kendo-label>
        <kendo-textbox
          showErrorIcon="initial"
          [id]="formControlNames.FirstName"
          [formControlName]="formControlNames.FirstName"
          appAfterValueChanged
          #firstName
        >
        </kendo-textbox>
        <kendo-formerror>Error: Username is required</kendo-formerror>
      </kendo-formfield>

      <kendo-formfield orientation="horizontal">
        <kendo-label class="!k-align-items-start" [for]="email" text="Email"></kendo-label>
        <kendo-textbox
          showErrorIcon="initial"
          [id]="formControlNames.Email"
          [formControlName]="formControlNames.Email"
          appAfterValueChanged
          #email
        >
        </kendo-textbox>
        <kendo-formerror>Error: Username is required</kendo-formerror>
      </kendo-formfield>

      <kendo-formfield orientation="horizontal">
        <kendo-label class="!k-align-items-start" [for]="role" text="Rolle"></kendo-label>
        <kendo-dropdownlist
          textField="text"
          valueField="value"
          [data]="roles"
          [id]="formControlNames.Role"
          [formControlName]="formControlNames.Role"
          #role
        >
        </kendo-dropdownlist>
        <kendo-formerror>Error: Username is required</kendo-formerror>
      </kendo-formfield>
    </div>

    <div class="col">
      <kendo-formfield orientation="horizontal">
        <kendo-label class="!k-align-items-start" [for]="lastName" text="Nachname"></kendo-label>
        <kendo-textbox
          showErrorIcon="initial"
          [id]="formControlNames.LastName"
          [formControlName]="formControlNames.LastName"
          appAfterValueChanged
          #lastName
        >
        </kendo-textbox>
        <kendo-formerror>Error: Username is required</kendo-formerror>
      </kendo-formfield>

      <kendo-formfield orientation="horizontal">
        <kendo-label class="!k-align-items-start" [for]="telephone" text="Telefon"></kendo-label>
        <kendo-textbox
          showErrorIcon="initial"
          [id]="formControlNames.Telephone"
          [formControlName]="formControlNames.Telephone"
          appAfterValueChanged
          #telephone
        >
        </kendo-textbox>
        <kendo-formerror>Error: Username is required</kendo-formerror>
      </kendo-formfield>
    </div>
  </form>
</div>
