import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { ProjectModel } from '../../../core/models/project/project.model';
import { cloneDeep } from 'lodash-es';

export interface ProjectState {
  project: ProjectModel;
}

@Injectable()
export class ProjectDetailDataStore extends ComponentStore<ProjectState> {
  public readonly addProject = this.updater((state: ProjectState, projectModel: ProjectModel) => ({
    project: projectModel,
  }));

  constructor() {
    super({ project: null });
  }

  public readonly selectProject = () => this.select((state) => state.project);
  public readonly updateProject = (updatedProject: ProjectModel) => {
    this.patchState((state) => {
      const updatedState = cloneDeep(state);
      updatedState.project = updatedProject;
      return updatedState;
    });
  };

  public readonly reset = () => {
    this.patchState(() => {
      return { project: null };
    });
  };
}
