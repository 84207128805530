<app-shive-grid #gridWrapperComponent [grid]="grid">
  <kendo-grid
    kendoGridSelectBy="id"
    scrollable="none"
    size="medium"
    [data]="gridData"
    [pageSize]="20"
    [selectable]="{ enabled: true, checkboxOnly: true }"
    [columnMenu]="{ filter: true }"
    (add)="openInsertMemberDialog()"
    #grid
  >
    <ng-template kendoGridToolbarTemplate>
      <div class="k-d-flex k-justify-content-end k-w-full">
        <button
          kendoGridAddCommand
          size="medium"
          themeColor="secondary"
          [disabled]="gridWrapperComponent.userOperation !== UserOperation.None"
        >
          <span class="custom-symbol user turquoise k-mr-3"></span>
          <span>Benutzer hinzufügen</span>
        </button>
      </div>
    </ng-template>

    <kendo-grid-checkbox-column
      [width]="40"
      [headerClass]="{ 'k-text-center': true }"
      class="list-selection-check"
      [resizable]="false"
      [columnMenu]="false"
      [showSelectAll]="true"
    >
    </kendo-grid-checkbox-column>

    <kendo-grid-column field="member_name" title="Name" [class]="'cell-with-avatar'">
      <ng-template kendoGridCellTemplate let-row>
        <app-shared-member-template [user]="row.member"></app-shared-member-template>
      </ng-template>
    </kendo-grid-column>

    @if (showBillableValueFormField) {
      <kendo-grid-column
        field="billable_value_per_member"
        [width]="10"
        [title]="billableValueColumnTitle"
        headerClass="billable-value-per-member"
        class="billable-value-per-member"
      >
        <ng-template kendoGridCellTemplate let-row>
          <div class="k-d-flex k-align-items-center">
            <div [class]="'!k-white-space-nowrap'">
              {{
                row.billingRateHistory[row.billingRateHistory.length - 1].billingRate
                  | shiveCurrency
              }}
            </div>
            <fa-icon
              class="edit turquoise icon-button k-display-flex k-align-items-stretch k-ml-3.5 fa-md"
              role="button"
              (click)="openNewBillableRateDialog(row.member.id)"
              [icon]="faPenToSquare"
            ></fa-icon>
          </div>
        </ng-template>
      </kendo-grid-column>
    }

    @if (showHoursPerMemberFormField) {
      <kendo-grid-column
        field="hours_per_member"
        [title]="hoursPerMemberColumnTitle"
        headerClass="hours-per-member"
        class="hours-per-member"
      >
        <ng-template kendoGridCellTemplate let-row>
          <kendo-numerictextbox
            [spinners]="false"
            [value]="+row.budgetHours"
            (valueChange)="inputValueChanged(row, $event)"
          >
          </kendo-numerictextbox>
        </ng-template>
      </kendo-grid-column>
    }

    <kendo-grid-column [columnMenu]="false" [width]="40">
      <ng-template kendoGridCellTemplate let-dataItem>
        <app-context-menu-anchor
          [dataItem]="dataItem"
          [gridWrapperComponent]="gridWrapperComponent"
        />
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</app-shive-grid>
