import { Injectable } from '@angular/core';
import { UserModel } from '../../../core/models/user/user.model';
import { Subject } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { take } from 'rxjs/operators';
import { UserDetailDataStore } from '../state-and-data-handling/user-detail-data.store';
import { assert } from '../../../core/assert/assert';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public userOperationConducted$ = new Subject<boolean>();

  constructor(private readonly userDetailDataStore: UserDetailDataStore) {}

  public addUser(user: UserModel): void {
    assert(user !== null, 'User model is invalid.');
    this.userDetailDataStore.addUser(user);
  }

  public getUserClone(): UserModel {
    return cloneDeep(this.getUser());
  }

  public filterAlreadyAddedMembers(
    allMembers: readonly UserModel[],
    projectMembers: readonly UserModel[],
  ): UserModel[] {
    return allMembers.filter(
      (member) => projectMembers.findIndex((element) => element.id === member.id) < 0,
    );
  }

  private getUser(): UserModel {
    let loadedUser: UserModel;
    this.userDetailDataStore
      .selectUser()
      .pipe(take(1))
      .subscribe((user) => {
        loadedUser = user;
      });
    return loadedUser;
  }
}
