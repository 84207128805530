import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserModel } from '../../models/user/user.model';
import { Actions, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromUserActions from './users.actions';

@Injectable({
  providedIn: 'root',
})
export class UsersHttpService {
  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
  ) {}

  public getUsers(): Observable<readonly UserModel[]> {
    this.store.dispatch(fromUserActions.fetchUserList());

    return this.actions$.pipe(
      ofType(fromUserActions.setUsers),
      map((result) => result.payload),
    );
  }

  public getUser(): Observable<UserModel> {
    // Todo: Endpoint currently not working
    // this.store.dispatch(
    //   fromUserActions.fetchSingleUser({
    //     payload: userId,
    //   }),
    // );
    //
    // return this.actions$.pipe(
    //   ofType(fromUserActions.setUsers),
    //   map((result) => result.payload),
    // );

    return of({
      id: 1,
      avatar: 'lsadfllsdfkjlljksdfa',
      email: 'maxmustermann@gmx.at',
      firstName: 'Max',
      lastName: 'Mustermann',
      username: 'maxi123',
      password: '123456',
    });
  }
}
