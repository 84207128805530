import { Injectable } from '@angular/core';
import {
  CSSActiveClass,
  CSSHighlightedClass,
  CSSNewClass,
  CSSResizedClass,
} from '../../../../../core/data-repository/css-constants';
import { CalendarService } from '../../../services/calendar.service';
import { TimeBlockRenderService } from '../rendering/time-block-render.service';
import { CalendarScrollbarService } from '../../../services/calendar-scrollbar.service';
import { ITimeBlockComponentItem } from '../time-block-component-items';
import { CssAttributes } from '../rendering/css-attributes';
import { TimeBlockDayOrWeekFulldayType } from '../../../../../core/models/timeblock/time-block-view-type.model';
import { TimeBlockStructureService } from '../time-block-structure/time-block-structure.service';
import { TimeBlockElementSelectorService } from '../rendering/time-block-element-selector.service';
import { CalendarView } from '../../../../../shared/data-types/calendar-types';

@Injectable()
export class TimeBlockHighlightingService {
  constructor(
    private readonly calendarService: CalendarService,
    private readonly timeBlockStructureService: TimeBlockStructureService,
    private readonly calendarScrollbarService: CalendarScrollbarService,
    private readonly timeBlockRenderService: TimeBlockRenderService,
  ) {}

  /**
   * Create a visual copy of the clicked time block to highlight it in the frontend.
   */
  public createHighlightedTimeBlockClone(timeBlock: ITimeBlockComponentItem): void {
    let parts: ITimeBlockComponentItem[];
    const calendarView = this.calendarService.model.calendarViewMode;
    if (
      (calendarView === CalendarView.DayGrid || calendarView === CalendarView.WeekGrid) &&
      timeBlock.timeBlockModel.isFullday
    ) {
      const headPart = this.timeBlockStructureService.retrieveTimeBlockPart(
        timeBlock.id,
        timeBlock.timeBlockModel.type,
        0,
      ) as ITimeBlockComponentItem;
      parts = [headPart];
    } else {
      parts = this.timeBlockStructureService.retrieveTimeBlockPart(
        timeBlock.id,
        timeBlock.timeBlockModel.type,
        -1,
      ) as ITimeBlockComponentItem[];
    }

    parts.forEach((timeBlockPart) => {
      if (timeBlockPart.timeBlockModel.componentRef === null) {
        return;
      }

      const htmlWrapper = TimeBlockElementSelectorService.getTimeBlockHTMLWrapper(timeBlockPart);
      const clone = htmlWrapper.cloneNode(true) as HTMLElement;
      if (timeBlock.timeBlockModel.timeBlockViewType instanceof TimeBlockDayOrWeekFulldayType) {
        // Horizontal block
        this.calcHorizontalHighlightedBlockGeometry(clone, htmlWrapper);
      } else {
        this.calcVerticalHighlightedBlockGeometry(clone, htmlWrapper, timeBlock);
      }

      this.timeBlockRenderService.removeStyle(clone, CssAttributes.transform);
      this.timeBlockRenderService.removeStyle(clone, CssAttributes.zIndex);
      this.timeBlockRenderService.addClass(clone, CSSHighlightedClass);
      this.timeBlockRenderService.addClass(clone, 'dialog-shadow');

      this.timeBlockRenderService.removeClass(clone, CSSActiveClass);
      this.timeBlockRenderService.removeClass(clone, CSSNewClass);
      this.timeBlockRenderService.removeClass(clone, CSSResizedClass);
      this.timeBlockRenderService.insertBefore(
        document.querySelector('.time-entry-dialog-wrapper'),
        clone,
        document.querySelector('.k-dialog'),
      );
    });
  }

  /**
   * Set geometrical and positional data of the horizontal highlighted block.
   */
  private calcHorizontalHighlightedBlockGeometry(
    clone: HTMLElement,
    timeBlockEl: HTMLElement,
  ): void {
    clone.style.top = `${timeBlockEl.getBoundingClientRect().top}px`;
    clone.style.left = `${timeBlockEl.getBoundingClientRect().left}px`;

    const computedStyle = getComputedStyle(timeBlockEl);
    clone.style.width = `${timeBlockEl.getBoundingClientRect().width - parseFloat(computedStyle.paddingRight)}px`;
    clone.style.height = `${timeBlockEl.getBoundingClientRect().height}px`;
  }

  /**
   * Set geometrical and positional data of the vertical highlighted block.
   */
  private calcVerticalHighlightedBlockGeometry(
    clone: HTMLElement,
    timeBlockEl: HTMLElement,
    timeBlock: ITimeBlockComponentItem,
  ): void {
    const geometryData = timeBlock.timeBlockModel.timeBlockViewType.geometryData;
    const calendarTopEdgeToBlockBottomEdge = geometryData.calendarTopEdgeToTimeBlockBottomEdge;

    const calendarBodyOffsetTop = this.calendarService.model.geometryData.calendarBodyOffsetTop;
    const calendarBodyHeight = this.calendarService.model.geometryData.calendarBodyVisibleHeight;
    const timeBlockTop = timeBlockEl.getBoundingClientRect().top;
    const timeBlockLeft = timeBlockEl.getBoundingClientRect().left;
    const timeBlockHeight = timeBlockEl.offsetHeight;

    if (timeBlockTop < calendarBodyOffsetTop) {
      clone.style.top = `${calendarBodyOffsetTop}px`;
      clone.style.height = `${timeBlockHeight - calendarBodyOffsetTop + timeBlockTop}px`;
    } else if (timeBlockTop + timeBlockHeight > calendarBodyOffsetTop + calendarBodyHeight) {
      clone.style.top = `${timeBlockTop}px`;
      clone.style.height = `${timeBlockHeight - calendarTopEdgeToBlockBottomEdge + this.calendarScrollbarService.calendarBodyScrollbar.scrollData.scrollPosY + calendarBodyHeight}px`;
    } else {
      clone.style.top = `${timeBlockTop}px`;
      clone.style.height = `${timeBlockHeight}px`;
    }

    clone.style.width = `${timeBlockEl.offsetWidth}px`;
    clone.style.left = `${timeBlockLeft}px`;
  }
}
