import { Injectable } from '@angular/core';
import { ITimeBlockComponentItem } from '../time-block-component-items';

@Injectable()
export class TimeBlockElementSelectorService {
  public static getTimeBlockHTMLWrapper(
    timeBlock: ITimeBlockComponentItem,
    nullable = false,
  ): HTMLElement | never | null {
    if (!timeBlock?.timeBlockModel.componentRef?.instance.timeBlockHTMLWrapper) {
      if (nullable) {
        return null;
      }
      throw new Error('Invalid block.');
    }
    return timeBlock.timeBlockModel.componentRef.instance.timeBlockHTMLWrapper.nativeElement;
  }

  public static getTimeBlockBody(
    timeBlock: ITimeBlockComponentItem,
    nullable = false,
  ): HTMLElement | never | null {
    if (!timeBlock?.timeBlockModel.componentRef?.instance.timeBlockHTMLWrapper) {
      if (nullable) {
        return null;
      }
      throw new Error('Invalid block.');
    }
    return timeBlock.timeBlockModel.componentRef.instance.timeBlockBody.nativeElement;
  }

  public static getTimeBlockFirstHandle(
    timeBlock: ITimeBlockComponentItem,
    nullable = false,
  ): HTMLElement | never | null {
    if (!timeBlock?.timeBlockModel.componentRef?.instance.timeBlockHTMLWrapper) {
      if (nullable) {
        return null;
      }
      throw new Error('Invalid block.');
    }
    return timeBlock.timeBlockModel.componentRef.instance.dragEdgeTemplates.first.dragEdge
      .nativeElement;
  }

  public static getTimeBlockSecondHandle(
    timeBlock: ITimeBlockComponentItem,
    nullable = false,
  ): HTMLElement | never | null {
    if (!timeBlock?.timeBlockModel.componentRef) {
      if (nullable) {
        return null;
      }
      throw new Error('Invalid block.');
    }
    return timeBlock.timeBlockModel.componentRef.instance.dragEdgeTemplates.last.dragEdge
      .nativeElement;
  }
}
