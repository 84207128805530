import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeHelper } from '../../../util/date-time-helper';

@Pipe({
  name: 'timeBlockAccumulatedWork',
})
export class TimeBlockAccumulatedWorkPipe implements PipeTransform {
  transform(accumulatedTimeSumMap: Map<string, number>, param?: Date | number): string {
    if (param) {
      return param instanceof Date
        ? this.calculateTimeSumForDate(accumulatedTimeSumMap, param)
        : this.calculateTimeSumForWeek(accumulatedTimeSumMap, param);
    }
    return this.calculateTotalTimeSum(accumulatedTimeSumMap);
  }

  private calculateTimeSumForDate(accumulatedTimeSumMap: Map<string, number>, date: Date): string {
    const key = DateTimeHelper.format(date);
    if (accumulatedTimeSumMap.has(key)) {
      const sumSeconds = accumulatedTimeSumMap.get(key);
      return this.formatSeconds(sumSeconds);
    }
    return '00:00';
  }

  private calculateTimeSumForWeek(accumulatedTimeSumMap: Map<string, number>, weekNumber: number) {
    const key = weekNumber.toString();
    if (accumulatedTimeSumMap.has(key)) {
      const sumSeconds = accumulatedTimeSumMap.get(key);
      return this.formatSeconds(sumSeconds);
    }
    return '00:00';
  }

  private calculateTotalTimeSum(accumulatedTimeSumMap: Map<string, number>): string {
    let sumSeconds = 0;
    accumulatedTimeSumMap.forEach((secondsWorkedPerDay) => (sumSeconds += secondsWorkedPerDay));
    return this.formatSeconds(sumSeconds);
  }

  private formatSeconds(sumSeconds: number): string {
    let days = 0;
    let hours = 0;
    let minutes = 0;

    if (sumSeconds > 0) {
      const duration = DateTimeHelper.intervalToDuration({ start: 0, end: sumSeconds * 1000 });

      if (typeof duration.days !== 'undefined') {
        days = duration.days;
      }

      if (typeof duration.hours !== 'undefined') {
        hours = duration.hours;
      }

      if (typeof duration.minutes !== 'undefined') {
        minutes = duration.minutes;
      }

      hours = days * 24 + hours;
    }

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }
}
