import { Slot } from './slot';
import { DateTimeHelper } from '../../../../../util/date-time-helper';
import { ITimeBlockComponentItem } from '../../../time-block-component-items';
import { SlotMonthViewTimeBlock } from './slot-month-view-time-block';

/**
 * Represents the slot data structure for the month view.
 */
export class MonthViewSlotVectorService {
  private readonly rowcount = 6;
  private readonly slotsPerWeeks = new Array<Slot[]>(this.rowcount);

  constructor(private readonly startDate: Date) {
    for (let i = 0; i < this.rowcount; i++) {
      this.slotsPerWeeks[i] = [];
    }
  }

  /**
   * Return the slots for the week of date (return the row in the calendar view in which date lies)
   * @param date The date of which is used to retrieve the week row
   */
  public getSlotsPerWeek(date: Date): Slot[] {
    const daysDiff = DateTimeHelper.differenceInDays(date, this.startDate);
    const rowInCalendar = Math.floor(daysDiff / 7);
    return this.slotsPerWeeks[rowInCalendar];
  }

  public updateGeometry(
    updateGeometry: (slots: Slot[]) => ITimeBlockComponentItem[],
  ): ITimeBlockComponentItem[] {
    const updatedTimeBlocks = new Array<ITimeBlockComponentItem>();
    this.slotsPerWeeks.forEach((slots: Slot[]) => {
      updatedTimeBlocks.push(...updateGeometry(slots));
    });
    return updatedTimeBlocks;
  }

  /**
   * Restore inner day timeblocks.
   */
  public removeWrapper(): void {
    this.slotsPerWeeks.forEach((slots: Slot[]) => {
      slots.forEach((slot: Slot) => {
        for (let i = 0; i < slot.timeBlocks.length; i++) {
          if (!slot.timeBlocks[i].timeBlockModel.isFullday) {
            const slotMonthViewTimeBlock = slot.timeBlocks[i] as SlotMonthViewTimeBlock;
            slot.timeBlocks[i] = slotMonthViewTimeBlock.realTimeBlock;
          }
        }
      });
    });
  }
}
