// ///////////////////////// Billable Rates
import { BillableRateOptions, BudgetOptions } from '../data-repository/dropdown-constants';

const FixedFee = 'Fixpreis';
const BillableRate = 'Nach Aufwand';
const NoBilling = 'Nicht verrechenbar';

// key: Billable rate option. value consists of label and group for dropdown control.
export const BillableRateOptionLiterals = new Map<BillableRateOptions, [string, string]>([
  [BillableRateOptions.FixedFeePerProject, ['Preis pauschal', FixedFee]],
  [BillableRateOptions.BillableValuePerTask, ['Preis pro Aufgabe', FixedFee]],
  [BillableRateOptions.BillableHourlyValueFixed, ['Stundensatz einheitlich', BillableRate]],
  [
    BillableRateOptions.BillableHourlyValuePerMember,
    ['Stundensatz pro Projektmitglied', BillableRate],
  ],
  [BillableRateOptions.BillableHourlyValuePerTask, ['Stundensatz pro Aufgabe', BillableRate]],
  [BillableRateOptions.NoBilling, ['Keine Verrechnung', NoBilling]],
]);

export const BudgetOptionLiterals = new Map<BudgetOptions, string>([
  [BudgetOptions.NoBudget, 'Kein Budget'],
  [BudgetOptions.TotalProjectFees, 'Gesamte Projektkosten'],
  [BudgetOptions.TotalProjectHours, 'Gesamte Projektstunden'],
  [BudgetOptions.CostsPerTask, 'Projektbudget in Euro pro Aufgabe'],
  [BudgetOptions.HoursPerTask, 'Projektbudget in Stunden pro Aufgabe'],
  [BudgetOptions.HoursPerMember, 'Projektstunden pro Projektmitglied'],
]);

export enum ResetBudgetOptions {
  Monthly,
  OnceAt,
}

export const ResetBudgetOptionLiterals = new Map<ResetBudgetOptions, string>([
  [ResetBudgetOptions.Monthly, 'monatlich'],
  [ResetBudgetOptions.OnceAt, 'einmalig am'],
]);
