import { Directive, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[appAfterValueChanged]',
  standalone: true,
})
export class AfterValueChangedDirective implements OnDestroy {
  @Input()
  public valueChangeDelay = 300;
  @Output()
  public readonly afterValueChanged: EventEmitter<unknown> = new EventEmitter<unknown>();

  private readonly stream$: Subject<unknown> = new Subject<unknown>();
  private readonly subscription: Subscription;

  constructor() {
    this.subscription = this.stream$
      .pipe(debounceTime(this.valueChangeDelay))
      .subscribe((value: unknown) => this.afterValueChanged.next(value));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('valueChange', ['$event'])
  public onValueChange(value: unknown): void {
    this.stream$.next(value);
  }
}
