import { createAction, props } from '@ngrx/store';
import { ClientModel } from '../../models/client/client.model';

export const fetchSingleClient = createAction(
  '[Clients] Fetch Single Client',
  props<{ payload: number }>(),
);
export const setSingleClient = createAction(
  '[Clients] Set Single Client',
  props<{ payload: ClientModel }>(),
);

export const fetchClientList = createAction('[Clients] Fetch Clients');
export const setClients = createAction(
  '[Clients] Set Clients',
  props<{ payload: ClientModel[] }>(),
);

export const addClient = createAction(
  '[Clients] Add Client',
  props<{ payload: ClientModel | ClientModel[] }>(),
);
export const clientAdded = createAction(
  '[Clients] Added Client',
  props<{ payload: ClientModel | ClientModel[] }>(),
);

export const updateClient = createAction(
  '[Clients] Update Client',
  props<{ payload: ClientModel | ClientModel[] }>(),
);
export const clientUpdated = createAction(
  '[Clients] Updated Client',
  props<{ payload: ClientModel | ClientModel[] }>(),
);

export const deleteClient = createAction(
  '[Clients] Delete Client',
  props<{ payload: ClientModel | ClientModel[] }>(),
);
export const clientDeleted = createAction(
  '[Clients] Deleted Client',
  props<{ payload: ClientModel | ClientModel[] }>(),
);

export const httpFail = createAction('[Clients] Http fail', props<{ payload: string }>());
