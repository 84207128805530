<div class="history-time-line-wrapper k-d-flex k-align-items-center">
  <ul class="history-time-line" [class.collapsed]="collapsed()" #historyTimeLine>
    @for (
      billingHistoryItem of billingRateHistory;
      track billingHistoryItem.id;
      let first = $first;
      let last = $last
    ) {
      <li
        class="history-time-line-item k-d-flex k-pos-relative"
        [ngClass]="{
          'history-time-line-item-end history-time-line-item--active ': last,
        }"
      >
        <div class="date k-w-fit k-align-self-end">
          {{ billingHistoryItem.startingFrom | date: "dd.MM.yyyy" }}
        </div>
        <div class="rate k-pos-absolute">
          {{ billingHistoryItem.billingRate | shiveCurrency }}
        </div>

        @if (billingRateHistory.length > maxVisibleItems && first && collapsed()) {
          <div
            class="collapse-time-line-items k-pos-absolute k-d-flex k-justify-content-center k-align-items-center k-w-full k-h-full"
          >
            <fa-icon [rotate]="90" [icon]="faWaveTriangle"></fa-icon>
            <fa-icon [rotate]="90" [icon]="faWaveTriangle"></fa-icon>
          </div>
        }
        @if (last) {
          <div class="arrow position-relative k-d-flex k-align-items-center k-flex-grow">
            <div id="today" class="position-absolute k-font-weight-normal">heute</div>
          </div>
        }
      </li>
    }
  </ul>
</div>

@if (billingRateHistory.length > maxVisibleItems) {
  <button kendoButton themeColor="base" id="collapse-button" class="mt-2" (click)="toggleHistory()">
    {{ collapsed() ? "Ausklappen" : "Einklappen" }}
  </button>
}
