export enum BillableRateOptions {
  FixedFeePerProject = 'billing_fixed',
  BillableValuePerTask = 'billing_value_per_task',
  BillableHourlyValueFixed = 'billing_hourly_value_fixed',
  BillableHourlyValuePerMember = 'billing_hourly_value_per_member',
  BillableHourlyValuePerTask = 'billing_hourly_value_per_task',
  NoBilling = 'billing_none',
}

export enum BudgetOptions {
  NoBudget = 'budget_none',
  TotalProjectFees = 'budget_fixed_fee',
  TotalProjectHours = 'budget_total_project_hours',
  CostsPerTask = 'budget_costs_per_task',
  HoursPerTask = 'budget_hours_per_task',
  HoursPerMember = 'budget_hours_per_member',
}
