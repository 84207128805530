export class ClientModel {
  public id = null;
  public name = '';
  public street: null;
  public city: null;
  public zip: null;
  public country: null;
  public email: string;
  // public since: Date;
  // public phone: string;
  // public mobile: string;
  // public fax: string;
  // public remark: string;
}
