import { createAction, props } from '@ngrx/store';
import { ProjectModel } from '../../models/project/project.model';

export const fetchSingleProject = createAction(
  '[Projects] Fetch Single Project',
  props<{ payload: number }>(),
);
export const setSingleProject = createAction(
  '[Projects] Set Single Project',
  props<{ payload: ProjectModel }>(),
);

export const fetchProjects = createAction(
  '[Projects] Fetch Projects',
  props<{ payload: number }>(),
);
export const setProjects = createAction(
  '[Projects] Set Projects',
  props<{ payload: ProjectModel[] }>(),
);

export const addProject = createAction(
  '[Projects] Add Project',
  props<{ payload: ProjectModel | ProjectModel[] }>(),
);
export const projectAdded = createAction(
  '[Projects] Added Project',
  props<{ payload: ProjectModel | ProjectModel[] }>(),
);

export const updateProject = createAction(
  '[Projects] Update Project',
  props<{ payload: ProjectModel | ProjectModel[] }>(),
);
export const projectUpdated = createAction(
  '[Projects] Updated Project',
  props<{ payload: ProjectModel | ProjectModel[] }>(),
);

export const deleteProject = createAction(
  '[Projects] Delete Project',
  props<{ payload: ProjectModel | ProjectModel[] }>(),
);
export const projectDeleted = createAction(
  '[Projects] Deleted Project',
  props<{ payload: ProjectModel | ProjectModel[] }>(),
);

export const httpFail = createAction('[Projects] Http fail', props<{ payload: string }>());
