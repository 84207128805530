import { NavigationWrapper } from '../../navigation-data';
import { ShiveRoutes } from '../../segments/route-segments';
import { PageKey } from '../../page-keys';
import { UrlSegmentDelimiter } from '../../../data-repository/css-constants';

export const Clients: NavigationWrapper = {
  title: 'Kunden',
  route: ShiveRoutes.Clients,
};

export const ClientList: NavigationWrapper = {
  title: 'Kundenübersicht',
  route: ShiveRoutes.ClientList,
};

export const ClientAdd: NavigationWrapper = {
  title: 'Neuer Kunde',
  route: ShiveRoutes.ClientAdd,
  previousPage: PageKey.ClientPageKeys.ClientList,
};

export const ClientEdit: NavigationWrapper = {
  title: 'Kunde bearbeiten',
  route: `${ShiveRoutes.ClientEdit + UrlSegmentDelimiter}*`,
  previousPage: PageKey.ClientPageKeys.ClientList,
};
