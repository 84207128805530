import { ElementRef, Injectable, QueryList, Renderer2, RendererFactory2 } from '@angular/core';
import { Subject } from 'rxjs';
import { RoutingService } from '../../routing/routing.service';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  public sidebarEvents$ = new Subject<TransitionEvent>();
  public isCompressed = false;
  public sidebarEnlargedWidth: number;
  public sidebarCompressedWidth: number;
  public currentRoute!: string;
  public translationInProgress = false;
  private readonly renderer: Renderer2;

  constructor(
    private readonly rendererFactory: RendererFactory2,
    private readonly routingService: RoutingService,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public closeMenus(
    item: string,
    parentListItem: QueryList<ElementRef<HTMLElement>>,
    parentNavItem: ElementRef<HTMLElement>,
  ): void {
    parentListItem.forEach((el) => {
      if (!el.nativeElement.classList.contains(item)) {
        this.renderer.removeClass(el.nativeElement, 'expanded');
      }
    });
    this.renderer.addClass(parentNavItem.nativeElement, 'all-sub-menus-closed');
  }

  public toggleSidebar(document: Document, aside: HTMLElement, mainPanel: HTMLElement): void {
    this.translationInProgress = true;
    const compressedWidth = document.documentElement.clientWidth - this.sidebarEnlargedWidth;
    const enlargedWidth = document.documentElement.clientWidth - this.sidebarCompressedWidth;
    const compressedScale = compressedWidth / enlargedWidth;

    if (this.isCompressed) {
      this.renderer.addClass(mainPanel, 'scale-content-area-compress');
      this.renderer.setStyle(mainPanel, 'transform', `scaleX(${compressedScale})`);
      this.invertScale(`scaleX(${1 / compressedScale})`, mainPanel);
    } else {
      this.renderer.removeClass(aside, 'expand-sidebar');
      this.renderer.addClass(mainPanel, 'scale-content-area-expand');
      this.renderer.setStyle(mainPanel, 'transform', `scaleX(${1 / compressedScale})`);
      this.invertScale(`scaleX(${compressedScale})`, mainPanel);
    }

    this.renderer.addClass(aside, 'collapse-sidebar');
    this.isCompressed = !this.isCompressed;
  }

  public setRouteData(url: string): void {
    const segments = this.routingService.getURLSegments(url);
    if (!segments) {
      return;
    }
    this.currentRoute = segments[0].path;
  }

  public invertScale(value: string, mainPanel: HTMLElement): void {
    const invertScaleElements = mainPanel.querySelectorAll('.invert-scale');
    invertScaleElements.forEach((el) => this.renderer.setStyle(el, 'transform', value));
  }
}
