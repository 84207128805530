import { Injectable } from '@angular/core';
import { TimeBlockRenderService } from '../../../../../rendering/time-block-render.service';
import { CalendarService } from '../../../../../../../services/calendar.service';
import { TimeBlockCrudService } from '../../../../../crud/time-block-crud.service';
import { ITimeBlockComponentItem } from '../../../../../time-block-component-items';
import { DateTimeHelper } from '../../../../../../../util/date-time-helper';
import { DragAndDropHandlerService } from './drag-and-drop-handler.service';
import { TimeBlockElementSelectorService } from '../../../../../rendering/time-block-element-selector.service';
import {
  TimeBlockDayOrWeekFulldayType,
  TimeBlockDayOrWeekInnerdayType,
} from '../../../../../../../../../core/models/timeblock/time-block-view-type.model';
import { TimeBlockDragResizeControllerService } from '../../time-block-drag-resize-handling/time-block-drag-resize-controller.service';
import { TimeBlockHttpTransformationService } from '../../../../../http/time-block-http-transformation.service';

@Injectable()
export class DragDropPositioningService {
  constructor(
    private readonly timeBlockRenderService: TimeBlockRenderService,
    private readonly calendarService: CalendarService,
    private readonly timeBlockDragResizeControllerService: TimeBlockDragResizeControllerService,
    private readonly dragAndDropHandlerService: DragAndDropHandlerService,
    private readonly timeBlockCrudService: TimeBlockCrudService,
  ) {}

  /**
   * For day or week inner day time blocks
   */
  public deployDayOrWeekInnerdayTimeBlocks(
    event: Interact.DragEvent,
    draggedTimeBlocks: ITimeBlockComponentItem[],
  ): void | never {
    const sourceContainerId = this.dragAndDropHandlerService.sourceContainerId;
    const targetContainerId = this.dragAndDropHandlerService.targetContainerId;

    const sourceContainer = this.calendarService.model.timeBlockHtmlContainers[sourceContainerId];
    const targetContainer = this.calendarService.model.timeBlockHtmlContainers[targetContainerId];

    // If user drops the time block with right mouse button, target container is undefined. Therefore, do nothing.
    if (typeof sourceContainer === 'undefined' || typeof targetContainer === 'undefined') {
      throw new Error('Container is undefined.');
    }

    draggedTimeBlocks.forEach((timeBlockPart) => {
      // / If block is dropped between drop zones, move it to the corresponding drop zone.
      if (event && !event.modifiers[0].inRange) {
        const originLeft = sourceContainer.nativeElement.getBoundingClientRect().left;
        const targetLeft = targetContainer.nativeElement.getBoundingClientRect().left;

        const translateX = targetLeft - originLeft;
        const timeBlockHTMLWrapper =
          TimeBlockElementSelectorService.getTimeBlockHTMLWrapper(timeBlockPart);
        this.timeBlockRenderService.moveElement(timeBlockHTMLWrapper, translateX, 0);
      }
    });

    const draggedTimeBlock = this.timeBlockDragResizeControllerService.TransformationTimeBlock;
    const daysMoved = targetContainerId - sourceContainerId;
    const newStartDate = DateTimeHelper.addDays(draggedTimeBlock.timeBlockModel.start, daysMoved);
    const newEndDate = DateTimeHelper.addDays(draggedTimeBlock.timeBlockModel.end, daysMoved);

    const timeBlockViewType = draggedTimeBlock.timeBlockModel
      .timeBlockViewType as TimeBlockDayOrWeekInnerdayType;
    timeBlockViewType.laneIndex = targetContainerId;

    // Reset the geometry data
    timeBlockViewType.geometryData.width = 100;
    timeBlockViewType.geometryData.containerLeftEdgeToTimeBlockLeftEdge = 0;

    draggedTimeBlock.timeBlockModel.start = newStartDate;
    // If end time is 00:00:00, set it to 23:59:59 to prevent the algorithm to create a new time block
    // on the next day.
    draggedTimeBlock.timeBlockModel.end =
      TimeBlockHttpTransformationService.transformEndDate(newEndDate);
    this.timeBlockCrudService.insertTimeBlock(draggedTimeBlock);
  }

  /**
   * For day or week full day time blocks
   */
  public deployFulldayTimeBlocks(
    event: Interact.DragEvent,
    draggedTimeBlock: ITimeBlockComponentItem,
  ): void {
    const sourceContainerId = this.dragAndDropHandlerService.sourceContainerId;
    const targetContainerId = this.dragAndDropHandlerService.targetContainerId;

    if (sourceContainerId === null || targetContainerId === null) {
      throw new Error('Container id is not valid.');
    }

    const timeBlockModel = draggedTimeBlock.timeBlockModel;
    const daysMoved = targetContainerId - sourceContainerId;
    const newStartDate = DateTimeHelper.addDays(timeBlockModel.start, daysMoved);
    const newEndDate = DateTimeHelper.addDays(timeBlockModel.end, daysMoved);

    // No need to insert month full day time blocks since they are already set in the drag() method.
    if (
      draggedTimeBlock.timeBlockModel.timeBlockViewType instanceof TimeBlockDayOrWeekFulldayType
    ) {
      this.insertDayOrWeekHorizontalTimeBlock(
        draggedTimeBlock,
        newStartDate,
        newEndDate,
        targetContainerId,
      );
    }
  }

  private insertDayOrWeekHorizontalTimeBlock(
    draggedTimeBlock: ITimeBlockComponentItem,
    newStartDate: Date,
    newEndDate: Date,
    targetContainerId: number,
  ): void {
    const timeBlockModel = draggedTimeBlock.timeBlockModel;
    const timeBlockViewType = timeBlockModel.timeBlockViewType as TimeBlockDayOrWeekFulldayType;
    timeBlockModel.start = newStartDate;
    timeBlockModel.end = newEndDate;
    timeBlockViewType.laneIndexStart = targetContainerId;
    timeBlockViewType.laneIndexEnd =
      targetContainerId + DateTimeHelper.differenceInDays(timeBlockModel.end, timeBlockModel.start);
    this.timeBlockCrudService.insertTimeBlock(draggedTimeBlock);
  }
}
