import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { of, take } from 'rxjs';
import { DropdownOption } from '../../../../../../shared/data-types/dropdown-option';
import { ClientsHttpService } from '../../../../../../core/state/clients/clients-http.service';
import {
  dropdownOptionToEntity,
  entityToDropdownOption,
  isDropdownValue,
  observableToDropdownOptions,
} from '../../../../../../shared/functions/dropdown-functions';
import { cloneDeep } from 'lodash-es';
import { ProjectModel } from '../../../../../../core/models/project/project.model';
import { ColorsHttpService } from '../../../../../../core/state/colors/colors-http.service';
import { selectColorByValue } from '../../../../../../core/state/colors/colors.selectors';
import { Store } from '@ngrx/store';
import { combineLatestWith, filter, switchMap } from 'rxjs/operators';
import { UserOperation } from '../../../../../../core/enums/user-operation';
import { ShiveDialogService } from '../../../../../../core/services/controls/shive-dialog.service';
import { CreateClientDialogComponent } from '../../dialogs/create-client-dialog/create-client-dialog.component';
import { ClientModel } from '../../../../../../core/models/client/client.model';
import { CreateEditClientDataService } from '../../../../../client/create-edit-client/data-handling/create-edit-client-data.service';
import { StatusModel } from '../../../../../../core/models/project/status.model';
import { ManageProjectService } from '../manage-project.service';
import { ProjectService } from '../../../../services/project.service';
import { assert } from '../../../../../../core/assert/assert';

@Component({
  selector: 'app-project-base-form',
  templateUrl: './project-base-form.component.html',
  styleUrls: ['./project-base-form.component.scss'],
})
export class ProjectBaseFormComponent implements OnInit, OnDestroy {
  @Input() projectStatuses!: StatusModel[];
  public statusOptions: readonly DropdownOption[] = [];
  public clients: DropdownOption[] = [];
  public projectBaseForm: FormGroup;
  public palette: string[] = [];
  public formControlNames: { [key: string]: string } = {
    ProjectName: 'name',
    StartsAt: 'startsAt',
    EndsAt: 'endsAt',
    Client: 'client',
    ProjectId: 'projectId',
    ProjectStatus: 'status',
    ProjectColor: 'color',
    Currency: 'currency',
    Remark: 'remark',
  };
  public selectedClient: DropdownOption;
  public readonly UserOperation = UserOperation;
  private readonly subs = new SubSink();

  constructor(
    public clientsHttpService: ClientsHttpService,
    private readonly projectService: ProjectService,
    private readonly colorsHttpService: ColorsHttpService,
    private readonly store: Store,
    private readonly createEditClientDataService: CreateEditClientDataService,
    private readonly shiveDialogService: ShiveDialogService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly manageProjectService: ManageProjectService,
  ) {}

  ngOnInit(): void {
    this.statusOptions = this.projectStatuses.map((status) => entityToDropdownOption(status));
    this.bootstrap();
  }

  ngOnDestroy(): void {
    this.projectBaseForm.reset();
    this.subs.unsubscribe();
  }

  public formValueChanged([value, formCtrlName]: [unknown, string]): void {
    assert(typeof formCtrlName !== 'undefined', `Form control name is undefined: ${formCtrlName}`);

    const projectClone = this.projectService.getProjectClone();
    if (formCtrlName === this.formControlNames.ProjectColor) {
      this.store
        .select(selectColorByValue(value as string))
        .pipe(take(1))
        .subscribe((color) => (projectClone[formCtrlName] = color));
    } else if (isDropdownValue(value)) {
      projectClone[formCtrlName] = dropdownOptionToEntity(value as DropdownOption);
    } else {
      projectClone[formCtrlName] = value;
    }

    this.projectService.updateProject(projectClone);
    this.projectService.userOperationConducted$.next(true);
  }

  public openClientDialog(): void {
    const ref = this.shiveDialogService.open({
      content: CreateClientDialogComponent,
      width: 572,
      title: 'Neuer Kunde',
    });

    this.subs.sink = ref.result
      .pipe(
        filter((value) => value !== UserOperation.Cancel),
        take(1),
      )
      .subscribe((clientModel: ClientModel) => {
        this.createEditClientDataService.sendRequests(clientModel);
        this.selectedClient = entityToDropdownOption(clientModel);
      });
  }

  private buildForm(project: ProjectModel): void {
    const projectData = {
      name: project.name,
      remark: project.remark,
      color: project.color?.value,
      startsAt: project.startsAt ?? new Date(),
      endsAt: project.endsAt ?? new Date(),
      projectStatus: project.status,
    };

    this.projectBaseForm = this.formBuilder.group({
      [this.formControlNames.ProjectName]: [projectData.name, Validators.required],
      [this.formControlNames.ProjectId]: [null],
      [this.formControlNames.Status]: [projectData.projectStatus, Validators.required],
      [this.formControlNames.ProjectColor]: [projectData.color, Validators.required],
      [this.formControlNames.StartsAt]: [projectData.startsAt, Validators.required],
      [this.formControlNames.EndsAt]: [projectData.endsAt, Validators.required],
      [this.formControlNames.Currency]: [null],
      [this.formControlNames.Remark]: [projectData.remark, Validators.required],
    });

    this.manageProjectService.projectBaseForm = this.projectBaseForm;
  }

  private bootstrap(): void {
    const projectClone = this.projectService.getProjectClone();
    this.buildForm(projectClone);
    this.selectedClient = entityToDropdownOption(projectClone.client);

    this.projectBaseForm.controls[this.formControlNames.Status].setValue(
      entityToDropdownOption(projectClone.status),
    );

    observableToDropdownOptions(this.clientsHttpService.getClients())
      .addUnassignedOption()
      .pipe(take(1))
      .subscribe((clientOptions) => (this.clients = clientOptions));

    this.loadColors();
  }

  private loadColors(): void {
    this.colorsHttpService
      .getColors()
      .pipe(
        take(1),
        switchMap((colors) => {
          this.palette = colors.map((color) => color.value);
          return of(this.projectService.getProjectClone()).pipe(
            combineLatestWith(of(colors)),
            take(1),
          );
        }),
      )
      .subscribe(([project, colors]) => {
        // New project, so assign first color in palette.
        if (project.id < 0) {
          const clonedProject = cloneDeep(project);
          clonedProject.color = colors[0];
          this.projectBaseForm.controls[this.formControlNames.ProjectColor].setValue(
            colors[0].value,
          );
          this.projectService.updateProject(clonedProject);
        }
      });
  }
}
