<aside id="project-summary">
  <section id="hours-summary" class="summary-box k-mt-2">
    <h6 class="summary-identifier k-mb-0 font-weight-medium">Geleistete Stunden</h6>
    <h2 class="summary-value font-weight-bold">75 Stunden (42%)</h2>
    <div class="summary-progress k-pos-relative k-w-100">
      <div class="summary-progress-current k-pos-absolute k-left-0"></div>
    </div>
    <h5 class="summary-info k-d-flex k-mb-0 k-mt-2.5">
      <div class="label k-mr-1.5">Gesamt:</div>
      <div class="value font-weight-medium">100 Stunden</div>
    </h5>
    <h5 class="summary-info k-d-flex k-mb-0">
      <div class="label k-mr-1.5">Verrechenbar:</div>
      <div class="value font-weight-medium">73 Stunden</div>
    </h5>
  </section>
  <hr />
  <section id="hours-summary1" class="summary-box k-mt-2">
    <h6 class="summary-identifier k-mb-0 font-weight-medium">Geleistete Stunden</h6>
    <h2 class="summary-value font-weight-bold">75 Stunden (42%)</h2>
    <div class="summary-progress k-pos-relative k-w-100">
      <div class="summary-progress-current k-pos-absolute k-left-0"></div>
    </div>
    <h5 class="summary-info k-d-flex k-mb-0 k-mt-2.5">
      <div class="label k-mr-1.5">Gesamt:</div>
      <div class="value font-weight-medium">100 Stunden</div>
    </h5>
    <h5 class="summary-info k-d-flex k-mb-0">
      <div class="label k-mr-1.5">Verrechenbar:</div>
      <div class="value font-weight-medium">73 Stunden</div>
    </h5>
  </section>
  <hr />
  <section id="hours-summary2" class="summary-box k-mt-2">
    <h6 class="summary-identifier k-mb-0 font-weight-medium">Geleistete Stunden</h6>
    <h2 class="summary-value font-weight-bold">75 Stunden (42%)</h2>
    <div class="summary-progress k-pos-relative k-w-100">
      <div class="summary-progress-current k-pos-absolute k-left-0"></div>
    </div>
    <h5 class="summary-info k-d-flex k-mb-0 k-mt-2.5">
      <div class="label k-mr-1.5">Gesamt:</div>
      <div class="value font-weight-medium">100 Stunden</div>
    </h5>
    <h5 class="summary-info k-d-flex k-mb-0">
      <div class="label k-mr-1.5">Verrechenbar:</div>
      <div class="value font-weight-medium">73 Stunden</div>
    </h5>
  </section>
</aside>
