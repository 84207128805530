export class DataStructureHelper {
  // Returns a new array.
  public static toArray<T, U>(map: Map<T, U>): U[] {
    return Array.from(map, ([, value]) => {
      return value;
    });
  }
}

export class DefaultDict {
  constructor(defaultInit) {
    return new Proxy(
      {},
      {
        get: (target, name) =>
          name in target
            ? target[name]
            : (target[name] =
                typeof defaultInit === 'function' ? new defaultInit().valueOf() : defaultInit),
      },
    );
  }
}
