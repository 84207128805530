import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EMPTY, Observable } from 'rxjs';
import { ClientModel } from '../../../../core/models/client/client.model';
import { ClientsHttpService } from '../../../../core/state/clients/clients-http.service';

@Injectable({
  providedIn: 'root',
})
export class CreateEditClientDataService {
  public editMode = false;
  public clientId?: number;

  constructor(private readonly clientsHttpService: ClientsHttpService) {}

  public loadClientData(clientBaseForm: FormGroup, clientId: number): Observable<unknown> {
    if (clientId) {
      this.clientId = clientId;
      this.editMode = true;
      return this.clientsHttpService.getSingleClient(clientId);
    }
    this.editMode = false;
    return EMPTY;
  }

  public sendRequests(clientModel: ClientModel): Observable<unknown> {
    if (!this.clientId) {
      return this.clientsHttpService.addClient(clientModel);
    }
    // Update existing client
    clientModel.id = this.clientId;
    return this.clientsHttpService.updateClient(clientModel);
  }
}
