<div class="card">
  <div class="card-header">
    <div class="title-row">
      <div class="title d-flex k-align-items-center">
        <h2 class="mb-0">Abwesenheitstypen</h2>
      </div>
    </div>
  </div>

  <div class="card-body">
    <div class="row gx-5 k-mt-5">
      <div id="absence-reasons-table-wrapper" class="col-6">
        <app-shive-grid #gridWrapperComponent [grid]="grid">
          <kendo-grid
            kendoGridSelectBy="id"
            scrollable="none"
            size="medium"
            [kendoGridBinding]="absenceReasons"
            [pageSize]="20"
            [selectable]="{ enabled: true, checkboxOnly: true }"
            [columnMenu]="{ filter: true }"
            (add)="upsertAbsenceReason(UserOperation.Create)"
            #grid
          >
            <ng-template kendoGridToolbarTemplate>
              <div class="k-d-flex k-justify-content-end k-w-full">
                <button
                  kendoGridAddCommand
                  size="medium"
                  themeColor="secondary"
                  [disabled]="gridWrapperComponent.userOperation !== UserOperation.None"
                >
                  <fa-icon class="icon turquoise k-mr-3" [icon]="faHousePersonLeave"></fa-icon>
                  <span>Abwesenheit hinzufügen</span>
                </button>
              </div>
            </ng-template>

            <kendo-grid-checkbox-column
              [width]="40"
              [headerClass]="{ 'k-text-center': true }"
              class="list-selection-check"
              [resizable]="false"
              [columnMenu]="false"
              [showSelectAll]="true"
            >
            </kendo-grid-checkbox-column>

            <kendo-grid-column field="absence_reason_name" title="Name">
              <ng-template kendoGridCellTemplate let-absenceReason>
                <div class="absence-reason-name">{{ absenceReason.name }}</div>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="absence_reason_is_working_time" title="Ist Arbeitszeit">
              <ng-template kendoGridCellTemplate let-absenceReason>
                <div class="absence-reason-is-working-time">{{ absenceReason.isWorkingTime }}</div>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="context_menu" title="" [columnMenu]="false" [width]="40">
              <ng-template kendoGridCellTemplate let-dataItem>
                <app-context-menu-anchor
                  [dataItem]="dataItem"
                  [gridWrapperComponent]="gridWrapperComponent"
                />
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </app-shive-grid>
      </div>
    </div>
  </div>
</div>
