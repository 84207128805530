import { HolidayTemplateModel } from '../../../../core/models/settings/holiday/holiday-template.model';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, Subject } from 'rxjs';
import { cloneDeep } from 'lodash-es';

export interface HolidayTemplateState {
  holidayTemplates: HolidayTemplateModel[];
}

@Injectable()
export class HolidaySettingsStore extends ComponentStore<HolidayTemplateState> {
  public userOperationConducted = new Subject<boolean>();
  public holidayTemplateDeletionList: HolidayTemplateModel[] = [];
  public readonly addHolidayTemplate = this.updater(
    (state: HolidayTemplateState, newHolidayTemplate: HolidayTemplateModel) => ({
      holidayTemplates: [...state.holidayTemplates, newHolidayTemplate],
    }),
  );
  public readonly selectHolidayTemplates$: Observable<HolidayTemplateModel[]> = this.select(
    (state) => state.holidayTemplates,
  );

  constructor() {
    super({ holidayTemplates: [] });
  }

  public readonly selectSingleHolidayTemplate = (templateId: number) =>
    this.select(this.selectHolidayTemplates$, (holidayTemplates) =>
      holidayTemplates.find((template) => template.id === templateId),
    );

  public readonly updateHolidayTemplate = (updatedholidayTemplateList: HolidayTemplateModel) => {
    this.patchState((state) => {
      const index = state.holidayTemplates.findIndex(
        (entry) => entry.id === updatedholidayTemplateList.id,
      );
      if (index < 0) {
        throw new Error('Existing holiday template list not found.');
      }

      const updatedState = cloneDeep(state);
      updatedState.holidayTemplates[index] = updatedholidayTemplateList;
      return updatedState;
    });
  };

  public readonly removeHolidayTemplate = (holidayTemplate: HolidayTemplateModel) => {
    this.patchState((state) => {
      const index = state.holidayTemplates.findIndex((entry) => entry.id === holidayTemplate.id);
      if (index < 0) {
        throw new Error('Existing holiday template list not found.');
      }

      // Store id of existing list for upcoming delete request.
      if (holidayTemplate.id >= 0) {
        this.holidayTemplateDeletionList.push(holidayTemplate);
      }

      const updatedState = cloneDeep(state);
      updatedState.holidayTemplates.splice(index, 1);
      return updatedState;
    });
  };

  public readonly reset = () => {
    this.patchState(() => {
      return { holidayTemplates: [] };
    });
  };
}
