import { Injectable } from '@angular/core';
import { CalendarEvents, CalendarView } from '../../../../../../shared/data-types/calendar-types';
import { ITimeBlockComponentItem } from '../../time-block-component-items';
import { CalendarService } from '../../../../services/calendar.service';
import { DayOrWeekCalendarModel } from '../../../../../../core/models/calendar/day-or-week-calendar.model';
import { TimeBlockStructureService } from '../../time-block-structure/time-block-structure.service';
import { DateTimeHelper } from '../../../../util/date-time-helper';
import { Subject } from 'rxjs';
import { IntersectionService } from '../intersection/intersection.service';
import { MonthCalendarModel } from '../../../../../../core/models/calendar/month-calendar.model';
import { Interval } from 'date-fns';

@Injectable()
export class TimeBlockDurationCalculationService {
  public calculateTimeBlockDurationSums$ = new Subject();

  constructor(
    private readonly calendarService: CalendarService,
    private readonly timeBlockStructureService: TimeBlockStructureService,
    private readonly intersectionService: IntersectionService,
  ) {}

  public initEvents(): void {
    this.calculateTimeBlockDurationSums$.subscribe(() => {
      this.calculateDailyTrackedTimeSum();
    });
  }

  private getEnd(end: Date, curDate: Date, isFullday: boolean): Date {
    let endTime = DateTimeHelper.mergeDateAndTime(curDate, end);
    if (isFullday) {
      endTime = DateTimeHelper.transformToExactlyMidnightIfNeeded(endTime, true);
      return endTime;
    }

    if (end.getDate() !== curDate.getDate()) {
      endTime.setHours(0, 0, 0, 0);
      endTime = DateTimeHelper.addDays(endTime, 1);
    }
    endTime = DateTimeHelper.transformToExactlyMidnightIfNeeded(endTime, true);
    return endTime;
  }

  private getStart(start: Date, curDate: Date, isFullday: boolean): Date {
    const startTime = DateTimeHelper.mergeDateAndTime(curDate, start);
    if (isFullday) {
      return startTime;
    }
    if (start.getDate() !== curDate.getDate()) {
      startTime.setHours(0, 0, 0, 0);
    }
    return startTime;
  }

  private calculateMaxDurationForDay(
    innerdayTimeBlocks: ITimeBlockComponentItem[],
    curDate: Date,
  ): number {
    const sortedTimeBlock = innerdayTimeBlocks.sort(
      (a: ITimeBlockComponentItem, b: ITimeBlockComponentItem) => {
        const aStart = this.getStart(
          a.timeBlockModel.start,
          curDate,
          a.timeBlockModel.isFullday,
        ).getTime();
        const aEnd = this.getStart(
          a.timeBlockModel.end,
          curDate,
          a.timeBlockModel.isFullday,
        ).getTime();
        const bStart = this.getStart(
          b.timeBlockModel.start,
          curDate,
          b.timeBlockModel.isFullday,
        ).getTime();
        const bEnd = this.getStart(
          b.timeBlockModel.end,
          curDate,
          b.timeBlockModel.isFullday,
        ).getTime();

        const start = aStart - bStart;
        if (start === 0) {
          const durationA = aEnd - aStart;
          const durationB = bEnd - bStart;
          return durationA - durationB;
        }
        return start;
      },
    );

    let timeAccumulator = 0;
    const predecessors: ITimeBlockComponentItem[] = [];
    sortedTimeBlock.forEach((curTimeBlock: ITimeBlockComponentItem) => {
      const additionalTime = this.getAdditionalTime(predecessors, curTimeBlock, curDate);
      timeAccumulator += additionalTime;
      predecessors.push(curTimeBlock);
    });
    return timeAccumulator;
  }

  private getAdditionalTime(
    predecessors: ITimeBlockComponentItem[],
    curTimeBlock: ITimeBlockComponentItem,
    curDate: Date,
  ): number {
    const intersectingTimeBlock = this.findIntersectingTimeBlockInnerday(
      predecessors,
      curTimeBlock,
      curDate,
    );

    let additionalTime;
    if (intersectingTimeBlock === null) {
      additionalTime = DateTimeHelper.differenceInSeconds(
        this.getEnd(
          curTimeBlock.timeBlockModel.end,
          curDate,
          curTimeBlock.timeBlockModel.isFullday,
        ),
        this.getStart(
          curTimeBlock.timeBlockModel.start,
          curDate,
          curTimeBlock.timeBlockModel.isFullday,
        ),
      );
    } else {
      additionalTime = DateTimeHelper.differenceInSeconds(
        this.getEnd(
          curTimeBlock.timeBlockModel.end,
          curDate,
          curTimeBlock.timeBlockModel.isFullday,
        ),
        this.getEnd(
          intersectingTimeBlock.timeBlockModel.end,
          curDate,
          intersectingTimeBlock.timeBlockModel.isFullday,
        ),
      );
    }

    return additionalTime > 0 ? additionalTime : 0;
  }

  private findIntersectingTimeBlockInnerday(
    predecessors: ITimeBlockComponentItem[],
    curTimeBlock: ITimeBlockComponentItem,
    curDate: Date,
  ): ITimeBlockComponentItem {
    for (const predecessor of predecessors) {
      if (
        this.intersectionService.intersects2(
          this.getStart(
            predecessor.timeBlockModel.start,
            curDate,
            predecessor.timeBlockModel.isFullday,
          ),
          this.getEnd(
            predecessor.timeBlockModel.end,
            curDate,
            predecessor.timeBlockModel.isFullday,
          ),
          this.getStart(
            curTimeBlock.timeBlockModel.start,
            curDate,
            curTimeBlock.timeBlockModel.isFullday,
          ),
          this.getEnd(
            curTimeBlock.timeBlockModel.end,
            curDate,
            curTimeBlock.timeBlockModel.isFullday,
          ),
        )
      ) {
        return predecessor;
      }
    }
    return null;
  }

  private calculateDailyTrackedTimeSum(): void {
    const calendarView = this.calendarService.model.calendarViewMode;

    if (calendarView === CalendarView.DayGrid || calendarView === CalendarView.WeekGrid) {
      // day or week view
      const fulldayCalendarModel = (this.calendarService.model as DayOrWeekCalendarModel)
        .fulldayCalendarModel;

      // inner day
      const visibleRange: Interval = {
        start: this.calendarService.model.calendarProperties.visibleStartDate,
        end: this.calendarService.model.calendarProperties.visibleEndDate,
      };

      const innerdayTimeBlocks = this.timeBlockStructureService.getTimeBlockMap(
        false,
        visibleRange,
      );
      const fulldayTimeBlocks = fulldayCalendarModel.timeBlocks;

      const accumulatedTimeMap = this.calculateWorkingTimeDurationPerDay(
        visibleRange,
        visibleRange,
        innerdayTimeBlocks,
        fulldayTimeBlocks,
      );
      this.calendarService.emitCalendarChange(
        this.calendarService.model,
        CalendarEvents.CalculatedWorkingTimeDurations,
        accumulatedTimeMap,
      );
    } else {
      // month view
      const calendarModel = (this.calendarService.model as MonthCalendarModel).fulldayCalendarModel;
      const currentMonth = calendarModel.calendarProperties.month;
      const year = calendarModel.calendarProperties.year;
      const currentMonthDate = new Date(year, currentMonth);

      const fullRange: Interval = {
        start: calendarModel.calendarProperties.visibleStartDate,
        end: calendarModel.calendarProperties.visibleEndDate,
      };

      const monthRange: Interval = {
        start: DateTimeHelper.startOfMonth(currentMonthDate),
        end: DateTimeHelper.endOfMonth(currentMonthDate),
      };

      const fulldayTimeBlocks = this.timeBlockStructureService.getTimeBlockMap(true, fullRange);
      const innerdayTimeBlocks = this.timeBlockStructureService.getTimeBlockMap(false, fullRange);
      const accumulatedTimeMap = this.calculateWorkingTimeDurationPerDay(
        monthRange,
        fullRange,
        innerdayTimeBlocks,
        fulldayTimeBlocks,
      );

      const daysPerWeek = 7;
      const accumulatedTimeMapPerWeek = new Map<string, number>();
      // group by week
      let dateCounter = 0;
      let weekNumber = '';
      for (const [dateString, y] of accumulatedTimeMap) {
        if (dateCounter % daysPerWeek === 0) {
          weekNumber = DateTimeHelper.weekNumber(
            DateTimeHelper.parse(dateString, 'yyyy-MM-dd', new Date()),
          ).toString();
          accumulatedTimeMapPerWeek.set(weekNumber, 0);
        }
        accumulatedTimeMapPerWeek.set(weekNumber, accumulatedTimeMapPerWeek.get(weekNumber) + y);
        dateCounter++;
      }
      this.calendarService.emitCalendarChange(
        this.calendarService.model,
        CalendarEvents.CalculatedWorkingTimeDurations,
        accumulatedTimeMapPerWeek,
      );
    }
  }

  private calculateWorkingTimeDurationPerDay(
    monthRange: Interval,
    fullRange: Interval,
    innerdayTimeBlocks: Map<string, ITimeBlockComponentItem[]>,
    fulldayTimeBlocks: Map<string, ITimeBlockComponentItem[]>,
  ): Map<string, number> {
    const accumulatedTimeMap = this.initAccumulatedTimeMap(fullRange);

    DateTimeHelper.eachDayOfInterval(monthRange.start as Date, monthRange.end as Date).forEach(
      (currentDate) => {
        const dateString = DateTimeHelper.format(currentDate);
        const timeBlocksForDay = [
          ...innerdayTimeBlocks.get(dateString),
          ...fulldayTimeBlocks.get(dateString),
        ];
        const duration = this.calculateMaxDurationForDay(
          timeBlocksForDay,
          DateTimeHelper.parse(dateString, 'yyyy-MM-dd', new Date()),
        );
        accumulatedTimeMap.set(dateString, duration);
      },
    );

    return accumulatedTimeMap;
  }

  private initAccumulatedTimeMap(range: Interval): Map<string, number> {
    const accumulatedTimeMap = new Map<string, number>();
    let currentDate = range.start as Date;
    while (currentDate <= range.end) {
      const dateString = DateTimeHelper.format(currentDate);
      accumulatedTimeMap.set(dateString, 0);
      currentDate = DateTimeHelper.addDays(currentDate, 1);
    }
    return accumulatedTimeMap;
  }
}
