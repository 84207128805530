import { Component, Input } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import {
  ContextMenuItemKeys,
  ShiveContextMenuService,
} from '../../../../core/services/controls/shive-context-menu.service';
import { UserOperation } from '../../../../core/enums/user-operation';

@Component({
  selector: 'app-shive-grid',
  templateUrl: './shive-grid.component.html',
  styleUrls: ['./shive-grid.component.scss'],
})
export class ShiveGridComponent {
  @Input() grid: GridComponent;
  public userOperation = UserOperation.None;
  public contextMenuEditFn: (item: any) => void;
  public contextMenuRemoveFn: (item: any) => void;
  public contextMenuDeleteFn: (item: any) => void;
  private readonly contextMenuItems: Map<ContextMenuItemKeys, (item: unknown) => void> = new Map();

  constructor(private readonly shiveContextMenuService: ShiveContextMenuService) {}

  public openContextMenu(anchor: HTMLDivElement, data): void {
    this.shiveContextMenuService.openContextMenu(
      anchor,
      this.contextMenuItems,
      data,
      this.userOperation,
    );
  }

  public setContextMenuItems(): void {
    window.setTimeout(() => {
      if (this.contextMenuEditFn) {
        this.contextMenuItems.set(ContextMenuItemKeys.Edit, this.contextMenuEditFn);
      }

      if (this.contextMenuRemoveFn) {
        this.contextMenuItems.set(ContextMenuItemKeys.Remove, this.contextMenuRemoveFn);
      }

      if (this.contextMenuDeleteFn) {
        this.contextMenuItems.set(ContextMenuItemKeys.Delete, this.contextMenuDeleteFn);
      }
    });
  }
}
