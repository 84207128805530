import { LogLevel } from './log-levels';
import { LogEntry } from './log-entry';
import { LogConsole, LogLocalStorage, LogPublisher } from './log-publisher';
import { ExcludeFromLogMetadataKey } from '../typescript-decorators/exclude-from-log.decorator';
import { processNested } from '../functions/object-functions';
import 'reflect-metadata';

export class Logger {
  public static LoggingEnabled = true;

  public static debug(msg: string, ...optionalParams) {
    Logger.writeToLog(msg, LogLevel.Debug, optionalParams);
  }

  public static error(msg: string, ...optionalParams) {
    Logger.writeToLog(msg, LogLevel.Error, optionalParams);
  }

  public static toggleLogging(enabled = true): void {
    Logger.LoggingEnabled = enabled;
  }

  private static writeToLog(msg: string, level = LogLevel.Debug, params = []) {
    if (!Logger.LoggingEnabled) {
      return;
    }

    const entry: LogEntry = new LogEntry();
    entry.message = msg;
    entry.level = level;
    entry.extraInfo = Logger.excludeFromLog(params) as [];
    entry.date = new Date();

    const loggers: LogPublisher[] = [];
    loggers.push(new LogLocalStorage());

    if (level === LogLevel.Info || level === LogLevel.Debug || level === LogLevel.Error) {
      loggers.push(new LogConsole());
    }

    loggers.forEach((logger) => logger.log(entry));
  }

  private static excludeFromLog(params): unknown {
    const plainObject = {};
    params.forEach((entry: object) => {
      processNested(entry, plainObject, Logger.excludeFn);
    });
    return plainObject;
  }

  private static excludeFn(entry: object, key: string | symbol, plainObject: object): void {
    if (
      Object.prototype.hasOwnProperty.call(entry, key) &&
      !Reflect.getMetadata(ExcludeFromLogMetadataKey, entry, key)
    ) {
      plainObject[key] = entry[key];
    }
  }
}
