import { AfterViewInit, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { HolidayTemplateNameModel } from '../../../../../core/models/settings/holiday/holiday-template-name.model';
import { GridComponent } from '@progress/kendo-angular-grid';
import { faEllipsis as faEllipsisRegular } from '@fortawesome/pro-regular-svg-icons';
import { faTreeChristmas } from '@fortawesome/pro-light-svg-icons';
import { UserOperation } from '../../../../../core/enums/user-operation';
import { combineLatestWith, filter, take } from 'rxjs/operators';
import { HolidayTemplateModel } from '../../../../../core/models/settings/holiday/holiday-template.model';
import { SubSink } from 'subsink';
import { HolidaySettingsHolidayDialogComponent } from '../dialogs/holiday-settings-holiday-dialog/holiday-settings-holiday-dialog.component';
import { ShiveDialogService } from '../../../../../core/services/controls/shive-dialog.service';
import { HolidaySettingsStore } from '../../state-and-data-handling/holiday-settings.store';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { cloneDeep } from 'lodash-es';
import { ShiveGridComponent } from '../../../../../shared/components/form-components/shive-grid/shive-grid.component';
import { generateDummyId } from '../../../../../shared/functions/array-functions';
import { Logger } from '../../../../../shared/logging/logger';

@Component({
  selector: 'app-holiday-settings-holiday-grid',
  templateUrl: './holiday-settings-holiday-grid.component.html',
  styleUrls: ['./holiday-settings-holiday-grid.component.scss'],
})
export class HolidaySettingsHolidayGridComponent implements AfterViewInit, OnDestroy {
  @Input() holidays: HolidayTemplateNameModel[];
  @Input() holidayListId: number;
  @ViewChild('gridWrapperComponent') gridWrapperComponent: ShiveGridComponent;
  @ViewChild('grid') grid: GridComponent;
  public readonly UserOperation = UserOperation;
  public readonly faEllipsis = faEllipsisRegular;
  public readonly faTreeChristmas = faTreeChristmas;
  private readonly subs = new SubSink();

  constructor(
    private readonly holidaySettingsStore: HolidaySettingsStore,
    private readonly shiveDialogService: ShiveDialogService,
  ) {}

  ngAfterViewInit(): void {
    this.initContextMenuCallbacks();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public upsertHoliday(
    userOperation: UserOperation,
    existingHoliday?: HolidayTemplateNameModel,
  ): void {
    const dialogRef = this.buildDialog(userOperation);

    const holidaySettingsHolidayDialogComponent = dialogRef.content
      .instance as HolidaySettingsHolidayDialogComponent;
    holidaySettingsHolidayDialogComponent.userOperation = userOperation;
    if (userOperation === UserOperation.Update) {
      holidaySettingsHolidayDialogComponent.holidayId = existingHoliday.id;
      holidaySettingsHolidayDialogComponent.holidayName = existingHoliday.name;
      holidaySettingsHolidayDialogComponent.holidayDate = existingHoliday.date;
    }
    holidaySettingsHolidayDialogComponent.holidays = [...this.holidays];

    this.subs.sink = dialogRef.result
      .pipe(
        filter((value) => value !== UserOperation.Cancel),
        combineLatestWith(
          this.holidaySettingsStore.selectSingleHolidayTemplate(this.holidayListId).pipe(take(1)),
        ),
        take(1),
      )
      .subscribe(([result, holidayTemplateModel]: [[string, Date], HolidayTemplateModel]) => {
        const clonedHolidayTemplateModel = cloneDeep(holidayTemplateModel);
        const [holidayName, holidayDate] = result;
        if (userOperation === UserOperation.Create) {
          this.insertHoliday(clonedHolidayTemplateModel, holidayName, holidayDate);
        } else if (userOperation === UserOperation.Update) {
          this.updateHoliday(
            clonedHolidayTemplateModel,
            holidayName,
            holidayDate,
            existingHoliday.id,
          );
        } else {
          throw new Error('Illegal user operation.');
        }

        this.holidays = [...clonedHolidayTemplateModel.holidays];
        Logger.debug('Template updated in upsertHoliday()', clonedHolidayTemplateModel);
        this.holidaySettingsStore.updateHolidayTemplate(clonedHolidayTemplateModel);
        this.holidaySettingsStore.userOperationConducted.next(true);
      });
  }

  private initContextMenuCallbacks(): void {
    this.gridWrapperComponent.contextMenuEditFn = (holiday: HolidayTemplateNameModel) => {
      this.upsertHoliday(UserOperation.Update, holiday);
    };

    this.gridWrapperComponent.contextMenuDeleteFn = (holiday: HolidayTemplateNameModel) => {
      this.removeHoliday(holiday.id);
    };

    this.gridWrapperComponent.setContextMenuItems();
  }

  private insertHoliday(
    updatedHolidayTemplateModel: HolidayTemplateModel,
    holidayName: string,
    holidayDate: Date,
  ): void {
    const holidayTemplateNameModel = new HolidayTemplateNameModel();
    holidayTemplateNameModel.id = generateDummyId([...updatedHolidayTemplateModel.holidays]);
    holidayTemplateNameModel.name = holidayName;
    holidayTemplateNameModel.date = holidayDate;
    updatedHolidayTemplateModel.holidays = [
      holidayTemplateNameModel,
      ...updatedHolidayTemplateModel.holidays,
    ];
  }

  private updateHoliday(
    updatedHolidayTemplateModel: HolidayTemplateModel,
    updatedHolidayName: string,
    updatedHolidayDate: Date,
    holidayId: number,
  ) {
    const editedHolidayIndex = updatedHolidayTemplateModel.holidays.findIndex(
      (holiday) => holiday.id === holidayId,
    );
    if (editedHolidayIndex < 0) {
      throw new Error('Holiday could not be found.');
    }
    updatedHolidayTemplateModel.holidays[editedHolidayIndex].name = updatedHolidayName;
    updatedHolidayTemplateModel.holidays[editedHolidayIndex].date = updatedHolidayDate;
  }

  private removeHoliday(holidayId: number) {
    this.subs.sink = this.holidaySettingsStore
      .selectSingleHolidayTemplate(this.holidayListId)
      .pipe(take(1))
      .subscribe((holidayTemplateModel) => {
        const clonedHolidayTemplateModel = cloneDeep(holidayTemplateModel);
        const clonedHolidays = cloneDeep([...clonedHolidayTemplateModel.holidays]);
        const removedHolidayIndex = clonedHolidayTemplateModel.holidays.findIndex(
          (holiday) => holiday.id === holidayId,
        );
        if (removedHolidayIndex < 0) {
          throw new Error('Holiday could not be found.');
        }
        clonedHolidays.splice(removedHolidayIndex, 1);
        clonedHolidayTemplateModel.holidays = clonedHolidays;
        this.holidays = [...clonedHolidayTemplateModel.holidays];
        Logger.debug('Template updated in upsertHoliday()', clonedHolidayTemplateModel);
        this.holidaySettingsStore.updateHolidayTemplate(clonedHolidayTemplateModel);
        this.holidaySettingsStore.userOperationConducted.next(true);
      });
  }

  private buildDialog(userOperation: UserOperation): DialogRef {
    return this.shiveDialogService.open({
      content: HolidaySettingsHolidayDialogComponent,
      width: 572,
      title: userOperation === UserOperation.Create ? 'Feiertag hinzufügen' : 'Feiertag editieren',
    });
  }
}
