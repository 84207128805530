export class TimeBlockGeometryData {
  public top = -1; // The distance of the top edge of the calendar body to the top edge of the time block.
  public left = -1; // The distance of the left edge of the calendar body to the left edge of the time block.
  public right = -1; // The distance of the right edge of the calendar body to the left edge of the time block.
  public width = -1;
  public height = -1;
  public bottom = -1; // The distance of the bottom edge of the calendar body to the bottom edge of the time block.
  // The distance of the left edge of the time block to the left edge of the surrounding container lane.
  public containerLeftEdgeToTimeBlockLeftEdge = 0;
  public calendarTopEdgeToTimeBlockBottomEdge = 0; // The offset from the top edge of the calendar to the bottom edge of the block.
  public calendarBottomEdgeToTimeBlockTopEdge = 0; // The offset from the bottom edge of the calendar to the top edge of the block.
}
