<div class="dialog-content k-d-flex k-align-items-center">
  <fa-icon class="turquoise k-mr-5" [icon]="faTriangleExclamation" size="2xl"></fa-icon>
  <div>ACHTUNG: Es gibt ungespeicherte Änderungen. Sollen die Änderungen verworfen werden?</div>
</div>

<footer class="k-d-flex k-justify-content-between">
  <button (click)="cancel()" kendoButton size="large" themeColor="secondary" class="k-mr-4 !k-pr-4">
    <span class="custom-symbol close k-mr-3"></span>
    <span class="label">Schließen</span>
  </button>

  <button kendoButton (click)="confirm()" class="!k-pr-4" themeColor="base" size="large">
    <fa-icon class="update text-white k-mr-3" [icon]="faCircleCheck"></fa-icon>
    <span>Verwefen</span>
  </button>
</footer>
