import {
  ElementRef,
  Injectable,
  Renderer2,
  RendererFactory2,
  RendererStyleFlags2,
} from '@angular/core';
import { CssAttributes } from './css-attributes';
import { DragPart } from '../interaction/movement/drag-and-resize/drag-part';
import { ITimeBlockComponentItem } from '../time-block-component-items';
import { TimeBlockRenderer } from './time-block-renderer';
import {
  TimeBlockDayOrWeekFulldayType,
  TimeBlockDayOrWeekInnerdayType,
  TimeBlockMonthFulldayType,
  TimeBlockMonthInnerdayType,
} from '../../../../../core/models/timeblock/time-block-view-type.model';
import { CalendarService } from '../../../services/calendar.service';
import { DayOrWeekCalendarModel } from '../../../../../core/models/calendar/day-or-week-calendar.model';

@Injectable()
export class TimeBlockRenderService {
  private readonly renderer: Renderer2;
  private readonly timeBlockRenderer: TimeBlockRenderer;

  constructor(
    private readonly rendererFactory: RendererFactory2,
    private readonly calendarService: CalendarService,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.timeBlockRenderer = new TimeBlockRenderer(this.renderer, calendarService);
  }

  public getAttribute(natEl: Element, attrKey: string): string {
    return natEl.getAttribute(attrKey);
  }

  public setStyle(
    natEl: unknown | HTMLElement,
    attrKey: string,
    attrVal: string,
    flags?: RendererStyleFlags2,
  ): void {
    this.renderer.setStyle(natEl, attrKey, attrVal, flags);
  }

  public removeStyle(natEl: unknown | HTMLElement, attrKey: string): void {
    this.renderer.removeStyle(natEl, attrKey);
  }

  public addClass(natEl: unknown | HTMLElement, cssClass: string): void {
    this.renderer.addClass(natEl, cssClass);
  }

  public removeClass(natEl: unknown | HTMLElement, cssClass: string): void {
    this.renderer.removeClass(natEl, cssClass);
  }

  public insertBefore(
    parent: unknown,
    newChild: unknown,
    refChild: unknown,
    isMove?: boolean,
  ): void {
    this.renderer.insertBefore(parent, newChild, refChild, isMove);
  }

  public moveElement(natEl: unknown | HTMLElement, x = 0, y = 0): void {
    const transformProp =
      'transform' in document.body.style
        ? 'transform'
        : 'webkitTransform' in document.body.style
          ? 'webkitTransform'
          : 'mozTransform' in document.body.style
            ? 'mozTransform'
            : 'oTransform' in document.body.style
              ? 'oTransform'
              : 'msTransform' in document.body.style
                ? 'msTransform'
                : null;

    if (transformProp) {
      this.setStyle(natEl, transformProp, `translate3d(${x}px, ${y}px, 0)`);
    } else {
      // / Fallback
      this.setStyle(natEl, CssAttributes.left, `${x}px`);
      this.setStyle(natEl, CssAttributes.top, `${y}px`);
    }
  }

  public toggleCalendarBodyClass(
    enable: boolean,
    dragPart: DragPart,
    calendarBodyElementRef: ElementRef,
  ): void {
    const cssClass =
      dragPart === DragPart.Top || dragPart === DragPart.Bottom || dragPart === DragPart.TopOrBottom
        ? 'resize'
        : 'drag';
    if (calendarBodyElementRef) {
      if (enable) {
        this.renderer.addClass(calendarBodyElementRef.nativeElement, cssClass);
      } else {
        this.renderer.removeClass(calendarBodyElementRef.nativeElement, cssClass);
      }
    }
  }

  public renderTimeBlock(timeBlock: ITimeBlockComponentItem): void {
    // Vertical block
    if (timeBlock.timeBlockModel.timeBlockViewType instanceof TimeBlockDayOrWeekInnerdayType) {
      // For week view calendar body
      this.timeBlockRenderer.renderDayOrWeekViewInnerdayTimeBlock(timeBlock);
    } else if (
      timeBlock.timeBlockModel.timeBlockViewType instanceof TimeBlockDayOrWeekFulldayType
    ) {
      // Horizontal block for full day and week
      const calModel = this.calendarService.model as DayOrWeekCalendarModel;
      this.timeBlockRenderer.renderDayOrWeekViewFulldayTimeBlock(
        timeBlock,
        calModel.fulldayCalendarModel.geometryData.calendarBodyWidth,
      );
    } else if (timeBlock.timeBlockModel.timeBlockViewType instanceof TimeBlockMonthFulldayType) {
      this.timeBlockRenderer.renderMonthViewFulldayTimeBlock(timeBlock);
    } else if (timeBlock.timeBlockModel.timeBlockViewType instanceof TimeBlockMonthInnerdayType) {
      this.timeBlockRenderer.renderMonthViewInnerdayTimeBlock(timeBlock);
    } else {
      throw new Error('Time block view type not supported.');
    }
  }
}
