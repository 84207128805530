import { CustomError } from '../../shared/data-types/client-error';

export function assert(condition: boolean, message?: string): void {
  if (!message) {
    message = 'No message provided.';
  }

  if (!condition) {
    throw new CustomError('Error', `Assertion failed: ${message}`);
  }
}
