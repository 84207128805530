import { Expose, Transform, Type } from 'class-transformer';
import { DateTimeHelper } from '../../../features/calendar/util/date-time-helper';

export class ProjectBillingEntryModel {
  public id? = null;
  @Expose({ name: 'member_id' })
  public memberId? = null;
  // Task: Billable value is either the all-in price or the hourly rate of the task to be invoiced.
  // Assignee: Billable value is the hourly rate of the assignees to be invoiced.
  @Expose({ name: 'billable_value' })
  @Type(() => Number)
  public billableValue = -1;
  // For new tasks, the negative task id is sent to the server.
  @Expose({ name: 'task_id' })
  public taskId? = null;
  @Expose({ name: 'starting_from' })
  @Transform(({ value }) => DateTimeHelper.truncateTime(value), { toPlainOnly: true })
  @Transform(({ value }) => new Date(value), { toClassOnly: true })
  public startingFrom: Date;
}
