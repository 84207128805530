import { Injectable } from '@angular/core';
import { BillingEntryService } from '../manage-project/project-calculation-form/services/billing-entry.service';
import { BudgetEntryService } from '../manage-project/project-calculation-form/services/budget-entry.service';
import { ProjectModel } from '../../../../../core/models/project/project.model';
import {
  BillingEntry,
  MemberGridRow,
  TaskGridRow,
} from '../../../../../shared/data-types/project-types';
import { assert } from '../../../../../core/assert/assert';
import { ProjectBillingEntryModel } from '../../../../../core/models/project/project-billing.model';
import { ProjectBudgetEntryModel } from '../../../../../core/models/project/project-budget.model';
import {
  BillableRateOptions,
  BudgetOptions,
} from '../../../../../core/data-repository/dropdown-constants';

@Injectable({
  providedIn: 'root',
})
export class ProjectBillingBudgetTransformerService {
  constructor(
    public readonly billingEntryService: BillingEntryService,
    public readonly budgetEntryService: BudgetEntryService,
  ) {}

  public transformBillingAndBudgetEntriesToMemberGridData(
    gridData: MemberGridRow[],
    project: ProjectModel,
  ): void {
    project.members.forEach((member, index) => {
      // set billing
      if (project.billingType === BillableRateOptions.BillableHourlyValuePerMember) {
        const entriesForMember = project.projectBillingEntries.filter(
          (entry) => entry.memberId === member.id,
        );
        gridData[index].billingRateHistory = entriesForMember.map(
          (projectBillingEntryModel, index) => {
            return this.billingEntryService.createBillingEntry(
              projectBillingEntryModel.billableValue,
              projectBillingEntryModel.startingFrom,
              index,
            );
          },
        );
      }

      // set budget
      if (project.budgetType === BudgetOptions.HoursPerMember) {
        const budgetEntries = project.projectBudgetEntries.filter(
          (entry) => entry.memberId === member.id,
        );
        assert(budgetEntries.length === 1);
        gridData[index].budgetHours = budgetEntries[0].hours;
      }
    });
  }

  public transformMemberGridDataToBillingAndBudgetEntries(
    gridData: MemberGridRow[],
  ): [ProjectBillingEntryModel[], ProjectBudgetEntryModel[]] {
    const billingEntries: ProjectBillingEntryModel[] = [];
    const budgetEntries: ProjectBudgetEntryModel[] = [];

    gridData.forEach((memberGridRow) => {
      memberGridRow.billingRateHistory.forEach((billingRateEntry) => {
        const entry = this.billingEntryService.createBillingEntryModel(
          billingRateEntry.billingRate,
          billingRateEntry.startingFrom,
          memberGridRow.member.id,
          'memberId',
        );
        billingEntries.push(entry);
      });

      const entry = this.budgetEntryService.createBudgetEntry(
        memberGridRow.budgetHours,
        'hours',
        null,
        memberGridRow.member.id,
      );
      budgetEntries.push(entry);
    });
    return [billingEntries, budgetEntries];
  }

  public transformBillingEntriesBillableHourlyValueFixed(project: ProjectModel): BillingEntry[] {
    if (
      project.billingType !== BillableRateOptions.BillableHourlyValueFixed &&
      project.billingType !== BillableRateOptions.FixedFeePerProject
    ) {
      return [this.billingEntryService.createBillingEntry(0, null, 0)];
    }
    return project.projectBillingEntries.map((projectBillingEntry, index) => {
      return this.billingEntryService.createBillingEntry(
        projectBillingEntry.billableValue,
        projectBillingEntry.startingFrom,
        index,
      );
    });
  }

  public transformHourlyFixedToBillingEntries(
    billingHistory: BillingEntry[],
  ): ProjectBillingEntryModel[] {
    return billingHistory.map((billingEntry) => {
      return this.billingEntryService.createBillingEntryModel(
        billingEntry.billingRate,
        billingEntry.startingFrom,
        null,
        null,
      );
    });
  }

  public transformBillingAndBudgetEntriesToTaskGridData(
    gridData: TaskGridRow[],
    project: ProjectModel,
  ): void {
    project.tasks.forEach((task, index) => {
      // set billing
      if (
        project.billingType === BillableRateOptions.BillableHourlyValuePerTask ||
        project.billingType === BillableRateOptions.BillableValuePerTask
      ) {
        const entriesForTask = project.projectBillingEntries.filter(
          (entry) => entry.taskId === task.id,
        );
        gridData[index].billingRateHistory = entriesForTask.map((x, entryIndex) => {
          return this.billingEntryService.createBillingEntry(
            x.billableValue,
            x.startingFrom,
            entryIndex,
          );
        });
      }

      // set budget
      const budgetEntries = project.projectBudgetEntries.filter(
        (entry) => entry.taskId === task.id,
      );
      if (project.budgetType === BudgetOptions.CostsPerTask) {
        assert(budgetEntries.length === 1);
        gridData[index].budgetCosts = budgetEntries[0].costs;
      } else if (project.budgetType === BudgetOptions.HoursPerTask) {
        assert(budgetEntries.length === 1);
        gridData[index].budgetHours = budgetEntries[0].hours;
      }
    });
  }

  public transformTaskGridDataToBillingAndBudgetEntries(
    gridData: TaskGridRow[],
    budgetCostsPerTask: boolean,
  ): [ProjectBillingEntryModel[], ProjectBudgetEntryModel[]] {
    const billingEntries: ProjectBillingEntryModel[] = [];
    const budgetEntries: ProjectBudgetEntryModel[] = [];

    gridData.forEach((taskGridRow) => {
      taskGridRow.billingRateHistory.forEach((billingRateEntry) => {
        const entry = this.billingEntryService.createBillingEntryModel(
          billingRateEntry.billingRate,
          billingRateEntry.startingFrom,
          taskGridRow.task.id,
          'taskId',
        );
        billingEntries.push(entry);
      });

      const budgetValue = budgetCostsPerTask ? taskGridRow.budgetCosts : taskGridRow.budgetHours;
      const budgetType = budgetCostsPerTask ? 'costs' : 'hours';
      const entry = this.budgetEntryService.createBudgetEntry(
        budgetValue,
        budgetType,
        taskGridRow.task.id,
        null,
      );
      budgetEntries.push(entry);
    });
    return [billingEntries, budgetEntries];
  }
}
