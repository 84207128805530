import { Injectable } from '@angular/core';
import { CalendarService } from '../../../services/calendar.service';
import { TimeBlockComponentHandlerService } from '../generation/time-block-component-handler.service';
import { TimeBlockHttpService } from '../../../../../core/state/time-blocks/time-block-http.service';
import { ITimeBlockComponentItem } from '../time-block-component-items';
import { TimeBlockStructureService } from '../time-block-structure/time-block-structure.service';
import { Observable, Subject } from 'rxjs';
import { TimeBlockItemBuilderService } from '../generation/time-block-item-builder.service';
import { CalendarEvents } from '../../../../../shared/data-types/calendar-types';
import { Store } from '@ngrx/store';
import { selectTimeBlocksForPeriod } from '../../../../../core/state/time-blocks/time-blocks.selectors';
import { take } from 'rxjs/operators';
import { purgeTimeBlockStore } from '../../../../../core/state/time-blocks/time-blocks.actions';
import { TimeBlockType } from '../../../../../shared/data-types/time-block-types';
import { TimeBlocksState } from '../../../../../core/state/time-blocks/time-blocks.reducer';

/**
 * This service is used for user interactions when changing time blocks. Changes are reflected in the UI.
 */
@Injectable()
export class TimeBlockCrudService {
  public reloadTimeBlocks$ = new Subject<boolean>();
  public timeBlocksFetched$ = new Subject<
    [Map<string, ITimeBlockComponentItem[]>, Map<string, ITimeBlockComponentItem[]>]
  >(); // / Fired as soon as time blocks have been fetched from the server.

  constructor(
    private readonly calendarService: CalendarService,
    private readonly timeBlockComponentHandlerService: TimeBlockComponentHandlerService,
    private readonly timeBlockStructureService: TimeBlockStructureService,
    private readonly timeBlockItemBuilderService: TimeBlockItemBuilderService,
    private readonly store: Store<TimeBlocksState>,
    private readonly timeBlockHttpService: TimeBlockHttpService,
  ) {}

  public insertTimeBlock(toBeAddedTimeBlock: ITimeBlockComponentItem): void {
    // Build the time block parts which are composed to a full time block from start to end
    const partArr = this.timeBlockItemBuilderService.buildTimeBlockParts(toBeAddedTimeBlock);

    if (!partArr) {
      throw new Error('Time block parts could not be built.');
    }

    partArr.forEach((part) => {
      // Clone the original time block
      const clonedComponent = part.clone(this.timeBlockItemBuilderService);

      // Build the Angular time block component.
      this.timeBlockComponentHandlerService.TimeBlockComponentBuilder.buildComponent(
        clonedComponent,
      );

      // Insert the new time block into the calendar model.
      clonedComponent.insertIntoCalendar(this.timeBlockStructureService);
      this.calendarService.emitCalendarChange(
        this.calendarService.model,
        CalendarEvents.AddedTimeBlock,
        clonedComponent,
      );
    });
  }

  public replaceTimeBlock(toBeReplacedTimeBlock: ITimeBlockComponentItem): void {
    // Replace the time block in the calendar model.
    toBeReplacedTimeBlock.replaceInCalendar(this.timeBlockStructureService);
    this.calendarService.emitCalendarChange(
      this.calendarService.model,
      CalendarEvents.ReplacedTimeBlock,
      toBeReplacedTimeBlock,
    );
  }

  public removeTimeBlock(
    toBeRemovedTimeBlock: ITimeBlockComponentItem,
    timeBlockType = TimeBlockType.ExistingBlock,
  ): void {
    const timeBlockParts = this.timeBlockStructureService.retrieveTimeBlockPart(
      toBeRemovedTimeBlock.id,
      timeBlockType,
      -1,
    ) as ITimeBlockComponentItem[];

    if (!timeBlockParts) {
      throw new Error('Time block parts could not be retrieved.');
    }

    timeBlockParts.forEach((tb) => {
      tb.removeFromCalendar(this.timeBlockStructureService);
      this.calendarService.emitCalendarChange(
        this.calendarService.model,
        CalendarEvents.RemovedTimeBlock,
        tb,
      );
    });
  }

  /**
   * Only fetches the first part (i.e. part 0) of each time block. To retrieve all time block parts of a time block, use the
   * retrieveTimeBlockPart() function in the time block structure services.
   */
  public fetchTimeBlocks(
    start: Date,
    end: Date,
    selectedUserIds: number[],
    fromStore: boolean,
  ): Observable<readonly ITimeBlockComponentItem[]> {
    if (fromStore) {
      return this.store.select(selectTimeBlocksForPeriod(start, end)).pipe(take(1));
    }
    return this.timeBlockHttpService.fetchTimeBlocks(start, end, selectedUserIds);
  }

  public purgeTimeBlockStore(): void {
    this.store.dispatch(purgeTimeBlockStore());
  }
}
