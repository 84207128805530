import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DateTimeHelper } from '../../../features/calendar/util/date-time-helper';
import { TimeBlocksState, timeBlockAdapter } from './time-blocks.reducer';

export const timeBlocksKey = 'timeBlocks';

export const selectTimeBlocks = createSelector(
  createFeatureSelector(timeBlocksKey),
  (timeBlockState: TimeBlocksState) => {
    return timeBlockAdapter.getSelectors().selectAll(timeBlockState);
  },
);

export const selectTimeBlocksForPeriod = (start: Date, end: Date) =>
  createSelector(selectTimeBlocks, (timeBlocks) => {
    return timeBlocks.filter((timeBlock) => {
      return (
        DateTimeHelper.isWithinInterval(timeBlock.timeBlockModel.start, { start, end }) ||
        DateTimeHelper.isWithinInterval(timeBlock.timeBlockModel.end, { start, end }) ||
        DateTimeHelper.areIntervalsOverlapping(
          { start: timeBlock.timeBlockModel.start, end: timeBlock.timeBlockModel.end },
          { start, end },
        )
      );
    });
  });
