<app-page-header>
  <ng-template #headerBarControlsTemplate>
    <!-- Template ref Needed for content child directive -->
    <button
      kendoButton
      class="k-ml-22 !k-pr-4"
      themeColor="secondary"
      [routerLink]="['/' + ShiveRoutes.Projects, ShiveRoutes.Create]"
      size="large"
    >
      <span class="custom-symbol add turquoise k-mr-3"></span>
      Neues Projekt
    </button>
  </ng-template>
</app-page-header>

<div class="card-wrapper k-mt-4">
  <div class="card">
    <div class="card-body">
      <div id="projects-table-wrapper" class="k-w-full">
        <app-shive-grid #gridWrapperComponent [grid]="grid">
          <kendo-grid
            #grid
            [kendoGridBinding]="listProjectDataService.gridData$ | async"
            kendoGridSelectBy="id"
            (cellClick)="onCellClick($event)"
            scrollable="none"
            size="medium"
            [pageSize]="20"
            [selectable]="{ enabled: true, checkboxOnly: true }"
            [columnMenu]="{ filter: true }"
          >
            <kendo-grid-checkbox-column
              [width]="60"
              [headerClass]="{ 'k-text-center': true }"
              [class]="{ 'k-text-center': true }"
              [resizable]="false"
              [columnMenu]="false"
              [showSelectAll]="true"
            ></kendo-grid-checkbox-column>

            <kendo-grid-column field="project_name" title="Projektname">
              <ng-template kendoGridCellTemplate let-project>
                <div class="k-d-flex">
                  <div
                    class="color-indicator k-mr-3"
                    [style.background-color]="project.color.value"
                  ></div>
                  <div class="project-name">{{ project.name }}</div>
                </div>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="project_client" title="Kunde">
              <ng-template kendoGridCellTemplate let-project>
                <div class="project-client">{{ project.client?.name }}</div>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="contextMenu" title="" [columnMenu]="false" [width]="60">
              <ng-template kendoGridCellTemplate let-dataItem>
                <app-context-menu-anchor
                  [dataItem]="dataItem"
                  [gridWrapperComponent]="gridWrapperComponent"
                />
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </app-shive-grid>
      </div>
    </div>
  </div>
</div>
