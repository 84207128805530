import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DragPart } from '../../interaction/movement/drag-and-resize/drag-part';
import { ITimeBlockComponentItem } from '../../time-block-component-items';
import * as svgIcons from '@progress/kendo-svg-icons';
import { TimeBlockModel } from '../../../../../../core/models/timeblock/time-block.model';
import { CalendarView } from '../../../../../../shared/data-types/calendar-types';

@Component({
  selector: 'app-drag-edge-template',
  templateUrl: './drag-edge-template.component.html',
  styleUrls: ['./drag-edge-template.component.scss'],
})
export class DragEdgeTemplateComponent {
  @ViewChild('dragEdge') public dragEdge: ElementRef<HTMLElement>;
  @Input() public dragPart: DragPart;
  @Input() public calendarView: CalendarView;
  @Input() public timeBlockComponentItem: ITimeBlockComponentItem;
  @Input() public model: TimeBlockModel;
  public CalendarView = CalendarView;
  public handleDragIcon = svgIcons.handleDragIcon;
}
