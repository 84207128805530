import { Injectable, TemplateRef } from '@angular/core';
import { take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ClientModel } from '../../../../core/models/client/client.model';
import { DialogCloseResult } from '@progress/kendo-angular-dialog';
import { ShiveDialogService } from '../../../../core/services/controls/shive-dialog.service';
import { ClientDialogComponent } from '../../dialogs/client-dialog/client-dialog.component';
import { UserOperation } from '../../../../core/enums/user-operation';
import { Actions, ofType } from '@ngrx/effects';
import * as fromClientActions from '../../../../core/state/clients/clients.actions';
import { Store } from '@ngrx/store';
import { selectAllClients } from '../../../../core/state/clients/clients.selectors';
import { ClientsHttpService } from '../../../../core/state/clients/clients-http.service';

@Injectable({
  providedIn: 'root',
})
export class ListClientDataService {
  public isLoading = true;
  public gridData$: Observable<ClientModel[]> = null;
  public dialogFooter: TemplateRef<unknown> = null;

  constructor(
    private readonly shiveDialogService: ShiveDialogService,
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly clientsHttpService: ClientsHttpService,
  ) {}

  public loadGridData(): void {
    this.gridData$ = this.clientsHttpService.getClients().pipe(
      take(1),
      tap(() => (this.isLoading = false)),
    );
  }

  public openClientDeleteDialog(clientModel: ClientModel): void {
    const ref = this.shiveDialogService.open({
      cssClass: 'client-delete-dialog-wrapper',
      content: ClientDialogComponent,
      actions: this.dialogFooter,
    });

    const clientDialogComponent = ref.content.instance as ClientDialogComponent;
    clientDialogComponent.clientModel = clientModel;
    clientDialogComponent.userOperation = UserOperation.Delete;

    // Delete client
    ref.result.subscribe((result: ClientModel | DialogCloseResult) => {
      if (result instanceof DialogCloseResult) {
        return;
      }

      this.clientsHttpService.deleteClient(result);
    });
  }

  public handleClientHttpCallback(): void {
    // POST and PUT are already handled in ProjectService
    this.actions$.pipe(ofType(fromClientActions.clientDeleted)).subscribe(() => {
      this.gridData$ = this.store.select(selectAllClients);
    });
  }
}
