<app-page-header [pageTitle]="title"></app-page-header>

<ul class="tabbar nav">
  @for (tab of tabs; track tab) {
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" [routerLink]="['/' + tab.route]">{{ tab.title }}</a>
    </li>
  }
</ul>

<router-outlet></router-outlet>
