import { UrlSegmentDelimiter } from '../data-repository/css-constants';

export interface NavigationWrapper {
  title: string;
  route: string;
  previousPage?: number;
}

export class PageMap extends Map<number, NavigationWrapper> {
  public getRoute(key: number): string {
    return this.get(key).route;
  }

  public getPageKey(slug: string): number | null {
    for (const [key, value] of this.entries()) {
      if (this.compare(value.route, slug)) {
        return key;
      }
    }
    return null;
  }

  public getTitle(key: number): string {
    return this.get(key).title;
  }

  public getPreviousPage(key: number): number {
    return this.get(key).previousPage;
  }

  private compare(route: string, slug: string): boolean | never {
    const routeSegments = route.split(UrlSegmentDelimiter);
    const slugSegments = slug.split(UrlSegmentDelimiter);

    if (routeSegments.length === 0 || slugSegments.length === 0) {
      throw new Error('Could not split route or slug.');
    }

    // Both routes are different in format.
    if (routeSegments.length !== slugSegments.length) {
      return false;
    }

    if (routeSegments.length === 1) {
      return route === slug;
    }
    return routeSegments.every((routeSegment, index) => {
      return routeSegment === '*' || slugSegments[index] === routeSegment;
    });
  }
}
