<div class="card">
  <div class="card-header">
    <div class="title-row">
      <div class="title d-flex k-align-items-center">
        <h2 class="mb-0">Feiertage</h2>
      </div>
    </div>
  </div>

  <div class="card-body">
    <div class="form-wrapper">
      <div class="k-form k-d-grid">
        <div class="col">
          <app-shive-dropdownlist
            #shiveDropdownList
            [label]="'Feiertagsliste'"
            [data]="holidayTemplateOptions"
            [permittedUserOperations]="[
              UserOperation.Create,
              UserOperation.Update,
              UserOperation.Delete,
              UserOperation.Duplicate,
            ]"
            [selectedValue]="selectedOption"
            (afterValueChanged)="selectionChange($event)"
            (addBtnClicked)="upsertHolidayTemplate(UserOperation.Create)"
            (editBtnClicked)="upsertHolidayTemplate(UserOperation.Update, $event)"
            (cloneBtnClicked)="duplicateHolidayTemplate($event)"
            (removeBtnClicked)="removeHolidayTemplate($event)"
          >
          </app-shive-dropdownlist>
        </div>
      </div>
    </div>

    <div class="row gx-5 k-mt-4">
      <div id="holidays-table-wrapper" class="col-6">
        <app-holiday-settings-holiday-grid
          [holidays]="holidays"
          [holidayListId]="+selectedOption?.value"
        ></app-holiday-settings-holiday-grid>
      </div>

      <div id="map-holidays-to-user-table-wrapper" class="col-6">
        <app-holiday-settings-user-grid
          [members]="members"
          [holidayListId]="+selectedOption?.value"
        ></app-holiday-settings-user-grid>
      </div>
    </div>
  </div>
</div>
