import { Injectable } from '@angular/core';
import { ITimeBlockComponentItem } from '../time-block-component-items';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { TimeBlockModifiedResponse } from '../../../../../shared/data-types/http-response-types';
import { forEach, forOwn } from 'lodash-es';
import { TimeBlockContentType } from '../../../../../shared/data-types/time-block-types';
import { TimeBlockItemBuilderService } from '../generation/time-block-item-builder.service';
import {
  TimeBlockAbsenceModel,
  TimeBlockAppointmentModel,
  TimeBlockModel,
  TimeBlockProjectModel,
} from '../../../../../core/models/timeblock/time-block.model';
import { DateTimeHelper } from '../../../util/date-time-helper';
import { HttpOperation } from '../../../../../core/enums/http-operation';
import { CalendarService } from '../../../services/calendar.service';
import { assert } from '../../../../../core/assert/assert';

@Injectable()
export class TimeBlockHttpTransformationService {
  constructor(
    private readonly timeBlockItemBuilderService: TimeBlockItemBuilderService,
    private readonly calendarService: CalendarService,
  ) {}

  /**
   * If the end time is 00:00:00, we have to use the previous day and use the end time of this day(i.e. 23:59:59.99999) and vice versa.
   * toPreviousDay === true: If the end time is 00:00:00, we have to use the previous day and use the end time of this day(i.e. 23:59:59.99999)
   * toPreviousDay === false: If the end time is between 23:59:01 (inclusive) and 23:59:59 (inclusive),
   * we have to use the next day and set the time to 00:00:00.
   * see SW-89
   */
  public static transformEndDate(value: string | Date, toPreviousDay = true): Date {
    const end = typeof value === 'string' ? new Date(value) : value;
    return toPreviousDay
      ? DateTimeHelper.transformToNearlyMidnightIfNeeded(end)
      : DateTimeHelper.transformToExactlyMidnightIfNeeded(end, true);
  }

  public static toTimeBlockMaps(
    from: Date,
    to: Date,
    toBeTransformed: readonly ITimeBlockComponentItem[],
  ): [Map<string, ITimeBlockComponentItem[]>, Map<string, ITimeBlockComponentItem[]>] {
    const timeBlockDateArr = DateTimeHelper.eachDayOfInterval(from, to);
    const timeBlockMapFullday = new Map(
      timeBlockDateArr.map((date) => [
        DateTimeHelper.format(date),
        new Array<ITimeBlockComponentItem>(),
      ]),
    );

    const timeBlockMapInnerday = new Map(
      timeBlockDateArr.map((date) => [
        DateTimeHelper.format(date),
        new Array<ITimeBlockComponentItem>(),
      ]),
    );

    forEach(toBeTransformed, (timeBlock: ITimeBlockComponentItem) => {
      const timeBlockStartDate = DateTimeHelper.format(timeBlock.timeBlockModel.start);
      const tbMap = timeBlock.timeBlockModel.isFullday ? timeBlockMapFullday : timeBlockMapInnerday;
      const firstKeyOfMap = DateTimeHelper.parse(
        tbMap.keys().next().value,
        'yyyy-MM-dd',
        new Date(),
      );

      if (tbMap.has(timeBlockStartDate)) {
        tbMap.get(timeBlockStartDate).push(timeBlock);
      } else if (DateTimeHelper.isBefore(timeBlock.timeBlockModel.start, firstKeyOfMap)) {
        const firstKeyOfMapStr = DateTimeHelper.format(firstKeyOfMap);
        tbMap.get(firstKeyOfMapStr).push(timeBlock);
      }
    });
    return [timeBlockMapFullday, timeBlockMapInnerday];
  }

  public toTimeBlockHTTPPost(timeBlockItem: ITimeBlockComponentItem): unknown {
    const timeBlockModelHTTP = instanceToPlain(timeBlockItem.timeBlockModel);

    if ('id' in timeBlockModelHTTP) {
      delete timeBlockModelHTTP.id;
    }

    if ('userId' in timeBlockModelHTTP) {
      delete timeBlockModelHTTP.user;
    }

    return timeBlockModelHTTP;
  }

  public toTimeBlockHTTPPut(timeBlockItem: ITimeBlockComponentItem): unknown {
    const timeBlockModelHTTP = instanceToPlain(timeBlockItem.timeBlockModel);

    if ('componentRef' in timeBlockModelHTTP) {
      delete timeBlockModelHTTP.componentRef;
    }

    if ('color' in timeBlockModelHTTP) {
      delete timeBlockModelHTTP.color;
    }

    if ('userId' in timeBlockModelHTTP) {
      delete timeBlockModelHTTP.user;
    }

    return timeBlockModelHTTP;
  }

  public toTimeBlockModifiedResponse(
    response,
    timeBlockContentType: TimeBlockContentType,
    httpOperation: HttpOperation,
  ): TimeBlockModifiedResponse {
    const modified = response.timeblocks;
    const responseCode = response.responsecode.code;

    const modifiedTimeBlockItems = this.toTimeBlockItems(
      modified,
      timeBlockContentType,
      httpOperation,
    );

    return {
      modifiedTimeBlockItems,
      responseCode,
    };
  }

  public toTimeBlockItems(
    timeBlocks: unknown[],
    timeBlockContentType: TimeBlockContentType,
    httpOperation: HttpOperation,
  ): ITimeBlockComponentItem[] {
    const timeBlockArr = new Array<ITimeBlockComponentItem>();
    forOwn(timeBlocks, (timeBlock) => {
      let modifiedTimeBlockModel: TimeBlockModel;
      if (timeBlockContentType === TimeBlockContentType.Project) {
        modifiedTimeBlockModel = plainToInstance(TimeBlockProjectModel, timeBlock);
      } else if (timeBlockContentType === TimeBlockContentType.Absence) {
        modifiedTimeBlockModel = plainToInstance(TimeBlockAbsenceModel, timeBlock);
      } else if (timeBlockContentType === TimeBlockContentType.Appointment) {
        modifiedTimeBlockModel = plainToInstance(TimeBlockAppointmentModel, timeBlock);
      } else {
        throw new Error('Time block content type not supported');
      }

      const tbItem = this.timeBlockItemBuilderService.buildFromExisting(
        modifiedTimeBlockModel,
        timeBlockContentType,
      );

      assert(
        !tbItem.timeBlockModel.isActive || !this.calendarService.model.shiveTrackerActive,
        'Active time block found, although the tracker is active.',
      );

      // For an initial loaded active time block, the end time returned from the server stays the same. Therefore, initialize it with
      // the correct end date and time.
      if (tbItem.timeBlockModel.isActive && httpOperation === HttpOperation.Get) {
        const now = DateTimeHelper.setSecondsAndMillisToZero(new Date());

        const start = tbItem.timeBlockModel.start;
        const differenceInMinutes = DateTimeHelper.differenceInMinutes(start, now);
        const minimumTimeBlockDuration =
          this.calendarService.model.calendarProperties.minimumTimeBlockDuration;

        // If the difference between the start time and the current time is too small, add the minimum time block duration to the start time.
        if (differenceInMinutes < minimumTimeBlockDuration) {
          tbItem.timeBlockModel.end = DateTimeHelper.addMinutes(
            tbItem.timeBlockModel.start,
            minimumTimeBlockDuration,
          );
        } else {
          tbItem.timeBlockModel.end = now;
        }
      }

      timeBlockArr.push(tbItem);
    });

    return timeBlockArr;
  }
}
