@if (!hideTitleBar) {
  <app-page-header [pageTitle]="title"></app-page-header>
}

<!-- Base data -->
<div class="card-wrapper">
  <div class="card">
    <div class="card-header">
      <div class="title-row">
        <div class="title k-d-flex k-align-items-center">
          <h2 class="mb-0">Projektdaten</h2>
        </div>
      </div>
    </div>

    <div class="card-body">
      <app-project-base-form [projectStatuses]="projectStatuses" />
    </div>
  </div>
</div>

<!-- Project Calculation -->
<div class="card-wrapper k-mt-10">
  <div class="card">
    <div class="card-header">
      <div class="title-row">
        <div class="title k-d-flex k-align-items-center">
          <h2 class="mb-0">Projektkalkulation</h2>
        </div>
      </div>
    </div>

    <div class="card-body">
      <app-project-calculation-form
        (selectedBillableRateOptionChanged$)="this.selectedBillableRateOption = $event"
        (selectedBudgetOptionChanged$)="this.selectedBudgetOption = $event"
      />
    </div>
  </div>
</div>

<!-- Project Members -->
<div class="card-wrapper k-mt-10">
  <div class="card">
    <div class="card-header">
      <div class="title-row">
        <div class="title k-d-flex k-align-items-center">
          <h2 class="mb-0">Projektmitglieder</h2>
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="table-wrapper">
        <app-project-member-grid
          [selectedBillableRateOption]="selectedBillableRateOption"
          [selectedBudgetOption]="selectedBudgetOption"
          (assigneeRemovedEmitter$)="removedAssigneeId = $event"
        >
        </app-project-member-grid>
      </div>
    </div>
  </div>
</div>

<!-- Tasks -->
<div class="card-wrapper k-mt-10">
  <div class="card">
    <div class="card-header">
      <div class="title-row">
        <div class="title k-d-flex k-align-items-center">
          <h2 class="k-mb-0">Aufgaben</h2>
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="table-wrapper">
        <app-project-task-grid
          [selectedBillableRateOption]="selectedBillableRateOption"
          [selectedBudgetOption]="selectedBudgetOption"
          [removedAssigneeId]="removedAssigneeId"
        >
        </app-project-task-grid>
      </div>
    </div>
  </div>
</div>

<app-page-footer>
  <ng-template #controlBarTemplate>
    <!-- Local reference is needed -->
    <button kendoButton (click)="cancel()" themeColor="secondary" size="large">Abbrechen</button>
    <button
      class="ms-3"
      (click)="save()"
      kendoButton
      [disabled]="saveButtonDisabled"
      themeColor="base"
      size="large"
    >
      <span class="custom-symbol save k-mr-3"></span>
      @if (userOperation === UserOperation.Create) {
        <span>Speichern</span>
      } @else {
        <span>Aktualisieren</span>
      }
    </button>
  </ng-template>
</app-page-footer>
