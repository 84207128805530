import { Component, ContentChild, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss'],
})
export class PageFooterComponent {
  @ContentChild('controlBarTemplate')
  public controlBarTemplate: TemplateRef<unknown>;
}
