@if (user !== undefined) {
  <div class="k-d-inline-flex k-align-items-center">
    <div class="shared-member-template-avatar">
      <ng-template *ngTemplateOutlet="avatar; context: { user }"></ng-template>
    </div>
    <div class="k-ml-4">
      <div>{{ user | memberName }}</div>
    </div>
  </div>
}

<ng-template #avatar let-user="user">
  @if (user !== null && user.id >= 0) {
    <img
      [alt]="user.firstName + ' ' + user.lastName"
      [src]="user.avatar | safeHtml: SecurityContext.RESOURCE_URL"
    />
  } @else {
    <fa-icon
      class="icon k-rounded-full k-d-flex k-align-items-center justify-content-center"
      [icon]="faUserVneck"
    ></fa-icon>
  }
</ng-template>
