import { Injectable } from '@angular/core';
import { UserModel } from '../../models/user/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  // Function that builds a unassigned user model if null is passed. A default user model has an id of -1.
  // Note: If a default user is sent to the server, it needs to be null.
  public static buildUnassignedUser(): UserModel {
    const defaultUser = new UserModel();
    // Set -1 as dummy id since null leads to problems with the dropdowns.
    defaultUser.id = -1;
    return defaultUser;
  }

  public filterAlreadyAddedMembers(
    allMembers: readonly UserModel[],
    projectMembers: readonly UserModel[],
  ): UserModel[] {
    return allMembers.filter(
      (member) => projectMembers.findIndex((element) => element.id === member.id) < 0,
    );
  }
}
