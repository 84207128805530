import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { SubSink } from 'subsink';
import { DragPart } from './interaction/movement/drag-and-resize/drag-part';
import { ITimeBlockComponentItem } from './time-block-component-items';
import { CalendarService } from '../../services/calendar.service';
import { TimeBlockGeometryService } from './calculation/geometry/time-block-geometry.service';
import { CalendarEvents, CalendarView } from '../../../../shared/data-types/calendar-types';
import { DragHandlerService } from './interaction/movement/drag-and-resize/drag-handler.service';
import { TimeBlockItemBuilderService } from './generation/time-block-item-builder.service';
import { BaseCalendarModel } from '../../../../core/models/calendar/base-calendar.model';
import { TimeBlockDragResizeService } from './interaction/movement/drag-and-resize/time-block-drag-resize-handling/time-block-drag-resize.service';
import {
  TimeBlockContentType,
  TimeBlockType,
} from '../../../../shared/data-types/time-block-types';
import {
  TimeBlockDayOrWeekInnerdayType,
  TimeBlockMonthInnerdayType,
} from '../../../../core/models/timeblock/time-block-view-type.model';
import { TimeBlockService } from './time-block.service';
import { CalendarServiceHelper } from '../../services/calendar-service-helper';
import {
  CSSFulldayTimeBlockOverflowsLeftClass,
  CSSFulldayTimeBlockOverflowsRightClass,
  CSSHasNextPart,
  CSSHasPrevPart,
  CSSHeadPart,
  CSSTrackedTimeBlock,
  FulldayTimeBlockHeightPx,
} from '../../../../core/data-repository/css-constants';
import { ColorBrightness } from '../../../../shared/data-types/color-types';
import { DragEdgeTemplateComponent } from './templates/drag-edge-template/drag-edge-template.component';
import { TimeBlockDialogService } from './services/time-block-dialog.service';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';

/**
 * Day and week inner day: Each lane has a separate time block component stored in the calendar model for each day from start to end.
 * Full day: Consists of only one time block in the calendar model from start to end.
 */
@Component({
  selector: 'app-time-block',
  templateUrl: './time-block.component.html',
  styleUrls: ['./time-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeBlockComponent implements AfterViewInit, OnDestroy {
  @ViewChild('timeBlock') public timeBlockHTMLWrapper: ElementRef<HTMLElement>;
  @ViewChildren('overflowIndicator') public overflowIndicators: QueryList<ElementRef<HTMLElement>>;
  @ViewChildren(DragEdgeTemplateComponent) dragEdgeTemplates: QueryList<DragEdgeTemplateComponent>;
  public timeBlockBody: ElementRef<HTMLElement>;
  public timeBlockComponentItem: ITimeBlockComponentItem;
  public DragPart = DragPart;
  public TimeBlockType = TimeBlockType;
  public TimeBlockContentType = TimeBlockContentType;
  public CalendarView = CalendarView;
  public calendarViewMode: CalendarView;
  public CSSFulldayTimeBlockOverflowsLeftClass = CSSFulldayTimeBlockOverflowsLeftClass;
  public CSSFulldayTimeBlockOverflowsRightClass = CSSFulldayTimeBlockOverflowsRightClass;
  public ColorBrightness = ColorBrightness;
  public fulldayTimeBlockHeight = FulldayTimeBlockHeightPx;
  public readonly CSSHasPrevPart = CSSHasPrevPart;
  public readonly CSSHasNextPart = CSSHasNextPart;
  public readonly faPenToSquare = faPenToSquare;
  public readonly CSSHeadPart = CSSHeadPart;
  public readonly CSSTrackedTimeBlock = CSSTrackedTimeBlock;

  private readonly subs = new SubSink();

  constructor(
    public cd: ChangeDetectorRef,
    public timeBlockService: TimeBlockService,
    private readonly timeBlockDragResizeService: TimeBlockDragResizeService,
    private readonly dragHandlerService: DragHandlerService,
    private readonly calendarService: CalendarService,
    private readonly timeBlockDialogService: TimeBlockDialogService,
    private readonly timeBlockItemBuilderService: TimeBlockItemBuilderService,
    private readonly timeBlockGeometryService: TimeBlockGeometryService,
  ) {}

  ngAfterViewInit(): void {
    const tbModel = this.timeBlockComponentItem.timeBlockModel;

    // Don't initialize ghost blocks, just render them.
    if (tbModel.type === TimeBlockType.GhostBlock) {
      return;
    }

    this.cd.detach();

    this.calendarViewMode = this.calendarService.model.calendarViewMode;
    // Re-render time block to update the height.
    this.cd.detectChanges();
    this.initBlock();

    // For month view inner day time blocks we don't want to drag or resize a newly created non-existing one. Just render it and open the
    // related dialog.
    if (
      tbModel.timeBlockViewType instanceof TimeBlockMonthInnerdayType &&
      tbModel.type === TimeBlockType.NonExistingBlock
    ) {
      this.timeBlockDialogService.openNewTimeBlockDialog(this.timeBlockComponentItem);
      return;
    }

    this.initEvents();
    this.initDragAndResizeBehaviour();
  }

  // Unsubscribe when the component dies
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private initBlock(): void {
    const tbModel = this.timeBlockComponentItem.timeBlockModel;
    if (tbModel.type === TimeBlockType.GhostBlock) {
      return;
    }

    this.timeBlockGeometryService.calculateInitialTimeBlockGeometry(this.timeBlockComponentItem);
    this.dragHandlerService.configureDragHandlers(this.timeBlockComponentItem);
    if (!tbModel.isFullday) {
      this.timeBlockService.setTimeBlockPrevNextCSSClasses(
        this.timeBlockComponentItem,
        this.timeBlockHTMLWrapper,
      );
    }
    this.cd.detectChanges();
  }

  private initEvents(): void {
    // As soon as a user starts to drag / resize this block, update ui via change detection.
    const targetEvents = [CalendarEvents.AddedTimeBlock, CalendarEvents.ReplacedTimeBlock];
    const callback = (
      _: BaseCalendarModel,
      _1: CalendarEvents[],
      timeBlock: ITimeBlockComponentItem,
    ): void => {
      if (!timeBlock) {
        throw new Error('Time block not specified.');
      }
      const timeBlockModel = this.timeBlockComponentItem.timeBlockModel;
      if (
        timeBlockModel.type === TimeBlockType.GhostBlock ||
        timeBlock.timeBlockModel.type === TimeBlockType.GhostBlock
      ) {
        return;
      }

      if (timeBlockModel.id === timeBlock.timeBlockModel.id) {
        // Make sure we set the correct time block since the time block ids can exist multiple times.
        if (timeBlockModel.partNumber !== timeBlock.timeBlockModel.partNumber) {
          return;
        }
        // Clone the time block to immediately set the new time block geometry data.
        this.timeBlockComponentItem = timeBlock.clone(this.timeBlockItemBuilderService);
        // Add extra rendering cycle to update hours and minutes on the time blocks after drag / resize.
        this.cd.detectChanges();
      }
    };

    this.subs.sink = CalendarServiceHelper.calendarModelUpdated(
      this.calendarService,
      callback,
      targetEvents,
    );
  }

  private initDragAndResizeBehaviour(): void {
    this.timeBlockService.configureFulldayOverflowIndicators(
      this.timeBlockComponentItem,
      this.overflowIndicators,
    );

    // Make non-transparent blocks draggable and resizable
    this.timeBlockDragResizeService.initialize(this.timeBlockComponentItem);

    // If block is a new block (non-existing), make it resizable
    this.makeNonExistingTimeBlockResizable();
  }

  private makeNonExistingTimeBlockResizable(): void {
    // Don't make month view inner day time blocks resizable.
    if (
      this.timeBlockComponentItem.timeBlockModel.timeBlockViewType instanceof
      TimeBlockMonthInnerdayType
    ) {
      return;
    }

    if (this.timeBlockComponentItem.timeBlockModel.type === TimeBlockType.NonExistingBlock) {
      const timeBlockDragResizeController =
        this.timeBlockDragResizeService.timeBlockDragResizeControllerService;
      timeBlockDragResizeController.TransformationTimeBlock = this.timeBlockComponentItem;

      // Vertical resizing
      if (
        this.timeBlockComponentItem.timeBlockModel.timeBlockViewType instanceof
        TimeBlockDayOrWeekInnerdayType
      ) {
        // If dragPart is already set from previous time block part, set drag part with this value.
        const dragPart =
          timeBlockDragResizeController.DragPart === DragPart.None
            ? DragPart.TopOrBottom
            : timeBlockDragResizeController.DragPart;
        this.timeBlockDragResizeService.verticalDayOrWeekResizeControllerService.resizeStarted$.next(
          dragPart,
        );
      } else {
        // Horizontal resizing
        // If dragPart is already set from previous time block part, set drag part with this value.
        const dragPart =
          timeBlockDragResizeController.DragPart === DragPart.None
            ? DragPart.LeftOrRight
            : timeBlockDragResizeController.DragPart;
        this.timeBlockDragResizeService.horizontalAllResizeControllerService.resizeStarted$.next(
          dragPart,
        );
      }
    }
  }
}
