import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { LinkbarService } from '../../core/ui-components/template-parts/linkbar/linkbar.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
  private readonly linkbarService = inject(LinkbarService);

  ngOnInit(): void {
    this.linkbarService.linksSet$.next(['a']);
  }

  ngOnDestroy(): void {
    this.linkbarService.linksSet$.next([]);
  }
}
