import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import * as fromColorsActions from './colors.actions';
import { map } from 'rxjs/operators';
import { ColorModel } from '../../models/color/color.model';

@Injectable({
  providedIn: 'root',
})
export class ColorsHttpService {
  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
  ) {}

  public getColors(): Observable<readonly ColorModel[]> {
    this.store.dispatch(fromColorsActions.fetchColorList());

    return this.actions$.pipe(
      ofType(fromColorsActions.setColors),
      map((result) => result.payload),
    );
  }
}
