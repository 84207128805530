import { Injectable } from '@angular/core';
import { CardinalPoints, DragDirection } from '../../drag-part';
import { TimeBlockRenderService } from '../../../../../rendering/time-block-render.service';
import {
  CollisionHandlerService,
  IntersectionType,
} from '../../../collision-handling/collision-handler.service';
import Autobind from '../../../../../../../../../shared/typescript-decorators/autobind.decorator';
import { SubSink } from 'subsink';
import { CalendarService } from '../../../../../../../services/calendar.service';
import {
  CalendarMouseHandlerService,
  MouseEventType,
} from '../../../../../../../mouse/calendar-mouse-handler.service';
import { ITimeBlockComponentItem } from '../../../../../time-block-component-items';
import { BaseCalendarModel } from '../../../../../../../../../core/models/calendar/base-calendar.model';
import { Position } from '../../../../../../../../../shared/data-structures/position';
import { TimeBlockCrudService } from '../../../../../crud/time-block-crud.service';
import { TimeBlockElementSelectorService } from '../../../../../rendering/time-block-element-selector.service';
import { TimeBlockDragResizeControllerService } from '../../time-block-drag-resize-handling/time-block-drag-resize-controller.service';
import { TimeBlockComponentHandlerService } from '../../../../../generation/time-block-component-handler.service';
import { TouchAndMouseHandlerService } from '../../../../../../../../../core/services/touch-and-mouse-handler.service';
import { CalendarEvents } from '../../../../../../../../../shared/data-types/calendar-types';
import { filter, take } from 'rxjs/operators';
import { switchMap } from 'rxjs';
import { TimeBlockStructureService } from '../../../../../time-block-structure/time-block-structure.service';
import { TimeBlockService } from '../../../../../time-block.service';
import { TimeCoordinateMappingService } from '../../../../../../../time-mapping/time-coordinate-mapping.service';
import { DateTimeHelper } from '../../../../../../../util/date-time-helper';
import {
  IDragTimeBlock,
  TimeBlockType,
} from '../../../../../../../../../shared/data-types/time-block-types';
import { CalendarServiceHelper } from '../../../../../../../services/calendar-service-helper';
import { DayOrWeekCalendarModel } from '../../../../../../../../../core/models/calendar/day-or-week-calendar.model';
import { AbstractDragController } from '../drag-and-drop/abstract-drag-controller';
import { DragDropPositioningService } from '../drag-and-drop/drag-drop-positioning.service';
import { DragAndDropHandlerService } from '../drag-and-drop/drag-and-drop-handler.service';
import { SharedHorizontalDragResizeService } from '../../time-block-drag-resize-handling/shared-horizontal-drag-resize.service';

@Injectable()
export class FreelyDayOrWeekDragControllerService
  extends AbstractDragController
  implements IDragTimeBlock
{
  public previousYCalendarCollision = false;
  private readonly subs = new SubSink();

  constructor(
    private readonly timeBlockDragResizeControllerService: TimeBlockDragResizeControllerService,
    private readonly calendarService: CalendarService,
    private readonly timeBlockService: TimeBlockService,
    private readonly calendarMouseHandlerService: CalendarMouseHandlerService,
    private readonly timeBlockCrudService: TimeBlockCrudService,
    private readonly timeBlockStructureService: TimeBlockStructureService,
    private readonly dragDropPositioningService: DragDropPositioningService,
    private readonly timeBlockRenderService: TimeBlockRenderService,
    private readonly timeBlockComponentHandler: TimeBlockComponentHandlerService,
    private readonly dragAndDropHandlerService: DragAndDropHandlerService,
    private readonly timeCoordinateMappingService: TimeCoordinateMappingService,
    private readonly collisionHandlerService: CollisionHandlerService,
    private readonly sharedDraggingResizingService: SharedHorizontalDragResizeService,
    private readonly touchAndMouseHandlerService: TouchAndMouseHandlerService,
  ) {
    super(
      timeBlockCrudService,
      timeBlockStructureService,
      dragDropPositioningService,
      timeBlockRenderService,
      timeBlockDragResizeControllerService,
      timeBlockService,
      dragAndDropHandlerService,
      sharedDraggingResizingService,
    );
    this.initEvents();
  }

  @Autobind
  public drag(event: Interact.DragEvent | number): void {
    if (!this.timeBlockDragResizeControllerService.TransformationTimeBlock?.timeBlockModel) {
      return;
    }

    let dx = 0;
    let dy = 0;
    let mouseEventType;

    // / Scroll by mouse wheel
    if (typeof event === 'number') {
      dx = 0;
      dy = event;
      mouseEventType = MouseEventType.Scroll;
    } else {
      // Move by drag
      dx = event.dx;
      dy = event.dy;
      mouseEventType = MouseEventType.Drag;
    }

    const horizontalDirection = dx <= 0 ? CardinalPoints.West : CardinalPoints.East;
    const verticalDirection = dy <= 0 ? CardinalPoints.North : CardinalPoints.South;

    this.dXAbs += dx;
    this.draggedParts.forEach((timeBlockPart) => {
      const dragData = this.buildDragDataObject(
        dx,
        dy,
        horizontalDirection,
        verticalDirection,
        mouseEventType,
        timeBlockPart,
      );

      this.processHorizontalDragging(timeBlockPart);
      this.processVerticalDragging(dragData, timeBlockPart);

      this.updateBlockData(dragData, timeBlockPart);
    });

    if (this.draggedParts.length === 1) {
      this.updateTimeBlockSchedule();
    }
  }

  /**
   * Only update the time portions (i.e. vertical dragging) of the dragging block.
   * Lane indices will be updated in the afterDragging() method.
   */
  private updateTimeBlockSchedule(): void {
    this.timeCoordinateMappingService.pixelsPerMinuteCalculated$
      .pipe(
        take(1),
        filter((ppm) => !!ppm),
      )
      .subscribe((ppm) => {
        const draggingTimeBlock = this.timeBlockDragResizeControllerService.TransformationTimeBlock;
        const timeBlockPart = this.timeBlockStructureService.retrieveTimeBlockPart(
          draggingTimeBlock.id,
          TimeBlockType.TransformingBlock,
          0,
        ) as ITimeBlockComponentItem;
        const startOfDay = DateTimeHelper.startOfDay(timeBlockPart.timeBlockModel.start);

        const geometryData = timeBlockPart.timeBlockModel.timeBlockViewType.geometryData;
        const minutesStart = timeBlockPart.timeBlockModel.timeBlockViewType.geometryData.top / ppm;
        const minutesEnd = geometryData.calendarTopEdgeToTimeBlockBottomEdge / ppm;
        draggingTimeBlock.timeBlockModel.start = DateTimeHelper.minutesToDate(
          minutesStart,
          startOfDay,
        );
        draggingTimeBlock.timeBlockModel.end = DateTimeHelper.minutesToDate(minutesEnd, startOfDay);

        this.calendarService.emitCalendarChange(
          this.calendarService.model,
          CalendarEvents.ReplacedTimeBlock,
          draggingTimeBlock,
        );
      });
  }

  private processHorizontalDragging(timeBlockPart: ITimeBlockComponentItem): void {
    if (
      !(
        CollisionHandlerService.collisionType === IntersectionType.intersectCalendarLeftEdge ||
        CollisionHandlerService.collisionType === IntersectionType.intersectCalendarRightEdge
      )
    ) {
      const timeBlockHTMLWrapper =
        TimeBlockElementSelectorService.getTimeBlockHTMLWrapper(timeBlockPart);
      this.timeBlockRenderService.moveElement(timeBlockHTMLWrapper, this.dXAbs, 0);
    }
  }

  private processVerticalDragging(
    dragData: DragData,
    timeBlockPart: ITimeBlockComponentItem,
  ): void {
    this.timeCoordinateMappingService.pixelsPerMinuteCalculated$
      .pipe(
        filter((ppm) => !!ppm),
        take(1),
      )
      .subscribe((ppm) => {
        const calendarHeight = this.calendarService.model.geometryData.calendarBodyVisibleHeight;
        // Get calendar mouse pos y without scrolling
        const mousePosY = this.timeCoordinateMappingService.toCalendarCoordinate(
          this.touchAndMouseHandlerService.currentMouseViewportPos,
        ).y;
        const calendarModel = this.calendarService.model as DayOrWeekCalendarModel;
        const snapDurationInPixels =
          calendarModel.calendarProperties.timeBlockDraggingDuration * ppm;

        // If there was a collision with one of the calendar's edges, wait until mouse cursor is within the calendar boundaries again.
        if (
          (CollisionHandlerService.collisionType === IntersectionType.intersectCalendarTopEdge &&
            mousePosY < 0) ||
          (CollisionHandlerService.collisionType === IntersectionType.intersectCalendarBottomEdge &&
            mousePosY > calendarHeight) ||
          this.draggedParts.length > 1
        ) {
          dragData.dy = 0;
          this.previousYCalendarCollision = true;
          return;
        }

        const calendarMousePosY =
          this.calendarMouseHandlerService.MouseMovePositionWrapper.mouseCalendarPosition.y;
        // The mouse cursor was outside the top or bottom edge of the calendar and just went inside again.
        if (
          this.previousYCalendarCollision &&
          !this.mouseOverTimeBlockCenterArea(timeBlockPart, dragData, calendarMousePosY)
        ) {
          // If the mouse cursor hasn't passed the half of the time block height yet, return.
          dragData.dy = 0;
          return;
        }

        // If the mouse cursor is outside the dragging time block, move it inside again.
        this.calibrate(timeBlockPart, dragData, calendarMousePosY);

        this.previousYCalendarCollision = false;
        this.updateVerticalDragData(dragData, snapDurationInPixels);
        this.collisionHandlerService.checkVerticalIntersections(timeBlockPart, dragData);
        if (
          CollisionHandlerService.collisionType === IntersectionType.intersectCalendarTopEdge ||
          CollisionHandlerService.collisionType === IntersectionType.intersectCalendarBottomEdge
        ) {
          this.restoreDragData(dragData);
        }
      });
  }

  /**
   * Check if the mouse cursor is inside the time block after it was outside the calendar borders.
   */
  private mouseOverTimeBlockCenterArea(
    timeBlockPart: ITimeBlockComponentItem,
    dragData: DragData,
    calendarMousePosY: number,
  ): boolean {
    const geometryData = timeBlockPart.timeBlockModel.timeBlockViewType.geometryData;
    const timeBlockCenterY = geometryData.top + geometryData.height / 2;
    const dragYDirection = dragData.direction.vertical;
    return (
      (dragYDirection === CardinalPoints.North && calendarMousePosY < timeBlockCenterY) ||
      (dragYDirection === CardinalPoints.South && calendarMousePosY > timeBlockCenterY)
    );
  }

  /**
   * Move the time block further in y direction so that the mouse cursor stays inside of it.
   */
  private calibrate(
    timeBlockPart: ITimeBlockComponentItem,
    dragData: DragData,
    calendarMousePosY: number,
  ) {
    if (dragData.mouseEvtType === MouseEventType.Scroll) {
      return;
    }

    const geometryData = timeBlockPart.timeBlockModel.timeBlockViewType.geometryData;
    if (
      calendarMousePosY < geometryData.top ||
      calendarMousePosY > geometryData.calendarTopEdgeToTimeBlockBottomEdge
    ) {
      dragData.dy +=
        calendarMousePosY < geometryData.top
          ? calendarMousePosY - geometryData.top
          : calendarMousePosY - geometryData.calendarTopEdgeToTimeBlockBottomEdge;
    }
  }

  /**
   * Reset drag data if a collision with the calendar's top or bottom edge occurred
   */
  private restoreDragData(dragData: DragData): void {
    if (dragData.direction.vertical === CardinalPoints.North) {
      const overflow = dragData.dragEdgePos.y;
      dragData.dy = dragData.dy - overflow;
      dragData.dragEdgePos.y = 0;
    } else {
      const overflow =
        dragData.dragEdgePos.y - this.calendarService.model.geometryData.calendarBodyHeight;
      dragData.dy -= overflow;
      dragData.dragEdgePos.y = this.calendarService.model.geometryData.calendarBodyHeight;
    }
  }

  private updateBlockData(dragData: DragData, timeBlockPart: ITimeBlockComponentItem): void {
    const geometryData = timeBlockPart.timeBlockModel.timeBlockViewType.geometryData;
    geometryData.left = dragData.dragEdgePos.x;

    const deltaY = dragData.dy;
    geometryData.top += deltaY;
    geometryData.calendarTopEdgeToTimeBlockBottomEdge += deltaY;
    geometryData.bottom -= deltaY;
    geometryData.calendarBottomEdgeToTimeBlockTopEdge -= deltaY;
  }

  private buildDragDataObject(
    dx: number,
    dy: number,
    horizontalDirection: CardinalPoints.West | CardinalPoints.East,
    verticalDirection: CardinalPoints.North | CardinalPoints.South,
    mouseEvtType: MouseEventType,
    timeBlockPart: ITimeBlockComponentItem,
  ): DragData {
    // / Don't update dragEdgePosY with dy since we need to check if vertical bound was passed (see DragSnapper class).
    const geometryData = timeBlockPart.timeBlockModel.timeBlockViewType.geometryData;
    const dragData = new DragData();
    dragData.dx = dx;
    dragData.dy = dy;
    dragData.direction.horizontal = horizontalDirection;
    dragData.direction.vertical = verticalDirection;
    dragData.blockWidth = geometryData.width;
    dragData.dragEdgePos.x = geometryData.left + dx;
    dragData.dragEdgePos.y =
      verticalDirection === CardinalPoints.North
        ? geometryData.top
        : geometryData.calendarTopEdgeToTimeBlockBottomEdge;
    dragData.mouseEvtType = mouseEvtType;

    return dragData;
  }

  /**
   * When moving the time block in y-direction, update its edge y-position.
   * A bound is a discrete step that the time block moves up or down.
   */
  private updateVerticalDragData(dragData: DragData, snapDurationInPixels: number): void {
    this.dYRelAggr += Math.abs(dragData.dy);

    // / The remainder is the amount of deviated pixels from the bound to the mouse cursor.
    // / It calculates the distance since tha last bound
    const remainder = this.dYRelAggr % snapDurationInPixels;
    const bound = Math.floor(this.dYRelAggr / snapDurationInPixels);
    const sign = dragData.direction.vertical === CardinalPoints.North ? -1 : 1;
    const difference = bound * snapDurationInPixels * sign;
    dragData.dragEdgePos.y += difference;
    dragData.dy = difference;
    this.dYRelAggr = remainder;
  }

  private initEvents(): void {
    this.subs.sink = this.calendarService.calendarViewInitialized$
      .pipe(
        filter((initialized) => initialized),
        switchMap(() => this.timeBlockService.getMinimumBlockHeight()),
      )
      .subscribe((minimumBlockHeight) => {
        if (!minimumBlockHeight) {
          return;
        }
        this.collisionHandlerService.CalendarBodyWidth =
          this.calendarService.model.geometryData.calendarBodyWidth;
        this.collisionHandlerService.CalendarBodyHeight =
          this.calendarService.model.geometryData.calendarBodyHeight;
        this.collisionHandlerService.MinimumBlockHeight = minimumBlockHeight;
      });

    // Update global time block after the original one has been altered by the user
    const targetEvents = [CalendarEvents.AddedTimeBlock, CalendarEvents.ReplacedTimeBlock];
    const callback = (
      calendarModel: BaseCalendarModel,
      _: CalendarEvents[],
      timeBlock: ITimeBlockComponentItem,
    ): void => {
      if (!this.timeBlockDragResizeControllerService.TransformationTimeBlock || !timeBlock) {
        return;
      }
    };
    this.subs.sink = CalendarServiceHelper.calendarModelUpdated(
      this.calendarService,
      callback,
      targetEvents,
    );
  }
}

export class DragData {
  public dx: number; // The distance the cursor has moved horizontally.
  public dy: number; // The distance the cursor has moved vertically.
  public direction: DragDirection = {
    horizontal: CardinalPoints.None,
    vertical: CardinalPoints.None,
  };
  public blockWidth: number;
  public dragEdgePos: Position = {
    x: 0, // Is always the left edge of the time block;
    y: 0, // Is either the top edge or the bottom edge of the block, depending on the drag direction.
  };
  public mouseEvtType: MouseEventType;
}
