@if (model) {
  @if (
    calendarView === CalendarView.DayGrid ||
    calendarView === CalendarView.WeekGrid ||
    (calendarView === CalendarView.MonthGrid && model.isFullday)
  ) {
    <div
      #dragEdge
      appTimeBlockDragResize
      [dragPart]="dragPart"
      [timeBlockId]="timeBlockComponentItem.timeBlockModel.id"
      [timeBlockPart]="timeBlockComponentItem.timeBlockModel.partNumber"
      class="resize-handle position-absolute tb"
      [ngClass]="dragPart"
      (click)="$event.stopPropagation()"
      [attr.data-drag-edge]="dragPart"
    >
      <span class="drag-indicator w-100 k-h-full text-center position-absolute tb">
        <kendo-svg-icon
          class="symbol !k-pos-absolute tb k-right-0"
          [ngClass]="
            timeBlockComponentItem.timeBlockModel.isFullday
              ? 'k-top-0 k-bottom-0'
              : 'k-rotate-90  k-left-0'
          "
          [icon]="handleDragIcon"
          size="small"
          [flip]="'vertical'"
        ></kendo-svg-icon>
      </span>
    </div>
  }
}
