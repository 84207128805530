<div class="form-wrapper">
  <form [formGroup]="budgetForm" class="k-form k-d-grid">
    <div class="col">
      <kendo-formfield class="!k-mt-0 !k-mb-0" orientation="horizontal">
        <kendo-label class="!k-align-items-start" [for]="budgetType" text="Budget"> </kendo-label>
        <kendo-dropdownlist
          textField="text"
          valueField="value"
          (selectionChange)="budgetOptionChanged($event)"
          [data]="budgetOptions"
          formControlName="budgetType"
          #budgetType
        >
        </kendo-dropdownlist>
      </kendo-formfield>
    </div>

    <div class="col">
      @if (selectedBudgetOption !== BudgetOptions.NoBudget) {
        <div>
          @if (
            selectedBudgetOption === BudgetOptions.TotalProjectFees ||
            selectedBudgetOption === BudgetOptions.TotalProjectHours
          ) {
            <div class="horizontal-controls k-d-flex">
              @if (selectedBudgetOption === BudgetOptions.TotalProjectFees) {
                <span class="prefix-currency">&euro;</span>
              }
              <kendo-formfield
                orientation="horizontal"
                class="budget-value k-d-flex k-mr-2.5 !k-mt-0 !k-mb-0"
              >
                <kendo-textbox
                  showErrorIcon="initial"
                  id="budgetValue"
                  formControlName="budgetValue"
                  [style.width.px]="100"
                  (valueChange)="budgetValueChanged($event.toString())"
                >
                </kendo-textbox>
              </kendo-formfield>
              @if (selectedBudgetOption === BudgetOptions.TotalProjectHours) {
                <span class="k-d-flex k-align-items-center k-mr-2.5">Stunden</span>
              }
            </div>
          }
          @if (
            selectedBudgetOption === BudgetOptions.HoursPerTask ||
            selectedBudgetOption === BudgetOptions.CostsPerTask ||
            selectedBudgetOption === BudgetOptions.HoursPerMember
          ) {
            <div class="text-box info k-mt-0 k-d-flex k-align-items-center">
              <div class="custom-symbol notification-info k-mr-2.5"></div>
              Hinweis:
              @if (
                selectedBudgetOption === BudgetOptions.HoursPerTask ||
                selectedBudgetOption === BudgetOptions.CostsPerTask
              ) {
                Bitte das Budget den Aufgaben in der Sektion unten zuweisen.
              }
              @if (selectedBudgetOption === BudgetOptions.HoursPerMember) {
                Bitte das Budget den Projektmitgliedern in der Sektion unten zuweisen.
              }
            </div>
          }
        </div>
      }
    </div>
  </form>
</div>
