import { NavigationWrapper } from '../../navigation-data';
import { ShiveRoutes } from '../../segments/route-segments';

export const Team: NavigationWrapper = {
  title: 'Team',
  route: ShiveRoutes.Team,
};

export const TeamList: NavigationWrapper = {
  title: 'Teamübersicht',
  route: ShiveRoutes.TeamList,
};
