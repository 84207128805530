import { Expose, Type } from 'class-transformer';

export class ProjectBudgetEntryModel {
  public id? = null;
  @Expose({ name: 'member_id' })
  public memberId? = null;
  @Expose({ name: 'project_id' })
  public projectId? = null;
  // Hours is the time budget for a task or assignee.
  @Type(() => Number)
  public hours = -1;
  // Costs is either the all-in budget or the billable rate of the task or assignee.
  @Type(() => Number)
  public costs = -1;
  // For new tasks, the negative task id is sent to the server.
  @Expose({ name: 'task_id' })
  public taskId? = null;
}
