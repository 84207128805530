import { createAction, props } from '@ngrx/store';
import { LoginModel } from '../../core/models/auth/login.model';
import { UserModel } from '../../core/models/user/user.model';
import { RegisterModel } from '../../core/models/auth/register.model';

export const fetchLoggedInUser = createAction('[Auth] Fetch Logged in user');
export const setLoggedInUser = createAction(
  '[Auth] Set logged in user',
  props<{ payload: UserModel }>(),
);

export const loginUser = createAction('[Auth] Log in user', props<{ payload: LoginModel }>());
export const userLoggedIn = createAction('[Auth] User logged in');

export const registerUser = createAction(
  '[Auth] Register user',
  props<{ payload: RegisterModel }>(),
);
export const userRegistered = createAction('[Auth] User registered');

export const logoutUser = createAction('[Auth] Logout user');
export const userLoggedOut = createAction('[Auth] User logged out');

export const unauthorized = createAction('[Auth] Unauthorized');
export const httpFail = createAction('[Auth] Http fail', props<{ payload: string }>());
