import { Exclude } from 'class-transformer';
import { IFulldayViewType, IInnerdayViewType } from '../../../shared/data-types/time-block-types';
import { TimeBlockGeometryData } from './time-block-geometry-data.model';

export class TimeBlockDayOrWeekInnerdayType implements IInnerdayViewType {
  @Exclude()
  public laneIndex = -1; // Lane index of the current selected part. lanes outside of view included (i.e. invisibleLaneIndex)

  @Exclude()
  public geometryData: TimeBlockGeometryData = null;
}

export class TimeBlockDayOrWeekFulldayType implements IFulldayViewType {
  @Exclude()
  public laneIndexStart = -1; // Lane index of the most left part. Lanes outside of view included (i.e. invisibleLaneIndex)

  @Exclude()
  public laneIndexEnd = -1; // Lane index of the most right part. Lanes outside of view included (i.e. invisibleLaneIndex)

  @Exclude()
  public geometryData: TimeBlockGeometryData = null;

  @Exclude()
  public parallelIndex = -1;
}

export class TimeBlockMonthInnerdayType implements IInnerdayViewType {
  @Exclude()
  public laneIndex = -1;

  @Exclude()
  public offsetStartDate = null;

  @Exclude()
  public geometryData: TimeBlockGeometryData = null;
}

export class TimeBlockMonthFulldayType implements IFulldayViewType {
  @Exclude()
  public laneIndexStart = -1; // A lane corresponds to a cell in the month view

  @Exclude()
  public laneIndexEnd = -1; // A lane corresponds to a cell in the month view

  @Exclude()
  public geometryData: TimeBlockGeometryData = null;

  @Exclude()
  public parallelIndex = -1;
}
