import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { CalendarMouseHandlerService } from '../../../../mouse/calendar-mouse-handler.service';
import { NewTimeBlockGenerationService } from '../../generation/new-time-block-generation.service';
import { CalendarScrollbarService } from '../../../../services/calendar-scrollbar.service';
import { CalendarView } from '../../../../../../shared/data-types/calendar-types';

@Directive({
  selector: '[appEmptyCalendarSpaceInteraction]',
})
export class EmptyCalendarSpaceInteractionDirective {
  @Input() public calendarView: CalendarView;
  @Input() public isFulldayLane: boolean;

  constructor(
    private readonly calendarMouseHandlerService: CalendarMouseHandlerService,
    private readonly newTimeBlockGenerationService: NewTimeBlockGenerationService,
    private readonly calendarScrollbarService: CalendarScrollbarService,
    private readonly element: ElementRef<HTMLElement>,
  ) {}

  @HostListener('mousedown', ['$event'])
  public mouseDown(e: MouseEvent): void | never {
    // Check if left mouse button was clicked.
    if (e.button !== 0 || !this.newTimeBlockGenerationService.readyForGeneration) {
      return;
    }

    this.newTimeBlockGenerationService.emptySpaceMouseDown$.next(true);
    // Explanation for day view / week view inner day time blocks:
    // User clicks on an empty space in the calendar.
    // First, the non-existing time block is being build.
    // Second, the time block with id NonExistingBlockGhostId can be resized by calling initDragAndResizeBehaviour() in
    // time-block.component.ts.
    // Third, the time block is being posted and added to the time block collection or removed when cancelled.
    if (!this.element.nativeElement.classList.contains('content-wrapper')) {
      e.stopPropagation();
      // Update mouseClickPositionWrapper
      this.calendarMouseHandlerService.mousePosChanged(
        e.clientX,
        e.clientY + this.calendarScrollbarService.calendarBodyScrollbar.scrollData.scrollPosY,
        e.type,
      );
      const dateString = this.element.nativeElement.getAttribute('date');
      if (!dateString) {
        throw new Error('Date attribute is not defined.');
      }

      const date = new Date(dateString);
      const containerId = +this.element.nativeElement.id;

      this.newTimeBlockGenerationService.buildUserGeneratedTimeBlock(
        this.calendarView,
        date,
        containerId,
        this.isFulldayLane,
      );
    }
  }
}
