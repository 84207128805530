import { Pipe, PipeTransform } from '@angular/core';
import { UserModel } from '../models/user/user.model';

@Pipe({
  name: 'memberName',
})
export class MemberNamePipe implements PipeTransform {
  transform(value: UserModel): string {
    if (value.id < 0) {
      return 'Nicht zugewiesen';
    }
    return `${value.firstName} ${value.lastName}`;
  }
}
