import { Component, Input, OnInit } from '@angular/core';
import { ProjectModel } from '../../../../core/models/project/project.model';

@Component({
  selector: 'app-attachment-project',
  templateUrl: './attachment-project.component.html',
  styleUrls: ['./attachment-project.component.scss'],
})
export class AttachmentProjectComponent implements OnInit {
  @Input() project: ProjectModel;
  public title = '';

  ngOnInit(): void {
    if (this.project) {
      this.title = this.project.name;
    } else {
      this.title = null;
    }
  }
}
