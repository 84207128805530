import { Injectable } from '@angular/core';
import { DateTimeHelper } from '../util/date-time-helper';
import { CalendarService } from '../services/calendar.service';
import { BehaviorSubject } from 'rxjs';
import { Position } from '../../../shared/data-structures/position';

@Injectable()
export class TimeCoordinateMappingService {
  public pixelsPerMinuteCalculated$ = new BehaviorSubject<number>(null);
  private pixelsPerMinute: number | null = null;

  constructor(private readonly calendarService: CalendarService) {
    this.calendarService.calendarViewInitialized$.subscribe((initialized) => {
      if (initialized) {
        const height = calendarService.model.geometryData.calendarBodyHeight;
        this.pixelsPerMinute = height / 1440;
        this.pixelsPerMinuteCalculated$.next(this.pixelsPerMinute);
      }
    });
  }

  public toCalendarCoordinate(worldPosition: Position): Position {
    const topOffset = this.calendarService.model.geometryData.calendarBodyOffsetTop;
    const leftOffset = this.calendarService.model.geometryData.calendarBodyOffsetLeft;
    return {
      x: worldPosition.x - leftOffset,
      y: worldPosition.y - topOffset,
    };
  }

  public toDateTime(calendarPositionY: number, ppm: number): Date {
    const minutes = calendarPositionY / ppm;
    const midnight = new Date();
    midnight.setHours(0, 0, 0, 0);
    return DateTimeHelper.minutesToDate(minutes, midnight);
  }

  /**
   * Calculate the distance between two date times in pixels.
   */
  public mapDurationToScreenLength(start: Date, end: Date): number | never {
    const pattern = 'HH:mm:ss.SSS';
    const formattedStartDate = DateTimeHelper.format(start, pattern);
    const formattedEndDate = DateTimeHelper.format(end, pattern);

    const startTime = DateTimeHelper.parse(formattedStartDate, pattern, new Date());
    const endTime = DateTimeHelper.parse(formattedEndDate, pattern, new Date());

    const minutesOffset = DateTimeHelper.differenceInMinutes(startTime, endTime);
    const ppm = this.PixelsPerMinute;
    return Math.round(minutesOffset * ppm);
  }

  public get PixelsPerMinute(): number | null | never {
    if (!this.pixelsPerMinute || isNaN(this.pixelsPerMinute)) {
      throw new Error(
        'Pixels per minute have not been calculated yet. Use the pixelsPerMinuteCalculated$ ' +
          'observable to wait for the calculation to finish.',
      );
    }
    return this.pixelsPerMinute;
  }
}
