import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { BaseCalendarModel } from '../../../core/models/calendar/base-calendar.model';
import { DateTimeHelper } from '../util/date-time-helper';
import { ITimeBlockComponentItem } from '../components/time-block/time-block-component-items';
import { CalendarViewHandlerService } from './calendar-view-handler.service';
import { ActivatedRoute } from '@angular/router';
import { CalendarEvents, CalendarView } from '../../../shared/data-types/calendar-types';
import { DayOrWeekCalendarModel } from '../../../core/models/calendar/day-or-week-calendar.model';
import { MonthCalendarModel } from '../../../core/models/calendar/month-calendar.model';
import {
  TimeBlockDayOrWeekInnerdayType,
  TimeBlockMonthInnerdayType,
} from '../../../core/models/timeblock/time-block-view-type.model';

@Injectable()
export class CalendarService {
  // Fired as soon as the view (day, week or month) is fully rendered.
  public readonly calendarViewInitialized$ = new BehaviorSubject<boolean>(null);
  // Fired whenever the calendar model updates. Don't use this subject directly, use CalendarServiceHelper instead.
  public calendarModelUpdated$ = new BehaviorSubject<
    [BaseCalendarModel, CalendarEvents | CalendarEvents[], unknown?]
  >(null);
  public calendarViewportResized$ = new Subject();

  private calendarModel: BaseCalendarModel;

  constructor(private readonly calendarViewHandlerService: CalendarViewHandlerService) {}
  /**
   * Caution: return a shallow copy here, so time block references of the original model are altered.
   */
  public get model(): BaseCalendarModel {
    return { ...this.calendarModel };
  }

  public init(activatedRoute: ActivatedRoute): void {
    this.calendarModel = this.calendarViewHandlerService.initConfigData(activatedRoute);
  }

  public getCalendarModelByTimeBlockType(timeBlock: ITimeBlockComponentItem): BaseCalendarModel {
    const calendarModel = this.model;
    const viewType = calendarModel.calendarViewMode;
    if (
      (viewType === CalendarView.DayGrid && !timeBlock.timeBlockModel.isFullday) ||
      (viewType === CalendarView.WeekGrid && !timeBlock.timeBlockModel.isFullday)
    ) {
      return calendarModel as DayOrWeekCalendarModel;
    } else if (
      (viewType === CalendarView.DayGrid && timeBlock.timeBlockModel.isFullday) ||
      (viewType === CalendarView.WeekGrid && timeBlock.timeBlockModel.isFullday)
    ) {
      return (calendarModel as DayOrWeekCalendarModel).fulldayCalendarModel;
    } else if (viewType === CalendarView.MonthGrid) {
      return calendarModel as MonthCalendarModel;
    }
    throw new Error('Calendar model type is not supported.');
  }

  /**
   * Called whenever something changes in the calendar model.
   * @param model The updated calendar model.
   * @param calendarEvents The events to listen to.
   * @param meta Optional metadata.
   */
  public emitCalendarChange(
    model: BaseCalendarModel,
    calendarEvents: CalendarEvents | CalendarEvents[],
    meta?: unknown,
  ): void {
    this.calendarModel = {
      ...model,
    };
    this.calendarModelUpdated$.next([model, calendarEvents, meta]);
  }

  /**
   * Update all necessary data that is displayed in the calendar
   */
  public updateCalendarData(
    date: Date,
    calendarEvents: CalendarEvents[],
    model: BaseCalendarModel,
  ): void {
    this.calendarModel = this.calendarViewHandlerService.updateCalendarData(date, model);
    this.emitCalendarChange(this.calendarModel, calendarEvents);
  }

  /**
   * Get the container index. The container with index 0 is the first invisible container.
   */
  public getContainerIndexByTimeBlock(timeBlock: ITimeBlockComponentItem): number {
    const timeBlockViewType = timeBlock.timeBlockModel.timeBlockViewType;
    const timeBlocks =
      timeBlockViewType instanceof TimeBlockMonthInnerdayType ||
      timeBlockViewType instanceof TimeBlockDayOrWeekInnerdayType
        ? this.calendarModel.timeBlocks
        : (this.calendarModel as DayOrWeekCalendarModel).fulldayCalendarModel.timeBlocks;

    const containerDate = this.getContainerDate(timeBlock);
    const dates = [...timeBlocks.keys()];
    const index = dates.indexOf(DateTimeHelper.format(containerDate));

    if (this.calendarModel.calendarViewMode !== CalendarView.MonthGrid && index < 0) {
      throw new Error('Inner day time blocks must  have a lane index >= 0.');
    }

    // Full day starts before current week, so take the first week lane
    const firstKeyOfMap = DateTimeHelper.parse(
      timeBlocks.keys().next().value,
      'yyyy-MM-dd',
      new Date(),
    );
    if (index < 0 && DateTimeHelper.isBefore(containerDate, firstKeyOfMap)) {
      return 0;
    } else if (index >= 0) {
      return index;
    }

    // Full day ends after current week, so take last week lane
    const keys = Array.from(timeBlocks.keys());
    const lastKeyOfMap = DateTimeHelper.parse(
      keys[keys.length - 1].toString(),
      'yyyy-MM-dd',
      new Date(),
    );
    if (index < 0 && DateTimeHelper.isAfter(containerDate, lastKeyOfMap)) {
      return keys.length - 1;
    } else if (index >= 0) {
      return index;
    }

    throw new Error(`Invalid index for date ${containerDate}`);
  }

  public getContainerIndexByDate(date: Date): number {
    return DateTimeHelper.differenceInDays(
      this.calendarModel.calendarProperties.offsetStartDate,
      date,
    );
  }

  private getContainerDate(timeBlock: ITimeBlockComponentItem): Date {
    const timeBlockViewType = timeBlock.timeBlockModel.timeBlockViewType;
    if ('laneIndex' in timeBlockViewType) {
      return DateTimeHelper.addDays(
        this.calendarModel.calendarProperties.offsetStartDate,
        +timeBlockViewType.laneIndex,
      );
    } else if ('laneIndexStart' in timeBlockViewType) {
      const laneIndex = +timeBlockViewType.laneIndexStart + timeBlock.timeBlockModel.partNumber;
      return DateTimeHelper.addDays(
        this.calendarModel.calendarProperties.offsetStartDate,
        laneIndex,
      );
    }
    throw new Error('No lane index in time block view type.');
  }
}
