import { createSelector } from '@ngrx/store';
import { AuthState } from '../../shared/data-types/auth-types';

export const AUTH_KEY = 'authData';
export const selectAuthFeature = (state) => state[AUTH_KEY];

export const selectAuthState = createSelector(selectAuthFeature, (state: AuthState) => state);
export const selectLoggedInUser = createSelector(
  selectAuthFeature,
  (state: AuthState) => state.user,
);
