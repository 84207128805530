import { createFeatureSelector, createSelector } from '@ngrx/store';
import { projectAdapter } from './projects.reducer';

export const selectAllProjects = createSelector(
  createFeatureSelector('projects'),
  projectAdapter.getSelectors().selectAll,
);

export const selectProjectById = (projectId: number) =>
  createSelector(selectAllProjects, (projects) =>
    projects.find((project) => project.id === projectId),
  );
