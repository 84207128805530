import { Component, ContentChild, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { SubSink } from 'subsink';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { NavigationModel } from '../../../models/navigation/navigation.model';
import { selectNavigation } from '../../../state/navigation/navigation.selectors';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit, OnDestroy {
  @ContentChild('headerBarControlsTemplate') public headerBarControlsTemplate: TemplateRef<unknown>;
  @ContentChild('headerBarArbitraryDataTemplate')
  public headerBarArbitraryDataTemplate: TemplateRef<unknown>;
  @Input() public pageTitle = '';
  public prevRoute = '';
  public prevTitle = '';
  private readonly subs = new SubSink();

  constructor(
    private readonly router: Router,
    private readonly store: Store<NavigationModel>,
  ) {}

  ngOnInit(): void {
    this.initRouteChanged();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public navBack(): void {
    void this.router.navigateByUrl(this.prevRoute);
  }

  private initRouteChanged(): void {
    this.subs.sink = this.store
      .select(selectNavigation)
      .pipe(map((storeData) => storeData))
      .subscribe((navigationData) => {
        if (!navigationData) {
          return;
        }

        this.pageTitle = this.pageTitle || navigationData.pageTitle;
        this.prevTitle = navigationData.prevTitle;
        this.prevRoute = navigationData.prevRoute;
      });
  }
}
