import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { CalendarView, DayComposite } from '../../../../shared/data-types/calendar-types';
import { DayCompositeConverter } from '../../converter/day-composite-converter';
import { DateTimeHelper } from '../../util/date-time-helper';
import { CalendarService } from '../../services/calendar.service';
import { DayOrWeekCalendarModel } from '../../../../core/models/calendar/day-or-week-calendar.model';
import {
  CalendarHeaderCSSId,
  FulldayCalendarContainerExpandable,
  FulldayCalendarContainerId,
  FulldayContainerBottomSpacing,
  FulldayContainerMinimumHeight,
  FulldayContainerTopSpacing,
  FulldayTimeBlockHeightPx,
  HorizontalTimeBlockGapPx,
} from '../../../../core/data-repository/css-constants';
import { CalendarScrollbarService } from '../../services/calendar-scrollbar.service';
import { DOCUMENT } from '@angular/common';
import { Subject, take } from 'rxjs';
import { assert } from '../../../../core/assert/assert';

@Injectable()
export class CalendarHeaderService {
  public fulldayContainerExpanded = false;
  public finishedHeightToggling$ = new Subject();
  private readonly renderer: Renderer2;
  private collapsedFulldayHeight: number;
  private expandedFulldayHeight: number;
  private yScrollTrack: HTMLDivElement;
  private readonly maxFulldayRowsCollapsed = 3;
  private readonly maxFulldayRowsExpanded = 6;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly calendarService: CalendarService,
    private readonly calendarScrollbarService: CalendarScrollbarService,
    private readonly rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public setHeaderDays(): DayComposite[] {
    const calendarModel = this.calendarService.model;
    const calendarView = calendarModel.calendarViewMode;
    let headerDays: DayComposite[];

    if (calendarView === CalendarView.DayGrid) {
      headerDays = DayCompositeConverter.toDayComposite([
        calendarModel.calendarProperties.visibleStartDate,
      ]);
    } else if (calendarView === CalendarView.WeekGrid) {
      headerDays = calendarModel.calendarProperties.days;
    } else if (calendarView === CalendarView.MonthGrid) {
      const startOfWeek = DateTimeHelper.startOfWeek(new Date(), {
        weekStartsOn: calendarModel.calendarProperties.weekStartsOn,
      });
      const endOfWeek = DateTimeHelper.endOfWeek(new Date(), {
        weekStartsOn: calendarModel.calendarProperties.weekStartsOn,
      });
      headerDays = DayCompositeConverter.toDayComposite(
        DateTimeHelper.eachDayOfInterval(startOfWeek, endOfWeek),
      );
    } else {
      throw new Error('Calendar view not supported.');
    }
    return headerDays;
  }

  public initDayOrWeekViewFulldayContainer(): void {
    // Initially hide y-track
    this.yScrollTrack = this.document.querySelector('.scrollbar-track-y');
    assert(typeof this.yScrollTrack !== 'undefined', 'y-scroll track not found.');
    this.renderer.removeClass(this.yScrollTrack, 'show');
  }

  /**
   * Calculate the height of the full day calendar container and set it accordingly.
   * If there is no full day time block, set a minimum height.
   */
  public renderDayOrWeekViewFulldayContainer(): void {
    this.setDayOrWeekFulldayContainerHeight();

    const fulldayHTMLTable = document.querySelector(FulldayCalendarContainerId);
    const model = this.calendarService.model as DayOrWeekCalendarModel;
    const maxExpandedHeight = this.calculateDayOrWeekViewFulldayContainerHeight(
      this.maxFulldayRowsExpanded,
    );

    // Set the max expanded height. If higher, a scroll bar is displayed.
    const calendarHeaderScrollContainer = fulldayHTMLTable.querySelector(CalendarHeaderCSSId);
    this.renderer.setStyle(calendarHeaderScrollContainer, 'max-height', `${maxExpandedHeight}px`);

    const highestParallelIndex = model.fulldayCalendarModel.highestParallelIndex;

    if (highestParallelIndex > this.maxFulldayRowsCollapsed) {
      this.renderer.addClass(fulldayHTMLTable, FulldayCalendarContainerExpandable);
    } else {
      this.renderer.removeClass(fulldayHTMLTable, FulldayCalendarContainerExpandable);
    }
  }

  public toggleDayOrWeekViewFulldayContainer(show?: boolean): void {
    if (typeof show !== 'undefined') {
      this.fulldayContainerExpanded = show;
    } else {
      this.fulldayContainerExpanded = !this.fulldayContainerExpanded;
    }
    this.setDayOrWeekFulldayContainerHeight();
    this.calendarScrollbarService.calendarHeaderScrollbar.disable(!this.fulldayContainerExpanded);

    this.finishedHeightToggling$.pipe(take(1)).subscribe(() => {
      if (this.fulldayContainerExpanded) {
        this.renderer.addClass(this.yScrollTrack, 'show');
      } else {
        this.calendarScrollbarService.calendarHeaderScrollbar.scrollToTop(
          this.expandedFulldayHeight,
        );
        this.renderer.removeClass(this.yScrollTrack, 'show');
      }
    });
  }

  /**
   * Set height of full day item container
   */
  public setDayOrWeekFulldayContainerHeight(): void {
    const model = this.calendarService.model as DayOrWeekCalendarModel;
    const fulldayGeometryData = model.fulldayCalendarModel.geometryData;
    const fulldayHTMLTimeBlockContainer = fulldayGeometryData.calendarBodyElementRef.nativeElement;

    assert(
      typeof fulldayHTMLTimeBlockContainer !== 'undefined',
      'Fullday html container not defined.',
    );

    const highestParallelIndex = model.fulldayCalendarModel.highestParallelIndex;
    const parallelRows = highestParallelIndex === 0 ? 0 : highestParallelIndex;
    const collapsedRows =
      parallelRows > this.maxFulldayRowsCollapsed ? this.maxFulldayRowsCollapsed : parallelRows;
    this.collapsedFulldayHeight = this.calculateDayOrWeekViewFulldayContainerHeight(collapsedRows);
    this.expandedFulldayHeight = this.calculateDayOrWeekViewFulldayContainerHeight(parallelRows);

    let height: number;
    if (this.fulldayContainerExpanded) {
      height = this.expandedFulldayHeight;
    } else {
      height = this.collapsedFulldayHeight;
    }
    fulldayGeometryData.calendarBodyHeight = height;
    fulldayGeometryData.calendarBodyVisibleHeight = height;

    this.renderer.setStyle(
      fulldayHTMLTimeBlockContainer,
      'height',
      `${fulldayGeometryData.calendarBodyHeight}px`,
    );
  }

  private calculateDayOrWeekViewFulldayContainerHeight(rowCount: number): number {
    // If there are no full day time blocks in the full day item container, return the default height
    if (rowCount === 0) {
      return FulldayContainerMinimumHeight;
    }

    return (
      FulldayTimeBlockHeightPx * rowCount +
      HorizontalTimeBlockGapPx * rowCount +
      FulldayContainerTopSpacing +
      FulldayContainerBottomSpacing
    );
  }
}
