import { Injectable } from '@angular/core';
import { ITimeBlockComponentItem } from '../../time-block-component-items';

@Injectable()
export class IntersectionService {
  constructor() {}

  public intersects(first: ITimeBlockComponentItem, second: ITimeBlockComponentItem): boolean {
    return this.intersects2(
      first.timeBlockModel.start,
      first.timeBlockModel.end,
      second.timeBlockModel.start,
      second.timeBlockModel.end,
    );
  }

  public intersects2(
    startFirst: Date,
    endFirst: Date,
    startSecond: Date,
    endSecond: Date,
  ): boolean {
    return (
      (startFirst <= startSecond && endFirst > startSecond) ||
      (endFirst >= endSecond && startFirst < endSecond) ||
      (startFirst >= startSecond && endFirst <= endSecond)
    );
  }
}
