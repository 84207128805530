import { Injectable } from '@angular/core';
import { ITimeBlockComponentItem } from '../../../../time-block-component-items';
import { TimeBlockItemBuilderService } from '../../../../generation/time-block-item-builder.service';
import { TimeBlockCrudService } from '../../../../crud/time-block-crud.service';
import { TimeBlockStructureService } from '../../../../time-block-structure/time-block-structure.service';
import { DateTimeHelper } from '../../../../../../util/date-time-helper';
import { TimeBlockDragResizeControllerService } from '../time-block-drag-resize-handling/time-block-drag-resize-controller.service';
import { DragPart } from '../drag-part';
import { DragHandlerService } from '../drag-handler.service';
import { TimeBlockElementSelectorService } from '../../../../rendering/time-block-element-selector.service';
import {
  CSSActiveClass,
  CSSDisplayTimeBlockDuration,
  CSSNewClass,
  CSSPressedClass,
  CSSResizedClass,
} from '../../../../../../../../core/data-repository/css-constants';
import { TimeBlockRenderService } from '../../../../rendering/time-block-render.service';
import { TimeBlockType } from '../../../../../../../../shared/data-types/time-block-types';
import { CalendarService } from '../../../../../../services/calendar.service';

@Injectable()
export class ResizeRowOrLaneSwitchService {
  public lastVisibleMouseLaneIndex = -1;

  constructor(
    private readonly timeBlockItemBuilderService: TimeBlockItemBuilderService,
    private readonly timeBlockCrudService: TimeBlockCrudService,
    private readonly dragHandlerService: DragHandlerService,
    private readonly calendarService: CalendarService,
    private readonly timeBlockRenderService: TimeBlockRenderService,
    private readonly timeBlockDragResizeControllerService: TimeBlockDragResizeControllerService,
    private readonly timeBlockStructureService: TimeBlockStructureService,
  ) {}

  /**
   * Handles lane switches when resizing an inner day week view time block part.
   */
  public handleLaneSwitch(
    visibleMouseLaneIndex: number,
    transformationTimeBlock: ITimeBlockComponentItem,
  ): void {
    const laneDiff = visibleMouseLaneIndex - this.lastVisibleMouseLaneIndex;
    if (laneDiff === 0) {
      return;
    }

    const dragPart = this.timeBlockDragResizeControllerService.DragPart;
    const activeTimeBlockClone = transformationTimeBlock.clone(this.timeBlockItemBuilderService);
    const selectedPartNr = this.addOrSubtractDays(
      transformationTimeBlock,
      activeTimeBlockClone,
      laneDiff,
      dragPart,
    );

    // Assuming we have one time block part and the top drop handler is moved to the right neighbour lane or the
    // bottom drop handler is moved to the left neighbour lane.
    let dragEdgeWasSwitched = false;
    const minimumTimeBlockDuration =
      this.calendarService.model.calendarProperties.minimumTimeBlockDuration;

    const start = activeTimeBlockClone.timeBlockModel.start;
    const end = activeTimeBlockClone.timeBlockModel.end;
    const isSameOrAfter =
      DateTimeHelper.differenceInMinutes(start, end) < minimumTimeBlockDuration ||
      DateTimeHelper.isAfter(start, end);

    if (isSameOrAfter) {
      // We have two parts and the drop handler is moved to part 0, but end time is before start time. So flip start and end times.
      if (
        DateTimeHelper.isSameDay(start, end) &&
        !transformationTimeBlock.timeBlockModel.isActive
      ) {
        this.dragHandlerService.flipAfterVerticalDragEdgeLaneSwitch(activeTimeBlockClone);
        dragEdgeWasSwitched = true;
      } else {
        return;
      }
    }

    this.timeBlockCrudService.removeTimeBlock(
      transformationTimeBlock,
      transformationTimeBlock.timeBlockModel.type,
    );
    this.timeBlockCrudService.insertTimeBlock(activeTimeBlockClone);
    const parts = this.timeBlockStructureService.retrieveTimeBlockPart(
      activeTimeBlockClone.id,
      activeTimeBlockClone.timeBlockModel.type,
      -1,
    ) as ITimeBlockComponentItem[];

    // Set current dragging time block part and CSS classes for all new dragging parts.
    parts.forEach((timeBlockPart) => {
      const timeBlockHTMLWrapper =
        TimeBlockElementSelectorService.getTimeBlockHTMLWrapper(timeBlockPart);

      this.timeBlockRenderService.addClass(timeBlockHTMLWrapper, CSSResizedClass);
      this.timeBlockRenderService.removeClass(timeBlockHTMLWrapper, CSSDisplayTimeBlockDuration);

      if (timeBlockPart.timeBlockModel.partNumber === selectedPartNr) {
        this.timeBlockDragResizeControllerService.TransformationTimeBlock = timeBlockPart;
        this.setDraggingTimeBlockPartCSSClasses(dragEdgeWasSwitched, dragPart);
        this.timeBlockRenderService.addClass(timeBlockHTMLWrapper, CSSDisplayTimeBlockDuration);
      }

      if (timeBlockPart.timeBlockModel.type === TimeBlockType.NonExistingBlock) {
        this.timeBlockRenderService.addClass(timeBlockHTMLWrapper, CSSNewClass);
      }
      this.timeBlockRenderService.addClass(timeBlockHTMLWrapper, CSSActiveClass);
    });

    if (
      dragEdgeWasSwitched &&
      this.timeBlockDragResizeControllerService.TransformationTimeBlock.timeBlockModel.partCount > 1
    ) {
      throw new Error('Invalid part count.');
    }

    this.lastVisibleMouseLaneIndex = visibleMouseLaneIndex;
  }

  private setDraggingTimeBlockPartCSSClasses(
    dragEdgeWasSwitched: boolean,
    dragPart: DragPart,
  ): void {
    if (dragEdgeWasSwitched) {
      this.dragHandlerService.setVerticalDragEdgeSwitchStyling();
    } else {
      let dragHandleHTMLElement = null;
      if (dragPart === DragPart.Top) {
        dragHandleHTMLElement = TimeBlockElementSelectorService.getTimeBlockFirstHandle(
          this.timeBlockDragResizeControllerService.TransformationTimeBlock,
        );
      } else if (dragPart === DragPart.Bottom) {
        dragHandleHTMLElement = TimeBlockElementSelectorService.getTimeBlockSecondHandle(
          this.timeBlockDragResizeControllerService.TransformationTimeBlock,
        );
      }

      if (!dragHandleHTMLElement) {
        return;
      }

      this.timeBlockRenderService.addClass(dragHandleHTMLElement, CSSPressedClass);
    }
  }

  private addOrSubtractDays(
    activeTimeBlockPart: ITimeBlockComponentItem,
    clone: ITimeBlockComponentItem,
    laneDiff: number,
    dragPart: DragPart,
  ): number {
    let selectedPartNr = -1;

    if (dragPart === DragPart.Top) {
      if (laneDiff > 0) {
        clone.timeBlockModel.start = DateTimeHelper.addDays(
          activeTimeBlockPart.timeBlockModel.start,
          laneDiff,
        );
      } else {
        clone.timeBlockModel.start = DateTimeHelper.subDays(
          activeTimeBlockPart.timeBlockModel.start,
          Math.abs(laneDiff),
        );
      }
      selectedPartNr = 0;
    } else {
      if (laneDiff > 0) {
        clone.timeBlockModel.end = DateTimeHelper.addDays(
          activeTimeBlockPart.timeBlockModel.end,
          laneDiff,
        );
      } else {
        clone.timeBlockModel.end = DateTimeHelper.subDays(
          activeTimeBlockPart.timeBlockModel.end,
          Math.abs(laneDiff),
        );
      }
      selectedPartNr = clone.timeBlockModel.partCount + laneDiff - 1;
    }

    return selectedPartNr;
  }
}
