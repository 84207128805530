<app-page-header>
  <ng-template #headerBarControlsTemplate>
    <!-- Template ref Needed for content child directive -->
    <button
      kendoButton
      class="k-ml-22 !k-pr-4"
      [routerLink]="['/' + ShiveRoutes.Clients, ShiveRoutes.Create]"
      size="large"
    >
      <span class="custom-symbol add turquoise k-mr-3"></span>
      Neuer Kunde
    </button>
  </ng-template>
</app-page-header>

<div class="card-wrapper k-mt-4">
  <div class="card">
    <div class="card-body">
      <div id="clients-table-wrapper" class="w-100">
        <kendo-grid
          #grid
          [kendoGridBinding]="listClientDataService.gridData$ | async"
          kendoGridSelectBy="id"
          (cellClick)="onCellClick($event)"
          scrollable="none"
          size="medium"
          [pageSize]="20"
          [selectable]="{ enabled: true, checkboxOnly: true }"
          [columnMenu]="{ filter: true }"
        >
          <kendo-grid-checkbox-column
            [width]="60"
            [headerClass]="{ 'k-text-center': true }"
            [class]="{ 'k-text-center': true }"
            [resizable]="false"
            [columnMenu]="false"
            [showSelectAll]="true"
          ></kendo-grid-checkbox-column>

          <kendo-grid-column field="client_client" title="Kunde">
            <ng-template kendoGridCellTemplate let-client>
              <div class="client-client">{{ client.name }}</div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="contextMenu" title="" [columnMenu]="false" [width]="60">
            <ng-template kendoGridCellTemplate let-client> </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </div>
    </div>
  </div>
</div>
