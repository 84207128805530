import {
  Duration,
  Interval,
  Locale,
  addDays,
  addHours,
  addMinutes,
  addMonths,
  addWeeks,
  areIntervalsOverlapping,
  differenceInCalendarDays,
  differenceInDays,
  differenceInSeconds,
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  intervalToDuration,
  isAfter,
  isBefore,
  isSameDay,
  isSameHour,
  isSameMinute,
  isSameSecond,
  isSameWeek,
  isToday,
  isWithinInterval,
  parse,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfToday,
  startOfWeek,
  subDays,
  subMinutes,
  subMonths,
  subWeeks,
} from 'date-fns';
import moment from 'moment';
import { Epoch, WeekOrdinal } from '../../../shared/data-types/calendar-types';

export class DateTimeHelper {
  public static parse(
    dateString: string,
    formatString: string,
    referenceDate: Date | number,
    options?: unknown,
  ): Date {
    return parse(dateString, formatString, referenceDate, options);
  }

  public static parseISO(argument: string, options?: { additionalDigits?: 0 | 1 | 2 }): Date {
    return parseISO(argument, options);
  }

  public static minutesToDate(minutes: number, referenceDate?: Date): Date {
    if (minutes < 0) {
      throw new Error(`Invalid minutes : ${minutes}`);
    }

    referenceDate = referenceDate ? referenceDate : new Date();
    return DateTimeHelper.addMinutes(referenceDate, minutes);
  }

  public static format(date: Date | number, pattern = 'yyyy-MM-dd'): string {
    return format(date, pattern);
  }

  public static addHours(date: Date | number, amount: number): Date {
    return addHours(date, amount);
  }

  public static addMinutes(date: Date | number, amount: number): Date {
    return addMinutes(date, amount);
  }

  public static addMonths(date: Date | number, amount: number): Date {
    return addMonths(date, amount);
  }

  public static addWeeks(date: Date | number, amount: number): Date {
    return addWeeks(date, amount);
  }

  public static addDays(date: Date | number, amount: number): Date {
    return addDays(date, amount);
  }

  public static areIntervalsOverlapping(
    intervalLeft: Interval,
    intervalRight: Interval,
    options?: {
      inclusive?: boolean;
    },
  ): boolean {
    return areIntervalsOverlapping(intervalLeft, intervalRight, options);
  }

  public static subMinutes(date: Date | number, amount: number): Date {
    return subMinutes(date, amount);
  }

  public static differenceInMinutes(start: Date | number, end: Date | number): number {
    const momEnd = moment(end);
    const duration = moment.duration(momEnd.diff(start));
    return duration.asMinutes();
  }

  public static differenceInDays(dateLeft: Date | number, dateRight: Date | number): number {
    return differenceInDays(dateLeft, dateRight);
  }

  public static differenceInSeconds(dateLeft: Date | number, dateRight: Date | number): number {
    return differenceInSeconds(dateLeft, dateRight);
  }

  public static differenceInCalendarDays(
    dateLeft: Date | number,
    dateRight: Date | number,
  ): number {
    return differenceInCalendarDays(dateLeft, dateRight);
  }

  public static intervalToDuration(interval: Interval): Duration {
    return intervalToDuration(interval);
  }

  public static isBefore(date: Date | number, compare: Date | number): boolean {
    return isBefore(date, compare);
  }

  public static isAfter(date: Date | number, compare: Date | number): boolean {
    return isAfter(date, compare);
  }

  public static isToday(date: Date | number): boolean {
    return isToday(date);
  }

  public static isSameWeek(
    dateLeft: Date | number,
    dateRight: Date | number,
    options?: {
      locale?: Locale;
      weekStartsOn?: WeekOrdinal;
    },
  ): boolean {
    return isSameWeek(dateLeft, dateRight, options);
  }

  public static isSameDay(dateLeft: Date | number, dateRight: Date | number): boolean {
    return isSameDay(dateLeft, dateRight);
  }

  public static isSameTime(dateLeft: Date, dateRight: Date): boolean {
    return (
      isSameHour(dateLeft, dateRight) &&
      isSameMinute(dateLeft, dateRight) &&
      isSameSecond(dateLeft, dateRight)
    );
  }

  public static isWithinInterval(
    date: Date | number,
    interval: Interval,
    startIncluded = true,
    endIncluded = true,
  ): boolean {
    const start = new Date(interval.start);
    const end = new Date(interval.end);

    if (startIncluded && endIncluded) {
      return isWithinInterval(date, interval);
    } else if (startIncluded && !endIncluded) {
      return isWithinInterval(date, interval) && DateTimeHelper.isBefore(date, end);
    } else if (!startIncluded && endIncluded) {
      return isWithinInterval(date, interval) && DateTimeHelper.isAfter(date, start);
    }
    // !startIncluded && !endIncluded
    return (
      isWithinInterval(date, interval) &&
      DateTimeHelper.isBefore(date, end) &&
      DateTimeHelper.isAfter(date, start)
    );
  }

  public static startOfToday(): Date {
    return startOfToday();
  }

  public static startOfDay(date: Date): Date {
    return startOfDay(date);
  }

  public static startOfWeek(
    date: Date | number,
    options?: { locale?: Locale; weekStartsOn?: WeekOrdinal },
  ): Date {
    return startOfWeek(date, options);
  }

  public static endOfDay(date: Date): Date {
    return endOfDay(date);
  }

  public static endOfWeek(
    date: Date | number,
    options?: { locale?: Locale; weekStartsOn?: WeekOrdinal },
  ): Date {
    return endOfWeek(date, options);
  }

  public static startOfMonth(date: Date): Date {
    return startOfMonth(date);
  }

  public static endOfMonth(date: Date): Date {
    return endOfMonth(date);
  }

  public static subMonths(date: Date | number, amount: number): Date {
    return subMonths(date, amount);
  }

  public static subWeeks(date: Date | number, amount: number): Date {
    return subWeeks(date, amount);
  }

  public static subDays(date: Date | number, amount: number): Date {
    return subDays(date, amount);
  }

  public static eachDayOfInterval(start: Date, end: Date): Date[] {
    if (DateTimeHelper.isBefore(end, start)) {
      throw new Error('End date is before start date.');
    }
    return eachDayOfInterval({ start, end });
  }

  public static eachWeekOfInterval(
    start: Date,
    end: Date,
    options?: { locale?: Locale; weekStartsOn?: WeekOrdinal },
  ): Date[] {
    return eachWeekOfInterval({ start, end }, options);
  }

  public static weekNumber(date?: Date): number {
    if (!date) {
      return moment().isoWeek();
    }
    return moment(date).isoWeek();
  }

  public static isWhen(day: Date): Epoch {
    const today = new Date();
    today.setHours(0, 0, 0);

    if (DateTimeHelper.isToday(day)) {
      return 'today';
    } else if (DateTimeHelper.isAfter(day, today)) {
      return 'future';
    } else if (DateTimeHelper.isBefore(day, today)) {
      return 'past';
    }
    return 'unknown';
  }

  public static mergeDateAndTime(dateVal: Date, timeVal: Date): Date {
    return new Date(
      dateVal.getFullYear(),
      dateVal.getMonth(),
      dateVal.getDate(),
      timeVal.getHours(),
      timeVal.getMinutes(),
      timeVal.getSeconds(),
      0,
    );
  }

  public static timeStringToDate(timeString: string): Date {
    // Get the current date
    const newDate = new Date();

    // Create a new date object with the time set to "08:00"
    const timeParts = timeString.split(':');
    newDate.setHours(+timeParts[0]);
    newDate.setMinutes(+timeParts[1]);
    newDate.setSeconds(+timeParts[2]);
    newDate.setMilliseconds(0);
    return newDate;
  }

  /**
   * @param date If date has a time between 23:59:01 (inclusive) and 23:59:59 (inclusive), transform it to 00:00:00.
   * @param useNextDay Whether to use the next day or keep the current day.
   */
  public static transformToExactlyMidnightIfNeeded(date: Date, useNextDay = false): Date {
    const right = new Date(date);
    right.setHours(23, 59, 0, 0);

    if (DateTimeHelper.differenceInSeconds(date, right) > 0) {
      if (useNextDay) {
        date = DateTimeHelper.addDays(date, 1);
      }
      return DateTimeHelper.startOfDay(date);
    }
    return date;
  }

  /**
   * @param date If date has an end time of 00:00:00, change it to 23:59:59.999 of previous day. Return the original date, otherwise.
   * Remark: End time should actually never be 00:00:00 since we prevent it when creating or updating time blocks.
   */
  public static transformToNearlyMidnightIfNeeded(date: Date): Date {
    const midnightDate = DateTimeHelper.startOfDay(date);
    if (DateTimeHelper.isSameTime(midnightDate, date)) {
      return DateTimeHelper.endOfDay(DateTimeHelper.subDays(date, 1));
    }
    return date;
  }

  public static truncateTime(value: Date): string {
    value.setHours(0, 0, 0, 0);
    return DateTimeHelper.dateToString(value);
  }

  public static setSecondsAndMillisToZero(date: Date): Date {
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  public static utcDate(): Date {
    const date = new Date();
    const offset = date.getTimezoneOffset();
    return moment(date).add(offset, 'm').toDate();
  }

  public static dateToString(date: Date, format = 'yyyy-MM-DDTHH:mm:ss'): string {
    return `${moment(date).format(format)}Z`;
  }

  public static dateToUtcString(date: Date, format = 'yyyy-MM-DDTHH:mm:ss'): string {
    const offset = date.getTimezoneOffset();
    return `${moment(date).add(offset, 'm').format(format)}Z`;
  }
}
