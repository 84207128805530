import { BaseCalendarModel, BaseCalendarProperties } from './base-calendar.model';
import { MonthOrdinal, WeekOrdinal } from '../../../shared/data-types/calendar-types';
import { DateTimeHelper } from '../../../features/calendar/util/date-time-helper';
import { CalendarConfigModel } from '../app-config/calendar-config.model';

export class DayOrWeekCalendarModel extends BaseCalendarModel {
  public fulldayCalendarModel = new FulldayOrWeekCalendarModel();
  public readonly calendarProperties = new DayOrWeekCalendarProperties();

  constructor(calendarOptions?: CalendarConfigModel) {
    super();
    if (!calendarOptions) {
      return;
    }

    this.calendarProperties.hoursOfDay = calendarOptions.hoursOfDay;
    this.calendarProperties.weekStartsOn = calendarOptions.weekStartsOn as WeekOrdinal;
    this.calendarProperties.timeFormat = calendarOptions.timeFormat;
    this.calendarProperties.timeBlockDraggingDuration = calendarOptions.timeBlockDraggingDuration;
    this.calendarProperties.minimumTimeBlockDuration = calendarOptions.minimumTimeBlockDuration;
    const today = new Date();
    this.calendarProperties.month = today.getMonth() as MonthOrdinal;
    this.calendarProperties.year = today.getFullYear();
    this.calendarProperties.workTimeStart = DateTimeHelper.timeStringToDate(
      calendarOptions.workTimeStart,
    );
    this.calendarProperties.workTimeEnd = DateTimeHelper.timeStringToDate(
      calendarOptions.workTimeEnd,
    );
    this.calendarProperties.fulldayDuration = calendarOptions.fulldayDuration;
  }
}

class DayOrWeekCalendarProperties extends BaseCalendarProperties {
  public timeBlockDraggingDuration: number; // In minutes
  public hoursOfDay: number;
}

class FulldayOrWeekCalendarModel extends BaseCalendarModel {
  public highestParallelIndex: number;
}
