import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DragPart } from '../../drag-part';
import { TimeBlockDragResizeControllerService } from '../../time-block-drag-resize-handling/time-block-drag-resize-controller.service';
import { TimeBlockRenderService } from '../../../../../rendering/time-block-render.service';
import { Position } from '../../../../../../../../../shared/data-structures/position';
import { TimeBlockCrudService } from '../../../../../crud/time-block-crud.service';
import { CalendarService } from '../../../../../../../services/calendar.service';
import { AbstractResizeController } from '../abstract-resize-controller';
import { DragHandlerService } from '../../drag-handler.service';
import { DayOrWeekCalendarModel } from 'src/app/core/models/calendar/day-or-week-calendar.model';
import { TimeBlockDayOrWeekFulldayType } from '../../../../../../../../../core/models/timeblock/time-block-view-type.model';
import { ITimeBlockComponentItem } from '../../../../../time-block-component-items';
import { TimeBlockStructureService } from '../../../../../time-block-structure/time-block-structure.service';
import { CalendarServiceHelper } from '../../../../../../../services/calendar-service-helper';
import {
  CalendarEvents,
  CalendarView,
} from '../../../../../../../../../shared/data-types/calendar-types';
import { CalendarMouseHandlerService } from '../../../../../../../mouse/calendar-mouse-handler.service';
import { SharedHorizontalDragResizeService } from '../../time-block-drag-resize-handling/shared-horizontal-drag-resize.service';
import { TimeBlockDialogService } from '../../../../../services/time-block-dialog.service';

@Injectable()
export class HorizontalAllResizeControllerService extends AbstractResizeController {
  public resizeStarted$ = new Subject<DragPart>();
  // The lane index of the first part of the time block (dragPart is right), or the lane index of the last part (dragPart is left)
  private laneIndexTail = -1;

  constructor(
    private readonly timeBlockDragResizeControllerService: TimeBlockDragResizeControllerService,
    private readonly timeBlockCrudService: TimeBlockCrudService,
    private readonly timeBlockStructureService: TimeBlockStructureService,
    private readonly timeBlockRenderService: TimeBlockRenderService,
    private readonly calendarService: CalendarService,
    private readonly sharedDraggingResizingService: SharedHorizontalDragResizeService,
    private readonly calendarMouseHandlerService: CalendarMouseHandlerService,
    private readonly timeBlockDialogService: TimeBlockDialogService,
    private readonly dragHandlerService: DragHandlerService,
  ) {
    super(timeBlockDragResizeControllerService, timeBlockDialogService, timeBlockCrudService);
  }

  public resize(calendarMousePos: Position): void {
    if (!this.timeBlockDragResizeControllerService.TransformationTimeBlock) {
      return;
    }

    const mouseLaneIndex = this.calendarMouseHandlerService.calcVisibleMouseLaneIndex(
      calendarMousePos.x,
      calendarMousePos.y,
      true,
    );
    if (this.laneIndexTail < mouseLaneIndex) {
      this.timeBlockDragResizeControllerService.DragPart = DragPart.Right;
    } else if (this.laneIndexTail > mouseLaneIndex) {
      this.timeBlockDragResizeControllerService.DragPart = DragPart.Left;
    }

    const transformationTimeBlock =
      this.timeBlockDragResizeControllerService.TransformationTimeBlock;
    const dragPart = this.timeBlockDragResizeControllerService.DragPart;

    this.dragHandlerService.handleHorizontalDragEdgeSwitchStyle(dragPart);
    this.setLaneIndices(transformationTimeBlock, mouseLaneIndex, dragPart);

    const calendarView = this.calendarService.model.calendarViewMode;
    if (calendarView === CalendarView.MonthGrid) {
      // Remove original time block and add the updated time block.
      this.sharedDraggingResizingService.dragOrResizeMonthTimeBlock();
    } else {
      this.updateTimeBlockSchedule();
    }

    this.calendarService.emitCalendarChange(
      this.calendarService.model,
      CalendarEvents.ReplacedTimeBlock,
      this.timeBlockDragResizeControllerService.TransformationTimeBlock,
    );
  }

  public beforeStartResizing(dragPart: DragPart): void {
    this.timeBlockDragResizeControllerService.DragPart = dragPart;

    this.timeBlockDragResizeControllerService.addCSSClasses(
      dragPart,
      this.timeBlockDragResizeControllerService.TransformationTimeBlock,
    );

    const timeBlockViewType = this.timeBlockDragResizeControllerService.TransformationTimeBlock
      .timeBlockModel.timeBlockViewType as TimeBlockDayOrWeekFulldayType;
    const calendarModel = this.calendarService.model as DayOrWeekCalendarModel;
    const dayOffset = CalendarServiceHelper.getInvisibleOffsetCount(
      calendarModel.fulldayCalendarModel,
    );
    this.laneIndexTail =
      dragPart === DragPart.Right
        ? timeBlockViewType.laneIndexStart - dayOffset
        : timeBlockViewType.laneIndexEnd - dayOffset;
  }

  protected updateTimeBlockSchedule(): void {
    this.sharedDraggingResizingService.dragOrResizeWeekTimeBlock();
  }

  private setLaneIndices(
    draggingBlock: ITimeBlockComponentItem,
    mouseIndex: number,
    dragPart: DragPart,
  ): void {
    const horizontalTimeBlockType = draggingBlock.timeBlockModel
      .timeBlockViewType as TimeBlockDayOrWeekFulldayType;
    const diff = mouseIndex - this.laneIndexTail;

    const calendarModel = this.calendarService.getCalendarModelByTimeBlockType(draggingBlock);
    if (dragPart === DragPart.Left) {
      horizontalTimeBlockType.laneIndexStart = CalendarServiceHelper.toInvisibleLaneIndex(
        calendarModel,
        this.laneIndexTail + diff,
      );
      horizontalTimeBlockType.laneIndexEnd = CalendarServiceHelper.toInvisibleLaneIndex(
        calendarModel,
        this.laneIndexTail,
      );
    } else {
      horizontalTimeBlockType.laneIndexEnd = CalendarServiceHelper.toInvisibleLaneIndex(
        calendarModel,
        this.laneIndexTail + diff,
      );
      horizontalTimeBlockType.laneIndexStart = CalendarServiceHelper.toInvisibleLaneIndex(
        calendarModel,
        this.laneIndexTail,
      );
    }
  }
}
