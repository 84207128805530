import { createAction, props } from '@ngrx/store';
import { StatusModel } from '../../../models/project/status.model';
import { ProjectOrTaskType } from '../../../../shared/data-types/status-types';

export const fetchStatus = createAction(
  '[Status] Fetch Status',
  props<{ payload: ProjectOrTaskType }>(),
);
export const setStatus = createAction('[Status] Set Status', props<{ payload: StatusModel[] }>());

export const addProjectStatus = createAction(
  '[Status] Add Status',
  props<{ payload: StatusModel | StatusModel[] }>(),
);
export const projectStatusAdded = createAction(
  '[Status] Added Status',
  props<{ payload: StatusModel | StatusModel[] }>(),
);

export const updateProjectStatus = createAction(
  '[Status] Update Status',
  props<{ payload: StatusModel | StatusModel[] }>(),
);
export const projectStatusUpdated = createAction(
  '[Status] Updated Status',
  props<{ payload: StatusModel | StatusModel[] }>(),
);

export const deleteProjectStatus = createAction(
  '[Status] Delete Status',
  props<{ payload: StatusModel | StatusModel[] }>(),
);
export const projectStatusDeleted = createAction(
  '[Status] Deleted Status',
  props<{ payload: StatusModel | StatusModel[] }>(),
);

export const httpFail = createAction('[Status] Http fail', props<{ payload: string }>());
