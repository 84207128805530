import { Injectable } from '@angular/core';
import { ProjectModel } from '../../../core/models/project/project.model';
import { ProjectDetailDataStore } from '../state-and-data-handling/project-detail-data.store';
import { take } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { Subject } from 'rxjs';
import { assert } from '../../../core/assert/assert';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  public userOperationConducted$ = new Subject<boolean>();

  constructor(private readonly projectDetailDataStore: ProjectDetailDataStore) {}

  public addProject(project: ProjectModel): void {
    assert(project !== null, 'Project model is invalid.');
    this.projectDetailDataStore.addProject(project);
  }

  public getProjectClone(): ProjectModel {
    return cloneDeep(this.getProject());
  }

  public updateProject(project: ProjectModel): void {
    this.projectDetailDataStore.updateProject(project);
  }

  public resetProjectStore(): void {
    this.projectDetailDataStore.reset();
  }

  private getProject(): ProjectModel {
    let loadedProject: ProjectModel;
    this.projectDetailDataStore
      .selectProject()
      .pipe(take(1))
      .subscribe((project) => {
        loadedProject = project;
      });
    return loadedProject;
  }
}
