import { Injectable } from '@angular/core';
import { ProjectModel } from '../../../core/models/project/project.model';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import * as fromProjectActions from '../../../core/state/projects/projects.actions';
import { Store } from '@ngrx/store';
import { ProjectsHttpService } from '../../../core/state/projects/projects-http.service';
import { selectAllProjects } from '../../../core/state/projects/projects.selectors';

@Injectable({
  providedIn: 'root',
})
export class ListProjectDataService {
  public isLoading = true;
  public gridData$: Observable<ProjectModel[]> = null;

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly projectsListHttpService: ProjectsHttpService,
  ) {}

  public loadGridData(): void {
    this.gridData$ = this.projectsListHttpService.getProjects().pipe(
      take(1),
      tap(() => (this.isLoading = false)),
    );
  }

  public deleteProject(project: ProjectModel): void {
    this.projectsListHttpService.deleteProject(project);
  }

  public cloneProject(): void {}

  public handleProjectHttpCallback(): void {
    // POST and PUT are already handled in ProjectService
    this.actions$.pipe(ofType(fromProjectActions.projectDeleted)).subscribe(() => {
      this.gridData$ = this.store.select(selectAllProjects);
    });
  }
}
