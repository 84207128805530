<div class="dialog-content">
  @switch (userOperation) {
    @case (UserOperation.Create) {
      <ng-container [ngTemplateOutlet]="addOrEditHolidayTemplate"> </ng-container>
    }
    @case (UserOperation.Update) {
      <ng-container [ngTemplateOutlet]="addOrEditHolidayTemplate"> </ng-container>
    }
  }
</div>

<footer class="k-d-flex k-justify-content-between">
  <button (click)="close()" kendoButton themeColor="secondary" size="large" class="k-mr-4 !k-pr-4">
    <span class="custom-symbol close k-mr-3"></span>
    <span class="label">Schließen</span>
  </button>

  <button kendoButton (click)="submit()" class="!k-pr-4" themeColor="base" size="large">
    @if (userOperation === UserOperation.Create) {
      <fa-icon class="add text-white k-mr-3" [icon]="faCirclePlus"></fa-icon>
      <span class="label">Hinzufügen</span>
    }

    @if (userOperation === UserOperation.Update) {
      <fa-icon class="update text-white k-mr-3" [icon]="faPenToSquare"></fa-icon>
      <span class="label">Aktualisieren</span>
    }

    @if (userOperation === UserOperation.Delete) {
      <span class="remove custom-symbol k-mr-3"></span>
      <span class="label">Löschen</span>
    }
  </button>
</footer>

<ng-template #addOrEditHolidayTemplate>
  <form class="k-form" [formGroup]="holidayTemplateForm">
    <kendo-formfield orientation="horizontal">
      <kendo-label
        class="!k-align-items-start"
        [for]="holidayTemplate"
        text="Feiertagsliste"
      ></kendo-label>
      <kendo-textbox
        showErrorIcon="initial"
        id="holidayTemplate"
        formControlName="holidayTemplateName"
        #holidayTemplate
      >
      </kendo-textbox>
      <kendo-formerror>Error: Full Name is required</kendo-formerror>
    </kendo-formfield>
  </form>
</ng-template>
