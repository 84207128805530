import { ITimeBlockComponentItem } from '../../../time-block-component-items';
import { IntersectionService } from '../../intersection/intersection.service';

export class Slot {
  public timeBlocks: ITimeBlockComponentItem[] = new Array<ITimeBlockComponentItem>();

  public constructor(private readonly intersectionService: IntersectionService) {}

  /**
   * Add a timeblock to this slot.
   */
  public insert(timeBlock: ITimeBlockComponentItem): void {
    this.timeBlocks.push(timeBlock);
  }

  /**
   * Check if timeBlockToCheck intersects at least one time block in this slot.
   */
  public intersectsTimeBlock(timeBlockToCheck: ITimeBlockComponentItem): boolean {
    for (const timeBlock of this.timeBlocks) {
      if (this.intersectionService.intersects(timeBlock, timeBlockToCheck)) {
        return true;
      }
    }
    return false;
  }
}
