import { createAction, props } from '@ngrx/store';
import { AbsenceReasonModel } from '../../../models/absence-reason/absence-reason.model';

export const fetchAbsenceReasons = createAction('[AbsenceReasons] Fetch AbsenceReasons');
export const setAbsenceReasons = createAction(
  '[AbsenceReasons] Set AbsenceReasons',
  props<{ payload: AbsenceReasonModel[] }>(),
);

export const addAbsenceReason = createAction(
  '[AbsenceReasons] Add AbsenceReason',
  props<{ payload: AbsenceReasonModel }>(),
);
export const absenceReasonAdded = createAction(
  '[AbsenceReasons] Added AbsenceReason',
  props<{ payload: AbsenceReasonModel }>(),
);

export const updateAbsenceReason = createAction(
  '[AbsenceReasons] Update AbsenceReason',
  props<{ payload: AbsenceReasonModel }>(),
);
export const absenceReasonUpdated = createAction(
  '[AbsenceReasons] Updated AbsenceReason',
  props<{ payload: AbsenceReasonModel }>(),
);

export const deleteAbsenceReason = createAction(
  '[AbsenceReasons] Delete AbsenceReason',
  props<{ payload: AbsenceReasonModel }>(),
);
export const absenceReasonDeleted = createAction(
  '[AbsenceReasons] Deleted AbsenceReason',
  props<{ payload: AbsenceReasonModel }>(),
);

export const httpFail = createAction('[AbsenceReasons] Http fail', props<{ payload: string }>());
