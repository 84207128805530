import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private readonly sanitizer: DomSanitizer) {}

  transform(unsafed: string | null, context: SecurityContext): string | SafeResourceUrl | null {
    if (!unsafed) {
      return null;
    }

    switch (context) {
      case SecurityContext.STYLE:
        return this.sanitizer.sanitize(SecurityContext.STYLE, `url(${unsafed})`);
      case SecurityContext.RESOURCE_URL:
        return this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/jpg;base64,${unsafed}`);
      default:
        return null;
    }
  }
}
