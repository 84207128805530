import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SecurityContext,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserOperation } from 'src/app/core/enums/user-operation';
import { ShiveGridComponent } from '../../../../../../shared/components/form-components/shive-grid/shive-grid.component';
import { ShiveDialogService } from '../../../../../../core/services/controls/shive-dialog.service';
import {
  BillableRateOptions,
  BudgetOptions,
} from '../../../../../../core/data-repository/dropdown-constants';
import { SubSink } from 'subsink';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { ProjectMemberService } from '../../services/project-member.service';
import { ProjectService } from '../../../../services/project.service';
import { BillingEntry, MemberGridRow } from '../../../../../../shared/data-types/project-types';
import { AddMemberDialogComponent } from '../../../../../../shared/components/dialogs/add-member-dialog/add-member-dialog.component';
import { filter } from 'rxjs/operators';
import { UserModel } from '../../../../../../core/models/user/user.model';
import { BillingHistoryDialogComponent } from '../../dialogs/billing-history-dialog/billing-history-dialog.component';
import { ProjectModel } from '../../../../../../core/models/project/project.model';
import { ProjectBillingBudgetTransformerService } from '../../services/project-billing-budget-transformer.service';
import { BillingEntryService } from '../project-calculation-form/services/billing-entry.service';

@Component({
  selector: 'app-project-member-grid',
  templateUrl: './project-member-grid.component.html',
  styleUrls: ['./project-member-grid.component.scss'],
})
export class ProjectMemberGridComponent implements OnChanges, AfterViewInit, OnDestroy, OnInit {
  @Input() selectedBillableRateOption: BillableRateOptions;
  @Input() selectedBudgetOption: BudgetOptions;
  @Output() readonly assigneeRemovedEmitter$ = new EventEmitter();
  @ViewChild('gridWrapperComponent') gridWrapperComponent: ShiveGridComponent;
  public formGroup: FormGroup;
  public showBillableValueFormField = false;
  public billableValueColumnTitle = 'Stundensatz';
  public showHoursPerMemberFormField = false;
  public hoursPerMemberColumnTitle = 'Stunden';
  public readonly UserOperation = UserOperation;
  public readonly SecurityContext = SecurityContext;
  public readonly faPenToSquare = faPenToSquare;
  public gridData: MemberGridRow[] = [];
  private readonly subs = new SubSink();

  constructor(
    private readonly shiveDialogService: ShiveDialogService,
    private readonly projectMemberService: ProjectMemberService,
    private readonly projectService: ProjectService,
    private readonly projectBillingBudgetTransformer: ProjectBillingBudgetTransformerService,
    private readonly billingEntryService: BillingEntryService,
  ) {}

  ngOnInit(): void {
    const projectClone = this.projectService.getProjectClone();

    projectClone.members.forEach((member) => {
      this.gridData.push({ member, budgetHours: 0, billingRateHistory: [] });
    });

    this.projectBillingBudgetTransformer.transformBillingAndBudgetEntriesToMemberGridData(
      this.gridData,
      projectClone,
    );
    this.showBillingBudget(projectClone);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // If values are initialized (firstChange === true), return.
    if (
      changes.selectedBillableRateOption?.firstChange ||
      changes.selectedBudgetOption?.firstChange
    ) {
      return;
    }

    const project = this.projectService.getProjectClone();

    if (
      changes.selectedBillableRateOption !== undefined &&
      changes.selectedBillableRateOption.currentValue ===
        BillableRateOptions.BillableHourlyValuePerMember
    ) {
      this.projectMemberService.resetBilling(this.gridData, project.startsAt);
      this.projectMemberService.syncProjectModel(this.gridData, project);
    }

    if (
      changes.selectedBudgetOption !== undefined &&
      changes.selectedBudgetOption.currentValue === BudgetOptions.HoursPerMember
    ) {
      this.projectMemberService.resetBudget(this.gridData);
      this.projectMemberService.syncProjectModel(this.gridData, project);
    }
    this.showBillingBudget(project);
  }

  ngAfterViewInit(): void {
    this.initContextMenuCallbacks();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public openInsertMemberDialog(): void {
    // Todo: the projectMemberService is wrong here; this should load all users in an account
    // Todo: load members in dialog
    const dialogRef = this.shiveDialogService.open({
      title: 'Benutzer hinzufügen',
      content: AddMemberDialogComponent,
      width: 572,
    });
    const addMemberDialogComponent = dialogRef.content.instance as AddMemberDialogComponent;
    addMemberDialogComponent.membersInGrid = [...this.projectService.getProjectClone().members];

    dialogRef.result
      .pipe(filter((dialogResult) => dialogResult !== UserOperation.Cancel))
      .subscribe((dialogResult) => {
        const newMembers: UserModel[] = dialogResult as UserModel[];
        this.insertMembers(newMembers);
        const projectClone = this.projectService.getProjectClone();
        this.projectMemberService.syncProjectModel(this.gridData, projectClone);
        this.projectService.userOperationConducted$.next(true);
      });
  }

  public openNewBillableRateDialog(memberId: number): void {
    const ref = this.shiveDialogService.open({
      title: 'Verrechenbarer Stundensatz',
      content: BillingHistoryDialogComponent,
      width: 572,
    });

    const dialogComponent = ref.content.instance as BillingHistoryDialogComponent;
    dialogComponent.billingRateHistory = this.projectMemberService.getHistoryByMemberId(
      this.gridData,
      memberId,
    );

    ref.result
      .pipe(filter((dialogResult) => dialogResult !== UserOperation.Cancel))
      .subscribe((dialogResult) => {
        const billingRateHistory = dialogResult as BillingEntry[];
        this.projectMemberService.setHistoryForMemberId(
          this.gridData,
          memberId,
          billingRateHistory,
        );
        this.projectMemberService.syncProjectModel(
          this.gridData,
          this.projectService.getProjectClone(),
        );
        this.projectService.userOperationConducted$.next(true);
      });
  }

  public inputValueChanged(memberGridRow: MemberGridRow, newValue: unknown): void {
    const projectClone = this.projectService.getProjectClone();
    memberGridRow.budgetHours = +newValue;
    this.projectMemberService.syncProjectModel(this.gridData, projectClone);
    this.projectService.userOperationConducted$.next(true);
  }

  private initContextMenuCallbacks(): void {
    this.gridWrapperComponent.contextMenuRemoveFn = (memberGridRow: MemberGridRow) => {
      this.gridData = this.projectMemberService.removeMember(memberGridRow.member, this.gridData);
      this.projectMemberService.syncProjectModel(
        this.gridData,
        this.projectService.getProjectClone(),
      );
      this.assigneeRemovedEmitter$.next(memberGridRow.member.id);
      this.projectService.userOperationConducted$.next(true);
    };

    this.gridWrapperComponent.setContextMenuItems();
  }

  private showBillingBudget(project: ProjectModel): void {
    this.showBillableValueFormField =
      project.billingType === BillableRateOptions.BillableHourlyValuePerMember;
    this.showHoursPerMemberFormField = project.budgetType === BudgetOptions.HoursPerMember;
  }

  private insertMembers(newMembers: UserModel[]): void {
    newMembers.forEach((member) => {
      this.gridData.push({
        member,
        billingRateHistory: [
          this.billingEntryService.createBillingEntry(
            0,
            this.projectService.getProjectClone().startsAt,
            0,
          ),
        ],
        budgetHours: 0,
      });
    });
  }
}
