import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BaseCalendarModel } from '../../../core/models/calendar/base-calendar.model';
import { CalendarView } from '../../../shared/data-types/calendar-types';

@Pipe({
  name: 'monthAndYear',
})
export class MonthAndYearPipe implements PipeTransform {
  constructor(private readonly sanitizer: DomSanitizer) {}

  /**
   *
   * Use calendarModel as parameter to trigger change detection for this pipe.
   */
  public transform(calendarModel: BaseCalendarModel): SafeHtml {
    if (
      calendarModel.calendarViewMode === CalendarView.DayGrid ||
      calendarModel.calendarViewMode === CalendarView.WeekGrid
    ) {
      const startDate = calendarModel.calendarProperties.visibleStartDate;
      const endDate = calendarModel.calendarProperties.visibleEndDate;

      const curStartMonth = startDate.getMonth();
      const curEndMonth = endDate.getMonth();
      const curStartYear = startDate.getFullYear();
      const curEndYear = endDate.getFullYear();

      const startYearSpan = `<span class="font-weight-normal">${curStartYear}</span>`;
      const endYearSpan = `<span class="font-weight-normal">${curEndYear}</span>`;

      const sanitizedStartYear = this.sanitizer.sanitize(SecurityContext.HTML, startYearSpan);
      const sanitizedEndYear = this.sanitizer.sanitize(SecurityContext.HTML, endYearSpan);

      if (curStartMonth === curEndMonth) {
        return `${startDate.toLocaleString('default', { month: 'long' })} ${sanitizedStartYear}`;
      }
      const abbrStartMonth = `${startDate.toLocaleString('default', { month: 'short' })}. `;
      const abbrEndMonth = `${endDate.toLocaleString('default', { month: 'short' })}. `;
      return `${abbrStartMonth + sanitizedStartYear} - ${abbrEndMonth}${sanitizedEndYear}`;
    }
    const currentMonth = calendarModel.calendarProperties.month;
    const year = calendarModel.calendarProperties.year;

    const formattedDate = new Date(year, currentMonth);
    const startYearSpan = `<span class="font-weight-normal">${year}</span>`;
    const sanitizedStartYear = this.sanitizer.sanitize(SecurityContext.HTML, startYearSpan);
    return `${formattedDate.toLocaleString('default', { month: 'long' })} ${sanitizedStartYear}`;
  }
}
