import { UserModel } from '../user/user.model';
import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { ColorModel } from '../color/color.model';
import { UserOperation } from '../../enums/user-operation';
import { EntityType } from '../../../shared/data-types/entity-types';
import { ProjectTaskModel } from './project-task.model';
import { StatusModel } from './status.model';
import { ProjectBillingEntryModel } from './project-billing.model';
import { ProjectBudgetEntryModel } from './project-budget.model';
import { BillableRateOptions, BudgetOptions } from '../../data-repository/dropdown-constants';
import { DateTimeHelper } from '../../../features/calendar/util/date-time-helper';

export class ProjectModel {
  public id = null;
  public name = '';
  @Expose({ name: 'client_id', toPlainOnly: true })
  @Transform(({ value }) => value?.id ?? null, { toPlainOnly: true })
  public client: EntityType = null;
  @Expose({ name: 'color_id', toPlainOnly: true })
  @Transform(({ value }) => value.id, { toPlainOnly: true })
  public color: ColorModel = null;
  @Expose({ name: 'status_id', toPlainOnly: true })
  @Transform(({ value }) => value.id, { toPlainOnly: true })
  @Type(() => StatusModel)
  public status: StatusModel = null;
  @Expose({ name: 'starts_at' })
  @Transform(({ value }) => DateTimeHelper.truncateTime(value), { toPlainOnly: true })
  @Transform(({ value }) => new Date(value), { toClassOnly: true })
  public startsAt: Date = null;
  @Expose({ name: 'ends_at' })
  @Transform(({ value }) => DateTimeHelper.truncateTime(value), { toPlainOnly: true })
  @Transform(({ value }) => new Date(value), { toClassOnly: true })
  public endsAt: Date = null;
  public remark = '';
  @Expose({ name: 'billingentry_set' })
  @Type(() => ProjectBillingEntryModel)
  public projectBillingEntries: readonly ProjectBillingEntryModel[] = [];
  @Expose({ name: 'budgetentry_set' })
  @Type(() => ProjectBudgetEntryModel)
  public projectBudgetEntries: readonly ProjectBudgetEntryModel[] = [];
  @Expose({ name: 'billing_type' })
  public billingType: BillableRateOptions = BillableRateOptions.NoBilling;
  @Expose({ name: 'budget_type' })
  public budgetType: BudgetOptions = BudgetOptions.NoBudget;
  @Expose({ name: 'tasks' })
  @Type(() => ProjectTaskModel)
  public tasks: readonly ProjectTaskModel[] = [];
  @Expose({ name: 'member_ids', toPlainOnly: true })
  @Type(() => UserModel)
  @Transform(({ value }) => value.map((member) => member.id), { toPlainOnly: true })
  public members: readonly UserModel[] = [];
  @Exclude()
  public userOperation = UserOperation.None;
}
