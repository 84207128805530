import { AbstractControl, ValidatorFn } from '@angular/forms';
import { DateTimeHelper } from '../../features/calendar/util/date-time-helper';

export class CustomSameDateValidator {
  public static uniqueHolidayDateInList(dateList: Date[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (
        typeof control.value !== 'undefined' &&
        dateList.findIndex((date) => DateTimeHelper.isSameDay(date, control.value)) >= 0
      ) {
        return { dateNotUnique: true };
      }
      return null;
    };
  }
}
