import { ITimeBlockComponentItem } from '../../../time-block-component-items';
import { CalendarService } from '../../../../../services/calendar.service';
import { TimeBlockStructureService } from '../../../time-block-structure/time-block-structure.service';
import { Slot } from './slot';
import { IntersectionService } from '../../intersection/intersection.service';
import { DayOrWeekCalendarModel } from '../../../../../../../core/models/calendar/day-or-week-calendar.model';
import { Injectable } from '@angular/core';
import { HorizontalSlotHandlerService } from './horizontal-slot-handler.service';

@Injectable()
export class ParallelTimeBlockWeekViewFulldayCalculatorService {
  constructor(
    private readonly timeBlockStructureService: TimeBlockStructureService,
    private readonly calendarService: CalendarService,
    private readonly intersectionService: IntersectionService,
    private readonly horizontalSlotHandlerService: HorizontalSlotHandlerService,
  ) {}

  public calculate(): ITimeBlockComponentItem[] {
    const fulldayCalendarModel = (this.calendarService.model as DayOrWeekCalendarModel)
      .fulldayCalendarModel;
    const visibleTimeBlockMap = this.timeBlockStructureService.getFulldayHeadParts(
      fulldayCalendarModel.timeBlocks,
    );
    const slots = new Array<Slot>();

    visibleTimeBlockMap.forEach((fulldayTimeBlocksPerDay: ITimeBlockComponentItem[]) => {
      this.horizontalSlotHandlerService.orderFullday(fulldayTimeBlocksPerDay);
      fulldayTimeBlocksPerDay.forEach((timeBlock: ITimeBlockComponentItem) => {
        this.horizontalSlotHandlerService.assignTimeBlockToSlot(
          timeBlock,
          slots,
          this.intersectionService,
        );
      });
    });
    (
      this.calendarService.model as DayOrWeekCalendarModel
    ).fulldayCalendarModel.highestParallelIndex = slots.length;
    return this.horizontalSlotHandlerService.updateGeometryHorizontalSlots(slots);
  }
}
