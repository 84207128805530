import { BaseCalendarModel, BaseCalendarProperties } from './base-calendar.model';
import { MonthOrdinal, WeekOrdinal } from '../../../shared/data-types/calendar-types';
import { DateTimeHelper } from '../../../features/calendar/util/date-time-helper';
import { CalendarConfigModel } from '../app-config/calendar-config.model';

export class MonthCalendarModel extends BaseCalendarModel {
  public fulldayCalendarModel = new FulldayMonthCalendarModel();
  public readonly calendarProperties = new MonthCalendarProperties();

  constructor(calendarOptions?: CalendarConfigModel) {
    super();
    if (!calendarOptions) {
      return;
    }
    this.calendarProperties.weekStartsOn = calendarOptions.weekStartsOn as WeekOrdinal;
    const today = new Date();
    this.calendarProperties.month = today.getMonth() as MonthOrdinal;
    this.calendarProperties.year = today.getFullYear();
    this.calendarProperties.days = [];
    this.calendarProperties.workTimeStart = DateTimeHelper.timeStringToDate(
      calendarOptions.workTimeStart,
    );
    this.calendarProperties.workTimeEnd = DateTimeHelper.timeStringToDate(
      calendarOptions.workTimeEnd,
    );
  }
}

class MonthCalendarProperties extends BaseCalendarProperties {
  public weeks: Date[][];
}

class FulldayMonthCalendarModel extends BaseCalendarModel {}
