import { Injectable } from '@angular/core';
import {
  PRIMARY_OUTLET,
  UrlSegment,
  UrlSegmentGroup,
  UrlSerializer,
  UrlTree,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  constructor(private readonly serializer: UrlSerializer) {}

  public getURLSegments(url: string | UrlTree): UrlSegment[] | null {
    let tree = url;
    if (typeof url === 'string') {
      tree = this.serializer.parse(url);
    }
    const group: UrlSegmentGroup = (tree as UrlTree).root.children[PRIMARY_OUTLET];
    return group?.segments;
  }
}
