import { Injectable } from '@angular/core';
import { webSocket } from 'rxjs/webSocket';
import Autobind from '../../shared/typescript-decorators/autobind.decorator';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

export interface Message {
  source: string;
  content: string;
}

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  public webSocket$: Observable<unknown>;
  private readonly webSocketSub$ = webSocket<string>(environment.wsUrl);

  constructor() {
    this.webSocket$ = this.webSocketSub$.asObservable();
  }

  @Autobind
  public sendMessage(): void {
    this.webSocketSub$.next(JSON.stringify('status'));
  }
}
