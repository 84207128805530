import { Injectable } from '@angular/core';
import { ITimeBlockComponentItem } from '../../time-block-component-items';
import { CalendarService } from '../../../../services/calendar.service';
import { TimeCoordinateMappingService } from '../../../../time-mapping/time-coordinate-mapping.service';
import { DragPart } from '../../interaction/movement/drag-and-resize/drag-part';
import { ResizeData } from '../../interaction/movement/drag-and-resize/resizing/resize-view-controller/vertical-day-or-week-resize-controller.service';
import { TimeBlockDragResizeControllerService } from '../../interaction/movement/drag-and-resize/time-block-drag-resize-handling/time-block-drag-resize-controller.service';
import { TimeBlockDayOrWeekInnerdayType } from '../../../../../../core/models/timeblock/time-block-view-type.model';
import { cloneDeep } from 'lodash-es';
import { TimeBlockGeometryCalculator } from './time-block-geometry-calculator';
import { CalendarGeometryService } from '../../../../services/calendar-geometry.service';
import { filter, take } from 'rxjs/operators';
import { TimeBlockService } from '../../time-block.service';

@Injectable()
export class TimeBlockGeometryService {
  private calculator: TimeBlockGeometryCalculator;

  constructor(
    private readonly calendarService: CalendarService,
    private readonly timeBlockDragResizeControllerService: TimeBlockDragResizeControllerService,
    private readonly calendarGeometryService: CalendarGeometryService,
    private readonly timeCoordinateMapperService: TimeCoordinateMappingService,
    private readonly timeBlockService: TimeBlockService,
  ) {}

  /**
   *
   * Calculate the time block geometry during resizing.
   */
  public calculateVerticalResizeBlockGeometry(
    clonedResizeData: ResizeData,
    snapDurationInPixels: number,
    calendarBodyHeight: number,
  ): void {
    const geometryData =
      this.timeBlockDragResizeControllerService.TransformationTimeBlock.timeBlockModel
        .timeBlockViewType.geometryData;
    const top = geometryData.top;
    const bottom = geometryData.bottom;
    const height = geometryData.height;
    let delta: number;

    if (clonedResizeData.dragPart === DragPart.Top) {
      // Resize by top edge
      delta = top - clonedResizeData.dragEdgePos.y;
      delta =
        Math.abs(delta) < snapDurationInPixels ? Math.sign(delta) * snapDurationInPixels : delta;
      // If delta is very small between the time block edge and the calendar top edge (< 5 pixel),
      // make it at least snapDurationInPixels height (i.e. 5 pixel).
      delta = top - delta < 0 ? top : delta;

      geometryData.top = top - delta;
      geometryData.height = height + delta;
      geometryData.calendarBottomEdgeToTimeBlockTopEdge = bottom + height + delta;
    } else {
      // Resize by bottom edge
      delta = calendarBodyHeight - bottom - clonedResizeData.dragEdgePos.y;
      delta =
        Math.abs(delta) < snapDurationInPixels ? Math.sign(delta) * snapDurationInPixels : delta;

      geometryData.bottom = bottom + delta;
      geometryData.height = height - delta;
      geometryData.calendarTopEdgeToTimeBlockBottomEdge = top + height + delta * -1;
    }

    if (geometryData.top < 0) {
      throw new Error(`Top is invalid: ${geometryData.top}`);
    }

    if (geometryData.bottom < 0) {
      throw new Error(`Bottom is invalid: ${geometryData.bottom}`);
    }

    if (geometryData.height < 0) {
      throw new Error(`Height is invalid: ${geometryData.height}`);
    }
  }

  /**
   * Calculate the time block geometry for each block. Wait until pixelsPerMinute have been calculated after page reload.
   */
  public calculateInitialTimeBlockGeometry(timeBlock: ITimeBlockComponentItem): void {
    this.calculator = new TimeBlockGeometryCalculator(
      this.calendarService,
      this.calendarGeometryService,
      this.timeCoordinateMapperService,
      this.timeBlockService,
    );

    // Inner day time block
    if (timeBlock.timeBlockModel.timeBlockViewType instanceof TimeBlockDayOrWeekInnerdayType) {
      this.timeCoordinateMapperService.pixelsPerMinuteCalculated$
        .pipe(
          take(1),
          filter((ppm) => !!ppm),
        )
        .subscribe(() => {
          this.calcAndEmitInitialGeometryData(timeBlock);
        });
    } else {
      // Full day type
      this.calcAndEmitInitialGeometryData(timeBlock);
    }
  }

  private calcAndEmitInitialGeometryData(timeBlock: ITimeBlockComponentItem): void {
    const clonedTimeBlockAlignmentModel = cloneDeep(timeBlock.timeBlockModel.timeBlockViewType);
    if (timeBlock.timeBlockModel.isFullday) {
      // For week view and month view time blocks that can be resized horizontally
      clonedTimeBlockAlignmentModel.geometryData =
        this.calculator.initializeFulldayTimeBlockGeometry(timeBlock);
    } else {
      // For week view or month view inner day time blocks that can be resized vertically
      clonedTimeBlockAlignmentModel.geometryData =
        this.calculator.initializeInnerdayTimeBlockGeometry(timeBlock);
    }

    timeBlock.timeBlockModel.timeBlockViewType = clonedTimeBlockAlignmentModel;
    this.calendarService.emitCalendarChange(this.calendarService.model, [], timeBlock);
  }
}
