import { ChangeDetectorRef, Directive, Input, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appTimeBlockContainer]',
})
export class TimeBlockContainerDirective {
  @Input() identifier?: string;

  constructor(
    public viewContainerRef: ViewContainerRef,
    public changeDetectorRef: ChangeDetectorRef,
  ) {}
}
