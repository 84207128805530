import { createAction, props } from '@ngrx/store';
import { TimerStatus } from '../../../../shared/data-types/timer-types';
import { ITimeBlockComponentItem } from '../../../../features/calendar/components/time-block/time-block-component-items';

export const postTimer = createAction('[Timer] Post Timer', props<{ payload: TimerStatus }>());
export const timerPosted = createAction(
  '[Timer] Timer Posted',
  props<{
    payload: {
      status: TimerStatus;
      generatedTimeBlock: ITimeBlockComponentItem;
    };
  }>(),
);
