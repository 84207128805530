import { ElementRef } from '@angular/core';
import { SubSink } from 'subsink';
import { DateTimeHelper } from '../../util/date-time-helper';
import { CalendarService } from '../../services/calendar.service';
import { TimeBlockCrudService } from '../../components/time-block/crud/time-block-crud.service';
import { ITimeBlockComponentItem } from '../../components/time-block/time-block-component-items';
import { TimeCoordinateMappingService } from '../../time-mapping/time-coordinate-mapping.service';
import { CalendarBodyCSSId } from '../../../../core/data-repository/css-constants';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { combineLatest, merge } from 'rxjs';
import { SidebarService } from '../../../../core/services/ui/sidebar.service';
import { TimeBlockHttpTransformationService } from '../../components/time-block/http/time-block-http-transformation.service';
import { MonthCalendarModel } from '../../../../core/models/calendar/month-calendar.model';
import { CalendarView } from '../../../../shared/data-types/calendar-types';
import { CalendarViewHandlerService } from '../../services/calendar-view-handler.service';
import { assert } from '../../../../core/assert/assert';

export abstract class ViewHandler {
  public DateTimeHelper = DateTimeHelper;

  protected subs = new SubSink();
  protected initialized = false;
  protected viewportSizeChanged = false;

  protected constructor(
    protected calService: CalendarService,
    protected tbCrudService: TimeBlockCrudService,
    protected tcMapperService: TimeCoordinateMappingService,
    protected sbBarService: SidebarService,
    protected calViewHandlerService: CalendarViewHandlerService,
  ) {
    if (!this.initialized) {
      this.initEvents();

      // If browser window was resized, reload all time blocks and
      // re-calculate all necessary things like time block geometry and calendar geometry
      this.subs.sink = merge(
        this.calService.calendarViewportResized$,
        this.sbBarService.sidebarEvents$,
      )
        .pipe(debounceTime(500))
        .subscribe(() => {
          this.viewportSizeChanged = true;
          this.initCalendarView();
        });
    }
  }

  protected abstract initCalendarView(): void;

  protected abstract initEvents(): void;

  protected abstract setModelTemplateData(): void;

  protected updateCalendarDimensions(itemContainer: ElementRef<HTMLElement>, scrollPosY): void {
    if (itemContainer) {
      const geometry = this.calService.model.geometryData;
      const bcr = itemContainer.nativeElement.getBoundingClientRect();

      geometry.calendarBodyVisibleHeight = document.querySelector(CalendarBodyCSSId)?.clientHeight;
      geometry.calendarBodyElementRef = itemContainer;
      geometry.calendarBodyOffsetLeft = Math.abs(bcr.left);
      geometry.calendarBodyOffsetTop = bcr.top + scrollPosY;
      geometry.calendarBodyWidth = bcr.width;
      geometry.calendarBodyHeight = bcr.height;
      this.calService.calendarViewInitialized$.next(true);
      this.initialized = true;

      // Initialize full day geometry model for month view.
      if (this.calService.model.calendarViewMode === CalendarView.MonthGrid) {
        const fulldayGeometry = (this.calService.model as MonthCalendarModel).fulldayCalendarModel
          .geometryData;
        fulldayGeometry.calendarBodyElementRef = itemContainer;
        fulldayGeometry.calendarBodyOffsetLeft = Math.abs(bcr.left);
        fulldayGeometry.calendarBodyOffsetTop = bcr.top + scrollPosY;
        fulldayGeometry.calendarBodyWidth = bcr.width;
        fulldayGeometry.calendarBodyHeight = bcr.height;
      }
    }
  }

  protected insertTimeBlocksIntoView(
    fulldayTimeBlockMap: Map<string, ITimeBlockComponentItem[]>,
    fulldayTimeBlocks: Map<string, ITimeBlockComponentItem[]>,
    innerdayTimeBlockMap: Map<string, ITimeBlockComponentItem[]>,
    innerdayTimeBlocks: Map<string, ITimeBlockComponentItem[]>,
  ): void {
    this.insertTimeBlocks(fulldayTimeBlockMap, fulldayTimeBlocks);
    this.insertTimeBlocks(innerdayTimeBlockMap, innerdayTimeBlocks);
    // Reset subject so that the values are purged.
    this.tbCrudService.timeBlocksFetched$.next(null);
    this.viewportSizeChanged = false;
  }

  /**
   * Fetch time blocks from server and send it to day view, week view or month view component.
   */
  protected sendTimeBlocksToView(): void {
    this.subs.sink = combineLatest([
      this.tbCrudService.reloadTimeBlocks$,
      this.tcMapperService.pixelsPerMinuteCalculated$,
    ])
      .pipe(
        filter(([reload, ppm]) => reload && !!ppm),
        switchMap(() => {
          const calProperties = this.calService.model.calendarProperties;

          const start = calProperties.offsetStartDate;
          const end = calProperties.offsetEndDate;
          const selectedUserIds = calProperties.selectedUserIds;

          assert(selectedUserIds.length > 0, 'No selected user id set.');

          return this.tbCrudService
            .fetchTimeBlocks(start, end, selectedUserIds, this.viewportSizeChanged)
            .pipe(
              map((timeBlocks) =>
                TimeBlockHttpTransformationService.toTimeBlockMaps(start, end, timeBlocks),
              ),
            );
        }),
        filter((timeBlocks) => !!timeBlocks),
      )
      .subscribe((response) => {
        if (response instanceof Array) {
          this.tbCrudService.timeBlocksFetched$.next(response);
        }
      });
  }

  protected destroy(): void {
    this.tcMapperService.pixelsPerMinuteCalculated$.next(null);
  }

  /**
   * Called after time blocks have been fetched from the server (initial load).
   * 1. Time blocks are inserted in the calendar model.
   * 2. The geometry data is being calculated in TimeBlockComponent (width, height, etc.).
   *
   * @param blockMap Includes the time blocks without geometry data.
   * @param calendarModelMap Includes the time blocks including the geometry data.
   */
  private insertTimeBlocks(
    blockMap: Map<string, ITimeBlockComponentItem[]>,
    calendarModelMap: Map<string, ITimeBlockComponentItem[]>,
  ): void {
    // / Remove all blocks from the calendar and re-render it accordingly.
    calendarModelMap.clear();

    const calendarStart = this.calService.model.calendarProperties.offsetStartDate;
    const calendarEnd = this.calService.model.calendarProperties.offsetEndDate;
    DateTimeHelper.eachDayOfInterval(calendarStart, calendarEnd).forEach((day) => {
      const key = DateTimeHelper.format(day);
      calendarModelMap.set(key, []);
    });

    blockMap.forEach((blockArr) => {
      blockArr.forEach((timeBlock) => {
        this.tbCrudService.insertTimeBlock(timeBlock);
      });
    });
  }
}
