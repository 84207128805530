import { createAction, props } from '@ngrx/store';
import { HolidayTemplateModel } from '../../../models/settings/holiday/holiday-template.model';

export const fetchHolidayTemplates = createAction('[HolidayTemplates] Fetch HolidayTemplates');
export const setHolidayTemplates = createAction(
  '[HolidayTemplates] Set HolidayTemplates',
  props<{ payload: HolidayTemplateModel[] }>(),
);

export const fetchHolidayTemplate = createAction(
  '[HolidayTemplates] Fetch HolidayTemplate',
  props<{ payload: number }>(),
);
export const setHolidayTemplate = createAction(
  '[HolidayTemplates] Set HolidayTemplate',
  props<{ payload: HolidayTemplateModel }>(),
);

export const addHolidayTemplate = createAction(
  '[HolidayTemplates] Add holidayTemplate',
  props<{ payload: HolidayTemplateModel }>(),
);
export const holidayTemplateAdded = createAction(
  '[HolidayTemplates] Added holidayTemplate',
  props<{
    payload: {
      newlyPersistedTemplate: HolidayTemplateModel;
      dummyTemplate: HolidayTemplateModel;
    };
  }>(),
);

export const updateHolidayTemplate = createAction(
  '[HolidayTemplates] Update holidayTemplate',
  props<{ payload: HolidayTemplateModel }>(),
);
export const holidayTemplateUpdated = createAction(
  '[HolidayTemplates] Updated holidayTemplate',
  props<{
    payload: HolidayTemplateModel;
  }>(),
);

export const deleteHolidayTemplate = createAction(
  '[HolidayTemplates] Delete holidayTemplate',
  props<{ payload: HolidayTemplateModel }>(),
);
export const holidayTemplateDeleted = createAction(
  '[HolidayTemplates] Deleted holidayTemplate',
  props<{
    payload: HolidayTemplateModel;
  }>(),
);

export const httpFail = createAction('[HolidayTemplates] Http fail', props<{ payload: string }>());
