import { Observable, of } from 'rxjs';
import { LogEntry } from './log-entry';

export abstract class LogPublisher {
  location: string;

  abstract log(entry: LogEntry): Observable<boolean>;

  abstract clear(): Observable<boolean>;
}

export class LogConsole extends LogPublisher {
  public log(entry: LogEntry): Observable<boolean> {
    // Log to console
    // eslint-disable-next-line no-console
    console.log(entry.buildLogString());
    return of(true);
  }

  public clear(): Observable<boolean> {
    // eslint-disable-next-line no-console
    console.clear();
    return of(true);
  }
}

export class LogLocalStorage extends LogPublisher {
  constructor() {
    // Must call `super()`from derived classes
    super();

    // Set location
    this.location = 'logging';
  }

  // Append log entry to local storage
  public log(entry: LogEntry): Observable<boolean> {
    let ret: boolean = false;
    let values: LogEntry[];

    try {
      // Get previous values from local storage
      values = JSON.parse(localStorage.getItem(this.location)) || [];
      // Add new log entry to array
      values.push(entry);
      // Store array into local storage
      localStorage.setItem(this.location, JSON.stringify(values));
      // Set return value
      ret = true;
    } catch (ex) {
      // Display error in console
      // eslint-disable-next-line no-console
      console.log(ex);
    }

    return of(ret);
  }

  // Clear all log entries from local storage
  public clear(): Observable<boolean> {
    localStorage.removeItem(this.location);
    return of(true);
  }
}
