import { Component } from '@angular/core';

@Component({
  selector: 'app-user-profile-working-hours',
  templateUrl: './user-profile-working-hours.component.html',
  styleUrl: './user-profile-working-hours.component.scss',
})
export class UserProfileWorkingHoursComponent {
  public gridData: any;
}
