<div class="form-wrapper">
  <form [formGroup]="employmentDataForm" class="k-form two-column-layout k-d-grid">
    <div class="col">
      <kendo-formfield orientation="horizontal">
        <kendo-label
          class="!k-align-items-start"
          [for]="paymentType"
          text="Bezahlung"
        ></kendo-label>
        <kendo-dropdownlist
          textField="text"
          valueField="value"
          [data]="paymentTypes"
          [id]="formControlNames.PaymentType"
          [formControlName]="formControlNames.PaymentType"
          #paymentType
        >
        </kendo-dropdownlist>
        <kendo-formerror>Error: Username is required</kendo-formerror>
      </kendo-formfield>
    </div>

    <div class="col">
      <kendo-formfield orientation="horizontal">
        <kendo-label class="!k-align-items-start" [for]="amount" text="Betrag"></kendo-label>
        <kendo-textbox showErrorIcon="initial" #amount> </kendo-textbox>
        <kendo-formerror>Error: Username is required</kendo-formerror>
      </kendo-formfield>
    </div>
  </form>
</div>
