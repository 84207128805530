<app-page-header></app-page-header>

<!-- Base data -->
<div class="card-wrapper">
  <app-holiday-settings></app-holiday-settings>
</div>

<div class="card-wrapper k-mt-10">
  <app-absence-reason-settings></app-absence-reason-settings>
</div>

<app-page-footer>
  <ng-template #controlBarTemplate>
    <!-- Local reference is needed -->
    <button kendoButton (click)="cancel()" themeColor="secondary" size="large">Abbrechen</button>
    <button
      class="ms-3"
      (click)="save()"
      kendoButton
      [disabled]="saveButtonDisabled"
      themeColor="base"
      size="large"
    >
      <span class="custom-symbol save k-mr-3"></span>
      <span> Speichern </span>
    </button>
  </ng-template>
</app-page-footer>
