import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { UserOperation } from '../../../../core/enums/user-operation';
import { DropdownOption } from '../../../data-types/dropdown-option';
import { faCopy, faPenToSquare, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { DropDownListComponent, PreventableEvent } from '@progress/kendo-angular-dropdowns';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';
import Autobind from '../../../typescript-decorators/autobind.decorator';
import { ShiveDialogService } from '../../../../core/services/controls/shive-dialog.service';

const matches = (el, selector) => (el.matches || el.msMatchesSelector).call(el, selector);

/**
 * This component encapsulates the vanilla kendo-ui dropdownlist and adds features like item duplication, deletion, etc.
 * For standard dropdown lists, use the vanilla kendo-ui dropdownlist component.
 */
@Component({
  selector: 'app-shive-dropdownlist',
  templateUrl: './shive-dropdownlist.component.html',
  styleUrls: ['./shive-dropdownlist.component.scss'],
})
export class ShiveDropdownlistComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() data: readonly DropdownOption[];
  @Input() selectedValue: DropdownOption;
  @Input() permittedUserOperations: readonly UserOperation[];
  @Output() readonly addBtnClicked = new EventEmitter();
  @Output() readonly editBtnClicked = new EventEmitter();
  @Output() readonly cloneBtnClicked = new EventEmitter();
  @Output() readonly removeBtnClicked = new EventEmitter();
  @Output() readonly afterValueChanged = new EventEmitter();
  @ViewChild('dropdownlist') public dropdownlist: DropDownListComponent;
  public readonly UserOperation = UserOperation;
  public readonly faPenToSquare = faPenToSquare;
  public readonly faCopy = faCopy;
  public readonly faTrash = faTrash;
  public readonly faPlus = faPlus;
  public popupSettings = {
    popupClass: 'shive-dropdownlist-popup',
  };
  public userOperation = UserOperation.None;
  private readonly docClickSubscription: Subscription = new Subscription();
  private oldSelectedValue: DropdownOption;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private readonly shiveDialogService: ShiveDialogService,
    private readonly renderer: Renderer2,
  ) {
    this.shiveDialogService.onOutsideClickSub.subscribe(() => this.dropdownlist.toggle(false));
  }

  ngOnInit(): void {
    this.docClickSubscription.add(this.renderer.listen('document', 'click', this.onDocumentClick));
  }

  ngOnDestroy(): void {
    this.docClickSubscription.unsubscribe();
  }

  public selectionChange(dataItem): void {
    if (this.userOperation === UserOperation.Create) {
      this.oldSelectedValue = this.selectedValue;
      window.setTimeout(() => {
        this.selectedValue = this.oldSelectedValue;
        this.userOperation = UserOperation.None;
      });
    }
    this.afterValueChanged.next(dataItem);
  }

  public add(): void {}

  public edit(dataItem): void {
    this.userOperation = UserOperation.Update;
    this.editBtnClicked.emit(dataItem);
  }

  public duplicate(dataItem): void {
    this.userOperation = UserOperation.Create;
    this.cloneBtnClicked.emit(dataItem);
  }

  public remove(dataItem): void {
    this.userOperation = UserOperation.Delete;
    this.removeBtnClicked.emit(dataItem);
  }

  public onClose($event: PreventableEvent): void {
    if (
      this.userOperation === UserOperation.Create ||
      this.userOperation === UserOperation.Update
    ) {
      $event.preventDefault();
    }
  }

  @Autobind
  private onDocumentClick(e): void {
    if (
      !matches(
        e.target,
        '.k-dropdownlist, .k-dropdownlist *, .k-dialog-wrapper, .k-dialog-wrapper *, .controls, .controls *',
      )
    ) {
      this.dropdownlist.toggle(false);
    }
  }
}
