import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SecurityContext,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ProjectModel } from '../../../../../../core/models/project/project.model';
import { ShiveGridComponent } from '../../../../../../shared/components/form-components/shive-grid/shive-grid.component';
import { UserOperation } from 'src/app/core/enums/user-operation';
import {
  BillableRateOptions,
  BudgetOptions,
} from '../../../../../../core/data-repository/dropdown-constants';
import { faListCheck } from '@fortawesome/pro-light-svg-icons';
import { SubSink } from 'subsink';
import { ProjectTaskDialogComponent } from '../../dialogs/project-task-dialog/project-task-dialog.component';
import { ShiveDialogService } from '../../../../../../core/services/controls/shive-dialog.service';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { BillingEntry, TaskGridRow } from '../../../../../../shared/data-types/project-types';
import { ProjectService } from '../../../../services/project.service';
import { ProjectTaskService } from '../../services/project-task.service';
import { filter } from 'rxjs/operators';
import { ProjectTaskModel } from '../../../../../../core/models/project/project-task.model';
import { ProjectBillingBudgetTransformerService } from '../../services/project-billing-budget-transformer.service';
import { BillingHistoryDialogComponent } from '../../dialogs/billing-history-dialog/billing-history-dialog.component';
import { BillingEntryService } from '../project-calculation-form/services/billing-entry.service';

@Component({
  selector: 'app-project-task-grid',
  templateUrl: './project-task-grid.component.html',
  styleUrls: ['./project-task-grid.component.scss'],
})
export class ProjectTaskGridComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() selectedBillableRateOption: BillableRateOptions;
  @Input() selectedBudgetOption: BudgetOptions;
  @Input() set removedAssigneeId(assigneeId: number) {
    this.projectTaskService.setUnassignedUserForRemovedAssignee(this.gridData, assigneeId);
  }
  @ViewChild('gridWrapperComponent') gridWrapperComponent: ShiveGridComponent;
  // public budgetedTasks: BudgetedTask[] = []; // BudgetedTask: id and taskId are the same for this data type.
  public showHourlyBillingRateFormField = false;
  public showBillingRateFormField = false;
  public showBudgetFeesPerTaskFormField = false;
  public showBudgetHoursPerTaskFormField = false;
  public billableValueColumnTitle = 'Verrechenbarer Preis';
  public budgetColumnTitle = 'Budget';
  public hoursPerTaskColumnTitle = 'Budget';
  public readonly UserOperation = UserOperation;
  public readonly SecurityContext = SecurityContext;
  public readonly faListCheck = faListCheck;
  public readonly faPenToSquare = faPenToSquare;
  public gridData: TaskGridRow[] = [];
  private readonly subs = new SubSink();

  constructor(
    private readonly shiveDialogService: ShiveDialogService,
    private readonly projectService: ProjectService,
    private readonly projectTaskService: ProjectTaskService,
    private readonly projectBillingBudgetTransformer: ProjectBillingBudgetTransformerService,
    private readonly billingEntryService: BillingEntryService,
  ) {}

  ngOnInit(): void {
    const projectClone = this.projectService.getProjectClone();

    projectClone.tasks.forEach((task) => {
      this.gridData.push({ task, budgetHours: 0, budgetCosts: 0, billingRateHistory: [] });
    });

    this.projectBillingBudgetTransformer.transformBillingAndBudgetEntriesToTaskGridData(
      this.gridData,
      projectClone,
    );
    this.showBilling(projectClone);
    this.showBudget(projectClone);
  }

  ngAfterViewInit(): void {
    this.initContextMenuCallbacks();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // If values are initialized (firstChange === true), return.
    if (
      changes.selectedBillableRateOption?.firstChange ||
      changes.selectedBudgetOption?.firstChange
    ) {
      return;
    }

    const projectClone = this.projectService.getProjectClone();

    if (changes.selectedBillableRateOption !== undefined) {
      if (
        changes.selectedBillableRateOption.currentValue ===
          BillableRateOptions.BillableHourlyValuePerTask ||
        changes.selectedBillableRateOption.currentValue === BillableRateOptions.BillableValuePerTask
      ) {
        this.projectTaskService.resetBilling(this.gridData);
        this.projectTaskService.syncProjectModel(this.gridData, projectClone);
      }
    }

    if (changes.selectedBudgetOption !== undefined) {
      if (
        changes.selectedBudgetOption.currentValue === BudgetOptions.HoursPerTask ||
        changes.selectedBudgetOption.currentValue === BudgetOptions.CostsPerTask
      ) {
        this.projectTaskService.resetBudget(this.gridData);
        this.projectTaskService.syncProjectModel(this.gridData, projectClone);
      }
    }

    this.showBilling(projectClone);
    this.showBudget(projectClone);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public openTaskDialog(userOperation: UserOperation, task?: ProjectTaskModel): void {
    const ref = this.shiveDialogService.open({
      content: ProjectTaskDialogComponent,
      width: 572,
      title: 'Aufgabe hinzufügen',
    });

    const projectTaskDialogComponent = ref.content.instance as ProjectTaskDialogComponent;
    projectTaskDialogComponent.task = task;
    projectTaskDialogComponent.userOperation = userOperation;

    ref.result
      .pipe(filter((result) => result !== UserOperation.Cancel))
      .subscribe((result: ProjectTaskModel) => {
        const projectClone = this.projectService.getProjectClone();
        this.upsertTask(result);
        this.projectTaskService.syncProjectModel(this.gridData, projectClone);
        this.projectService.userOperationConducted$.next(true);
      });

    const addTaskDialogComponent = ref.content.instance as ProjectTaskDialogComponent;
    addTaskDialogComponent.users = this.projectService.getProjectClone().members;
  }

  public inputValueChanged(gridData: TaskGridRow, newValue: unknown, columnType: string): void {
    if (columnType === 'budgetCosts') {
      gridData.budgetCosts = +newValue;
    } else if (columnType === 'budgetHours') {
      gridData.budgetHours = +newValue;
    } else {
      // billingRate
      gridData.billingRateHistory[gridData.billingRateHistory.length - 1].billingRate = +newValue;
    }
    this.projectTaskService.syncProjectModel(this.gridData, this.projectService.getProjectClone());

    this.projectService.userOperationConducted$.next(true);
  }

  public openNewBillableRateDialog(taskId: number): void {
    const ref = this.shiveDialogService.open({
      title: 'Verrechenbarer Stundensatz',
      content: BillingHistoryDialogComponent,
      width: 572,
    });

    const dialogComponent = ref.content.instance as BillingHistoryDialogComponent;
    dialogComponent.billingRateHistory = this.projectTaskService.getHistoryByTaskId(
      this.gridData,
      taskId,
    );

    ref.result
      .pipe(filter((dialogResult) => dialogResult !== UserOperation.Cancel))
      .subscribe((dialogResult) => {
        const billingRateHistory = dialogResult as BillingEntry[];
        this.projectTaskService.setHistoryForTaskId(this.gridData, taskId, billingRateHistory);
        this.projectTaskService.syncProjectModel(
          this.gridData,
          this.projectService.getProjectClone(),
        );
        this.projectService.userOperationConducted$.next(true);
      });
  }

  private showBudget(project: ProjectModel): void {
    const projectBudgetType = project.budgetType;
    if (projectBudgetType === BudgetOptions.CostsPerTask) {
      this.showBudgetFeesPerTaskFormField = true;
      this.showBudgetHoursPerTaskFormField = false;
    } else if (projectBudgetType === BudgetOptions.HoursPerTask) {
      this.showBudgetFeesPerTaskFormField = false;
      this.showBudgetHoursPerTaskFormField = true;
    } else {
      this.showBudgetFeesPerTaskFormField = false;
      this.showBudgetHoursPerTaskFormField = false;
    }
  }

  private showBilling(project: ProjectModel): void {
    this.showHourlyBillingRateFormField =
      project.billingType === BillableRateOptions.BillableHourlyValuePerTask;
    this.showBillingRateFormField =
      project.billingType === BillableRateOptions.BillableValuePerTask;
  }

  /**
   * Add a new task if id is negative or update an existing task
   * @param task
   * @private
   */
  private upsertTask(task: ProjectTaskModel): void {
    const updatingTask = this.gridData.find((taskGridRow) => taskGridRow.task.id === task.id);

    // create a new task
    if (!updatingTask) {
      this.gridData.push({
        task,
        budgetHours: 0,
        budgetCosts: 0,
        billingRateHistory: [
          this.billingEntryService.createBillingEntry(
            0,
            this.projectService.getProjectClone().startsAt,
            0,
          ),
        ],
      });
    } else {
      // update existing task
      updatingTask.task.name = task.name;
      updatingTask.task.assignee = task.assignee;
      updatingTask.task.status = task.status;
    }
  }

  private initContextMenuCallbacks(): void {
    this.gridWrapperComponent.contextMenuEditFn = (taskGridRow: TaskGridRow) => {
      this.openTaskDialog(UserOperation.Update, taskGridRow.task);
    };

    this.gridWrapperComponent.contextMenuRemoveFn = (taskGridRow: TaskGridRow) => {
      this.projectTaskService.removeTask(taskGridRow.task);
      this.projectTaskService.removeTaskGridRow(this.gridData, taskGridRow);
      this.projectTaskService.syncProjectModel(
        this.gridData,
        this.projectService.getProjectClone(),
      );
      this.projectService.userOperationConducted$.next(true);
    };

    this.gridWrapperComponent.setContextMenuItems();
  }
}
