import { Observable } from 'rxjs';

export type DropdownOption = {
  text: string;
  value: number | string;
};

export type GroupedDropdownOption = DropdownOption & {
  category: string;
};

export class DropdownsWithUnassigned<T> extends Observable<T> {}

export const DropdownDefaultItem = {
  text: 'Nicht zugewiesen',
  value: null,
};
