<div id="user-profile-base-data-wrapper">
  <div>
    <div class="card-wrapper">
      <div class="card">
        <div class="card-header">
          <div class="title-row">
            <div class="title k-d-flex k-align-items-center">
              <h2 class="mb-0">Profildaten</h2>
            </div>
          </div>
        </div>

        <div class="card-body">
          <app-user-profile-personal-data />
        </div>
      </div>
    </div>

    <div class="card-wrapper k-mt-10">
      <div class="card">
        <div class="card-header">
          <div class="title-row">
            <div class="title k-d-flex k-align-items-center">
              <h2 class="mb-0">Beschäftigung</h2>
            </div>
          </div>
        </div>

        <div class="card-body">
          <app-user-profile-employment-data />
        </div>
      </div>
    </div>

    <div class="card-wrapper k-mt-10">
      <div class="card">
        <div class="card-header">
          <div class="title-row">
            <div class="title k-d-flex k-align-items-center">
              <h2 class="mb-0">Gehalt</h2>
            </div>
          </div>
        </div>

        <div class="card-body">
          <app-user-profile-salary-data />
        </div>
      </div>
    </div>
  </div>

  <aside id="quicklinks" class="k-pl-10 k-pos-sticky">
    <div class="card-wrapper">
      <div class="card">
        <div class="card-header">
          <div class="title-row">
            <div class="title k-d-flex k-align-items-center">
              <h2 class="mb-0">Inhalt</h2>
            </div>
          </div>
        </div>

        <div id="link-list" class="card-body">
          <a href="" class="item k-d-block k-pos-relative active">Persönliche Daten</a>
          <a href="" class="item k-d-block k-pos-relative">Beschäftigung</a>
          <a href="" class="item k-d-block k-pos-relative">Gehalt</a>
        </div>
      </div>
    </div>
  </aside>
</div>
