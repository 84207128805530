import { Component, OnInit } from '@angular/core';
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShiveDialogService } from '../../../../../../core/services/controls/shive-dialog.service';
import { ClientModel } from '../../../../../../core/models/client/client.model';
import { UserOperation } from '../../../../../../core/enums/user-operation';

@Component({
  selector: 'app-create-client-dialog',
  templateUrl: './create-client-dialog.component.html',
  styleUrls: ['./create-client-dialog.component.scss'],
})
export class CreateClientDialogComponent implements OnInit {
  public clientForm: FormGroup;
  public readonly faCirclePlus = faCirclePlus;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly shiveDialogService: ShiveDialogService,
  ) {}

  ngOnInit(): void {
    this.clientForm = this.formBuilder.group({
      clientName: ['', Validators.required],
    });
  }

  public close(): void {
    this.shiveDialogService.close(UserOperation.Cancel);
  }

  public submit(): void {
    const formData = this.clientForm.value;

    const clientModel = new ClientModel();
    clientModel.name = formData.clientName;
    this.shiveDialogService.close(clientModel);
  }
}
