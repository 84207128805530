import { TimeBlockStructureService } from '../../../time-block-structure/time-block-structure.service';
import { CalendarService } from '../../../../../services/calendar.service';
import { IntersectionService } from '../../intersection/intersection.service';
import { ITimeBlockComponentItem } from '../../../time-block-component-items';
import { MonthCalendarModel } from '../../../../../../../core/models/calendar/month-calendar.model';
import { DateTimeHelper } from '../../../../../util/date-time-helper';
import { MonthViewSlotVectorService } from './month-view-slot-vector.service';
import { SlotMonthViewTimeBlock } from './slot-month-view-time-block';
import { Injectable } from '@angular/core';
import { HorizontalSlotHandlerService } from './horizontal-slot-handler.service';
import { Interval } from 'date-fns';

@Injectable()
export class ParallelTimeBlockMonthViewCalculatorService {
  constructor(
    private readonly timeBlockStructureService: TimeBlockStructureService,
    private readonly calendarService: CalendarService,
    private readonly intersectionService: IntersectionService,
    private readonly horizontalSlotHandlerService: HorizontalSlotHandlerService,
  ) {}

  calculate(): ITimeBlockComponentItem[] {
    const calendarModel = (this.calendarService.model as MonthCalendarModel).fulldayCalendarModel;
    const fullRange: Interval = {
      start: calendarModel.calendarProperties.visibleStartDate,
      end: calendarModel.calendarProperties.visibleEndDate,
    };
    const timeBlockMapFullday = this.timeBlockStructureService.getTimeBlockMap(true, fullRange);
    const fullDayTimeBlockHeadMap =
      this.timeBlockStructureService.getFulldayHeadParts(timeBlockMapFullday);
    const timeBlockMapInnerday = this.timeBlockStructureService.getTimeBlockMap(false, fullRange);

    const slotVector = new MonthViewSlotVectorService(fullRange.start as Date);

    // fill in full day time blocks first
    fullDayTimeBlockHeadMap.forEach(
      (fulldayTimeBlocksPerDay: ITimeBlockComponentItem[], date: string): void => {
        const slotsPerWeek = slotVector.getSlotsPerWeek(DateTimeHelper.parseISO(date));
        this.horizontalSlotHandlerService.orderFullday(fulldayTimeBlocksPerDay);
        fulldayTimeBlocksPerDay.forEach((timeBlock: ITimeBlockComponentItem) => {
          this.horizontalSlotHandlerService.assignTimeBlockToSlot(
            timeBlock,
            slotsPerWeek,
            this.intersectionService,
          );
        });
      },
    );

    // then fill slots with inner day time blocks
    timeBlockMapInnerday.forEach(
      (innerdayTimeBlocksPerDay: ITimeBlockComponentItem[], dateString: string): void => {
        const date = DateTimeHelper.parseISO(dateString);
        const slotsPerWeek = slotVector.getSlotsPerWeek(date);
        this.horizontalSlotHandlerService.orderInnerdayByStartDate(innerdayTimeBlocksPerDay);
        innerdayTimeBlocksPerDay.forEach((timeBlock: ITimeBlockComponentItem) => {
          this.horizontalSlotHandlerService.assignTimeBlockToSlot(
            SlotMonthViewTimeBlock.fromITimeBlockComponentItem(
              timeBlock,
              DateTimeHelper.startOfDay(date),
              DateTimeHelper.endOfDay(date),
            ),
            slotsPerWeek,
            this.intersectionService,
          );
        });
      },
    );

    slotVector.removeWrapper();
    return slotVector.updateGeometry(
      this.horizontalSlotHandlerService.updateGeometryHorizontalSlots,
    );
  }
}
