@if (header !== null) {
  <div class="context-header-wrapper k-pos-relative px-3">
    <ng-container [ngTemplateOutlet]="header"></ng-container>
  </div>
}

<div class="context-content-wrapper k-pos-relative">
  @for (item of items; track item) {
    <div class="context-item k-d-flex {{ item.itemClass }}" (click)="click(item)" role="button">
      <span class="icon-button k-d-flex k-align-items-center">
        <fa-icon class="k-mr-3 {{ item.iconColorClass }}" [icon]="item.faIcon"></fa-icon>
      </span>
      <span>{{ item.text }}</span>
    </div>
  }
</div>

@if (footer !== null) {
  <div class="context-footer-wrapper k-pos-relative">
    <ng-container [ngTemplateOutlet]="footer"></ng-container>
  </div>
}
