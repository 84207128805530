import { Injectable } from '@angular/core';
import { ITimeBlockComponentItem } from '../time-block-component-items';
import { DataStructureHelper } from '../../../../../shared/data-structures/data-structure-helper';
import { DayOrWeekCalendarModel } from '../../../../../core/models/calendar/day-or-week-calendar.model';
import { MonthCalendarModel } from '../../../../../core/models/calendar/month-calendar.model';
import { CalendarService } from '../../../services/calendar.service';

@Injectable()
export class TimeBlockMappingService {
  constructor(private readonly calendarService: CalendarService) {}

  /** Retrieve all time blocks of the calendar model. */
  public timeBlockInnerdayMapToArray(): ITimeBlockComponentItem[][] {
    return DataStructureHelper.toArray(this.calendarService.model.timeBlocks);
  }

  /** Retrieve all full day time blocks of the calendar model. */
  public timeBlockFulldayBlockMapToArray(): ITimeBlockComponentItem[][] {
    const model = this.calendarService.model as DayOrWeekCalendarModel | MonthCalendarModel;
    if (!model.fulldayCalendarModel?.timeBlocks) {
      throw new Error('Time blocks are not set.');
    }
    return DataStructureHelper.toArray(model.fulldayCalendarModel.timeBlocks);
  }
}
