<app-shive-grid #gridWrapperComponent [grid]="grid">
  <kendo-grid
    kendoGridSelectBy="id"
    scrollable="none"
    [data]="gridData"
    [pageSize]="20"
    [selectable]="{ enabled: true, checkboxOnly: true }"
    [columnMenu]="{ filter: true }"
    (add)="openTaskDialog(UserOperation.Create)"
    #grid
  >
    <ng-template kendoGridToolbarTemplate>
      <div class="k-d-flex k-justify-content-end k-w-full">
        <button
          kendoGridAddCommand
          size="medium"
          themeColor="secondary"
          [disabled]="gridWrapperComponent.userOperation !== UserOperation.None"
        >
          <fa-icon class="icon turquoise k-mr-3 k-mt-0.5" size="lg" [icon]="faListCheck"></fa-icon>
          <span>Aufgabe hinzufügen</span>
        </button>
      </div>
    </ng-template>

    <kendo-grid-checkbox-column
      [width]="40"
      [headerClass]="{ 'k-text-center': true }"
      class="list-selection-check"
      [resizable]="false"
      [columnMenu]="false"
      [showSelectAll]="true"
    >
    </kendo-grid-checkbox-column>

    <kendo-grid-column field="task_name" title="Bezeichnung" [class]="'!k-white-space-nowrap'">
      <ng-template kendoGridCellTemplate let-row>
        <div class="task-name">{{ row.task.name }}</div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="assigned_to" title="Zugewiesen" [class]="'cell-with-avatar'">
      <ng-template kendoGridCellTemplate let-row>
        <app-shared-member-template [user]="row.task.assignee"></app-shared-member-template>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="task_status" title="Status">
      <ng-template kendoGridCellTemplate let-row>
        <div class="task-status">{{ row.task.status.name }}</div>
      </ng-template>
    </kendo-grid-column>

    @if (showHourlyBillingRateFormField) {
      <kendo-grid-column field="billable_hourly_value_per_task" [title]="billableValueColumnTitle">
        <ng-template kendoGridCellTemplate let-row>
          <div class="k-d-flex">
            <span class="prefix-currency">&euro;</span>
            {{ +row.billingRateHistory[row.billingRateHistory.length - 1].billingRate }}
            <fa-icon
              class="edit turquoise icon-button k-display-flex k-align-items-stretch k-ml-3.5 fa-md"
              role="button"
              (click)="openNewBillableRateDialog(row.task.id)"
              [icon]="faPenToSquare"
            ></fa-icon>
          </div>
        </ng-template>
      </kendo-grid-column>
    }

    @if (showBillingRateFormField) {
      <kendo-grid-column field="billable_value_per_task" [title]="billableValueColumnTitle">
        <ng-template kendoGridCellTemplate let-row>
          <div class="k-d-flex">
            <span class="prefix-currency">&euro;</span>
            <kendo-numerictextbox
              [spinners]="false"
              format="m2"
              [value]="+row.billingRateHistory[row.billingRateHistory.length - 1].billingRate"
              (valueChange)="inputValueChanged(row, $event, 'billingRate')"
            >
            </kendo-numerictextbox>
          </div>
        </ng-template>
      </kendo-grid-column>
    }

    @if (showBudgetFeesPerTaskFormField) {
      <kendo-grid-column field="budget_per_task" [title]="budgetColumnTitle">
        <ng-template kendoGridCellTemplate let-row>
          <div class="k-d-flex">
            <span class="prefix-currency">&euro;</span>
            <kendo-numerictextbox
              [spinners]="false"
              format="m2"
              [value]="+row.budgetCosts"
              (valueChange)="inputValueChanged(row, $event, 'budgetCosts')"
            >
            </kendo-numerictextbox>
          </div>
        </ng-template>
      </kendo-grid-column>
    }

    @if (showBudgetHoursPerTaskFormField) {
      <kendo-grid-column field="hours_per_task" [title]="hoursPerTaskColumnTitle">
        <ng-template kendoGridCellTemplate let-row>
          <div class="k-d-flex">
            <kendo-numerictextbox
              [spinners]="false"
              [value]="+row.budgetHours"
              (valueChange)="inputValueChanged(row, $event, 'budgetHours')"
            >
            </kendo-numerictextbox>
            <span class="postfix-hours">Stunden</span>
          </div>
        </ng-template>
      </kendo-grid-column>
    }

    <kendo-grid-column [columnMenu]="false" [width]="40">
      <ng-template kendoGridCellTemplate let-dataItem>
        <app-context-menu-anchor
          [dataItem]="dataItem"
          [gridWrapperComponent]="gridWrapperComponent"
        />
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</app-shive-grid>
