import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ShiveDialogService } from '../services/controls/shive-dialog.service';
import { PendingChangesDialogComponent } from '../../shared/components/dialogs/pending-changes-dialog/pending-changes-dialog.component';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PendingChangesGuard {
  constructor(private readonly shiveDialogService: ShiveDialogService) {}

  canDeactivate(
    component: ComponentCanDeactivate,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (component.canDeactivate()) {
      return true;
    }
    // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
    // when navigating away from your angular app, the browser will show a generic warning message
    // see http://stackoverflow.com/a/42207299/7307355
    const ref = this.shiveDialogService.open({
      title: 'Änderungen verwerfen',
      content: PendingChangesDialogComponent,
      width: 572,
    });

    return (ref.content.instance as PendingChangesDialogComponent).onClose$.pipe(
      map((result) => result),
    );
  }
}

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}
