import { Injectable } from '@angular/core';
import {
  CollisionHandlerService,
  IntersectionType,
} from '../../../collision-handling/collision-handler.service';
import { CardinalPoints, DragDirection, DragPart } from '../../drag-part';
import { CalendarService } from '../../../../../../../services/calendar.service';
import { TimeBlockCrudService } from '../../../../../crud/time-block-crud.service';
import { Subject, combineLatest } from 'rxjs';
import { Position } from '../../../../../../../../../shared/data-structures/position';
import { TimeCoordinateMappingService } from '../../../../../../../time-mapping/time-coordinate-mapping.service';
import { DayOrWeekCalendarModel } from '../../../../../../../../../core/models/calendar/day-or-week-calendar.model';
import { TimeBlockDragResizeControllerService } from '../../time-block-drag-resize-handling/time-block-drag-resize-controller.service';
import { TimeBlockGeometryService } from '../../../../../calculation/geometry/time-block-geometry.service';
import { AbstractResizeController } from '../abstract-resize-controller';
import { DragHandlerService } from '../../drag-handler.service';
import { filter, map, switchMap } from 'rxjs/operators';
import { TimeBlockGeometryData } from '../../../../../../../../../core/models/timeblock/time-block-geometry-data.model';
import {
  CalendarEvents,
  CalendarView,
} from '../../../../../../../../../shared/data-types/calendar-types';
import { TimeBlockDayOrWeekInnerdayType } from '../../../../../../../../../core/models/timeblock/time-block-view-type.model';
import { ResizeRowOrLaneSwitchService } from '../resize-row-or-lane-switch.service';
import { TimeBlockService } from '../../../../../time-block.service';
import { CalendarServiceHelper } from '../../../../../../../services/calendar-service-helper';
import { ResizeTimeBlockScheduleCalculatorService } from '../resize-time-block-schedule-calculator.service';
import { ResizeCalculatorService } from '../resize-calculator.service';
import { CalendarMouseHandlerService } from '../../../../../../../mouse/calendar-mouse-handler.service';
import { TimeBlockDialogService } from '../../../../../services/time-block-dialog.service';

@Injectable()
export class VerticalDayOrWeekResizeControllerService extends AbstractResizeController {
  public resizeStarted$ = new Subject<DragPart>();

  private calendarBodyHeight = -1;
  private snapDurationInPixels = -1;
  private cursorDeviation = -1; // / Gap between mouse cursor and drag edge.
  private minimumTimeBlockHeight = -1;

  constructor(
    private readonly calendarService: CalendarService,
    private readonly calendarMouseHandlerService: CalendarMouseHandlerService,
    private readonly timeBlockCrudService: TimeBlockCrudService,
    private readonly timeCoordinateMappingService: TimeCoordinateMappingService,
    private readonly timeBlockService: TimeBlockService,
    private readonly timeBlockDialogService: TimeBlockDialogService,
    private readonly timeBlockGeometryService: TimeBlockGeometryService,
    private readonly timeBlockDragResizeControllerService: TimeBlockDragResizeControllerService,
    private readonly collisionHandlerService: CollisionHandlerService,
    private readonly dragHandlerService: DragHandlerService,
    private readonly resizeCalculatorService: ResizeCalculatorService,
    private readonly resizeRowOrLaneSwitchService: ResizeRowOrLaneSwitchService,
    private readonly resizeTimeBlockScheduleCalculatorService: ResizeTimeBlockScheduleCalculatorService,
  ) {
    super(timeBlockDragResizeControllerService, timeBlockDialogService, timeBlockCrudService);
    this.initEvents();
  }

  public resize(calendarMousePos: Position, dragDirection: DragDirection): void {
    if (!this.timeBlockDragResizeControllerService.TransformationTimeBlock) {
      return;
    }

    // If user creates a new time block via dragging, drag part is initially undefined.
    if (
      this.timeBlockDragResizeControllerService.DragPart === DragPart.TopOrBottom &&
      dragDirection.vertical !== CardinalPoints.None
    ) {
      this.timeBlockDragResizeControllerService.DragPart =
        dragDirection.vertical === CardinalPoints.North ? DragPart.Top : DragPart.Bottom;
    }

    const resizeData = new ResizeData();
    resizeData.mousePos = {
      x: calendarMousePos.x,
      y: calendarMousePos.y,
    };
    resizeData.dragEdgePos = {
      x: calendarMousePos.x,
      y: calendarMousePos.y + this.cursorDeviation,
    };
    resizeData.direction = dragDirection;

    let transformationTimeBlock = this.timeBlockDragResizeControllerService.TransformationTimeBlock;
    const visibleMouseLaneIndex = this.calendarMouseHandlerService.calcVisibleMouseLaneIndex(
      calendarMousePos.x,
      null,
      false,
    );
    this.resizeRowOrLaneSwitchService.handleLaneSwitch(
      visibleMouseLaneIndex,
      transformationTimeBlock,
    );
    resizeData.dragPart = this.timeBlockDragResizeControllerService.DragPart;

    const geometryData = transformationTimeBlock.timeBlockModel.timeBlockViewType.geometryData;
    if (geometryData.height < 0) {
      throw new Error(`Invalid height: ${geometryData.height}`);
    }

    // Re-initialize dragging block to get the updated time block values.
    transformationTimeBlock = this.timeBlockDragResizeControllerService.TransformationTimeBlock;
    if (
      !this.resizeCalculatorService.passedVerticalBound(
        resizeData,
        transformationTimeBlock.timeBlockModel,
      )
    ) {
      return;
    }

    this.collisionHandlerService.checkVerticalIntersections(transformationTimeBlock, resizeData);
    this.collisionHandlerService.checkDragEdgeSwitch(
      resizeData,
      transformationTimeBlock.timeBlockModel,
    );

    // Prevent downward resizing of active time block.
    if (
      transformationTimeBlock.timeBlockModel.isActive &&
      (CollisionHandlerService.collisionType === IntersectionType.intersectCalendarBottomEdge ||
        CollisionHandlerService.collisionType === IntersectionType.intersectSelf ||
        CollisionHandlerService.collisionType === IntersectionType.intersectWithDragBottomEdge)
    ) {
      this.restoreWhenSelfHit(geometryData, resizeData);
      this.updateTimeBlockSchedule();
      return;
    }

    // Check collision types
    this.checkCollisionTypes(resizeData);
  }

  public beforeStartResizing(dragPart: DragPart): void {
    const mouseCalendarPosY =
      this.calendarMouseHandlerService.MouseClickPositionWrapper.mouseCalendarPosition.y;
    const tbModel =
      this.timeBlockDragResizeControllerService.TransformationTimeBlock.timeBlockModel;

    if (dragPart === DragPart.Top) {
      this.cursorDeviation = tbModel.timeBlockViewType.geometryData.top - mouseCalendarPosY;
    } else if (dragPart === DragPart.Bottom) {
      const geometryData = tbModel.timeBlockViewType.geometryData;
      this.cursorDeviation = geometryData.calendarTopEdgeToTimeBlockBottomEdge - mouseCalendarPosY;
    }

    this.timeBlockDragResizeControllerService.DragPart = dragPart;
    this.timeBlockDragResizeControllerService.addCSSClasses(
      dragPart,
      this.timeBlockDragResizeControllerService.TransformationTimeBlock,
    );

    const timeBlockViewType = tbModel.timeBlockViewType as TimeBlockDayOrWeekInnerdayType;
    this.resizeRowOrLaneSwitchService.lastVisibleMouseLaneIndex =
      CalendarServiceHelper.toVisibleLaneIndex(
        this.calendarService.model,
        timeBlockViewType.laneIndex,
      );
  }

  protected updateTimeBlockSchedule(): void {
    this.resizeTimeBlockScheduleCalculatorService.calculateSchedule();
    this.calendarService.emitCalendarChange(
      this.calendarService.model,
      CalendarEvents.ReplacedTimeBlock,
      this.timeBlockDragResizeControllerService.TransformationTimeBlock,
    );
  }

  private restoreTimeBlockGeometry(clonedResizeData: ResizeData): void {
    const geometryData =
      this.timeBlockDragResizeControllerService.TransformationTimeBlock.timeBlockModel
        .timeBlockViewType.geometryData;

    if (CollisionHandlerService.collisionType === IntersectionType.intersectSelf) {
      this.restoreWhenSelfHit(geometryData, clonedResizeData);
    } else if (
      CollisionHandlerService.collisionType === IntersectionType.intersectCalendarTopEdge
    ) {
      geometryData.top = 0;
      geometryData.height = this.calendarBodyHeight - geometryData.bottom;
      geometryData.calendarBottomEdgeToTimeBlockTopEdge = this.calendarBodyHeight;
    } else if (
      CollisionHandlerService.collisionType === IntersectionType.intersectCalendarBottomEdge
    ) {
      geometryData.bottom = 0;
      geometryData.height = this.calendarBodyHeight - geometryData.top;
      geometryData.calendarTopEdgeToTimeBlockBottomEdge = this.calendarBodyHeight;
    }

    if (geometryData.height <= 0) {
      throw new Error('Height is null.');
    }
  }

  private restoreWhenSelfHit(
    additionalData: TimeBlockGeometryData,
    clonedResizeData: ResizeData,
  ): void {
    if (clonedResizeData.dragPart === DragPart.Top) {
      additionalData.top =
        additionalData.calendarTopEdgeToTimeBlockBottomEdge - this.minimumTimeBlockHeight;
      additionalData.calendarBottomEdgeToTimeBlockTopEdge =
        additionalData.bottom + this.minimumTimeBlockHeight;
    } else {
      additionalData.bottom =
        additionalData.calendarBottomEdgeToTimeBlockTopEdge - this.minimumTimeBlockHeight;
      additionalData.calendarTopEdgeToTimeBlockBottomEdge =
        additionalData.top + this.minimumTimeBlockHeight;
    }

    if (additionalData.bottom < 0 || additionalData.top < 0) {
      throw new Error('Invalid top or bottom value.');
    }

    const geometryData =
      this.timeBlockDragResizeControllerService.TransformationTimeBlock.timeBlockModel
        .timeBlockViewType.geometryData;
    geometryData.height = this.minimumTimeBlockHeight;
  }

  private initEvents(): void {
    this.calendarService.calendarViewInitialized$
      .pipe(
        filter((initialized) => initialized),
        switchMap(() => {
          return combineLatest([
            this.timeBlockService.getMinimumBlockHeight(),
            this.timeCoordinateMappingService.pixelsPerMinuteCalculated$.pipe(
              filter((ppm) => !!ppm),
            ),
          ]);
        }),
        map((response) => response),
      )
      .subscribe(([minBlockHeight, pixelsPerMinute]) => {
        const model = this.calendarService.model as DayOrWeekCalendarModel;

        // The following stuff is only needed for the day or week view.
        if (
          !minBlockHeight ||
          !pixelsPerMinute ||
          model.calendarViewMode === CalendarView.MonthGrid
        ) {
          return;
        }
        const minimumBlockHeight = minBlockHeight;
        this.snapDurationInPixels =
          model.calendarProperties.timeBlockDraggingDuration * pixelsPerMinute;
        this.calendarBodyHeight = model.geometryData.calendarBodyHeight;
        this.collisionHandlerService.CalendarBodyWidth = model.geometryData.calendarBodyWidth;
        this.collisionHandlerService.CalendarBodyHeight = this.calendarBodyHeight;
        this.collisionHandlerService.MinimumBlockHeight = minimumBlockHeight;
        this.minimumTimeBlockHeight = minimumBlockHeight;
      });
  }

  private checkCollisionTypes(resizeData: ResizeData) {
    if (
      CollisionHandlerService.collisionType === IntersectionType.intersectCalendarTopEdge ||
      CollisionHandlerService.collisionType === IntersectionType.intersectCalendarBottomEdge ||
      CollisionHandlerService.collisionType === IntersectionType.intersectSelf
    ) {
      // If there is a collision with a calendar edge or a drag handler, reset time block geometry.
      this.restoreTimeBlockGeometry(resizeData);
      this.updateTimeBlockSchedule();
    } else if (
      CollisionHandlerService.collisionType === IntersectionType.intersectWithDragTopEdge ||
      CollisionHandlerService.collisionType === IntersectionType.intersectWithDragBottomEdge
    ) {
      // After the time block geometry was restored in the upper if statement, flip drag edges.
      this.dragHandlerService.flipVerticalDragEdgeSelf(this.minimumTimeBlockHeight);
      // No need to call updateTimeBlockSchedule() here since we update start and end times in the handleVerticalDragEdgeSwitch() function.
    } else {
      this.timeBlockGeometryService.calculateVerticalResizeBlockGeometry(
        resizeData,
        this.snapDurationInPixels,
        this.calendarBodyHeight,
      );
      this.updateTimeBlockSchedule();
    }
  }
}

export class ResizeData {
  public dragPart = DragPart.None;
  public mousePos: Position = {
    x: 0,
    y: 0,
  };
  public dragEdgePos: Position = {
    x: 0,
    y: 0,
  };
  public direction: DragDirection = {
    horizontal: CardinalPoints.None,
    vertical: CardinalPoints.None,
  };
}
