import { Injectable } from '@angular/core';
import { TimeEntryDialogComponent } from '../../../dialogs/time-entry-dialog/time-entry-dialog.component';
import { TimeBlockElementSelectorService } from '../rendering/time-block-element-selector.service';
import { UserOperation } from '../../../../../core/enums/user-operation';
import { TimeBlockType } from '../../../../../shared/data-types/time-block-types';
import { ShiveDialogService } from '../../../../../core/services/controls/shive-dialog.service';
import { TimeBlockCrudService } from '../crud/time-block-crud.service';
import { ITimeBlockComponentItem } from '../time-block-component-items';
import { CustomError } from '../../../../../shared/data-types/client-error';

/**
 * This services
 */
@Injectable()
export class TimeBlockDialogService {
  constructor(
    private readonly shiveDialogService: ShiveDialogService,
    private readonly timeBlockCrudService: TimeBlockCrudService,
  ) {}

  public openNewTimeBlockDialog(newTimeBlock: ITimeBlockComponentItem): void {
    const ref = this.shiveDialogService.open({
      cssClass: 'time-entry-dialog-wrapper opacity-0',
      content: TimeEntryDialogComponent,
    });

    if (!newTimeBlock) {
      throw new CustomError('Error', 'Time block is not set.');
    }

    const timeEntryDialogComponent = ref.content.instance as TimeEntryDialogComponent;
    timeEntryDialogComponent.selectedTimeBlockComponent = newTimeBlock;
    timeEntryDialogComponent.selectedHTMLElementBlock =
      TimeBlockElementSelectorService.getTimeBlockHTMLWrapper(newTimeBlock);
    timeEntryDialogComponent.dialogRef = ref;

    ref.result.subscribe((userOperation) => {
      // If the user cancels the creation of the time block, remove the dummy block from the calendar model.
      if (userOperation === UserOperation.Cancel) {
        this.timeBlockCrudService.removeTimeBlock(newTimeBlock, TimeBlockType.NonExistingBlock);
      }
    });
  }

  public openExistingTimeBlockDialog(
    selectedHTMLElementBlock: HTMLElement,
    selectedTimeBlockComponent: ITimeBlockComponentItem,
  ): void {
    const ref = this.shiveDialogService.open({
      cssClass: 'time-entry-dialog-wrapper tab-strip opacity-0',
      content: TimeEntryDialogComponent,
    });

    const timeEntryDialogComponent = ref.content.instance as TimeEntryDialogComponent;
    timeEntryDialogComponent.selectedHTMLElementBlock = selectedHTMLElementBlock;
    timeEntryDialogComponent.selectedTimeBlockComponent = selectedTimeBlockComponent;
    timeEntryDialogComponent.dialogRef = ref;
  }
}
