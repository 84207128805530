import { createAction, props } from '@ngrx/store';
import {
  TimeBlockDeletedResponse,
  TimeBlockModifiedResponse,
} from '../../../shared/data-types/http-response-types';
import { HttpErrorType } from '../../../shared/data-types/http-error-type';
import { ITimeBlockComponentItem } from '../../../features/calendar/components/time-block/time-block-component-items';
import { undoable } from '../undo-redo/undo-redo.actions';
import { UndoRedoMeta } from '../../../shared/data-types/undo-redo-types';
import { TimeBlockFetchData } from '../../../shared/data-types/time-block-types';

export const fetchTimeBlockList = createAction(
  '[Time Blocks] Fetch Timeblocks',
  props<{
    payload: TimeBlockFetchData;
  }>(),
);
// This action should only be called once during loading process, otherwise undo/redo functionality might break.
export const setInitialTimeBlocks = undoable(
  createAction(
    '[Time Blocks] Set TimeBlocks',
    props<{
      payload: readonly ITimeBlockComponentItem[];
      storeInHistory: boolean;
    }>(),
  ),
);

export const addTimeBlock = createAction(
  '[Time Blocks] Add TimeBlock',
  props<{ payload: ITimeBlockComponentItem }>(),
);
export const timeBlockAdded = createAction(
  '[Time Blocks] TimeBlock added',
  props<{ payload: TimeBlockModifiedResponse }>(),
);

export const updateTimeBlock = undoable(
  createAction(
    '[Time Blocks] Update TimeBlock',
    props<{
      payload: ITimeBlockComponentItem;
      storeInHistory: boolean;
      undoRedoMeta: UndoRedoMeta;
    }>(),
  ),
);
export const timeBlockUpdated = createAction(
  '[Time Blocks] TimeBlock updated',
  props<{ payload: TimeBlockModifiedResponse }>(),
);

export const deleteTimeBlock = createAction(
  '[Time Blocks] Delete TimeBlock',
  props<{ payload: number }>(),
);
export const timeBlockDeleted = createAction(
  '[Time Blocks] TimeBlock deleted',
  props<{ payload: TimeBlockDeletedResponse }>(),
);

export const purgeTimeBlockStore = createAction('[Time Blocks] Purge TimeBlocks');

export const httpFail = createAction(
  '[Time Blocks] Http fail',
  props<{ payload: HttpErrorType }>(),
);
