import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { TimeBlockContainerDirective } from '../time-block-container.directive';
import { filter } from 'rxjs/operators';
import { CalendarService } from '../../services/calendar.service';
import { TimeBlockCrudService } from '../../components/time-block/crud/time-block-crud.service';
import { TimeCoordinateMappingService } from '../../time-mapping/time-coordinate-mapping.service';
import { MonthViewHandler } from '../handlers/month-view-handler';
import { CalendarEvents, CalendarView } from '../../../../shared/data-types/calendar-types';
import { CalendarScrollbarService } from '../../services/calendar-scrollbar.service';
import { MonthCalendarModel } from '../../../../core/models/calendar/month-calendar.model';
import { TimeBlockHookContainer } from '../../../../shared/data-types/time-block-types';
import { SidebarService } from '../../../../core/services/ui/sidebar.service';
import { ParallelTimeBlockGeometryCalculatorService } from '../../components/time-block/calculation/geometry/parallel-time-block-geometry-calculator.service';
import { TimeBlockInjectable } from '../time-block-injectable';
import { TimeBlockDurationCalculationService } from '../../components/time-block/calculation/duration/time-block-duration-calculation.service';
import { BaseCalendarModel } from '../../../../core/models/calendar/base-calendar.model';
import { CalendarServiceHelper } from '../../services/calendar-service-helper';
import { CalendarViewHandlerService } from '../../services/calendar-view-handler.service';
import { TimeBlockMonthViewContainerRendererService } from '../../components/time-block/rendering/time-block-month-view-container-renderer.service';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-month-view',
  templateUrl: './month-view.component.html',
  styleUrls: ['./month-view.component.scss'],
})
export class MonthViewComponent
  extends MonthViewHandler
  implements TimeBlockInjectable, OnInit, AfterViewInit, OnDestroy
{
  // A lane is a table cell in the month view.
  @ViewChildren(TimeBlockContainerDirective)
  public timeLanePlaceholders!: QueryList<TimeBlockContainerDirective>;
  // Calendar body
  @ViewChild('htmlItemContainer') public htmlItemContainer: ElementRef<HTMLElement>;
  @ViewChildren('bucket', { read: ElementRef }) public buckets: QueryList<ElementRef>;
  public accumulatedTimeMap = new Map<string, number>();
  // A lane is a table cell in the month view.
  public CalendarView = CalendarView;
  public readonly faPlus = faPlus;

  constructor(
    public calendarService: CalendarService,
    public timeBlockContainerRendererService: TimeBlockMonthViewContainerRendererService,
    private readonly calendarViewHandlerService: CalendarViewHandlerService,
    private readonly timeBlockCrudService: TimeBlockCrudService,
    private readonly timeCoordinateMappingService: TimeCoordinateMappingService,
    private readonly calendarScrollbarService: CalendarScrollbarService,
    private readonly parallelTimeBlockGeometryCalculatorService: ParallelTimeBlockGeometryCalculatorService,
    private readonly timeBlockDurationCalculationService: TimeBlockDurationCalculationService,
    private readonly cd: ChangeDetectorRef,
    private readonly sidebarService: SidebarService,
  ) {
    super(
      calendarService,
      timeBlockCrudService,
      timeCoordinateMappingService,
      sidebarService,
      calendarViewHandlerService,
    );
  }

  ngOnInit(): void {
    this.initViewportResize();
  }

  ngAfterViewInit(): void {
    this.cd.detach();

    window.setTimeout(() => {
      this.initCalendarView();
    });

    const calendarModel = new MonthCalendarModel();
    Object.assign(calendarModel, this.calendarService.model);

    this.calendarService.emitCalendarChange(calendarModel, CalendarEvents.InitializedCalendarView);

    this.subs.sink = this.timeBlockCrudService.timeBlocksFetched$
      .pipe(filter((timeBlocks) => timeBlocks !== null))
      .subscribe(([fulldayTimeBlockMap, innerdayTimeBlockMap]) => {
        this.cd.detectChanges();
        this.setModelTemplateData();

        const model = this.calendarService.model as MonthCalendarModel;
        this.insertTimeBlocksIntoView(
          fulldayTimeBlockMap,
          model.fulldayCalendarModel.timeBlocks,
          innerdayTimeBlockMap,
          model.timeBlocks,
        );

        // Set the ordering for full day time blocks and calendar body time blocks.
        this.parallelTimeBlockGeometryCalculatorService.updateParallelTimeBlockGeometries(null);
        this.calendarService.emitCalendarChange(model, CalendarEvents.RenderedTimeBlocks);
        // Hide vertical overflowing time blocks.
        this.timeBlockContainerRendererService.collapseMonthViewOverflowingTimeBlocks();
        // Calculate time block duration
        this.timeBlockDurationCalculationService.calculateTimeBlockDurationSums$.next(null);
      });

    this.checkIfOvernight();
    const targetEvents = [CalendarEvents.CalculatedWorkingTimeDurations];
    const callback = (
      _: BaseCalendarModel,
      calendarEvents: CalendarEvents[],
      meta: unknown,
    ): void => {
      if (calendarEvents.includes(CalendarEvents.CalculatedWorkingTimeDurations)) {
        this.accumulatedTimeMap = meta as Map<string, number>;
        this.cd.detectChanges();
      }
    };

    this.subs.sink = CalendarServiceHelper.calendarModelUpdated(
      this.calendarService,
      callback,
      targetEvents,
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.destroy();
  }

  protected setModelTemplateData(): void {
    const updatedMonthCalendarModel = this.calendarService.model as MonthCalendarModel;
    updatedMonthCalendarModel.timeBlockContainers = this.timeLanePlaceholders.map((e) => {
      e.viewContainerRef.clear();
      const hookContainer: TimeBlockHookContainer = {
        vcr: e.viewContainerRef,
        cd: e.changeDetectorRef,
      };
      return hookContainer;
    });
    updatedMonthCalendarModel.timeBlockHtmlContainers = this.buckets.toArray();
    // Init full day month calendar model
    updatedMonthCalendarModel.fulldayCalendarModel.timeBlockContainers =
      updatedMonthCalendarModel.timeBlockContainers;
    updatedMonthCalendarModel.fulldayCalendarModel.timeBlockHtmlContainers =
      updatedMonthCalendarModel.timeBlockHtmlContainers;
    // Emit calendar model
    this.calendarService.emitCalendarChange(updatedMonthCalendarModel, []);
  }

  protected initCalendarView(): void {
    if (!this.initialized || this.viewportSizeChanged) {
      this.cd.detectChanges();
      this.updateCalendarDimensions(
        this.htmlItemContainer,
        this.calendarScrollbarService.calendarBodyScrollbar.scrollData.scrollPosY,
      );
    }
  }

  private initViewportResize(): void {
    this.subs.sink = this.calendarService.calendarViewportResized$.subscribe(() => {
      this.timeBlockContainerRendererService.collapseMonthViewOverflowingTimeBlocks();
    });
  }
}
