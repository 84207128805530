import { Component, OnDestroy, inject } from '@angular/core';
import { ProjectService } from './services/project.service';

@Component({
  selector: 'app-projects',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})
export class ProjectComponent implements OnDestroy {
  private readonly projectService = inject(ProjectService);

  ngOnDestroy(): void {
    this.projectService.resetProjectStore();
  }
}
