import { Injectable } from '@angular/core';
import { Position } from '../../../../../../../../shared/data-structures/position';
import { CardinalPoints, DragDirection, DragPart } from '../drag-part';
import { ITimeBlockComponentItem } from '../../../../time-block-component-items';
import { TimeBlockItemBuilderService } from '../../../../generation/time-block-item-builder.service';
import Autobind from '../../../../../../../../shared/typescript-decorators/autobind.decorator';
import { TimeBlockRenderService } from '../../../../rendering/time-block-render.service';
import { TimeBlockElementSelectorService } from '../../../../rendering/time-block-element-selector.service';
import { DragOrResizeInteraction } from './time-block-drag-resize.service';
import { CalendarService } from '../../../../../../services/calendar.service';
import { TimeBlockCrudService } from '../../../../crud/time-block-crud.service';
import { HorizontalAllResizeControllerService } from '../resizing/resize-view-controller/horizontal-all-resize-controller.service';
import { VerticalDayOrWeekResizeControllerService } from '../resizing/resize-view-controller/vertical-day-or-week-resize-controller.service';
import {
  IDragTimeBlock,
  TimeBlockType,
} from '../../../../../../../../shared/data-types/time-block-types';
import { TimeBlockStructureService } from '../../../../time-block-structure/time-block-structure.service';
import {
  CSSActiveClass,
  CSSDisplayTimeBlockDuration,
  CSSGhostClass,
  CSSNewClass,
  CSSPressedClass,
  CSSResizedClass,
} from '../../../../../../../../core/data-repository/css-constants';
import { CalendarServiceHelper } from '../../../../../../services/calendar-service-helper';
import { TimeBlockDayOrWeekInnerdayType } from '../../../../../../../../core/models/timeblock/time-block-view-type.model';
import { CssAttributes } from '../../../../rendering/css-attributes';
import { CalendarGeometryService } from '../../../../../../services/calendar-geometry.service';

// Helper services for storing variable data and do some calculation.
@Injectable()
export class TimeBlockDragResizeControllerService {
  public currentDragResizeInteraction = DragOrResizeInteraction.None;
  public dragOrResizeActionInProgress = false;
  public lastWorldMousePos: Position = {
    x: 0,
    y: 0,
  };
  public resizeControllerService:
    | HorizontalAllResizeControllerService
    | VerticalDayOrWeekResizeControllerService;
  public dragControllerService: IDragTimeBlock;

  private draggingOrResizingTimeBlock: ITimeBlockComponentItem;
  private dragPart = DragPart.None;
  private ghostTimeBlock: ITimeBlockComponentItem;

  constructor(
    public calendarService: CalendarService,
    private readonly calendarGeometryService: CalendarGeometryService,
    private readonly timeBlockItemBuilderService: TimeBlockItemBuilderService,
    private readonly timeBlockStructureService: TimeBlockStructureService,
    private readonly timeBlockCrudService: TimeBlockCrudService,
    private readonly timeBlockRenderService: TimeBlockRenderService,
  ) {}

  public get DragPart(): DragPart {
    return this.dragPart;
  }

  public set DragPart(dragPart: DragPart) {
    this.dragPart = dragPart;
  }

  /**
   * The current transformed time block part.
   */
  public get TransformationTimeBlock(): ITimeBlockComponentItem {
    return this.draggingOrResizingTimeBlock;
  }

  /**
   * @param timeBlock Store the time block (part) of the dragging time block.
   */
  public set TransformationTimeBlock(timeBlock: ITimeBlockComponentItem) {
    this.draggingOrResizingTimeBlock = timeBlock?.clone(this.timeBlockItemBuilderService);
  }

  public get GhostTimeBlock(): ITimeBlockComponentItem {
    return this.ghostTimeBlock;
  }

  /**
   * @param timeBlock Store the time block (part) of the ghost time block.
   */
  public set GhostTimeBlock(timeBlock: ITimeBlockComponentItem) {
    this.ghostTimeBlock = timeBlock;
  }

  public isResizeAction(
    currentDraggedBlock: number,
    interaction: DragOrResizeInteraction,
  ): boolean {
    return (
      typeof this.TransformationTimeBlock !== 'undefined' &&
      currentDraggedBlock === this.TransformationTimeBlock.timeBlockModel.id &&
      interaction === DragOrResizeInteraction.Resize
    );
  }

  public isDragAction(currentResizedBlock: number, interaction: DragOrResizeInteraction): boolean {
    return (
      typeof this.TransformationTimeBlock !== 'undefined' &&
      currentResizedBlock === this.TransformationTimeBlock.timeBlockModel.id &&
      interaction === DragOrResizeInteraction.Drag
    );
  }

  public getResizeDirection(
    mouseViewportPosition: Position,
    mouseCalendarPosition: Position,
    curPos: Position,
  ): DragDirection {
    const dragDirection: DragDirection = {
      horizontal: CardinalPoints.None,
      vertical: CardinalPoints.None,
    };

    // If cursor is outside of calendar, set the drag direction so that the block will be fully resized to the calendar's edges.
    if (
      mouseCalendarPosition.y < 0 ||
      mouseCalendarPosition.y >= this.calendarService.model.geometryData.calendarBodyHeight
    ) {
      dragDirection.vertical =
        mouseCalendarPosition.y < 0 ? CardinalPoints.North : CardinalPoints.South;
      return dragDirection;
    }

    const oldPos = mouseViewportPosition;

    if (curPos.y) {
      if (curPos.y < oldPos.y) {
        dragDirection.vertical = CardinalPoints.South;
      } else if (curPos.y > oldPos.y) {
        dragDirection.vertical = CardinalPoints.North;
      }
    }

    return dragDirection;
  }

  /**
   * Called in InteractHandlerService when a user starts transforming (dragging or resizing) a time block.
   * When a user starts to drag or resize a time block, the original time block from the calendar model is used as ghost.
   * So no copy of the time block is created for the ghost time block (in contrast to when creating a new time block).
   * The type is set to TimeBlockType.GhostBlock.
   * The dragging block is a clone of the calendar time block and is deleted after the drag / resize action has been finished.
   *
   * Remember: When a drag or resize action is in progress, there is one ghost time block (type = 0)
   * and one transforming block (type = 1) composed of one or multiple parts in the corresponding lane(s).
   * The term "lane" depicts a container for a time block.
   */
  @Autobind
  public buildGhostAndTransformationBlock(
    originalTimeBlock: ITimeBlockComponentItem,
  ): ITimeBlockComponentItem {
    const transformationTimeBlock = this.timeBlockItemBuilderService.buildFromExisting(
      originalTimeBlock,
      originalTimeBlock.timeBlockContentType,
    );
    this.buildTransformationTimeBlock(transformationTimeBlock, originalTimeBlock);
    this.buildGhostTimeBlock(transformationTimeBlock, originalTimeBlock);
    return this.TransformationTimeBlock;
  }

  /**
   * Add CSS classes for drag handlers and for the dragged time block parts.
   * Remark: No need to remove them afterwards since the time block
   * parts will be replaced after finishing the HTTP request.
   */
  public addCSSClasses(dragPart: DragPart, activeTimeBlock: ITimeBlockComponentItem): void {
    const resizeTimeBlockPartId = activeTimeBlock.timeBlockModel.id;
    const resizeTimeBlockPartType = activeTimeBlock.timeBlockModel.type;

    // Add CSS class for the week view inner day resizing time block part
    if (!activeTimeBlock.timeBlockModel.isFullday) {
      const partNumber =
        dragPart === DragPart.Top ? 0 : activeTimeBlock.timeBlockModel.partCount - 1;
      const resizedTimeBlockPart = this.timeBlockStructureService.retrieveTimeBlockPart(
        resizeTimeBlockPartId,
        resizeTimeBlockPartType,
        partNumber,
      ) as ITimeBlockComponentItem;

      const timeBlockHTMLWrapper = TimeBlockElementSelectorService.getTimeBlockHTMLWrapper(
        resizedTimeBlockPart,
        true,
      );

      if (timeBlockHTMLWrapper) {
        this.timeBlockRenderService.addClass(timeBlockHTMLWrapper, CSSDisplayTimeBlockDuration);
      }
    }

    // Add CSS classes for all time block parts.
    if (resizeTimeBlockPartType === TimeBlockType.TransformingBlock) {
      const draggedParts = this.timeBlockStructureService.retrieveTimeBlockPart(
        resizeTimeBlockPartId,
        resizeTimeBlockPartType,
        -1,
      ) as ITimeBlockComponentItem[];
      draggedParts.forEach((timeBlockPart) => {
        const timeBlockHTMLWrapper = TimeBlockElementSelectorService.getTimeBlockHTMLWrapper(
          timeBlockPart,
          true,
        );
        if (timeBlockHTMLWrapper) {
          this.timeBlockRenderService.addClass(timeBlockHTMLWrapper, CSSResizedClass);
          this.timeBlockRenderService.addClass(timeBlockHTMLWrapper, CSSActiveClass);
        }
      });
    } else if (resizeTimeBlockPartType === TimeBlockType.NonExistingBlock) {
      const timeBlockHTMLWrapper =
        TimeBlockElementSelectorService.getTimeBlockHTMLWrapper(activeTimeBlock);
      this.timeBlockRenderService.addClass(timeBlockHTMLWrapper, CSSNewClass);
      // For new non-existing time blocks, the CSS 'active' class needs to be appended since interact.js is not used for resizing here.
      this.timeBlockRenderService.addClass(timeBlockHTMLWrapper, CSSActiveClass);
    }

    let dragHandleHTMLElement: HTMLElement;
    if (dragPart === DragPart.Top || dragPart === DragPart.Left) {
      dragHandleHTMLElement =
        TimeBlockElementSelectorService.getTimeBlockFirstHandle(activeTimeBlock);
    } else {
      dragHandleHTMLElement =
        TimeBlockElementSelectorService.getTimeBlockSecondHandle(activeTimeBlock);
    }
    this.timeBlockRenderService.addClass(dragHandleHTMLElement, CSSPressedClass);
  }

  /**
   * Add appropriate styling and left offset for dragged / resized time blocks.
   */
  private setCSSLeftAttribute(
    draggingPart: ITimeBlockComponentItem,
    htmlWrapper: HTMLElement,
  ): void {
    const timeBlockViewType = draggingPart.timeBlockModel
      .timeBlockViewType as TimeBlockDayOrWeekInnerdayType;
    const isInsideOfView = CalendarServiceHelper.isInsideOfVisibleCalendarView(
      this.calendarService.model,
      timeBlockViewType.laneIndex,
    );

    if (!isInsideOfView) {
      let left: number;
      if (
        timeBlockViewType.laneIndex <
        CalendarServiceHelper.getFirstVisibleLaneIndex(this.calendarService.model)
      ) {
        // Time block part is left of the visible calendar area
        left = draggingPart.timeBlockModel.timeBlockViewType.geometryData.left;
      } else {
        // Time block part is right of the visible calendar area
        const right = draggingPart.timeBlockModel.timeBlockViewType.geometryData.right;
        left = right - this.calendarGeometryService.getDayOrWeekInnerdayLaneWidth();
      }

      this.timeBlockRenderService.setStyle(htmlWrapper, CssAttributes.left, `${left}px`);
    } else {
      this.timeBlockRenderService.addClass(htmlWrapper, 'start-0');
    }
  }

  private buildTransformationTimeBlock(
    transformationTimeBlock: ITimeBlockComponentItem,
    originalTimeBlock: ITimeBlockComponentItem,
  ) {
    transformationTimeBlock.timeBlockModel.type = TimeBlockType.TransformingBlock;
    // Reset the component ref since it will be rebuilt next.
    transformationTimeBlock.timeBlockModel.componentRef = null;

    this.timeBlockCrudService.insertTimeBlock(transformationTimeBlock);

    const draggingTimeBlockParts = this.timeBlockStructureService.retrieveTimeBlockPart(
      transformationTimeBlock.id,
      TimeBlockType.TransformingBlock,
      -1,
    ) as ITimeBlockComponentItem[];

    draggingTimeBlockParts.forEach((draggingPart) => {
      // ///// Create the transforming block
      if (
        draggingPart.id === originalTimeBlock.id &&
        draggingPart.timeBlockModel.partNumber === originalTimeBlock.timeBlockModel.partNumber
      ) {
        // Store the transforming block part
        this.TransformationTimeBlock = draggingPart;
      }

      // If this time block part is a head part and therefore has a component ref, set all necessary CSS classes
      if (!draggingPart.timeBlockModel.componentRef) {
        return;
      }

      const htmlWrapper = TimeBlockElementSelectorService.getTimeBlockHTMLWrapper(draggingPart);

      if (draggingPart.timeBlockModel.isFullday) {
        this.timeBlockRenderService.addClass(htmlWrapper, 'start-0');
      } else {
        this.setCSSLeftAttribute(draggingPart, htmlWrapper);
      }

      this.timeBlockRenderService.addClass(htmlWrapper, CSSActiveClass);
    });
  }

  private buildGhostTimeBlock(
    transformationTimeBlock: ITimeBlockComponentItem,
    originalTimeBlock: ITimeBlockComponentItem,
  ) {
    const existingBlockParts = this.timeBlockStructureService.retrieveTimeBlockPart(
      transformationTimeBlock.id,
      TimeBlockType.ExistingBlock,
      -1,
    ) as ITimeBlockComponentItem[];

    existingBlockParts.forEach((existingPart) => {
      // ///// Mark the original time block as a ghost
      existingPart.timeBlockModel.type = TimeBlockType.GhostBlock;
      if (existingPart.timeBlockModel.componentRef) {
        existingPart.timeBlockModel.componentRef.instance.timeBlockComponentItem.timeBlockModel.type =
          TimeBlockType.GhostBlock;
        this.timeBlockRenderService.addClass(
          TimeBlockElementSelectorService.getTimeBlockHTMLWrapper(existingPart),
          CSSGhostClass,
        );
      }

      if (
        existingPart.id === originalTimeBlock.id &&
        existingPart.timeBlockModel.partNumber === originalTimeBlock.timeBlockModel.partNumber
      ) {
        // Store the ghost block part
        this.GhostTimeBlock = existingPart;
      }
    });
  }
}
