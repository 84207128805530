import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ShiveNotificationService } from '../../../services/controls/shive-notification.service';
import * as fromHolidayTemplatesActions from '../../settings/holiday-templates/holiday-templates.actions';
import { HolidayTemplateModel } from '../../../models/settings/holiday/holiday-template.model';

@Injectable({
  providedIn: 'root',
})
export class HolidayTemplatesHttpService {
  constructor(
    private readonly store: Store,
    private readonly shiveNotificationService: ShiveNotificationService,
    private readonly actions$: Actions,
  ) {}

  public getHolidayTemplates(): Observable<readonly HolidayTemplateModel[]> {
    this.store.dispatch(fromHolidayTemplatesActions.fetchHolidayTemplates());

    return this.actions$.pipe(
      ofType(fromHolidayTemplatesActions.setHolidayTemplates),
      map((result) => result.payload),
    );
  }

  public getHolidayTemplate(templateId: number): Observable<HolidayTemplateModel> {
    this.store.dispatch(
      fromHolidayTemplatesActions.fetchHolidayTemplate({
        payload: templateId,
      }),
    );

    return this.actions$.pipe(
      ofType(fromHolidayTemplatesActions.setHolidayTemplate),
      map((result) => result.payload),
    );
  }

  public addHolidayTemplate(holidayTemplateModel: HolidayTemplateModel): Observable<unknown> {
    this.store.dispatch(
      fromHolidayTemplatesActions.addHolidayTemplate({
        payload: holidayTemplateModel,
      }),
    );

    return this.actions$.pipe(ofType(fromHolidayTemplatesActions.holidayTemplateAdded));
  }

  public updateHolidayTemplate(holidayTemplateModel: HolidayTemplateModel): Observable<unknown> {
    this.store.dispatch(
      fromHolidayTemplatesActions.updateHolidayTemplate({
        payload: holidayTemplateModel,
      }),
    );

    return this.actions$.pipe(ofType(fromHolidayTemplatesActions.holidayTemplateUpdated));
  }

  public deleteHolidayTemplate(holidayTemplateModel: HolidayTemplateModel): Observable<unknown> {
    this.store.dispatch(
      fromHolidayTemplatesActions.deleteHolidayTemplate({
        payload: holidayTemplateModel,
      }),
    );

    return this.actions$.pipe(ofType(fromHolidayTemplatesActions.holidayTemplateDeleted));
  }
}
