import { Injectable } from '@angular/core';
import { AbsenceReasonsHttpService } from '../../../../core/state/settings/absence-reasons/absence-reasons-http.service';
import { AbsenceReasonsStore } from '../state-and-data-handling/absence-reasons.store';
import { take } from 'rxjs/operators';
import { AbsenceReasonModel } from '../../../../core/models/absence-reason/absence-reason.model';

@Injectable({
  providedIn: 'root',
})
export class AbsenceReasonSettingsService {
  constructor(
    public absenceReasonsStore: AbsenceReasonsStore,
    private readonly absenceReasonsHttpService: AbsenceReasonsHttpService,
  ) {}

  public emitAbsenceReasonsHttpRequests(): void {
    this.absenceReasonsStore.selectAbsenceReasons$.pipe(take(1)).subscribe((absenceReasons) => {
      this.sendPostOrPutRequests(absenceReasons);
      this.sendDeleteRequests();
    });
  }

  private sendPostOrPutRequests(absenceReasons: AbsenceReasonModel[]) {
    absenceReasons.forEach((absenceReason) => {
      if (absenceReason.id < 0) {
        this.absenceReasonsHttpService.addAbsenceReason(absenceReason);
        // Remove dummy absence reason from store and add the real one after the POST request.
        this.absenceReasonsStore.removeAbsenceReason(absenceReason);
      } else {
        this.absenceReasonsHttpService.updateAbsenceReason(absenceReason);
      }
    });
  }

  private sendDeleteRequests() {
    const deletionList = this.absenceReasonsStore.absenceReasonDeletionList;
    deletionList.forEach((absenceReasonId) => {
      this.absenceReasonsHttpService.deleteAbsenceReason(absenceReasonId);
    });
    this.absenceReasonsStore.absenceReasonDeletionList = [];
  }
}
