import { ElementRef, Injectable, QueryList } from '@angular/core';
import { TimeBlockRenderService } from './rendering/time-block-render.service';
import { SubSink } from 'subsink';
import { Observable } from 'rxjs';
import { CalendarService } from '../../services/calendar.service';
import { TimeCoordinateMappingService } from '../../time-mapping/time-coordinate-mapping.service';
import { CalendarEvents } from '../../../../shared/data-types/calendar-types';
import { filter, map } from 'rxjs/operators';
import { ITimeBlockComponentItem } from './time-block-component-items';
import { CalendarServiceHelper } from '../../services/calendar-service-helper';
import {
  TimeBlockContentType,
  TimeBlockType,
} from '../../../../shared/data-types/time-block-types';
import { DateTimeHelper } from '../../util/date-time-helper';
import { TimeBlockDurationCalculationService } from './calculation/duration/time-block-duration-calculation.service';
import { TimeBlockAbsenceModel } from '../../../../core/models/timeblock/time-block.model';
import { makeSafeForCSS } from '../../../../shared/functions/string-functions';
import { CSSHasNextPart, CSSHasPrevPart } from '../../../../core/data-repository/css-constants';

@Injectable()
export class TimeBlockService {
  private readonly subs = new SubSink();

  constructor(
    private readonly calendarService: CalendarService,
    private readonly timeBlockDurationCalculationService: TimeBlockDurationCalculationService,
    private readonly timeBlockRenderService: TimeBlockRenderService,
    private readonly timeCoordinateMapperService: TimeCoordinateMappingService,
  ) {
    this.initEvents();
  }

  public getMinimumBlockHeight(): Observable<number> {
    return this.timeCoordinateMapperService.pixelsPerMinuteCalculated$.pipe(
      filter((ppm) => !!ppm),
      map((ppm) => {
        const minimumTimeBlockDuration =
          this.calendarService.model.calendarProperties.minimumTimeBlockDuration;
        if (ppm && minimumTimeBlockDuration) {
          return ppm * minimumTimeBlockDuration;
        }
        return null;
      }),
    );
  }

  public configureFulldayOverflowIndicators(
    timeBlockComponentItem: ITimeBlockComponentItem,
    overflowIndicators: QueryList<ElementRef<HTMLElement>>,
  ): void {
    const timeBlockModel = timeBlockComponentItem.timeBlockModel;
    if (
      timeBlockModel.partNumber === 0 &&
      timeBlockModel.isFullday &&
      timeBlockModel.type === TimeBlockType.ExistingBlock
    ) {
      const calendarModel =
        this.calendarService.getCalendarModelByTimeBlockType(timeBlockComponentItem);
      if (
        DateTimeHelper.isBefore(
          timeBlockComponentItem.timeBlockModel.start,
          calendarModel.calendarProperties.visibleStartDate,
        )
      ) {
        const leftIndicatorHTMLEl = overflowIndicators.first.nativeElement;
        this.timeBlockRenderService.removeClass(leftIndicatorHTMLEl, 'k-d-none');
      }

      if (
        DateTimeHelper.isAfter(
          timeBlockComponentItem.timeBlockModel.end,
          calendarModel.calendarProperties.visibleEndDate,
        )
      ) {
        const rightIndicatorHTMLEl = overflowIndicators.last.nativeElement;
        this.timeBlockRenderService.removeClass(rightIndicatorHTMLEl, 'k-d-none');
      }
    }
  }

  public isTimeBlockOutsideOfView(timeBlockComponentItem: ITimeBlockComponentItem): boolean {
    const timeBlockModel = timeBlockComponentItem.timeBlockModel;
    const visibleCalendarStart = this.calendarService.model.calendarProperties.visibleStartDate;
    const visibleCalendarEnd = this.calendarService.model.calendarProperties.visibleEndDate;

    return (
      (DateTimeHelper.isBefore(timeBlockModel.start, visibleCalendarStart) &&
        DateTimeHelper.isBefore(timeBlockModel.end, visibleCalendarStart)) ||
      (DateTimeHelper.isAfter(timeBlockModel.start, visibleCalendarEnd) &&
        DateTimeHelper.isAfter(timeBlockModel.end, visibleCalendarEnd))
    );
  }

  public getTimeBlockVisiblePartCount(timeBlockComponentItem: ITimeBlockComponentItem): number {
    const visibleCalendarStart = this.calendarService.model.calendarProperties.visibleStartDate;
    const visibleCalendarEnd = this.calendarService.model.calendarProperties.visibleEndDate;

    const timeBlockModel = timeBlockComponentItem.timeBlockModel;
    const visibleTimeBlockStart = DateTimeHelper.isBefore(
      timeBlockModel.start,
      visibleCalendarStart,
    )
      ? visibleCalendarStart
      : timeBlockModel.start;
    const visibleTimeBlockEnd = DateTimeHelper.isAfter(timeBlockModel.end, visibleCalendarEnd)
      ? visibleCalendarEnd
      : timeBlockModel.end;

    return DateTimeHelper.differenceInDays(visibleTimeBlockEnd, visibleTimeBlockStart);
  }

  public getTimeBlockContentTypeClass(timeBlockComponentItem: ITimeBlockComponentItem): string {
    if (timeBlockComponentItem.timeBlockContentType === TimeBlockContentType.Absence) {
      const absenceReason = (timeBlockComponentItem.timeBlockModel as TimeBlockAbsenceModel).reason;
      return typeof absenceReason !== 'string' ? '' : makeSafeForCSS(absenceReason);
    }

    return '';
  }

  /**
   * Previous and next classes apply only for day and week inner day time blocks.
   */
  public setTimeBlockPrevNextCSSClasses(
    timeBlockComponentItem: ITimeBlockComponentItem,
    timeBlockHtmlWrapper: ElementRef<HTMLElement>,
  ): void {
    const partNr = timeBlockComponentItem.timeBlockModel.partNumber;
    const partCount = timeBlockComponentItem.timeBlockModel.partCount;
    if (partNr === 0) {
      this.timeBlockRenderService.removeClass(timeBlockHtmlWrapper.nativeElement, CSSHasPrevPart);
    }

    if (partNr === partCount - 1) {
      this.timeBlockRenderService.removeClass(timeBlockHtmlWrapper.nativeElement, CSSHasNextPart);
    }
  }

  private initEvents(): void {
    const targetEvents = [CalendarEvents.AddedTimeBlock, CalendarEvents.ReplacedTimeBlock];
    const callback = (_: any, _1: CalendarEvents[], timeBlock: ITimeBlockComponentItem): void => {
      if (
        timeBlock?.timeBlockModel.componentRef &&
        typeof timeBlock.timeBlockModel.timeBlockViewType.geometryData?.top !== 'undefined'
      ) {
        this.timeBlockRenderService.renderTimeBlock(timeBlock);
      }
    };
    this.subs.sink = CalendarServiceHelper.calendarModelUpdated(
      this.calendarService,
      callback,
      targetEvents,
    );

    this.timeBlockDurationCalculationService.initEvents();
  }
}
