import { createAction } from '@ngrx/store';
import { Action, ActionCreator, NotAllowedCheck } from '@ngrx/store/src/models';

// Marker constant for actions which are eligible for undo/redo operations.
export const undoable = <T extends string, P extends object>(
  creator: ActionCreator<T, (props: P & NotAllowedCheck<P>) => P & Action<T>>,
): ((props: P & NotAllowedCheck<P>) => P & Action<T>) & Action<T> => {
  const newCreator = (props?: P & NotAllowedCheck<P>) => ({
    ...creator(props),
  });

  const newCreatorEnhanced = Object.defineProperties(newCreator, {
    type: {
      value: creator.type,
      writable: false,
    },
  }) as unknown;
  return newCreatorEnhanced as ((props: P & NotAllowedCheck<P>) => P & Action<T>) & Action<T>;
};

export const undo = createAction('[Undo Redo] Undo Action');
export const redo = createAction('[Undo Redo] Redo Action');
