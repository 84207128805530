import { LogLevel } from './log-levels';

export class LogEntry {
  public date: Date = null;
  public message: string = '';
  public level: LogLevel = LogLevel.Debug;
  public extraInfo = [];

  public buildLogString(): string {
    let ret: string = '';

    ret = `${this.date} - `;
    ret += LogLevel[this.level].toUpperCase();
    ret += ` - ${this.message}`;
    if (this.extraInfo.length) {
      ret += ` - Extra Info: ${this.formatParams(this.extraInfo)}`;
    }

    return ret;
  }

  private formatParams(params): string {
    let ret: string = params.join(',');

    // Is there at least one object in the array?
    if (params.some((p) => typeof p === 'object')) {
      ret = '';

      // Build comma-delimited string
      for (const item of params) {
        ret += `${JSON.stringify(item)},`;
      }
    }

    return ret;
  }
}
