import { createAction, props } from '@ngrx/store';
import { AppConfigModel } from '../../models/app-config/app-config.model';

export const fetchAppConfigData = createAction('[AppConfig] Fetch AppConfig data');
export const setAppConfigData = createAction(
  '[AppConfig] Set AppConfig data',
  props<{ payload: AppConfigModel }>(),
);

export const httpFail = createAction('[AppConfig] Http fail', props<{ payload: string }>());
